export const AdminDashboard = 'AdminDashboard';
export const OrganizationAdminDashboard = 'OrganizationAdminDashboard';
export const TeamAdminDashboard = 'TeamAdminDashboard';
export const VerifiedAssessmentAdminDashboard = 'VerifiedAssessmentAdminDashboard';
export const VerifiedAssessmentImport = 'VerifiedAssessmentImport';
export const TeamCreateNew = 'TeamCreateNew';
export const TeamEdit = 'TeamEdit';
export const OrgCreate = 'OrgCreate';
export const OrgEdit = 'OrgEdit';
export const SchoolSearchAdminDashboard = 'SchoolSearchAdminDashboard';
export const UserAdminDashboard = 'UserAdminDashboard';
export const SchoolCreate = 'SchoolCreate';
export const SchoolEdit = 'SchoolEdit';
export const AssessmentAdd = 'AssessmentAdd';
export const Jobs = 'AdminBackendJobs';
export const FeatureFlags = 'FeatureFlags';
export const SchemaUpgrade = 'SchemaUpgrade';
export const SPQUploadAdminDashboard = 'SPQUploadAdminDashboard'

export const EventAdmin = 'EventAdmin';
export const EventDashboard = 'EventDashboard';
export const EventOrderAdmin = 'EventOrderAdmin';