
import {Component, Mixins, Watch} from 'vue-property-decorator';
import {mdiMagnify} from '@mdi/js';
import {DebounceMixin} from '@/mixins';
import api from '@/api/athlete';
import {AthleteProfileModel} from '@/models/athlete/AthleteProfileModel';

  @Component({
  	components: {}
  })
export default class AthleteSearchSuggestion extends Mixins(DebounceMixin){

    mdiMagnify = mdiMagnify;

    search: string = '';
    isSearchLoading: boolean = false;
    athletes: AthleteProfileModel[] = [];
    searchModel = null;

    async addPlayer(player) {
    	await this.$store.dispatch('athletes/loadById', player.value.id);
    	const athlete = this.$store.getters['athletes/find'](player.value.id);
    	this.searchModel = ''; // clear add player field
    	this.$emit('selected', athlete);
    }

    get searchResults () {
    	return this.athletes.map((entry: Record<'firstName' |'lastName', string>) => {
    		return {
    			text: `${entry.firstName} ${entry.lastName}`,
    			value: entry
    		}
    	})
    }

    @Watch('search')
    async onSearchUpdate (val) {
    	if (!val || val === this.searchModel) return;

    	if (this.isSearchLoading) return;

    	this.isSearchLoading = true;

    	//this.loadFilteredAthletes({s:val})

    	try {
    		this.debounceCallback(
    			'search',
    			async () => this.athletes = await api.getAll({s: val}),
    			1000,
    		);
    	} catch (err) {
    		console.error(err);
    	}
    	this.isSearchLoading = false;
    }
}
