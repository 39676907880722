/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfig } from 'vue-router';
import * as Routes from '@/../types/constants/web_client_user.routes';

import AccountSettings from '@/views/AccountSettings.vue';
import AccountSettingsPersonalInfo from '@/components/profile/settings/AccountSettingsPersonalInfo.vue';
import AccountSettingsProfilePicture from '@/components/profile/settings/AccountSettingsProfilePicture.vue';
import AccountSettingsChangePassword from '@/components/profile/settings/AccountSettingsChangePassword.vue';

export const SettingsChildRouteConfigs: RouteConfig[] = [
	{
		path: 'settings',
		component: AccountSettings,
		meta: {
			pageTitle: 'Settings',
		},
		children: [
			{
				path: '',
				name: Routes.AccountSettingsPersonalInfo,
				meta: {
					pageTitle: 'Settings',
				},
				component: AccountSettingsPersonalInfo,
			},
			{
				path: 'profile-picture',
				name: Routes.AccountSettingsProfilePicture,
				meta: {
					pageTitle: 'Settings',
				},
				component: AccountSettingsProfilePicture,
			},
			{
				path: 'change-password',
				name: Routes.AccountSettingsChangePassword,
				meta: {
					pageTitle: 'Settings',
				},
				component: AccountSettingsChangePassword,
			},
		],
	},
];