import { Component, Vue } from 'vue-property-decorator';
import { coursesStore } from '../../store/index';
import { AssignmentModel, CourseModel, StudentModel, RubricModel } from '@/models/course';

/**
 * Provides a list of courses that the aecurrent user is a student in.
 */
@Component
export class AssignmentMixin extends Vue {

	get CurrentCourseId(): string {
		return this.$route.params.courseId;
	}
	get CurrentCourse(): CourseModel | undefined {
		return coursesStore.myCourses.find(c => c.id === this.CurrentCourseId);
	}

	get CurrentStudentId(): string | undefined {
		if (this.$route.params.studentId) {
			return this.$route.params.studentId
		}
		return undefined;
	}

	get CurrentStudent(): StudentModel{
		if (this.CurrentStudentId === undefined) return new StudentModel();
		if (this.CurrentCourse === undefined) return new StudentModel();
		return this.CurrentCourse.students.find(s => s.coachProfileId == this.CurrentStudentId);
	}

	get CurrentStudentCoachProfileId(): string | null{
		if (this.CurrentStudentId === undefined) return null;
		if (this.CurrentCourse === undefined) return null;
		if (!this.CurrentStudent) return null;
		return this.CurrentStudent.coachProfileId;
	}

	get CurrentAssignmentId(): string | undefined {
		if (this.$route.params.assignmentId) {
			return this.$route.params.assignmentId
		}
		return undefined;
	}

	get CurrentAssignment(): AssignmentModel | undefined {
		if (this.CurrentAssignmentId === undefined) return undefined;
		if (this.CurrentStudent === undefined) return undefined;
		return this.CurrentStudent.assignments.find(a => a.id == this.CurrentAssignmentId);
	}
	get CurrentAssignmentName(): string | undefined {
		if(this.CurrentAssignment === undefined) return;
		return this.CurrentAssignment.name;
	}
	get CurrentAssignmentSubmittedAt(): Date | null {
		if(this.CurrentAssignment === undefined || this.CurrentAssignment.submittedAt === null) return null;
		return new Date(this.CurrentAssignment.submittedAt);
	}
	get CurrentAssignmentDescription(): string | undefined {
		if(this.CurrentAssignment === undefined) return;
		return this.CurrentAssignment.description;
	}
	get CurrentAssignmentDetails(): string | null {
		if(this.CurrentAssignment === undefined) return null;
		return this.CurrentAssignment.details;
	}

	get AssignmentRubric() : RubricModel | undefined {
		if (this.CurrentAssignment === undefined) return undefined;
		return this.CurrentAssignment.rubric;
	}

}
