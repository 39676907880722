export enum MuxEventType {
	ASSET_CREATED = 'video.asset.created',
	ASSET_READY = 'video.asset.ready',
	ASSET_ERRORED = 'video.asset.errored',
	ASSET_UPDATED = 'video.asset.updated',
	ASSET_DELETED = 'video.asset.deleted',
	ASSET_LIVE_STREAM_COMPLETED = 'video.asset.live_stream_completed',
	ASSET_STATIC_RENDITIONS_READY = 'video.asset.static_renditions.ready',
	ASSET_STATIC_RENDITIONS_PREPARING = 'video.asset.static_renditions.preparing',
	ASSET_STATIC_RENDITIONS_DELETED = 'video.asset.static_renditions.deleted',
	ASSET_STATIC_RENDITIONS_ERRORED = 'video.asset.static_renditions.errored',
	ASSET_MASTER_READY = 'video.asset.master.ready',
	ASSET_MASTER_PREPARING = 'video.asset.master.preparing',
	ASSET_MASTER_DELETED = 'video.asset.master.deleted',
	ASSET_MASTER_ERRORED = 'video.asset.master.errored',
	ASSET_TRACK_CREATED = 'video.asset.track.created',
	ASSET_TRACK_READY = 'video.asset.track.ready',
	ASSET_TRACK_ERRORED = 'video.asset.track.errored',
	ASSET_TRACK_DELETED = 'video.asset.track.deleted',
	VIDEO_UPLOAD_ASSET_CREATED = 'video.upload.asset_created',
	VIDEO_UPLOAD_CANCELLED = 'video.upload.cancelled',
	VIDEO_UPLOAD_CREATED = 'video.upload.created',
	VIDEO_UPLOAD_ERRORED = 'video.upload.errored',
	VIDEO_LIVE_STREAM_CREATED = 'video.live_stream.created',
	VIDEO_LIVE_STREAM_CONNECTED = 'video.live_stream.connected',
	VIDEO_LIVE_STREAM_RECORDING = 'video.live_stream.recording',
	VIDEO_LIVE_STREAM_ACTIVE = 'video.live_stream.active',
	VIDEO_LIVE_STREAM_DISCONNECTED = 'video.live_stream.disconnected',
	VIDEO_LIVE_STREAM_IDLE = 'video.live_stream.idle',
	VIDEO_LIVE_STREAM_UPDATED = 'video.live_stream.updated',
	VIDEO_LIVE_STREAM_ENABLED = 'video.live_stream.enabled',
	VIDEO_LIVE_STREAM_DISABLED = 'video.live_stream.disabled',
	VIDEO_LIVE_STREAM_DELETED = 'video.live_stream.deleted',
	VIDEO_LIVE_STREAM_SIMULCAST_TARGET_CREATED = 'video.live_stream.simulcast_target.created',
	VIDEO_LIVE_STREAM_SIMULCAST_TARGET_IDLE = 'video.live_stream.simulcast_target.idle',
	VIDEO_LIVE_STREAM_SIMULCAST_TARGET_STARTING = 'video.live_stream.simulcast_target.starting',
	VIDEO_LIVE_STREAM_SIMULCAST_TARGET_BROADCASTING = 'video.live_stream.simulcast_target.broadcasting',
	VIDEO_LIVE_STREAM_SIMULCAST_TARGET_ERRORED = 'video.live_stream.simulcast_target.errored',
	VIDEO_LIVE_STREAM_SIMULCAST_TARGET_DELETE = 'video.live_stream.simulcast_target.delete'
}

export enum MuxEventObjectType {
	ASSET="asset"
}

export enum MuxEventDataStatus {
	CREATED="created",
	PREPARING="preparing"
}
export enum MuxMasterAccess{
	NONE="none",
	TEMPORARY="temporary",
}
export enum MuxStoredResolution {
	SD="SD",
	HD="HD"
}
export enum MuxPlaybackPolicy {
	PUBLIC="public",
	SIGNED="signed",
}
export enum MuxMP4Support {
	NONE="none",
	STANDARD="standard"
}