export class Tag<M = unknown> {
	text: string;
	color?: string;
	/** Optional: The Athlete, Coach, Team, or other DomainObject this tag is referring to */
	subjectId?: string;
	metadata?: M;
	constructor(text: string, subjectId?: string, color?: string) {
		this.text = text
		this.subjectId = subjectId
		this.color = color
	}
	setMetadata(metadata: M): Tag<M>{
		this.metadata = metadata;
		return this;
	}
}
