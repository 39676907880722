/**
 * This trick to simulate a v-if like hide was found through this article: https://codeburst.io/reusable-vue-directives-v-can-753bf54e563f
 * 
 * @author https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs#43543814
 */
export function commentNode(el, vnode) {
	const comment = document.createComment(' ')

	Object.defineProperty(comment, 'setAttribute', {
		value: () => undefined
	})

	vnode.text = ' '
	vnode.elm = comment
	vnode.isComment = true
	vnode.context = undefined
	vnode.tag = undefined
	vnode.data.directives = undefined

	if (vnode.componentInstance) {
		vnode.componentInstance.$el = comment
	}

	if (el.parentNode) {
		el.parentNode.replaceChild(comment, el)
	}
}