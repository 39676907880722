
import { Component, Prop, Mixins, PropSync } from 'vue-property-decorator';
import { VuetifyMixin } from '../../../../mixins/VuetifyMixin';
import SoccerFieldGrid from './SoccerFieldGrid.vue';
import PositionCard, { PositionCardProps } from '../PositionCard.vue';
import { DepthChartModel, DepthChartPosition } from '../../../../models/depthChart';
import { PlayerOnTeam } from '../../../../models/team/PlayerOnTeam';
import { SoccerPosition, BaseSoccerPosition } from '../../../../../types/enums/sports';
import LineupPositionCard from '../LineupPositionCard.vue';
@Component({
	components: { PositionCard, SoccerFieldGrid, LineupPositionCard },
})
export default class SoccerDepthChart extends Mixins(VuetifyMixin){

	@Prop({ required: true }) private roster: PlayerOnTeam[];
	@PropSync('depthChart',{ required: true }) private DepthChart: DepthChartModel;
	@Prop({ default: false }) fromCreateLineup: boolean;
	@Prop({ default: 660 }) private height: number;
	showGoalie: boolean = false;

	$refs:{
		depthChart: HTMLDivElement;
	}

	width: number = 0;
	onResize(): void{
		this.width = this.$refs.depthChart.clientWidth;
	}
	getPositionWidth(row: number): number | undefined{
		if(this.IsMobile){
			const count = this.countPositionsInRow(row);
			if(this.width < count * 108){
				return (this.width / count) - 8;
			}
		}
		return;
	}

	countPositionsInRow(row: number): number{
		return this.DepthChart.positions.filter(p => {
			return (p.row === row);
		}).length;
	}
	getPosition(row: number, col: number): DepthChartPosition | undefined{
		return this.DepthChart.positions.find(p => {
			return (p.row === row) && (p.col === col) && (p.position !== SoccerPosition.Goalkeeper);
		});
	}
	getPositionProps(row: number, col: number): PositionCardProps{
		const position = this.getPosition(row,col);
		let players: PlayerOnTeam[] = []
		if (this.fromCreateLineup){
			players = this.playersFromRoster([position.playerIds[0]]);
		} else {
			players = this.playersFromRoster(position.playerIds);
		}
		if(position){
			return {
				position: position,
				players: players,
				empty: position.playerIds.length === 0,
			};
		}else{
			return {
				position: new DepthChartPosition({
					number: "",
					row,
					col,
					position: "Empty",
				}),
				empty: true,
			};
		}
	}
	get Goalie(): DepthChartPosition{
		return this.DepthChart.positions.find(p => {
			return p.position === SoccerPosition.Goalkeeper;
		});
	}
	get Goalies(): PlayerOnTeam[]{
		return this.playersFromRoster([this.DepthChart.positions[10].playerIds[0]])
	}
	get GoalieProps(): PositionCardProps{
		const goalie = this.Goalie;
		const position = new DepthChartPosition({
			position: BaseSoccerPosition.Goalkeeper,
			number: "1",
			row: 0,
			col: 0,
		});
		let goalies: PlayerOnTeam[] = [];
		if (this.fromCreateLineup){
			goalies = this.playersFromRoster([goalie.playerIds[0]]);
		} else {
			goalies = this.playersFromRoster(goalie.playerIds);
		}
		return {
			goalie: true,
			position,
			players: goalies,
			empty: goalies.length === 0,
		};
	}
	updated: boolean = false;

	editPosition(row: number, col: number, player: PlayerOnTeam): void{
		this.updated = true;
		const position = this.getPosition(row, col);
		if(position !== undefined){
			this.DepthChart = new DepthChartModel().load(this.DepthChart)
			position.playerIds[0] = player.id;
			this.$emit('edit:position', position);
		}else{
			const newPosition: DepthChartPosition = new DepthChartPosition().load({
				row, col,
				playerIds: [],
				position: "",
				label: "",
				number: "",
			});
			this.$emit('edit:position', newPosition);
		}
	}
	/*
	* Returns PlayerOnTeam objects from a list of playerIds. Filters out missing player ids.
	*/
	playersFromRoster(playerIds: string[]){
		return playerIds.map(id => this.roster.find(p => p.id === id)).filter(p => !!p);
	}

	edit(row: number, col:number): void{
		const position = this.getPosition(row, col);
		if(position !== undefined){
			this.$emit('edit:position', position);
		}else{
			const newPosition: DepthChartPosition = new DepthChartPosition().load({
				row, col,
				playerIds: [],
				position: "",
				label: "",
				number: "",
			});
			this.$emit('edit:position', newPosition);
		}
	}
	editGoaliePosition(goalie: PlayerOnTeam): void{
		this.updated = true;
		this.showGoalie = true;
		if(this.Goalie){
			
			let playerIds = this.playersFromRoster(this.Goalie.playerIds);
			playerIds[0] = goalie;
			const newGoalie = new DepthChartPosition({
				position: SoccerPosition.Goalkeeper,
				number: "1",
				row: 0,
				col: 0,
			})
			newGoalie.playerIds = playerIds.map(x => x.id)
			newGoalie.label = "Goalkeeper"
			this.DepthChart = new DepthChartModel().load(this.DepthChart)
			this.$emit('edit:position', newGoalie);
		}else{
			const newGoalie: DepthChartPosition = new DepthChartPosition().load({
				playerIds: [],
				position: SoccerPosition.Goalkeeper,
				label: "",
				number: "",
				row: 0,
				col: 0,
			});
			this.$emit('edit:position', newGoalie);
		}		
	}
	editGoalie(): void{
		if(this.Goalie){
			this.$emit('edit:position', this.Goalie);
		}else{
			const newGoalie: DepthChartPosition = new DepthChartPosition().load({
				playerIds: [],
				position: SoccerPosition.Goalkeeper,
				label: "",
				number: "",
				row: 0,
				col: 0,
			});
			this.$emit('edit:position', newGoalie);
		}
	}

}
