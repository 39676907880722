
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { mdiChevronLeft, mdiAlert } from '@mdi/js';

import Page from './Page.vue';
import Alert from '../components/ui/Alert.vue';
import SurveySvg from '../components/svg/SurveySvg.vue';
import MultiStepForm from '../components/forms/MultiStepForm.vue';
import { MultiStepFormMixin } from '../mixins/MultiStepFormMixin';
import TeamCreateTeamInfo from '../components/teams/TeamCreateTeamInfo.vue';
import TeamCreateStaffInfo from '../components/teams/TeamCreateStaffInfo.vue';
import TeamCreateTeamRoster from '../components/teams/TeamCreateTeamRoster.vue';
import { TeamModel } from '../models/team';
import { StaffRole } from '../../types/enums';
import { TeamDashboard, CoachDashboard } from '../../types/constants/web_client_user.routes';

import { teamCreateStore, teamsStore } from '../store';
import { StaffMember } from '../models/team/StaffMember';
import { AuthMixin, MyCoachMixin } from '../mixins';
import { TeamCreateFormValue, TeamCreateOptions } from '@/../types/interfaces';

@Component({
	components: {
		Alert,
		MultiStepForm,
		Page,
		SurveySvg,
		TeamCreateTeamInfo,
		TeamCreateStaffInfo,
		TeamCreateTeamRoster,
	},
})
export default class TeamCreateForm extends Mixins(MultiStepFormMixin, AuthMixin, MyCoachMixin){
	mdiChevronLeft = mdiChevronLeft;
	mdiAlert = mdiAlert;
	get IsEdit(): boolean{
		return this.editTeamId !== undefined;
	}
	@Prop({ default: undefined }) editTeamId?: string;
	@Prop({ default: false }) selfRoleOptional: boolean;
	@Watch('editTeamId') async checkEditTeam(): Promise<void>{
		if(this.IsEdit){
			await this.setupEditTeam(this.editTeamId);
		}else{
			this.resetForm();
		}
	}

	errorMessage: string | null = null;
	async mounted(): Promise<void>{
		this.checkEditTeam();
	}
	editTeam: TeamModel | null = null;
	get EditTeamName(): string{
		if(this.editTeam === null) return this.formValue.step1.name;
		return this.editTeam.name
	}
	async setupEditTeam(teamId: string): Promise<void>{
		const editTeam = await teamsStore.loadTeamById(teamId);
		this.editTeam = editTeam;
		this.goBackWithPrevious = false;
		this.formValue.step1.name = editTeam.name;
		this.formValue.step1.country = editTeam.country;
		this.formValue.step1.city = editTeam.city;
		this.formValue.step1.sportId = editTeam.sportId;
		this.formValue.step1.gender = editTeam.gender;
		this.formValue.step1.seasonFormat = editTeam.seasonFormat;
		this.formValue.step1.logoUrl = editTeam.logoUrl;

		this.formValue.step2.staffMembers = editTeam.staffMembers;

		this.formValue.step3.players = editTeam.players;
	}
	resetForm(): void{
		this.formValue = this.defaultForm();
	}

	newTeamId: string = "";
	formComplete: boolean = false;
	get Loading(): boolean{
		return teamCreateStore.createTeamLoading || teamsStore.loadTeamByIdLoading;
	}
	steps = 3;
	currentStep = 1;

	get CurrentStepValid(): boolean {
		const step = this.formValue[this.CurrentStepKey];
		return step ? step.valid : true;
	}

	get Progress(): number{
		switch(this.currentStep){
		case 1: return 33;
		case 2: return 66;
		case 3: return 95;
		default: return 100;
		}
	}

	defaultForm = (): TeamCreateFormValue => ({
		step1: {
			valid: true,
			name: "",
			country: "",
			city: "",
			sportId: "",
			gender: "",
			seasonFormat: "",
			seasonYear: "",
			seasonStartDate: "",
			seasonEndDate: "",
			organizationTypeId: "",
			organizationName: "",
			logoUrl: null,
		},
		step2: {
			valid: true,
			myRole: "",
			makeEmailPublic: false,
			staffMembers: [],
			sendStaffInvitations: true,
		},
		step3: {
			valid: true,
			players: [],
			invitePlayers: [],
			sendPlayerInvitations: true,
		}
	});
	formValue = this.defaultForm();

	getErrors(error: Record<string, any>): string[][]{
		return Object.values(error).filter(x => x.length !== 0)
	}

	async finish(validate: () => Promise<boolean>): Promise<void>{
		// Validate the form
		if (!(await validate())) {
			this.errorMessage = 'The follow field(s) must be completed:'
			window.scrollTo(0, 0)
			return;
		}

		const isEdit = this.editTeamId !== undefined;
		let team: TeamModel;
		if(isEdit){
			team = teamsStore.myTeams.find(t => t.id === this.editTeamId);
		}
		if(team === undefined){
			team = new TeamModel();
		}
		const { name, country, city, sportId, gender, seasonFormat, organizationTypeId, organizationName, logoUrl } = this.formValue.step1;
		team.name = name;
		team.country = country;
		team.city = city;
		team.sportId = sportId;
		team.gender = gender;
		team.seasonFormat = seasonFormat;
		team.organizationTypeId = organizationTypeId;
		team.organizationName = organizationName;
		team.logoUrl = logoUrl;

		const staffMembers: StaffMember[] = this.formValue.step2.staffMembers
		const sendStaffInvitations = this.formValue.step2.sendStaffInvitations;

		team.staffMembers = [...staffMembers];

		const { players, invitePlayers, sendPlayerInvitations } = this.formValue.step3;

		team.players = [
			...players,
			...invitePlayers,
		];

		const opts: TeamCreateOptions = {
			invitePlayers: sendPlayerInvitations,
			inviteStaff: sendStaffInvitations,
			sourceCoachId: this.MyCoachId,
		};

		if(!isEdit){
			console.groupCollapsed(`TeamCreate`);
			console.log("Create Team", { formValue: this.formValue });
			console.log("TeamModel", team);
			console.log("opts", { opts });
			const newTeam = await teamCreateStore.createTeam({team, opts});
			console.groupEnd();
			this.formComplete = true;
			this.newTeamId = newTeam.id;
			teamsStore.spliceTeam({ team: newTeam });
		}else{
			console.groupCollapsed(`TeamEdit`);
			team.id = this.editTeamId;
			const newTeam = await teamCreateStore.patchTeam({team, opts});
			console.groupEnd();
			this.formComplete = true;
			this.newTeamId = newTeam.id;
			teamsStore.spliceTeam({ team: newTeam });
		}
		this.errorMessage = null;
		this.viewTeamProfile();
	}
	cancel(): void{
		this.errorMessage = null;
		this.goToDashboard();
	}

	@Prop({ default: null }) teamDashboardRoute: string | null;
	get TeamDashboard(): string{
		if(this.teamDashboardRoute !== null) return this.teamDashboardRoute;
		return TeamDashboard;
	}
	@Prop({ default: null }) dashboardRoute: string | null;
	get Dashboard(): string{
		if(this.dashboardRoute !== null) return this.dashboardRoute;
		return CoachDashboard;
	}

	viewTeamProfile(): void{
		this.$router.push({
			name: this.TeamDashboard,
			params: { teamId: this.newTeamId },
		});
	}
	goToDashboard(): void{
		this.$router.push({ name: this.Dashboard });
	}
}
