/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfig } from 'vue-router';
import { coachAuthGuard } from './routeGuards/coachAuthGuard';
import { CoachSideNavLayout, SimplePageLayout } from '@/layouts';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { HinderRouteMetadata } from './router';

const StudentCourse = () => import(/* webpackChunkName: "CourseStudentModule" */'@/views/student/StudentCourse.vue');
const AssignmentSubmission = () => import(/* webpackChunkName: "CourseStudentModule" */'@/views/student/AssignmentSubmission.vue');
const ViewEvaluation = () => import(/* webpackChunkName: "CourseStudentModule" */'@/views/student/ViewEvaluation.vue');

export const StudentRouteConfigs: RouteConfig[] = [{
	beforeEnter: coachAuthGuard,
	path: '/s',
	name: Routes.StudentDashboard,
	component: CoachSideNavLayout,
	children: [
		{
			path: 'courses',
			name: Routes.SimplePageLayout,
			component: SimplePageLayout,
			props: { pageTitle: 'Course Dashboard' },
			children: [
				{	
					name: Routes.StudentCoursePage,
					path: ':courseId',
					component: StudentCourse,
					meta:<HinderRouteMetadata>{
						pageTitle: "Licensing Course",
					}
				},
				{
					name: Routes.AssignmentSubmission,
					path: ':courseId/assignment/:assignmentId/upload',
					component: AssignmentSubmission,
					meta: <HinderRouteMetadata>{
						pageTitle: "Back to course",
						useBackButton: true,
					}
				},
				{
					name: Routes.StudentViewEvaluation,
					path: ':courseId/assignment/:assignmentId/evaluation',
					component: ViewEvaluation,
					meta: <HinderRouteMetadata>{
						pageTitle: "Assignment Evaluation",
					}
				},
			],
		}
	]
}];