
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { VMenu } from 'vuetify/lib';
import { ContextMenuItem } from '../../../types/interfaces';
import { mdiDotsHorizontal, mdiPlus } from '@mdi/js';
import { VueConstructor } from 'vue';
import { VuetifyMixin } from '@/mixins';

@Component
export default class ContextMenu extends Mixins(VuetifyMixin){
	mdiDotsHorizontal = mdiDotsHorizontal;
	@Prop({ required: true, default: [{icon: mdiPlus, text: 'Add'}] }) items: ContextMenuItem[];
	@Prop({ default: "baColorDeepBlue" }) color: string;
	@Prop({ default: "baColorPrimaryBlue" }) menuIconColor: string;
	@Prop({ default: true, type: Boolean }) dark: boolean;
	@Prop({ default: false, type: Boolean }) darkIcon: boolean;
	@Prop({ default: mdiDotsHorizontal }) icon: string;
	@Prop({ default: false, type: Boolean }) inline: boolean;

	get Color(): string{
		return this.getColor(this.color);
	}
	get MenuIconColor(): string{
		return this.getColor(this.menuIconColor);
	}

	get TextClass():Record<string, boolean>{
		return {
			[`${this.menuIconColor}--text`]: this.inline,
		};
	}

	get RootComponent(): string | VueConstructor{
		if(this.inline === true){
			return 'div';
		}
		return VMenu;
	}

	clickItem(item: ContextMenuItem): void{
		this.$emit('click:item', { item });
		if(item.click) item.click();
	}
}
