
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class ProgressBar extends Mixins(VuetifyMixin){
	@Prop({ default: 0, type: Number }) private progress: number;
	@Prop({ default: 20, type: Number }) private height: number;
	@Prop({ default: 4, type: Number }) private padding: number;
	@Prop({ default: 'baColorChartsLow' }) private color: string;
	@Prop({ default: 'baColorAppForeground' }) private backgroundColor: string;
	@Prop({ default: null }) private borderColor: string | null;
	@Prop({ default: 1, type: Number }) private borderSize: number;
	@Prop({ default: false, type: Boolean }) private hideLeftBorder: boolean;
	@Prop({ default: false, type: Boolean }) private tileLeft: boolean;
	@Prop({ default: false, type: Boolean }) private inset: boolean;
	@Prop({ default: false, type: Boolean }) private loading: boolean;

	isMounted: boolean = false;
	mounted(): void{
		this.isMounted = true;
	}

	get BorderRadius(): string{
		let left = 10;
		let right = 10;
		if(this.tileLeft === true){
			left = 0;
		}
		return `${left}px ${right}px ${right}px ${left}px`;
	}

	get Padding(): number{
		if(this.inset){
			return this.padding;
		}
		return 0;
	}

	/** Allows overriding background color without using `inset` */
	get UseBackground(): boolean{
		return this.backgroundColor !== 'baColorAppForeground';
	}

	get WrapperStyle(): Record<string, any>{
		return {
			'line-height': `${this.height - this.Padding * 2}px`,
			'height': `${this.height}px`,
			'padding': `${this.Padding}px`,
			'margin-left': `-${this.Padding}px`,
			'background-color': `${this.getColor(this.inset || this.UseBackground ? this.backgroundColor : 'none')} !important`,
			'border-radius': this.BorderRadius,
		};
	}
	get FillStyle(): Record<string, any>{
		const fillStyle = {
			'height': `${this.FillHeight}px`,
			'width': `${this.FillWidth}`,
			// 'margin': `${this.Padding}px`,
			'background-color': `${this.getColor(this.color)} !important`,
			'border-radius': this.BorderRadius,
		};
		if(this.borderColor !== null){
			fillStyle['border'] = `${this.borderSize}px solid ${this.getColor(this.borderColor)}`;
		}
		if(this.hideLeftBorder){
			fillStyle['border-left'] = 'none';
		}
		return fillStyle;
	}

	get FillHeight(): number{
		return this.height - this.Padding * 2;
	}
	get FillWidth(): string{
		return `${this.progress > 100 ? 100 : this.progress}%`;
	}
}
