
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { recurringNotificationApi } from "@/api/RecurringNotificationApi";

/**
 * Displays a loading screen. Optionally redirects after a fixed period of time
 */
@Component
export default class InvitationDecline extends Mixins(VuetifyMixin){
	@Prop({ type: String }) text: string

	loading = true;
	async created(): Promise<void> {
		this.loading = true;
		await recurringNotificationApi.declineInvitation(this.key);
		this.loading = false;
	}
	get key(): string | null {
		return this.$route.params.key ? this.$route.params.key : null;
	}
	async decline(): Promise<void> {
		await recurringNotificationApi.declineInvitation(this.key);
	}
}
