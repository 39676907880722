
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { mdiContentCopy } from '@mdi/js';
import { VuetifyMixin } from '../../mixins';

@Component
export default class CopyToClipboard extends Mixins(VuetifyMixin){
	mdiContentCopy = mdiContentCopy;
	@Prop({ default: 'baColorPrimaryBlue' }) hoverColor: string;
	@Prop() text: string;
	@Prop() clipboardText: string;
	@Prop({  type: Boolean, default: false }) noIcon: boolean;
	@Prop({  type: Boolean, default: false }) button: boolean;
	@Prop({  type: Boolean, default: false }) dark: boolean;
	@Prop({ type: String, default: 'baColorPrimaryText'}) defaultIconColor: string;
	@Prop({ type: String, default: mdiContentCopy }) icon: string;

	$refs:{
		animatedText: HTMLSpanElement;
		copyToClipboardEl: HTMLSpanElement;
	}

	get Color(){
		return this.getColor(this.dark ? 'white' : this.defaultIconColor);
	}
	get HoverColor(){
		return this.getColor(this.hoverColor);
	}
	getTextColor(hovered: boolean){
		return hovered ? this.HoverColor : this.Color;
	}
	
	useClipboardText: string;

	@Watch('clipboardText') setupClipboardText(){
		if(this.clipboardText && this.clipboardText.length > 0){
			this.useClipboardText = this.clipboardText;
		}else{
			this.useClipboardText = this.text;
		}
	}

	mounted(){
		this.setupClipboardText();
	}

	runClickAnimation(){
		this.$refs.animatedText.classList.remove('run-animation');
		this.$refs.animatedText.offsetWidth;
		this.$refs.animatedText.classList.add('run-animation');
	}
	copyToClipboard(){
		if(this.button === true){
			this.$emit('click');
			return;
		}
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = this.useClipboardText;
		this.$refs.copyToClipboardEl.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		this.$refs.copyToClipboardEl.removeChild(selBox);
		this.runClickAnimation();
	}
}
