
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiCloseCircle, mdiPlus, mdiChevronDown, mdiInformationOutline } from '@mdi/js';
import { capitalize } from '@/pipes';
import AccessControlForm from '@/components/profile/settings/AccessControlForm.vue';
import AthleteAccessControlUserRow from './AthleteAccessControlUserRow.vue';
import PermissionsSelector from '@/components/forms/PermissionsSelector.vue';
import UserAclInfoProvider from '@/components/hoc/UserAclInfoProvider.vue';
import { FormRulesMixin, AuthMixin } from '@/mixins';
import { UserAcl, AthleteUserRelationship, AthleteProfileRole, AthleteRelationship } from '@/../types/interfaces';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { AthleteProfileRoleName, AthleteProfileRelationshipName } from '@/../types/enums';
import { getDaysBetween } from '@/helpers/date';
import AddAccessControlDialog from "@/components/profile/athlete/AddAccessControlDialog.vue";

@Component({
	components: {
		AccessControlForm,
		AthleteAccessControlUserRow,
		PermissionsSelector,
		UserAclInfoProvider,
		AddAccessControlDialog
	},
})
export default class AthleteAccessControlFormSlim extends Mixins(AuthMixin, FormRulesMixin){
	capitalize = capitalize;
	mdiInformationOutline = mdiInformationOutline;
	mdiCloseCircle = mdiCloseCircle;
	mdiChevronDown = mdiChevronDown;
	mdiPlus = mdiPlus;

	temporaryUsers: AthleteUserRelationship[] = [];

	showInviteDialog: boolean = false;

	async created(): Promise<void> {
		this.eagerRelationships = this.value.userRelationships;
	}
	@Prop({ required: true }) value: AthleteProfileModel;
	input(value: AthleteProfileModel): void{
		this.$emit('input', value);
	}


	get ReadOnlyRole(): AthleteProfileRole{
		return AthleteProfileModel.Roles.find(role => role.name === AthleteProfileRoleName.ReadOnly);
	}
	get ParentRelationship(): AthleteRelationship{
		return AthleteProfileModel.Relationships.find(relationship => relationship.name === AthleteProfileRelationshipName.Parent);
	}

	get Roles(): AthleteProfileRole[]{
		return AthleteProfileModel.Roles;
	}
	get AvailableRelationships(): AthleteRelationship[]{
		if(this.AthleteUserExists){
			return AthleteProfileModel.Relationships.filter(rel => rel.name !== AthleteProfileRelationshipName.Athlete);
		}
		return AthleteProfileModel.Relationships;
	}
	get AthleteFullName(): string{
		if(!this.value) return '';
		return this.value.FullName;
	}
	get AthleteEmailAddress(): string{
		if(!this.value?.email) return '';
		return this.value.email;
	}
	get AthleteFirstName(): string{
		if(!this.value) return '';
		return this.value.firstName;
	}
	get AthleteUserExists(): boolean{
		return this.AthleteUser !== null;
	}
	get AthleteUser(): UserAcl | null{
		if(!this.value) return null;		

		let athleteRelation: AthleteUserRelationship;
		// Check if it is a parent account
		if (this.value.userRelationships.length === 1 && this.value.userRelationships[0].relationship.name === "parent") {
			athleteRelation = this.value.userRelationships[0];
		}
		// Otherwise return the relationship
		else {
			athleteRelation = this.value.userRelationships.find(rel => rel.relationship.name === AthleteProfileRelationshipName.Athlete);
		}
		
		if(athleteRelation === undefined) return null;
		const athleteUser = this.value.users.find(u => u.id === athleteRelation.userId)
		if(athleteUser === undefined) return null;
		return athleteUser;
	}
	get AthleteAgeYears(): number{
		return getDaysBetween(this.value.birthDate,new Date()).days/365;
	}
	get AthleteInvitationPending(): boolean{
		if(!this.AthleteUserExists) return false;
		return this.AthleteUser.id.startsWith('tmp_')
	}
	
	eagerRelationships: AthleteUserRelationship[] = []
	get AthleteRelationships(): AthleteUserRelationship[] {
		return this.eagerRelationships;
	}
	get Users(): UserAcl[]{
		if(!this.value) return [];
		return this.value.users;
	}
	
	addUserValid: boolean = false;
	addUserForm: AthleteUserRelationship = {
		role: AthleteProfileModel.Roles.find(r => r.name === AthleteProfileRoleName.Owner),
		relationship: undefined,
		inviteEmail: "",
		userId: "",
	};
	showAddUser(): void{
		this.addUserForm = {
			role: AthleteProfileModel.Roles.find(r => r.name === AthleteProfileRoleName.Owner),
			relationship: undefined,
			inviteEmail: "",
			inviteName: "",
			userId: "",
		};
		this.showInviteDialog = true;
	}
	inviteAthlete(): void{
		this.addUserForm = {
			role: AthleteProfileModel.Roles.find(role => role.name === AthleteProfileRoleName.Owner),
			relationship: AthleteProfileModel.Relationships.find(rel => rel.name === AthleteProfileRelationshipName.Athlete),
			userId: "",
			inviteName: this.AthleteFullName,
			inviteEmail: this.AthleteEmailAddress,
		};
		this.showInviteDialog = true;
	}
	get ShowAthleteMessage(): boolean{
		if(this.addUserForm.relationship && this.addUserForm.relationship.name === AthleteProfileRelationshipName.Athlete){
			return true;
		}
		return false;
	}
	get ShowOwnerWarning(): boolean{
		if(this.addUserForm.role && this.addUserForm.role.name === AthleteProfileRoleName.Owner){
			return true;
		}
		return false;
	}
	addTempUser(): void{
		const tempUserRelation: AthleteUserRelationship = {
			...this.addUserForm,
			role: AthleteProfileModel.Roles.find(role => role.name === AthleteProfileRoleName.Owner),
		};
		this.value.setUser(this.addUserForm.userId, tempUserRelation);
		this.showInviteDialog = false;
		this.onSave();
	}

	removeAthlete(): void{
		this.value.removeUser(this.AthleteUser.id);
		this.onSave();
	}
	removeUser(): void{
		this.onSave();
	}

	onSave(): void{
		this.$emit('save', this.value);
	}
	onCancel(): void{
		this.$emit('cancel');
	}
}
