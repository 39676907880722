import store from '../store';
import { localforage } from '@/plugins/localforage';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Route, NavigationGuardNext } from 'vue-router';
import router from '../../router/router';

const Mutations = {
	INIT_STORE: 'INIT_STORE',
	LOAD_STATE: 'LOAD_STATE',
	PUSH_NAVIGATION: 'PUSH_NAVIGATION',
	POP_NAVIGATION: 'POP_NAVIGATION',
}

const name = 'NavigationStore';
const navigationTargetsKey = `${name}/navigationTargets`;

if (store.state[name]) {
	store.unregisterModule(name)
}

@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class NavigationModule extends VuexModule {
	
	initialized: boolean = true;
	navigationTargets: Route[] = [];
	
	get Initialized(): boolean {
		return this.initialized;
	}

	get HasNavigationQueued(): boolean {
		return this.navigationTargets.length > 0;
	}

	/**
	 * Replace `next` in a navigation guard with the result of this getter to intercept next calls and stash nagivation if the user was redirected.
	 * 
	 * ```
	 * (to, from, next) => {
	 *     next = navigationStore.StashNavigationNext(to, next);
	 *     if(redirect){
	 *         next({...}); // nagivationStore will stash the `to` to be accessed later
	 *     }
	 *     next(); // Calls next normally.
	 * }
	 * ```
	 */
	get StashNavigationNext(): (to: Route, next: NavigationGuardNext, options?: {ignoreRoutes?: string[]}) => NavigationGuardNext{
		return function (to: Route, next: NavigationGuardNext, { ignoreRoutes = [] } = {}){
			if (ignoreRoutes.includes(to.name)) return next;
			return function(...args: any[]){
				if (args.length > 0) {
					this.pushNavigation(to);
				}
				return next(...args);
			}.bind(this);
		}
	}

	@Action({ rawError: true }) async loadState(): Promise<void> {
		const navigationTargets = await localforage.getItem<Route[]>(navigationTargetsKey);
		this.context.commit(Mutations.LOAD_STATE,{
			navigationTargets,
		});
	}
	@Action({ rawError: true }) async saveState(): Promise<void> {
		localforage.setItem(navigationTargetsKey, this.navigationTargets);
	}
	@Mutation [Mutations.LOAD_STATE]({ navigationTargets }: { navigationTargets: Route[] }): void {
		this.initialized = true;
		if (Array.isArray(navigationTargets)){
			this.navigationTargets = navigationTargets;
		}
	}

	@Action({ rawError: true }) async pushNavigation(to: Route): Promise<void> {
		console.log("pushNavigation", { to: JSON.stringify(to) });
		this.context.commit(Mutations.PUSH_NAVIGATION, to);
		// this.saveState();
	}

	@Action({ rawError: true }) async popNavigation(): Promise<void> {
		this.context.commit(Mutations.POP_NAVIGATION);
		// this.saveState();
	}
	
	@Mutation [Mutations.PUSH_NAVIGATION](to: Route): void {
		this.navigationTargets.push(to);
	}
	@Mutation [Mutations.POP_NAVIGATION](): void {
		const to = this.navigationTargets.pop();
		console.log("POP_NAVIGATION", { to });
		router.push(to);
	}

	
}
