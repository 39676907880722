
import { Component, Prop, Mixins } from 'vue-property-decorator';
import RoundedButton from './RoundedButton.vue';
import { VuetifyMixin } from '@/mixins';

@Component({
	components: { RoundedButton },
})
export default class FormAddArea extends Mixins(VuetifyMixin){

	@Prop({ default: 140 }) private height: number;
	@Prop({ default: 'baColorOffWhite' }) private backgroundColor: string;
	@Prop({ default: 'baColorLightGray' }) private borderColor: string;
	@Prop({ default: 'add' }) private icon: string;
	@Prop({ default: 'Add' }) private buttonText: string;
	@Prop({ default: false }) private multiple: boolean;
	@Prop({ default: false, type: Boolean }) private smallIcon: boolean;
	@Prop({ default: 'dashed' }) private borderStyle: string;

	get SheetStyle(): Record<string, string>{
		return {
			'background-color': this.getColor(this.backgroundColor),
			'border': `1px ${this.BorderStyle} ${this.getColor(this.borderColor)}`,
			'box-sizing': 'border-box',
			'border-radius': '8px',
		};
	}
	get BorderStyle(): string{
		return this.borderStyle;
	}

}
