
import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { mdiChevronLeft, mdiMenu, mdiCircle, mdiCircleOutline } from "@mdi/js";
import MultiStepNavItem from "@/components/forms/MultiStepNavItem.vue";
import { VuetifyMixin } from '@/mixins';

@Component({
	components: {
		MultiStepNavItem
	}
})
export default class MultiStepForm extends Mixins(VuetifyMixin) {
	mdiChevronLeft = mdiChevronLeft;
	mdiMenu = mdiMenu;
	mdiCircle = mdiCircle;
	mdiCircleOutline = mdiCircleOutline;

	@Prop({ default: false, type: Boolean }) noHeader: boolean;
	@Prop({ default: false, type: Boolean }) hideMobileDots: boolean;
	@Prop({ default: false, type: Boolean }) hideControls: boolean;
	@Prop({ default: false, type: Boolean }) loading: boolean;
	@Prop({ default: 'pa-4 px-md-6 ', type: String }) padding: string;
	get Padding(): string{
		if(this.sidenav) return this.padding;
		return 'pa-12';
	}
	@Prop({ required: true }) steps: number;
	@Prop({ default: () => [] }) stepLabels: string[];
	@Prop({ default: true, type: Boolean }) currentStepValid: boolean;

	@Prop({ default: false, type: Boolean }) flat: boolean;
	get UseCollapsibleSideNav(): boolean{
		return this.sidenav && this.$vuetify.breakpoint.smAndDown;
	}
	get ShowSideNavSlot(): boolean{
		return this.sidenav;
	}
	@Watch('UseCollapsibleSideNav') sizeNavSizeChange(): void{
		this.showSideNav = false;
	}
	get SideNavWidth(): string{
		if(this.$vuetify.breakpoint.lgAndUp) return '256px !important';
		return '180px !important';
	}
	get ShowSideNav(): boolean{
		return !this.UseCollapsibleSideNav || this.showSideNav;
	}
	set ShowSideNav(sidenav : boolean){
		this.updateShowSideNav(sidenav);
	}
	updateShowSideNav(sidenav : boolean): void{
		this.showSideNav = sidenav;
	}
	showSideNav: boolean = false;
	toggleMenu(): void{
		if(!this.UseCollapsibleSideNav){
			this.showSideNav = false;
		}else{
			this.showSideNav = !this.showSideNav;
		}
	}

	get RootCardProps(): Record<string, any>{
		if(this.flat && !this.sidenav){
			return {
				flat: true,
				color: 'transparent',
			};
		}
		if(this.flat && this.sidenav){
			return {
				flat: true,
				class: 'soft-shadow elevation-0',
			};
		}
		return {
		}
	}
	get NavigationDrawerProps(): Record<string, any>{
		return {
			'mobile-breakpoint': "sm",
			'class': {
				'fill-height': true,
			},
			'style': {
				'min-width': this.SideNavWidth,
			},
			'right': this.UseCollapsibleSideNav && !this.IsMobile,
			'permanent': !this.UseCollapsibleSideNav,
			'absolute': this.UseCollapsibleSideNav,
			'temporary': this.UseCollapsibleSideNav,
		};
	}

	/** Allow v-model to bind currentStep */
	@Prop({ required: true }) currentStep: number;
	input(currentStep: number) {
		this.$emit("input", currentStep);
		this.$emit("update:current-step", currentStep);
	}

	@Prop({ default: false, required: false, type: Boolean }) sidenav: boolean;
	@Prop({ default: null, required: false }) sidenavBadgeNumbers: number[];

	get Progress() {
		return ~~((this.currentStep / this.steps) * 100);
	}
	get Steps() {
		return new Array(this.steps).fill(0).map((_, index) => index + 1);
	}
	get IsFirstStep() {
		return this.currentStep === 1;
	}
	get IsLastStep() {
		return this.currentStep === this.steps;
	}
	get StepLabel() {
		return this.stepLabels[this.currentStep - 1] || "";
	}

	get StepperOptions() {
		return {
			stepLabel: this.StepLabel,
			isFirstStep: this.IsFirstStep,
			isLastStep: this.IsLastStep,
			currentStep: this.currentStep,
			totalSteps: this.steps,
			progress: this.Progress,
			controls: {
				next: this.next,
				previous: this.previous,
				cancel: this.cancel,
				finish: this.finish
			}
		};
	}

	next() {
		this.$emit("next");
	}
	previous() {
		this.$emit("previous");
	}
	cancel() {
		this.$emit("cancel");
	}
	finish() {
		this.$emit("finish");
	}

	set CurrentStep(value: number) {
		this.$emit("update:currentStep", value);
	}
	get CurrentStep(): number {
		return this.currentStep;
	}
}
