
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProductModel } from '@/models/product/ProductModel';
import { productApi } from '@/api/ProductApi';
import { notificationStore } from '@/store';
import { NotificationModel } from '@/models/notification/NotificationModel';
@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class ProductInfoProvider extends Vue{
	@Prop({ default: 'div' }) as: string;

	productsLoading: boolean = false;
	productsInitialized: boolean = false;
	products: ProductModel[] = [];

	async loadProductInformation(): Promise<void>{
		this.productsLoading = true;
		this.products = await productApi.findAllWithAccess().catch((error) => {
			console.error("Failed to load products", error);
			notificationStore.pushErrorNotification(new NotificationModel("Something went wrong. Please try again later"));
			return [];
		});
		if(this.products.length > 0) this.productsInitialized = true;
		this.productsLoading = false;
	}

	created(): void{
		this.loadProductInformation();
	}

	get SlotProps(): Record<string, any>{
		return {
			productsLoading: this.productsLoading,
			productsInitialized: this.productsInitialized,
			products: this.products,
			ComparativeData: this.ComparativeData,
		};
	}

	get ComparativeData(): ProductModel | undefined { 
		return this.products.find(product => product.name.toLowerCase().indexOf('comparative data') >= 0)
	}
}
