import { PerformanceCategory, GradeName } from '../../types/enums';

export function performanceCategoryToLabel(value: PerformanceCategory): string{
	if(value === PerformanceCategory.College) return 'College';
	if(value === PerformanceCategory.Competitive) return 'Competitive';
	if(value === PerformanceCategory.HighPerformance) return 'High Performance';
	if(value === PerformanceCategory.International) return 'International';
	if(value === PerformanceCategory.ProAcademy) return 'Pro Academy';
	if(value === PerformanceCategory.Professional) return 'Professional';
	if(value === PerformanceCategory.Recreational) return 'Pre Competitive';
	return 'Unranked';
}

export function performanceCategoryToColor(value: PerformanceCategory, options: { background: boolean } = { background: false }): string{
	const { background = false } = options;
	if (value === PerformanceCategory.Recreational) {
		return background === false ? 'baColorRecreationalGreen' : 'baColorRecreationalGreenBG';
	}
	if (value === PerformanceCategory.Competitive) {
		return background === false ? 'baColorCompetitiveTeal' : 'baColorCompetitiveTealBG';
	}
	if (value === PerformanceCategory.HighPerformance || value === PerformanceCategory.College) {
		return background === false ? 'baColorHighPerformanceBlue' : 'baColorHighPerformanceBlueBG';
	}
	if (value === PerformanceCategory.ProAcademy || value === PerformanceCategory.Professional) {
		return background === false ? 'baColorProPurple' : 'baColorProPurpleBG';
	}
	if (value === PerformanceCategory.International) {
		return background === false ? 'baColorInternationalPink' : 'baColorInternationalPinkBG';
	}
	return background === false ? 'baColorUnrankedYellow' : 'baColorUnrankedYellowBG';;
}


export function gradeToColor(value: GradeName, options: { background: boolean } = { background: false }): string{
	const { background = false } = options;
	if (value === GradeName.Approaching) {
		return background === false ? 'baColorAccentGold' : 'baColorAccentGoldLighten';
	}
	if (value === GradeName.Meets || (value as string) === "Meeting") {
		return background === false ? 'baColorRecreationalGreen' : 'baColorRecreationalGreenBG';
	}
	if (value === GradeName.Exceeds) {
		return background === false ? 'baColorProPurple' : 'baColorProPurpleBG';
	}
	return background === false ? 'baColorUnrankedYellow' : 'baColorUnrankedYellowBG';;
}

