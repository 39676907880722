
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mdiCreditCardOutline } from '@mdi/js';
import { PaymentMethodModel } from '@/models/stripe/PaymentMethodModel';

@Component
export default class PaymentMethodIcon extends Vue{
	mdiCreditCardOutline = mdiCreditCardOutline;
	@Prop({ default: 32 }) height: number;
	@Prop({ default: null }) brand: string | null;
	@Prop({ default: null }) paymentMethod: PaymentMethodModel | null;
	@Prop({ type: Boolean, default: false }) mastercard: boolean;
	get IsVisa(): boolean{
		if(this.brand !== null) return this.brand === 'visa';
		if(this.paymentMethod === null) return false;
		if(!this.paymentMethod.stripePaymentMethodDetails) return false;
		if(!this.paymentMethod.stripePaymentMethodDetails.card) return false;
		if(this.paymentMethod.stripePaymentMethodDetails.card.brand === 'visa') return true;
		return false;
	}
	get IsMastercard(): boolean{
		if(this.brand !== null) return this.brand === 'mastercard';
		if(this.paymentMethod === null) return false;
		if(!this.paymentMethod.stripePaymentMethodDetails) return false;
		if(!this.paymentMethod.stripePaymentMethodDetails.card) return false;
		if(this.paymentMethod.stripePaymentMethodDetails.card.brand === 'mastercard') return true;
		return false;
	}
	get AspectRatio(): number{
		return 48/32;
	}
}
