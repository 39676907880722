import { Gender } from "../enums";

/**
 * Data entered in one row of our event spreadsheet.
 * https://docs.google.com/spreadsheets/d/16H7_xuUQ8WEB7UKbXNNcqJOyBVtb-TwA1LIOoh1d8QQ/edit#gid=1275015519
 */
export type AssessmentEventDataEntryRow = {
	/** Added fields to help with development */
	AttendeeId: string;
	AssessmentDate: string;
	TeamName: string;
	League: string;
	WaiverCompleted: string;
	FirstName: string;
	LastName: string;
	DateOfBirth: string;
	Gender: Gender;
	Email: string;
	TicketType: string;
	AthleteID: string;
	/** Questions collected at ticket purchase */
	Club: string;
	CellPhone: string;
	AthleteEmailAddressIfDifferent: string;
	ParentOrGuardianEmailAddress: string;
	whatLevelAreYouCurrentlyPlayingAt: string;
	whatIsYourPosition: string;
	whatIsYourCompetitiveLevel: string;
	dominantFoot: string;
	BirthMonth: string;
	BirthDay: number;
	BirthYear: number;
	/** Data entry from the event */
	StandingHeightCM: number;
	SittingHeightCM: number;
	BodyMassKG: number;
	CMJ_cm_Trial_1: number;
	Contact_Time_Trial_1: number;
	Jump_Height_cm_Trial_1: number;
	CMJ_cm_Trial_2: number;
	Contact_Time_Trial_2: number;
	Jump_Height_cm_Trial_2: number;
	Sprint_10m_s_Trial_1: number;
	Sprint_20m_Trial_1: number;
	Sprint_35m_Trial_1: number;
	Sprint_10m_s_Trial_2: number;
	Sprint_20m_Trial_2: number;
	Sprint_35m_Trial_2: number;
	YoYo: number;
};

export const AssessmentDataEntryColumnNameMap: Record<keyof AssessmentEventDataEntryRow, string> = {
	AssessmentDate: "Date of Assessment (DD/MM/YYYY)",
	AttendeeId: 'Attendee Id',
	TeamName: 'Team Name',
	League: 'League',
	WaiverCompleted: 'Waiver Completed',
	FirstName: 'First Name',
	LastName: 'Last Name',
	DateOfBirth: 'Date of Birth (YYYY-MM-DD)',
	Gender: "Male/Female",
	Email: 'Email',
	TicketType: 'Ticket Type',
	AthleteID: 'Athlete ID',
	Club: 'Club',
	CellPhone: 'Cell Phone',
	AthleteEmailAddressIfDifferent: 'Athlete email address (if different from parent/guardian)',
	ParentOrGuardianEmailAddress: 'Parent/Guardian email address',
	whatLevelAreYouCurrentlyPlayingAt: 'Select the level you are currently playing',
	whatIsYourPosition: 'What position do you mainly play?',
	whatIsYourCompetitiveLevel: 'What is your competitive level',
	dominantFoot: "Left/Right",
	BirthMonth: 'Birth Month',
	BirthDay: 'Birth Day',
	BirthYear: 'Birth Year',
	StandingHeightCM: 'Standing Height (cm)',
	SittingHeightCM: 'Sitting Height (cm)',
	BodyMassKG: 'Body Mass (kg)',
	CMJ_cm_Trial_1: 'CMJ (cm) Trial 1',
	Contact_Time_Trial_1: 'Contact Time - Trial 1',
	Jump_Height_cm_Trial_1: 'Jump Height (cm) Trial 1',
	CMJ_cm_Trial_2: 'CMJ (cm) Trial 2',
	Contact_Time_Trial_2: 'Contact Time - Trial 2',
	Jump_Height_cm_Trial_2: 'Jump Height (cm) Trial 2',
	Sprint_10m_s_Trial_1: 'Sprint 10 m (s) Trial 1',
	Sprint_20m_Trial_1: 'Sprint 20m (2) Trial 1',
	Sprint_35m_Trial_1: 'Sprint 35m (2) Trial 1',
	Sprint_10m_s_Trial_2: 'Sprint 10 m (s) Trial 2',
	Sprint_20m_Trial_2: 'Sprint 20m (2) Trial 2',
	Sprint_35m_Trial_2: 'Sprint 35m (2) Trial 2',
	YoYo: 'Yo-Yo',
}
/** Ordered column names from data entry spreadsheet */
export const AssessmentDataEntryColumnNames = Object.values(AssessmentDataEntryColumnNameMap);