import { DomainObject } from './DomainObject';

export abstract class FrontEndModel implements DomainObject{
	id: string;
	_v?: number;
	copy<T = any>(): any {
		return { ...<T><unknown>this };
	}
	copyAsTemplate<T = any>(): any {
		const newObj = this.copy<T>();
		delete newObj['_id'];
		delete newObj['id'];
		return newObj;
	}
	load<T = this>(obj: Partial<T>): this{
		Object.assign(this, obj);
		return this;
	}
}