
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiChevronUp, mdiChevronDown } from '@mdi/js';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';

@Component
export default class SortIcon extends Mixins(VuetifyMixin){
	mdiChevronUp = mdiChevronUp;
	mdiChevronDown = mdiChevronDown;

	@Prop({ default: false, type: Boolean }) private active: boolean;
	@Prop({ default: false, type: Boolean }) private desc: boolean;

	@Prop({ default: 1 }) private size: number;
	@Prop({ default: 'baColorDeepBlue' }) private color: string;
	@Prop({ default: 'baColorGray9' }) private disabledColor: string;

	get TopColor(){
		if(!this.active) return this.getColor(this.disabledColor);
		if(this.desc) return this.getColor(this.disabledColor);
		else return this.getColor(this.color);
	}
	get BottomColor(){
		if(!this.active) return this.getColor(this.disabledColor);
		if(!this.desc) return this.getColor(this.disabledColor);
		else return this.getColor(this.color);
	}

}
