import { Component, Vue, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

@Component
export class CSVUploadMixin extends Vue {

	@Prop({
		default: () => ([
			{ text: "", sortable: false, value: "row", align: "end", width: "60px" },
			{ text: "Jersey #", sortable: false, value: "number", align: "center" },
			{ text: "First Name", sortable: true, value: "firstName" },
			{ text: "Last Name", sortable: true, value: "lastName" },
			{ text: "Email", sortable: true, value: "email" },
			{ text: "Position", sortable: true, value: "position" },
			{ text: "Grad Year", sortable: true, value: "gradYear" }
		])
	}) CSVDataTableHeaders: DataTableHeader[];

	sampleTemplateLocation = "/download/SampleTemplate.csv";

	downloadTemplate(): void {
		window.open(this.sampleTemplateLocation,'_self');
	}
}