/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { CourseModel } from '../../models/course';
import { courseApi } from '@/api/CourseApi';
import { sourceVideoMuxModelApi } from '../../api/SourceVideoMuxApi';
import { AssignmentStatus } from '@/../types/enums';

const Mutations = {
	LOAD_MY_COURSES: 'LOAD_MY_COURSES',
	LOAD_MY_COURSES_SUCCESS: 'LOAD_MY_COURSES_SUCCESS',
	LOAD_MY_COURSES_FAILURE: 'LOAD_MY_COURSES_FAILURE',
	
	UPDATE_A_COURSE: 'UPDATE_A_COURSE',
	UPDATE_A_COURSE_SUCCESS: 'UPDATE_A_COURSE_SUCCESS',
	UPDATE_A_COURSE_FAILURE: 'UPDATE_A_COURSE_FAILURE',

}

const name = 'StudentCoursesStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class StudentCoursesModule extends VuexModule {

	myCoursesInitialized: boolean = false;
	myCoursesLoading: boolean = false;
	myCourses: CourseModel[] = [];
	
	updatingCourse: boolean = false;

	/**
		* Action & Mutations for Loading Students Courses
		*/
	@Action({
		rawError: true
	})
	async loadMyCourses(coachId: string) {
		this.context.commit(Mutations.LOAD_MY_COURSES);
		try {
			const courses = await courseApi.findByStudentId({ coachId });
			this.context.commit(Mutations.LOAD_MY_COURSES_SUCCESS, courses);
		} catch (e) {
			console.error("Failed to Create Course", e);
			this.context.commit(Mutations.LOAD_MY_COURSES_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_MY_COURSES]() {
		this.myCoursesLoading = true;
	}
	@Mutation [Mutations.LOAD_MY_COURSES_SUCCESS](courses: CourseModel[]) {
		this.myCoursesLoading = false;
		this.myCourses = courses;
		this.myCoursesInitialized = true;
	}
	@Mutation [Mutations.LOAD_MY_COURSES_FAILURE](error: any) {
		this.myCoursesLoading = false;
	}
	
	/**
		* Add SourceVideo to Assignment in Students Course
		*/
	@Action({
		rawError: true
	})
	async addSourceVideoToAssignmentById({ sourceVideoId, courseId, studentId, assignmentId }: { sourceVideoId: string, courseId: string, studentId: string, assignmentId: string }) {
		this.context.commit(Mutations.UPDATE_A_COURSE);
		// console.log('-----------------------------------------');
		// console.log('In the addSourceVideoToAssignmentById store action');
		// console.log('sourceVideoId is: ', sourceVideoId);
		// console.log('courseId is: ', courseId);
		// console.log('studentId is: ', studentId);
		// console.log('assignmentId is: ', assignmentId);
		// console.log('-----------------------------------------');
		try {
			const theSourceVideo = await sourceVideoMuxModelApi.findById(sourceVideoId);
			if (theSourceVideo) {
			
				const updatedCourse = await courseApi.addSourceVideoIdToAssignmentForStudent({
					courseId,
					studentId,
					assignmentId,
					sourceVideoId,
				});
				// console.log('The updated course from the backend is: ', updatedCourse);
				this.context.commit(Mutations.UPDATE_A_COURSE_SUCCESS, updatedCourse);
				return updatedCourse;
			}
		} catch (e) {
			console.error("Failed to Add SourceVideo to Assignment in Course", e);
			this.context.commit(Mutations.UPDATE_A_COURSE_FAILURE, e);
		}
	}

	@Mutation [Mutations.UPDATE_A_COURSE]() {
		this.updatingCourse = true;
	}
	@Mutation [Mutations.UPDATE_A_COURSE_SUCCESS](theUpdate: CourseModel) {
		const courseIndex = this.myCourses.findIndex(course => course.id === theUpdate.id)
		this.myCourses.splice(courseIndex, 1, theUpdate)
		this.updatingCourse = false;
	}
	@Mutation [Mutations.UPDATE_A_COURSE_FAILURE](error: any) {
		this.updatingCourse = false;
	}

	/** 
		* Update Assignment Status in Students Course
		*/
	@Action({
		rawError: true
	})
	async updateAssignmentStatus({ theStatus, courseId, studentId, assignmentId }: { theStatus: AssignmentStatus, assignmentId: string, courseId: string, studentId: string }) {
		this.context.commit(Mutations.UPDATE_A_COURSE);
		try {
			const tempCourse = this.myCourses.find(course => course.id === courseId);
			const studentIndex = tempCourse.students.findIndex(student => student.coachProfileId === studentId);
			const assignmentIndex = tempCourse.students[studentIndex].assignments.findIndex(asmnt => asmnt.id === assignmentId);
			const assignment = tempCourse.students[studentIndex].assignments[assignmentIndex];

			if (theStatus === AssignmentStatus.ReadyForReview && assignment.status !== AssignmentStatus.ReadyForReview){
				assignment.submittedAt = new Date();
			}
			assignment.status = theStatus;

			const updatedCourse = await courseApi.updateAssignmentForStudent({ courseId: courseId, studentId: studentId, assignment: assignment });

			this.context.commit(Mutations.UPDATE_A_COURSE_SUCCESS, updatedCourse);
			return updatedCourse;
		
		} catch (e) {
			console.error("Failed to update status of Assignment in Course", e);
			this.context.commit(Mutations.UPDATE_A_COURSE_FAILURE, e);
		}
	}

}
