import { FrontEndModel } from '../FrontEndModel';
import { StudentModel } from './StudentModel';
import { AssignmentModel } from './AssignmentModel';
import { StaffMember } from '../team/StaffMember';
import { CourseStaffRole, CourseType } from '../../../types/enums';
import { ActionableModel } from '../actions/ActionableModel';
 
export class CourseModel extends FrontEndModel {
	name: string = "";
	/** Rich text details section */
	details: string | null = null;
	startDate: Date;
	endDate: Date;
	color: string;
	curriculum: AssignmentModel[] = [];
	assignmentTemplates: AssignmentModel[] = [];
	staffMembers: StaffMember[] = [];
	programType: string = "";
	// students: StudentModel[] = [];
	studentRefs: Array<StudentModel | string> = [];
	get students(): StudentModel[]{
		const populated = this.studentRefs.filter(doc => (typeof doc !== 'string'));
		return <StudentModel[]>populated;
	}
	/**
	 * Kept for backwards compatibility. Assigns students to studentRefs if it's currently empty.
	 */
	set students(students: StudentModel[]){
		if(this.studentRefs.length === 0){
			this.studentRefs = students;
		}
		return;
	}
	type: CourseType = CourseType.Standard;

	studentJoinUrl: ActionableModel | null = null;
	staffJoinUrl: ActionableModel | null = null;

	get StaffMembersWithoutOwner(): StaffMember[]{
		return this.staffMembers.filter(s => s.role !== CourseStaffRole.Owner);
	}
	get Instructors(): StaffMember[]{
		return this.staffMembers.filter(s => s.role === CourseStaffRole.Instructor);
	}
	get Mentors(): StaffMember[]{
		return this.staffMembers.filter(s => s.role === CourseStaffRole.Mentor);
	}

	static AllRefs: string[] = [
		'studentRefs',
	];


	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		this.curriculum = this.curriculum.map(p => new AssignmentModel().load(p));
		this.assignmentTemplates = this.assignmentTemplates.map(p => new AssignmentModel().load(p));
		this.students = this.students.map(p => new StudentModel().load(p));
		if(obj['studentJoinUrl'] && typeof obj['studentJoinUrl'] === 'object') this.studentJoinUrl = new ActionableModel().load(obj['studentJoinUrl']);
		if(obj['staffJoinUrl'] && typeof obj['staffJoinUrl'] === 'object') this.staffJoinUrl = new ActionableModel().load(obj['staffJoinUrl']);
		if(obj['startDate']) this.startDate = new Date(obj['startDate']);
		if(obj['endDate']) this.endDate = new Date(obj['endDate']);
		return this;
	}

}