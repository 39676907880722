
import { Component, Prop, Mixins } from 'vue-property-decorator';

import { mdiPlus, mdiCloseCircle, mdiMapMarkerOutline, mdiHomeCityOutline, mdiChevronDown, mdiCalendarBlank } from '@mdi/js';
import FormAddArea from '../forms/FormAddArea.vue';
import FileUploadArea from '../forms/FileUploadArea.vue';
import MonthPicker from '../forms/MonthPicker.vue';
import { OrganizationTypeSelectorMixin, SportsSelectorMixin, CountriesSelectorMixin } from '../../mixins/SelectorMixins';

import { mdiCameraPlusOutline } from '@mdi/js';
import { DatePickerAllowedDatesFunction } from 'vuetify';

@Component({
	components: { FormAddArea, FileUploadArea, MonthPicker },
})
export default class TeamCreateTeamInfo extends Mixins(OrganizationTypeSelectorMixin, SportsSelectorMixin, CountriesSelectorMixin){
	mdiPlus = mdiPlus;
	mdiMapMarkerOutline = mdiMapMarkerOutline;
	mdiHomeCityOutline = mdiHomeCityOutline;
	mdiChevronDown = mdiChevronDown;
	mdiCloseCircle = mdiCloseCircle;
	mdiCameraPlusOutline = mdiCameraPlusOutline;
	mdiCalendarBlank = mdiCalendarBlank;

	showLinkOrgDialog: boolean = false;

	thisYear: number = new Date().getFullYear();
	@Prop() value = {
		valid: false,
		name: "",
		country: "",
		city: "",
		sportId: "",
		gender: "",
		seasonFormat: "year",
		seasonYear: this.thisYear,
		seasonStartDate: null,
		seasonEndDate: null,
		organizationTypeId: "",
		organizationName: "",
		logoUrl: null,
	};
	input(): void{
		this.$emit('input', this.value);
	}

	requiredRule = [
		(v: any): boolean | string => !!v || 'Required',
	];

	$refs:{
		form: HTMLFormElement;
	}

	organizationDialog = {
		name: "",
		typeId: "",
	}

	get SeasonYears(): number[]{
		return new Array(5).fill(0).map((_, i) => this.thisYear+i);
	}

	async submit($event: Event): Promise<void> {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}

	get EndAllowedDates(): DatePickerAllowedDatesFunction{
		if(!this.value.seasonStartDate) return () => true;
		return (date: string) => {
			const [ year, month ] = date.split('-');
			return (+year >= this.value.seasonStartDate.getUTCFullYear()) && (+month > this.value.seasonStartDate.getUTCMonth());
		}
	}
}
