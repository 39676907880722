/* Routing Mixin.ts */
/* Vue mixin containing routing functions */

import { Component, Mixins } from 'vue-property-decorator';
import { Route } from 'vue-router';
import * as Routes from '../../types/constants/web_client_user.routes';
import { MyAthleteMixin } from '@/mixins';

/**
 * Provides routing routines
 */
@Component
export class AthleteRoutingMixin extends Mixins(MyAthleteMixin) {
	get RouteToAthleteDashboard(): Partial<Route>{
		return {
			name: Routes.AthleteProfile,
			params:{
				athleteId: this.MyAthleteId,
			}
		}
	}

	get RouteToAthleteProfile(): Partial<Route>{
		return {
			name: Routes.AthleteRecruitingProfileView,
			params:{
				athleteId: this.MyAthleteId
			}
		}
	}

	get RouteToAthleteSchoolSearch(): Partial<Route>{
		return {
			name: Routes.AthleteSchoolSearch,
			params:{
				athleteId: this.MyAthleteId
			}
		}
	}

	get RouteToAthleteSelfReport(): Partial<Route>{
		return {
			name: Routes.AthleteSelfReportSummary,
			params:{
				athleteId: this.MyAthleteId
			}
		}
	}

	get RouteToAthletePhysicalAssessments(): Partial<Route>{
		return {
			name: Routes.AthleteVerifiedAssessmentSummary,
			params:{
				athleteId: this.MyAthleteId
			}
		}
	}

	get RouteToAthleteMindset(): Partial<Route>{
		return {
			name: Routes.AthleteMindsetReport,
			params:{
				athleteId: this.MyAthleteId
			}
		}
	}
	get RouteToAthleteScoutingReports(): Partial<Route>{
		return {
			name: Routes.AthleteScoutingReportsView,
			params:{
				athleteId: this.MyAthleteId
			}
		}
	}

	get RouteToAthletePersonality(): Partial<Route>{
		return {
			name: Routes.AthleteClaimSPQAssessmentForm,
			params:{
				athleteId: this.MyAthleteId
			}
		}
	}

	get RouteToAthleteComparative(): Partial<Route>{
		// If it is already on the path then just return the current path
		if (this.$route.path.startsWith('/comparative/')) {
			return {
				name: this.$route.name,
				params: this.$route.params
			}
		}

		return { name: Routes.AthleteComparativeData }
	}

	get RouteToAthleteVideo(): Partial<Route> {
		return {
			name: Routes.AthleteVideoStudio,
			params: {
				athleteId: this.MyAthleteId
			}
		}
	}

	get RouteToAthleteClaimScoutingReport(): Partial<Route> {
		return {
			name: Routes.AthleteClaimScoutingReportForm,
		}
	}

	RouteToAthleteScoutingReport(reportId): Partial<Route> {
		return {
			name: Routes.AthleteScoutingReport,
			params:{
				reportId: reportId,
			}
		};
	}

	goToAthleteDashboard(): void{
		this.$router.push(this.RouteToAthleteScoutingReports);
	}
	goToScoutingReport(reportId){
		this.$router.push(this.RouteToAthleteScoutingReport(reportId));
	}

}
