import { getEnumValues } from './get-enum-values';
export enum Gender {
	Male="male",
	Female="female",
}
export enum TeamGender{
	Male="male",
	Female="female",
	Coed="coed",
}
export enum SportGender {
	MEN = "men",
	WOMEN = "women",
	COED = "coed",
}
export const TeamGenderValues = getEnumValues<TeamGender>(TeamGender)
export const GenderValues = getEnumValues<Gender>(Gender)