import { FrontEndModel } from '../FrontEndModel';
import * as ProductKeys from '@/../types/constants/product-keys';

export class ProductModel extends FrontEndModel {

	static ProductKeys = ProductKeys;

	public stripeProductId: string | null = null;
	public key: string;
	public name: string;

	
}