/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { LineupModel } from '../../models/lineup';
import { CreateDepthChartFormData, InvitePlayerFormValue, InviteStaffFormValue } from '../../../types/interfaces';
import { SoccerPosition, InvitationStatus } from '../../../types/enums';
import { TeamModel, PlayerOnTeam } from '../../models/team';
import { DepthChartModel, DepthChartPosition } from '../../models/depthChart';
import { teamApi } from '@/api/TeamApi';
import { StaffMember } from '@/models/team/StaffMember';
import { PageState } from '@/models/PageState';
import { logGAEvent } from '@/plugins/firebase';

const Mutations = {
	SELECT_DEPTH_CHART: 'SELECT_DEPTH_CHART',

	LOAD_TEAM: 'LOAD_TEAM',
	LOAD_TEAM_SUCCESS: 'LOAD_TEAM_SUCCESS',
	LOAD_TEAM_FAILURE: 'LOAD_TEAM_FAILURE',

	MANUAL_ADD_PLAYER: 'MANUAL_ADD_PLAYER',
	MANUAL_ADD_PLAYER_SUCCESS: 'MANUAL_ADD_PLAYER_SUCCESS',
	MANUAL_ADD_PLAYER_FAILURE: 'MANUAL_ADD_PLAYER_FAILURE',

	MANUAL_ADD_STAFF: 'MANUAL_ADD_STAFF',
	MANUAL_ADD_STAFF_SUCCESS: 'MANUAL_ADD_STAFF_SUCCESS',
	MANUAL_ADD_STAFF_FAILURE: 'MANUAL_ADD_STAFF_FAILURE',

	MANUAL_INVITE_PLAYER: 'MANUAL_INVITE_PLAYER',
	MANUAL_INVITE_PLAYER_SUCCESS: 'MANUAL_INVITE_PLAYER_SUCCESS',
	MANUAL_INVITE_PLAYER_FAILURE: 'MANUAL_INVITE_PLAYER_FAILURE',

	MANUAL_INVITE_STAFF: 'MANUAL_INVITE_STAFF',
	MANUAL_INVITE_STAFF_SUCCESS: 'MANUAL_INVITE_STAFF_SUCCESS',
	MANUAL_INVITE_STAFF_FAILURE: 'MANUAL_INVITE_STAFF_FAILURE',

	CREATE_DEPTH_CHART: 'CREATE_DEPTH_CHART',
	CREATE_DEPTH_CHART_SUCCESS: 'CREATE_DEPTH_CHART_SUCCESS',
	CREATE_DEPTH_CHART_FAILURE: 'CREATE_DEPTH_CHART_FAILURE',

	RENAME_DEPTH_CHART: 'RENAME_DEPTH_CHART',
	RENAME_DEPTH_CHART_SUCCESS: 'RENAME_DEPTH_CHART_SUCCESS',
	RENAME_DEPTH_CHART_FAILURE: 'RENAME_DEPTH_CHART_FAILURE',

	EDIT_DEPTH_CHART_POSITION: 'EDIT_DEPTH_CHART_POSITION',
	SAVE_DEPTH_CHART_POSITION: 'SAVE_DEPTH_CHART_POSITION',
	SAVE_DEPTH_CHART_POSITION_SUCCESS: 'SAVE_DEPTH_CHART_POSITION_SUCCESS',
	SAVE_DEPTH_CHART_POSITION_FAILURE: 'SAVE_DEPTH_CHART_POSITION_FAILURE',
	CANCEL_EDIT_DEPTH_CHART_POSITION: 'CANCEL_EDIT_DEPTH_CHART_POSITION',

	DELETE_DEPTH_CHART: 'DELETE_DEPTH_CHART',
	DELETE_DEPTH_CHART_SUCCESS: 'DELETE_DEPTH_CHART_SUCCESS',
	DELETE_DEPTH_CHART_FAILURE: 'DELETE_DEPTH_CHART_FAILURE',

	CREATE_TEMP_LINEUP: 'CREATE_TEMP_LINEUP',
	CREATE_TEMP_LINEUP_SUCCESS: 'CREATE_TEMP_LINEUP_SUCCESS',
	CREATE_TEMP_LINEUP_FAILURE: 'CREATE_TEMP_LINEUP_FAILURE',

	CREATE_LINEUP: 'CREATE_LINEUP',
	CREATE_LINEUP_SUCCESS: 'CREATE_LINEUP_SUCCESS',
	CREATE_LINEUP_FAILURE: 'CREATE_LINEUP_FAILURE',

	DELETE_LINEUP: 'DELETE_LINEUP',
	DELETE_LINEUP_SUCCESS: 'DELETE_LINEUP_SUCCESS',
	DELETE_LINEUP_FAILURE: 'DELETE_LINEUP_FAILURE',

	SAVE_LINEUP_POSITION: 'SAVE_LINEUP_POSITION',
	SAVE_LINEUP_POSITION_SUCCESS: 'SAVE_LINEUP_POSITION_SUCCESS',
	SAVE_LINEUP_POSITION_FAILURE: 'SAVE_LINEUP_POSITION_FAILURE',

	SAVE_LINEUP_SUBSTITUTE: 'SAVE_LINEUP_SUBSTITUTE',
	SAVE_LINEUP_SUBSTITUTE_SUCCESS: 'SAVE_LINEUP_SUBSTITUTE_SUCCESS',
	SAVE_LINEUP_SUBSTITUTE_FAILURE: 'SAVE_LINEUP_SUBSTITUTE_FAILURE',

	RENAME_LINEUP: 'RENAME_LINEUP',
	RENAME_LINEUP_SUCCESS: 'RENAME_LINEUP_SUCCESS',
	RENAME_LINEUP_FAILURE: 'RENAME_LINEUP_FAILURE',

	SELECT_LINEUP: 'SELECT_LINEUP',
	
	PATCH_PLAYER: 'PATCH_PLAYER',
	PATCH_PLAYER_SUCCESS: 'PATCH_PLAYER_SUCCESS',
	PATCH_PLAYER_FAILURE: 'PATCH_PLAYER_FAILURE',

	PATCH_STAFF: 'PATCH_STAFF',
	PATCH_STAFF_SUCCESS: 'PATCH_STAFF_SUCCESS',
	PATCH_STAFF_FAILURE: 'PATCH_STAFF_FAILURE',
	
	REMOVE_PLAYER: 'REMOVE_PLAYER',
	REMOVE_PLAYER_SUCCESS: 'REMOVE_PLAYER_SUCCESS',
	REMOVE_PLAYER_FAILURE: 'REMOVE_PLAYER_FAILURE',
};

export interface PatchPlayerParams{
	teamId: string,
	playerId: string,
	playerPatch: Partial<PlayerOnTeam>,
}
export interface RemovePlayerParams{
	teamId: string,
	playerId: string,
}

const name = 'TeamDashboardStore';

if(store.state[name]){
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name: name,
	store: store
})
export default class TeamDashboardModule extends VuexModule {

	get initialized(): boolean{
		return !this.loadingTeamState.IsInitial;
	}
	get loadingTeam(): boolean{
		return this.loadingTeamState.IsLoading;
	}
	loadingTeamState: PageState = new PageState("Initial");
	team: TeamModel = new TeamModel();

	selectedDepthChartId: string | null = null;
	get SelectedDepthChart(): DepthChartModel | undefined{
		return this.team.depthCharts.find(d => d.id === this.selectedDepthChartId);
	}
	@Action({ rawError: true })
	async selectDepthChart(id?: string){
		let selectId = id;
		if (!selectId){
			if (this.team.depthCharts.length > 0) selectId = this.team.depthCharts[0].id;
			else selectId = null;
		}
		this.context.commit(Mutations.SELECT_DEPTH_CHART, selectId);
	}
	@Mutation [Mutations.SELECT_DEPTH_CHART](id: string | null) {
		this.selectedDepthChartId = id;
	}

	@Action({
		rawError: true,
	}) async loadTeam(id: string) {
		this.context.commit(Mutations.LOAD_TEAM);
		try {
			const team = await teamApi.findById(id, { populate: [ 'wtl' ] });
			if(team === null) throw null;
			this.context.commit(Mutations.LOAD_TEAM_SUCCESS, team);
			await this.selectDepthChart();
		} catch (e) {
			this.context.commit(Mutations.LOAD_TEAM_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_TEAM]() {
		this.loadingTeamState = new PageState("Loading");
	}
	@Mutation [Mutations.LOAD_TEAM_SUCCESS](team: TeamModel) {
		this.loadingTeamState = new PageState("Ready");
		this.team = team;
		this.editPositionData = null;
	}
	@Mutation [Mutations.LOAD_TEAM_FAILURE](error: any) {
		this.loadingTeamState = PageState.getPageState(error);
	}

	manualAddLoading: boolean = false;
	manualAddError: string | null = null;

	@Action({
		rawError: true
	}) async addPlayerToTeam({teamId, player} :{teamId: string, player: InvitePlayerFormValue}){
		this.context.commit(Mutations.MANUAL_ADD_PLAYER);
		try {
			let playerId = player.id;
			let newPlayer: PlayerOnTeam | undefined;
			if (!playerId){
				newPlayer = await teamApi.createPlayer(teamId, <PlayerOnTeam>player);
				playerId = newPlayer.id;
			}
			this.context.commit(Mutations.MANUAL_ADD_PLAYER_SUCCESS, newPlayer);
		}catch(e){
			console.log("Failed to Create Player", e);
			this.context.commit(Mutations.MANUAL_ADD_PLAYER_FAILURE, e);
		}
	}
	@Mutation [Mutations.MANUAL_ADD_PLAYER]() {
		this.manualAddLoading = true;
		this.manualAddError = null;
	}
	@Mutation [Mutations.MANUAL_ADD_PLAYER_SUCCESS](newPlayer?: PlayerOnTeam) {
		this.manualAddLoading = false;
		if (newPlayer){
			this.team.players.push(newPlayer);
		}
	}
	@Mutation [Mutations.MANUAL_ADD_PLAYER_FAILURE](errorMessage: string) {
		this.manualAddLoading = false;
		this.manualAddError = errorMessage;
	}

	@Action({
		rawError: true
	}) async addStaffToTeam({teamId, staff} :{teamId: string, staff: InviteStaffFormValue}){
		this.context.commit(Mutations.MANUAL_ADD_STAFF);
		try {
			let staffId = staff.id;
			let newStaff: StaffMember | undefined;
			if (!staffId){
				newStaff = await teamApi.createStaff(teamId, <StaffMember>staff);
				staffId = newStaff.id;
			}
			this.context.commit(Mutations.MANUAL_ADD_STAFF_SUCCESS, newStaff);
		}catch(e){
			console.log("Failed to Create Player", e);
			this.context.commit(Mutations.MANUAL_ADD_PLAYER_FAILURE, e);
		}
	}
	@Mutation [Mutations.MANUAL_ADD_STAFF]() {
		this.manualAddLoading = true;
		this.manualAddError = null;
	}
	@Mutation [Mutations.MANUAL_ADD_STAFF_SUCCESS](newStaff?: StaffMember) {
		this.manualAddLoading = false;
		if (newStaff){
			this.team.staffMembers.push(newStaff);
		}
	}
	@Mutation [Mutations.MANUAL_ADD_STAFF_FAILURE](errorMessage: string) {
		this.manualAddLoading = false;
		this.manualAddError = errorMessage;
	}

	manualInviteLoading: boolean = false;
	manualInviteError: string | null = null;

	@Action({
		rawError: true,
	}) async invitePlayerByEmail({ teamId, email, player } :{teamId: string, email: string, player: InvitePlayerFormValue}) {
		this.context.commit(Mutations.MANUAL_INVITE_PLAYER);
		try {
			let playerId = player.id;
			let newPlayer: PlayerOnTeam | undefined;
			if (!playerId){
				newPlayer = await teamApi.createPlayer(teamId, <PlayerOnTeam>player);
				playerId = newPlayer.id;
			}
			try{
				await teamApi.inviteAthleteToTeamByEmail({ teamId, email, playerId: playerId});
			}catch(e){
				console.log("Failed to invite send athlete invitation", e);
			}
			this.context.commit(Mutations.MANUAL_INVITE_PLAYER_SUCCESS, newPlayer);
			this.context.commit(Mutations.PATCH_PLAYER_SUCCESS, { teamId, playerId, playerPatch: { invitationStatus: InvitationStatus.Pending } });
		} catch (e) {
			console.error("Failed to Create Team", e);
			this.context.commit(Mutations.MANUAL_INVITE_PLAYER_FAILURE, e);
		}
	}
	@Mutation [Mutations.MANUAL_INVITE_PLAYER]() {
		this.manualInviteLoading = true;
		this.manualInviteError = null;
	}
	@Mutation [Mutations.MANUAL_INVITE_PLAYER_SUCCESS](newPlayer?: PlayerOnTeam) {
		this.manualInviteLoading = false;
		if (newPlayer){
			this.team.players.push(newPlayer);
		}
	}
	@Mutation [Mutations.MANUAL_INVITE_PLAYER_FAILURE](errorMessage: string) {
		this.manualInviteLoading = false;
		this.manualInviteError = errorMessage;
	}

	@Action({
		rawError: true,
	}) async inviteStaffByEmail({ teamId, email, staff } :{teamId: string, email: string, staff: InviteStaffFormValue}) {
		this.context.commit(Mutations.MANUAL_INVITE_STAFF);
		try {
			let staffId = staff.id;
			let newStaff: StaffMember | undefined;
			if (!staffId){
				newStaff = await teamApi.createStaff(teamId, <StaffMember>staff);
				staffId = newStaff.id;
			}
			try{
				await teamApi.inviteStaffToTeamByEmail({ teamId, email, staffId: staffId});
			}catch(e){
				console.log("Failed to invite send staff invitation", e);
			}
			this.context.commit(Mutations.MANUAL_INVITE_STAFF_SUCCESS, newStaff);
			// this.context.commit(Mutations.PATCH_STAFF_SUCCESS, { teamId, staffId, staffPatch: { invitationStatus: InvitationStatus.Pending } });
		} catch (e) {
			console.error("Failed to Create Team", e);
			this.context.commit(Mutations.MANUAL_INVITE_PLAYER_FAILURE, e);
		}
	}
	@Mutation [Mutations.MANUAL_INVITE_STAFF]() {
		this.manualInviteLoading = true;
		this.manualInviteError = null;
	}
	@Mutation [Mutations.MANUAL_INVITE_STAFF_SUCCESS](newStaff?: StaffMember) {
		this.manualInviteLoading = false;
		if (newStaff){
			this.team.staffMembers.push(newStaff);
		}
	}
	@Mutation [Mutations.MANUAL_INVITE_STAFF_FAILURE](errorMessage: string) {
		this.manualInviteLoading = false;
		this.manualInviteError = errorMessage;
	}
	// @Mutation [Mutations.PATCH_STAFF_SUCCESS]({ teamId, staffId, staffPatch }: { teamId: string, staffId:string, staffPatch: string }) {
	// 	const staff = this.team.staffMembers.find(p => p.id === staffId);
	// 	Object.assign(staff, staffPatch);
	// 	// this.patchPlayerLoading = false;
	// }

	createDepthChartLoading: boolean = false;
	createDepthChartError: string | null = null;

	@Action({
		rawError: true,
	}) async createDepthChart({ teamId, depthChartForm }: {
		teamId: string,
		depthChartForm: CreateDepthChartFormData
	}) {
		this.context.commit(Mutations.CREATE_DEPTH_CHART);
		try {
			const depthChart = new DepthChartModel().load({
				...depthChartForm.template,
				name: depthChartForm.name,
			});
			if(depthChartForm.autoFill){
				depthChart.autoFillPositions(this.team.players);
			}
			const newDepthChart = await teamApi.createDepthChart({
				teamId,
				depthChart,
			});
			logGAEvent("create_depth_chart");
			this.context.commit(Mutations.CREATE_DEPTH_CHART_SUCCESS, newDepthChart);
			await this.selectDepthChart(newDepthChart.id);
		} catch (e) {
			console.error("Failed to Create DepthChart", e);
			this.context.commit(Mutations.CREATE_DEPTH_CHART_FAILURE, e);
		}
	}
	@Mutation [Mutations.CREATE_DEPTH_CHART]() {
		this.createDepthChartLoading = true;
		this.createDepthChartError = null;
	}
	@Mutation [Mutations.CREATE_DEPTH_CHART_SUCCESS](newDepthChart: DepthChartModel) {
		this.createDepthChartLoading = false;
		this.team.depthCharts.push(newDepthChart);
	}
	@Mutation [Mutations.CREATE_DEPTH_CHART_FAILURE](errorMessage: string) {
		this.createDepthChartLoading = false;
		this.createDepthChartError = errorMessage;
	}

	editDepthChartId: string | null = null;
	editPositionData: DepthChartPosition | null = null;

	@Action({ rawError: true })
	editDepthChartPosition({ depthChartId, position }: { depthChartId: string, position: DepthChartPosition }){
		this.context.commit(Mutations.EDIT_DEPTH_CHART_POSITION, { depthChartId, position });
	}
	@Mutation [Mutations.EDIT_DEPTH_CHART_POSITION]({ depthChartId, position }: { depthChartId: string, position: DepthChartPosition }) {
		this.editDepthChartId = depthChartId;
		this.editPositionData = new DepthChartPosition().load({
			...position,
			playerIds: position.playerIds.slice(),
		});
	}

	saveDepthChartLoading: boolean = false;
	saveDepthChartError: string | null = null;

	@Action({ rawError: true })
	async saveDepthChartPosition({ depthChartId, position }: { depthChartId: string, position: DepthChartPosition }) {
		this.context.commit(Mutations.SAVE_DEPTH_CHART_POSITION, { depthChartId, position });
		const depthChart = this.team.depthCharts.find(d => d.id === depthChartId);
		await teamApi.patchDepthChart({
			teamId: this.team.id,
			depthChartId,
			depthChartPatch:{
				positions: depthChart.positions,
			}
		});
		this.context.commit(Mutations.SAVE_DEPTH_CHART_POSITION_SUCCESS);
	}
	@Mutation [Mutations.SAVE_DEPTH_CHART_POSITION]({ depthChartId, position }: { depthChartId: string, index: number, position: DepthChartPosition }) {
		this.saveDepthChartLoading = true;
		const depthChart = this.team.depthCharts.find(d => d.id === depthChartId);
		const findFn = position.position === SoccerPosition.Goalkeeper ?
			p => p.position === SoccerPosition.Goalkeeper :
			p => (p.row === position.row) && (p.col === position.col) && (p.position !== SoccerPosition.Goalkeeper);

		const positionIndex = depthChart.positions.findIndex(findFn);
		if (positionIndex === -1) {
			depthChart.positions.push(position);
		} else {
			const [ oldPosition ] = depthChart.positions.splice(positionIndex, 1, position);
			/** If the position number changed, find the current position which has that number and swap it with the positions original number */
			if(oldPosition.number !== position.number){
				const swapIndex = depthChart.positions.findIndex((p, i) => i !== positionIndex && p.number === position.number);
				if (swapIndex > -1){
					const originalPosition = depthChart.positions[swapIndex];
					depthChart.positions.splice(swapIndex, 1, { ...originalPosition, number: oldPosition.number} as DepthChartPosition);
				}
			}
		}
		this.editDepthChartId = null;
		this.editPositionData = null;
	}
	@Mutation [Mutations.SAVE_DEPTH_CHART_POSITION_SUCCESS]() {
		this.saveDepthChartLoading = false;
	}
	@Mutation [Mutations.SAVE_DEPTH_CHART_POSITION_FAILURE](errorMessage: string) {
		this.saveDepthChartLoading = false;
		this.saveDepthChartError = errorMessage;
	}

	@Action({ rawError: true })
	cancelDepthChartPosition() {
		this.context.commit(Mutations.CANCEL_EDIT_DEPTH_CHART_POSITION);
	}
	@Mutation [Mutations.CANCEL_EDIT_DEPTH_CHART_POSITION]() {
		this.editDepthChartId = null;
		this.editPositionData = null;
	}

	deleteDepthChartLoading: boolean = false;
	deleteDepthChartError: string | null = null;

	@Action({
		rawError: true,
	}) async deleteDepthChart({ teamId, depthChartId }: { teamId: string, depthChartId: string }) {
		this.context.commit(Mutations.DELETE_DEPTH_CHART);
		try {
			await teamApi.deleteDepthChart({ teamId, depthChartId });
			this.context.commit(Mutations.DELETE_DEPTH_CHART_SUCCESS, { teamId, depthChartId });
			await this.selectDepthChart();
		} catch (e) {
			console.error("Failed to Create Team", e);
			this.context.commit(Mutations.DELETE_DEPTH_CHART_FAILURE, e);
		}
	}
	@Mutation [Mutations.DELETE_DEPTH_CHART]() {
		this.deleteDepthChartLoading = true;
		this.deleteDepthChartError = null;
	}
	@Mutation [Mutations.DELETE_DEPTH_CHART_SUCCESS]({ teamId, depthChartId }: { teamId: string, depthChartId: string }) {
		const deletedIndex = this.team.depthCharts.findIndex(d => d.id === depthChartId);
		this.team.depthCharts.splice(deletedIndex, 1);
		this.deleteDepthChartLoading = false;
	}
	@Mutation [Mutations.DELETE_DEPTH_CHART_FAILURE](errorMessage: string) {
		this.deleteDepthChartLoading = false;
		this.deleteDepthChartError = errorMessage;
	}

	@Action({
		rawError: true,
	}) async duplicateDepthChart({ teamId, name, depthChartId }: { teamId: string, name: string, depthChartId: string }) {
		const depthChart = this.team.depthCharts.find(dc => dc.id === depthChartId);
		const dcTemplate = new DepthChartModel().load(depthChart).copyAsTemplate();
		await this.createDepthChart({
			teamId,
			depthChartForm:{
				name: name,
				template: dcTemplate,
				autoFill: false,
			}
		})
	}

	renameDepthChartLoading = false;
	renameDepthChartError = null;
	@Action({
		rawError: true,
	}) async renameDepthChart({ teamId, newName, depthChartId }: { teamId: string, newName: string, depthChartId: string }) {
		this.context.commit(Mutations.RENAME_DEPTH_CHART);
		try {
			await teamApi.patchDepthChart({
				teamId,
				depthChartId,
				depthChartPatch: {
					name: newName,
				}
			});
			this.context.commit(Mutations.RENAME_DEPTH_CHART_SUCCESS, { newName, depthChartId });
		} catch (e) {
			console.error("Failed to Create DepthChart", e);
			this.context.commit(Mutations.RENAME_DEPTH_CHART_FAILURE, e);
		}
	}
	@Mutation [Mutations.RENAME_DEPTH_CHART]() {
		this.renameDepthChartLoading = true;
		this.renameDepthChartError = null;
	}
	@Mutation [Mutations.RENAME_DEPTH_CHART_SUCCESS]({ newName, depthChartId }: { newName:string, depthChartId: string }) {
		const dc = this.team.depthCharts.find(dc => dc.id === depthChartId);
		dc.name = newName;
		this.renameDepthChartLoading = false;
	}
	@Mutation [Mutations.RENAME_DEPTH_CHART_FAILURE](errorMessage: string) {
		this.renameDepthChartLoading = false;
		this.renameDepthChartError = errorMessage;
	}

	patchPlayerLoading = false;
	patchPlayerError = null;
	@Action({
		rawError: true,
	}) async patchPlayer({ teamId, playerId, playerPatch }: PatchPlayerParams) {
		this.context.commit(Mutations.PATCH_PLAYER);
		try {
			const updatedPlayer = await teamApi.patchPlayer({
				teamId,
				playerId,
				playerPatch,
			});
			this.context.commit(Mutations.PATCH_PLAYER_SUCCESS, { teamId, playerId, playerPatch: updatedPlayer });
		} catch (e) {
			console.error("Failed to Create Player", e);
			this.context.commit(Mutations.PATCH_PLAYER_FAILURE, e);
		}
	}
	@Mutation [Mutations.PATCH_PLAYER]() {
		this.patchPlayerLoading = true;
		this.patchPlayerError = null;
	}
	@Mutation [Mutations.PATCH_PLAYER_SUCCESS]({ teamId, playerId, playerPatch }: { teamId: string, playerId:string, playerPatch: string }) {
		const player = this.team.players.find(p => p.id === playerId);
		Object.assign(player, playerPatch);
		this.patchPlayerLoading = false;
	}
	@Mutation [Mutations.PATCH_PLAYER_FAILURE](errorMessage: string) {
		this.patchPlayerLoading = false;
		this.patchPlayerError = errorMessage;
	}

	removePlayerLoading = false;
	removePlayerError = null;
	@Action({
		rawError: true,
	}) async removePlayer({ teamId, playerId }: RemovePlayerParams) {
		this.context.commit(Mutations.REMOVE_PLAYER);
		try {
			await teamApi.deletePlayer({
				teamId,
				playerId,
			});
			this.context.commit(Mutations.REMOVE_PLAYER_SUCCESS, { teamId, playerId });
		} catch (e) {
			console.error("Failed to Create Player", e);
			this.context.commit(Mutations.REMOVE_PLAYER_FAILURE, e);
		}
	}
	@Mutation [Mutations.REMOVE_PLAYER]() {
		this.removePlayerLoading = true;
		this.removePlayerError = null;
	}
	@Mutation [Mutations.REMOVE_PLAYER_SUCCESS]({ teamId, playerId }: { teamId: string, playerId:string }) {
		const index = this.team.players.findIndex(p => p.id === playerId);
		this.team.players.splice(index, 1);
		this.removePlayerLoading = false;
	}
	@Mutation [Mutations.REMOVE_PLAYER_FAILURE](errorMessage: string) {
		this.removePlayerLoading = false;
		this.removePlayerError = errorMessage;
	}
}