export const OrgAdminDashboard = 'OrgAdminDashboard';
export const OrgAdminManageTeams = 'OrgAdminManageTeams';
export const OrgAdminManagePlayers = 'OrgAdminManagePlayers';
export const OrgAdminManageStaff = 'OrgAdminManageStaff';

export const TeamDashboard = 'TeamDashboard';
export const TeamCreate = 'TeamCreate';
export const TeamEventCreate = 'TeamEventCreate';
export const TeamEventEdit = 'TeamEventEdit';
export const EditTeam = 'EditTeam';
