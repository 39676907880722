import store from '../store';
import { Module, VuexModule, Mutation, Action, MutationAction, } from 'vuex-module-decorators';
import { StatisticModel } from '../../models/statistic'
import { statisticApi } from '@/api/StatisticApi';
import { AgeGroup } from '@best-athletes/ba-types';
import { U13_Fake_Statistics, O20_Fake_Statistics } from '../../../types/constants/statistic';
import { Comparison } from 'types/interfaces';

const Mutations = {
	SET_AGE_GROUPS: 'SET_AGE_GROUPS',

	LOAD_COMPARATIVE_DATA: 'LOAD_COMPARATIVE_DATA',
	LOAD_COMPARATIVE_DATA_SUCCESS: 'LOAD_COMPARATIVE_DATA_SUCCESS',
	LOAD_COMPARATIVE_DATA_FAILURE: 'LOAD_COMPARATIVE_DATA_FAILURE',

	CLEAR_USER_PERFORMANCE_CATEGORIES: 'CLEAR_USER_PERFORMANCE_CATEGORIES',

	GET_USER_PERFORMANCE_CATEGORIES: 'GET_USER_PERFORMANCE_CATEGORIES',
	GET_USER_PERFORMANCE_CATEGORIES_SUCCESS: 'GET_USER_PERFORMANCE_CATEGORIES_SUCCESS',
	GET_USER_PERFORMANCE_CATEGORIES_FAILURE: 'GET_USER_PERFORMANCE_CATEGORIES_FAILURE',
}

const name = 'ComparativeDataStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
/**
 * This store loads athlete profiles which aren't necessarily the user's profile.
 */
export default class ComparativeDataModule extends VuexModule {
	
	featureAvailable: boolean = true;

	@MutationAction
	async setFeatureAvailable(featureAvailable: boolean = false): Promise<{ featureAvailable: boolean }> {
		// TODO[epic=payments,seq=303] Use value provided
		return { featureAvailable: true }
	}


	get Loading(): boolean{
		return !this.ComparisonReady || this.ComparativeDataReady;
	}

	get ComparativeDataLoading(): boolean{
		return this.comparativeDataLoading;
	}

	get Over18(): boolean{
		return [
			AgeGroup.U18,
			AgeGroup.U19,
			AgeGroup.U20,
			AgeGroup.O20,
		].includes(this.MyAgeGroup);
	}

	get MyAgeGroup(): AgeGroup{
		return this.context.rootGetters['AthleteProfileStore/AthleteAgeGroup'];
	}
	compareGroups: AgeGroup[] = [];

	@Action({
		rawError: true,
	}) async setComparativeGroups({ ageGroups, assessmentId, athleteId }: { ageGroups: AgeGroup[], assessmentId: string, athleteId: string }): Promise<void> {
		try {
			this.context.commit(Mutations.SET_AGE_GROUPS, { ageGroups, assessmentId });
			this.loadComparativeData({ athleteId, assessmentId });
		} catch (e) {
			console.error("Failed to Set Age Groups", e);
		}
	}
	@Mutation [Mutations.SET_AGE_GROUPS]({ ageGroups }: { ageGroups: AgeGroup[] }): void {
		this.compareGroups = ageGroups;
	}

	get ComparativeDataReady(): boolean{
		return this.comparativeDataInitialized && !this.comparativeDataLoading;
	}
	comparativeDataLoading: boolean = false;
	comparativeDataInitialized: boolean = false;

	statistics: StatisticModel[] = [];

	@Action({
		rawError: true,
	}) async loadComparativeData({ assessmentId, athleteId }: { assessmentId: string, athleteId: string }): Promise<StatisticModel[]> {
		try {
			if (this.featureAvailable === false) {
				return this.loadDummyComparativeData();
			}

			this.context.commit(Mutations.LOAD_COMPARATIVE_DATA);
			const statistics = await statisticApi.globalStatistics(athleteId, {
				assessmentId,
				ageGroup: [this.MyAgeGroup, ...this.compareGroups],
			});
			this.context.commit(Mutations.LOAD_COMPARATIVE_DATA_SUCCESS, { statistics });
			return statistics;
		} catch (e) {
			console.error("Failed to Load Comparative Data", e);
			this.context.commit(Mutations.LOAD_COMPARATIVE_DATA_FAILURE, e);
		}
	}
	@Action({})
	async loadDummyComparativeData(): Promise<StatisticModel[]> {
		const U13_stats = U13_Fake_Statistics.map(stat => new StatisticModel().load(stat));
		const O20_stats = O20_Fake_Statistics.map(stat => new StatisticModel().load(stat));
		const statistics = [...U13_stats, ...O20_stats];
		this.context.commit(Mutations.LOAD_COMPARATIVE_DATA);
		this.context.commit(Mutations.LOAD_COMPARATIVE_DATA_SUCCESS, { statistics });
		return statistics;
	}

	@Mutation [Mutations.LOAD_COMPARATIVE_DATA](): void {
		this.comparativeDataLoading = true;
	}
	@Mutation [Mutations.LOAD_COMPARATIVE_DATA_SUCCESS]({ statistics }: { statistics: StatisticModel[] }): void {
		this.statistics = statistics;
		this.comparativeDataLoading = false;
		this.comparativeDataInitialized = true;
	}
	@Mutation [Mutations.LOAD_COMPARATIVE_DATA_FAILURE](error: any): void {
		this.comparativeDataLoading = false;
	}

	get ComparisonReady(): boolean {
		return this.comparisonInitialized && this.comparison !== null;
	}
	get Comparison(): Comparison | null {
		return this.comparison;
	}
	comparisonLoading: boolean = false;
	comparisonInitialized: boolean = false;
	comparison: Comparison | null = null;
	@Action({
		rawError: true,
	}) async getUserPerformanceCategories({ assessmentId }: { assessmentId: string }): Promise<Comparison> {
		try {
			this.context.commit(Mutations.GET_USER_PERFORMANCE_CATEGORIES);
			const [ comparison ] = await statisticApi.compare({
				assessmentId,
			});
			this.context.commit(Mutations.GET_USER_PERFORMANCE_CATEGORIES_SUCCESS, { comparison });
			return comparison;
		} catch (e) {
			console.error("Failed to Load Comparative Data", e);
			this.context.commit(Mutations.GET_USER_PERFORMANCE_CATEGORIES_FAILURE, e);
		}
	}

	@Mutation [Mutations.GET_USER_PERFORMANCE_CATEGORIES](): void {
		this.comparisonLoading = true;
	}
	@Mutation [Mutations.GET_USER_PERFORMANCE_CATEGORIES_SUCCESS]({ comparison }: { comparison: Comparison }): void {
		this.comparison = comparison;
		this.comparisonLoading = false;
		this.comparisonInitialized = true;
	}
	@Mutation [Mutations.GET_USER_PERFORMANCE_CATEGORIES_FAILURE](error: any): void {
		this.comparisonLoading = false;
	}

	@Action({
		rawError: true,
	}) async clearUserPerformanceCategories(): Promise<void> {
		this.context.commit(Mutations.CLEAR_USER_PERFORMANCE_CATEGORIES);
	}
	@Mutation [Mutations.CLEAR_USER_PERFORMANCE_CATEGORIES](): void {
		this.comparativeDataLoading = false;
		this.comparison = null;
	}
}
