
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import * as Routes from "@/../types/constants/web_client_user.routes";
import {
	mdiPlus,
	mdiClose,
	mdiMapMarkerOutline,
	mdiChevronDown,
	mdiPaperclip,
	mdiFormatListBulleted,
	mdiDelete,
	mdiDeleteEmpty,
	mdiContentCut,
	mdiCalendar,
	mdiClockCheckOutline,
	mdiCheck,
} from "@mdi/js";
import Discussion from '@/components/courses/Discussion.vue';
import { CurrentTeamMixin, FormRulesMixin, VuetifyMixin, AuthMixin, FeatureFlagMixin } from "@/mixins";
import EventTypesGroup from "@/components/calendar/EventTypesGroup.vue";
import AttachmentsArea from "@/components/forms/AttachmentsArea.vue";
import DatePicker from "@/components/forms/DatePicker.vue";
import TeamMemberSelector from "@/components/forms/TeamMemberSelector.vue";
import TimePickerDialog from "@/components/calendar/TimePickerDialog.vue";
import PlayerManagement from "@/components/teams/PlayerManagement.vue";
import CreateLineupForm from "@/components/teams/CreateLineupForm.vue";
import LineupCard from "@/components/teams/LineupCard.vue";
import ConfirmationDialog from "@/components/ui/ConfirmationDialog.vue";
import PlayerTagger from "@/components/teams/PlayerTagger.vue";
import RecurrenceSelector from "@/components/calendar/RecurrenceSelector.vue";

import {
	clearTime,
	formatDateSlashesYYYYMMDD,
	getDifferenceString,
	getTime,
	getTodayUTC,
	eventProgress,
	formatDateHyphensYYYYMMDD,
	formatDate,
} from "@/helpers";
import { TeamEventType, EventProgress, RecurrenceRuleFrequency } from "@/../types/enums";
import { CalendarEventParticipant, ContextMenuItem, CalendarEventCreateOptions } from "@/../types/interfaces";
import { HOME, AWAY } from "@/../types/constants/team-events";
import { CalendarEventModel } from "@/models/calendar/CalendarEventModel";
import { GameResultModel } from "@/models/calendar/GameResultModel";
import { teamApi } from "@/api/TeamApi";
import BoxScore from "@/components/teams/BoxScore.vue";

import BAVideoStudio from '@/components/videoStudio/BAVideoStudio.vue';
import VideoClipLibrary from '@/components/ui/videos/VideoClipLibrary.vue';
import VideoLibraryProvider from '@/components/hoc/VideoLibraryProvider.vue';
import { Tag } from '@/models/tag/Tag';
import { getEventColor } from '@/helpers/default-event-types';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import { CalendarApi, CalendarEventsApi } from '@/api/CalendarApi';
import { GameResultApi } from '@/api/GameResultApi';
import { LineupModel } from '@/models/lineup';
import { RecurrenceRuleModel } from '@/models/calendar/RecurrenceRuleModel';
import { DepthChartTemplatesMixin } from '@/mixins';
import { DepthChartModel, DepthChartPosition } from '@/models/depthChart';
import { TeamModel } from '@/models/team';
import SoccerDepthChart from '../DepthChart/Soccer/SoccerDepthChart.vue';

enum CalendarEditMode {
	ThisEventOnly,
	ThisAndFollowingEvents,
	AllEvents,
}
@Component({
	components: {
		AttachmentsArea,
		BAVideoStudio,
		CreateLineupForm,
		DatePicker,
		EventTypesGroup,
		TimePickerDialog,
		TeamMemberSelector,
		PlayerManagement,
		LineupCard,
		PlayerTagger,
		RecurrenceSelector,
		ConfirmationDialog,
		BoxScore,
		Discussion,
		VideoClipLibrary,
		VideoLibraryProvider,
		SoccerDepthChart,
	},
})
export default class TeamEventCreateFrom extends Mixins(
	CurrentTeamMixin,
	FormRulesMixin,
	VuetifyMixin,
	AuthMixin,
	DepthChartTemplatesMixin,
	FeatureFlagMixin,
) {
	mdiPlus = mdiPlus;
	mdiDelete = mdiDelete;
	mdiDeleteEmpty = mdiDeleteEmpty;
	mdiClose = mdiClose;
	mdiPaperclip = mdiPaperclip;
	mdiMapMarkerOutline = mdiMapMarkerOutline;
	mdiChevronDown = mdiChevronDown;
	mdiFormatListBulleted = mdiFormatListBulleted;
	mdiContentCut = mdiContentCut;
	mdiCalendar = mdiCalendar;
	mdiClockCheckOutline = mdiClockCheckOutline;
	mdiCheck = mdiCheck;
	formatDate = formatDate;

	$refs: {
		attachmentsArea: AttachmentsArea;
	};

	calendarConfirmDialogVisible: boolean = false;
	calendarEditMode: CalendarEditMode = CalendarEditMode.AllEvents;
	CalendarEditMode = CalendarEditMode;

	calendarNotificationDialogVisible: boolean = false;
	notificationFormValue = {
		message: ""
	};
	openNoficationDialog(): void{
		this.notificationFormValue = { message: "" };
		this.calendarNotificationDialogVisible = true;
	}

	get EventVideoTagFilter(): Tag[]{
		return [
			new Tag(this.editEvent.EventName, this.editEvent.id, this.getColor(getEventColor(this.editEvent.eventType))),
		];
	}

	get ResultsBtnText(): string{
		return "View Results";
	}

	filterPlayers: Tag[] = [];
	playerClipFilter(clip: VideoClipModel): boolean{
		return clip.hasTag(this.filterPlayers);
	}

	videoStudioVisible: boolean = false;
	get ShowEventVideoClips(): boolean{
		return this.IsEditMode;
	}
	get EditEventReady(): boolean{
		return this.editEvent !== null;
	}

	get VideoParentId(): string{
		return this.teamId;
	}
	get EventId(): string | undefined{
		return this.teamEventId;
	}
	get FileUploadApiPrefix(): string {
		return `team/${this.teamId}/calendar/${this.calendarId}/calendarEvent/${this.DocumentUploadId}/files`;
	}

	get IsEditMode(): boolean {
		return this.teamEventId !== undefined;
	}
	get HasParticipants(): boolean {
		return this.formValue.participants.length > 0;
	}

	get EventName(): string {
		if (this.editEvent === null) return this.formValue.eventName;
		if (!this.editEvent.name) return this.editEvent.EventName;
		return this.editEvent.name;
	}

	get EventProgress(): EventProgress{
		if (this.editEvent === null){
			return null
		}
		return eventProgress(this.EventStart, this.EventEnd, this.FocusDate);
	}
	// Original event that is being editted
	editEvent: CalendarEventModel | null = null;
	editGameResult: GameResultModel | null = null;
	rootEvent: CalendarEventModel | null = null;
	// The previous event the edit event was chained off from; only available if the previousEventId is available on editEvent
	previousEvent: CalendarEventModel | null = null;

	get AthleteMode(): boolean {
		return this.athleteMode;
	}

	@Prop({ default: false, type: Boolean }) athleteMode: boolean;
	@Prop({ default: false, type: Boolean }) readOnly: boolean;
	@Prop() calendarEventsApi: CalendarEventsApi;
	@Prop() teamId: string;
	@Prop() teamEventId: string;
	@Prop({ default: null }) focusDate: string;
	get FocusDate(): Date {
		return new Date(this.focusDate ? this.focusDate : formatDate(new Date()));
	}
	@Prop() calendarId: string;
	@Watch("teamEventId") async checkEditTeam(): Promise<void> {
		if (this.teamEventId !== undefined) {
			await this.setupEditForm(this.teamEventId);
		} else {
			await this.resetForm(true);
		}
	}
	async created(): Promise<void> {
		await this.checkEditTeam();
	}
	async resetForm(getId: boolean = false): Promise<void> {
		if(getId){
			await this.initDocumentUploadId();
		}
		this.formValue = this.defaultForm();
	}
	get DocumentUploadId(): string | null{
		const dateSlug = formatDateHyphensYYYYMMDD(this.FocusDate)
		if(this.EventRootId) return `${this.EventRootId}_${dateSlug}`;
		return `${this.documentUploadId}_${dateSlug}`;
	}
	get LineupTitle(): string{
		return this.lineupTitle
	}
	get DepthCharts(): DepthChartModel[]{
		return this.CurrentTeam.depthCharts;
	}
	get EventRootId(): string | null{
		if(this.editEvent){
			return this.editEvent.RootId
		}
		return null;
	}
	
	documentUploadId: string | null = null;
	async initDocumentUploadId(): Promise<void>{
		const { uuid } = await teamApi.uuid();
		this.documentUploadId = uuid;
	}

	async setupEditForm(teamEventId: string): Promise<void> {
		const event = await this.calendarEventsApi.findById(teamEventId);
		this.editEvent = event;
		this.rootEvent = event.rootId !== null ? await this.calendarEventsApi.findById(event.rootId) : new CalendarEventModel().load(event);
		this.previousEvent = event.previousEventId !== null ? await this.calendarEventsApi.findById(event.previousEventId) : null;
		const gameResult = await this.gameResultApi(this.EventRootId).findById(this.GameResultId);
		const rootRecurrenceRule = this.rootEvent !== null && this.rootEvent.recurrenceRule !== null ?
			new RecurrenceRuleModel(this.rootEvent.recurrenceRule.frequency).load(this.rootEvent.recurrenceRule) : null; 
		this.editGameResult = gameResult;
		await this.resetForm();
		this.formValue.eventName = this.EventName;
		this.formValue.eventType = event.eventType;

		// If it is not a recurring event, use the start date from the event
		if (event.recurrenceRule === null) {
			this.formValue.eventInfo = {
				startDate: new Date(
					event.start.getFullYear(),
					event.start.getMonth(),
					event.start.getDate()
				),
				startTime: getTime(event.start, { twentyFourHour: true }),
				endDate: new Date(
					event.end.getFullYear(),
					event.end.getMonth(),
					event.end.getDate()
				),
				endTime: getTime(event.end, { twentyFourHour: true })
			};
		}
		// If it a recurring event, use the date clicked
		else {
			const start = this.FocusDate;
			const end = new Date(start);
			const difference = event.end.getDate() - event.start.getDate();
			end.setDate(end.getDate() + difference);

			this.formValue.eventInfo = {
				startDate: new Date(
					start.getFullYear(),
					start.getMonth(),
					start.getDate()
				),
				startTime: getTime(event.start, { twentyFourHour: true }),
				endDate: new Date(
					end.getFullYear(),
					end.getMonth(),
					end.getDate()
				),
				endTime: getTime(event.end, { twentyFourHour: true })
			};
		}
		this.formValue.eventLocation = {
			venue: event.venue,
			address: event.address,
			venueNotes: event.venueNotes
		};
		if(gameResult !== null){
			this.formValue.gameDetails = {
				homeOrAway: gameResult.homeOrAway,
				opponent: gameResult.opponent,
				uniformCombination: gameResult.uniformCombination,
				lineup: gameResult.lineup,
				shareLineup: gameResult.shareLineup,
				homeScore: gameResult.homeScore,
				opponentScore: gameResult.opponentScore,
				gameOccurence: gameResult.gameOccurence,
			};
			this.lineup = gameResult.lineup;
		}
		this.formValue.participants = event.participants;
		this.unsavedSelectDepthChartModel = new DepthChartModel();
		if (this.lineup !== null){
			this.unsavedSelectDepthChartModel.name = this.lineup.name;
			this.unsavedSelectDepthChartModel.positions = this.lineup.positions;
			this.unsavedSelectDepthChartModel.formation = this.lineup.formation;
			this.unsavedSelectDepthChartModel.type = this.lineup.type;
			let depthChartRow = []
			const lineupRow = this.lineup.formation.split('-')
			for (let i = 0; i < lineupRow.length; i++){
				depthChartRow[i] = parseInt(lineupRow[i])
			}
			this.unsavedSelectDepthChartModel.grid.name = this.lineup.formation;
			this.unsavedSelectDepthChartModel.grid.rows = depthChartRow;
		}
		this.savedSelectDepthChartModel = this.unsavedSelectDepthChartModel.copy()
		if (rootRecurrenceRule !== null) {
			this.formRecurrenceRule = new RecurrenceRuleModel(rootRecurrenceRule.frequency).load(rootRecurrenceRule);
			this.originalRecurrenceRule = new RecurrenceRuleModel(rootRecurrenceRule.frequency).load(rootRecurrenceRule);
		}
	}

	changedButton(participants: CalendarEventParticipant[]): void{
		this.formValue.participants = participants;
	}
	
	changeFormValue(event: CalendarEventModel): void{
		this.formValue = event;
	}
	async saveClicked(): Promise<void> {
		this.isRecurrenceRuleModified();
		if (this.IsEditMode && (this.editEvent.recurrenceRule !== null || this.RecurrenceRuleModified || this.editEvent.inclusionDates?.length > 0)) {
			this.calendarConfirmDialogVisible = true;
		}else if(this.IsEditMode || this.HasParticipants){
			this.openNoficationDialog();
		}else {
			this.submit();
		}
	}
	submitConfirmRecurrence(): void{
		this.calendarConfirmDialogVisible = false;
		if(this.IsEditMode){
			this.openNoficationDialog();
		}else {
			this.submit();
		}
	}
	submitConfirmNotify(notify: boolean = false): void{
		this.calendarNotificationDialogVisible = false;
		this.submit({
			notifyParticipants: notify,
			sourceCoachId: this.CoachId,
			message: this.notificationFormValue.message,
		});
	}

	clearLineup(): void{
		this.formValue.gameDetails.lineup = null;
		this.lineup = null;
		this.lineupName = null;
		this.unsavedSelectDepthChartModel = null;
	}

	get CalendarApi(): CalendarApi | null {
		if (this.teamId === undefined) return null;
		return new CalendarApi('team', this.teamId);
	}

	get HomeOrAway(): string[] {
		return [HOME, AWAY];
	}

	get FormColumns(): {
		left: Record<string, string>;
		right: Record<string, string>;
		}{
		return {
			left: {
				cols: "12",
				lg: "6",
				md: "7"
			},
			right: {
				cols: "12",
				lg: "6",
				md: "5"
			}
		};
	}

	updateLineup():void{
		this.unsavedSelectDepthChartModel.positions = this.lineup.positions;
	}
	showLineup(lineup: LineupModel):void{
		this.showLineupsDialog = false;
		this.unsavedSelectDepthChartModel.name = lineup.formation;
		this.unsavedSelectDepthChartModel.formation = lineup.name;
		this.savedSelectDepthChartModel = this.unsavedSelectDepthChartModel.copy();
		this.formValue.gameDetails.lineup = lineup.copy();
		this.formValue.gameDetails.lineup.type = this.typeOfLineup;
	}

	goBack(): void {
		if (this.$route.query.largeCalendar !== undefined) {
			this.$router.push({
				name: Routes.FullScreenCalendar,
				params:{
					teamId: this.teamId
				}
			});
		}else {
			this.$router.push({
				name: Routes.TeamDashboard
			});
		}
	}

	get Loading(): boolean {
		return !this.CurrentTeamReady;
	}
	get Submitting(): boolean {
		return this.submitLoading;
	}

	get DefaultStartDateUTC(): Date {
		return this.FocusDate;
	}

	get TodayUTC(): Date {
		return getTodayUTC();
	}
	get EventStartDay(): Date {
		return clearTime(this.EventStart);
	}
	get allowedEndDates(): (date: string) => boolean{
		return (date: string): boolean => {
			return clearTime(new Date(this.formValue.eventInfo.startDate)) <= clearTime(new Date(date));
		};
	}
	/** Default the End Date to the same day */
	updateStartDate(): void {
		this.formValue.eventInfo.endDate = this.formValue.eventInfo.startDate;
	}

	/**
	 * Returns when the collection of events start
	 */
	get RootEventStart(): Date | null{
		if (this.IsEditMode && this.rootEvent !== null && this.rootEvent.recurrenceRule !== null) {
			return  this.rootEvent.recurrenceRule.startDate
		}
		else {
			return this.EventStart;
		}
	}

	/**
	 * Used to calculate event duration using difference (EventEnd)
	 */
	get EventStart(): Date | null {
		const startDate = this.formValue.eventInfo.startDate;
		const startTime = this.formValue.eventInfo.startTime;
		if (!startDate || !startTime) {
			return null;
		}
		const date = `${formatDateSlashesYYYYMMDD(
			new Date(startDate),
			true
		)} ${startTime}`;
		return new Date(date);
	}

	/**
	 * Used to calculate event duration using difference (EventStart)
	 */
	get EventEnd(): Date | null {
		const { endDate, endTime } = this.formValue.eventInfo;
		if (!endDate || !endTime) {
			return null;
		}
		return new Date(
			`${formatDateSlashesYYYYMMDD(new Date(endDate), true)} ${endTime}`
		);
	}
	get EventDuration(): string | null {
		if (this.EventStart === null || this.EventEnd === null) {
			return null;
		}
		return getDifferenceString(this.EventStart, this.EventEnd, [
			"days",
			"hours",
			"minutes"
		]);
	}

	longDate(date: Date): string {
		return formatDateHyphensYYYYMMDD(date)
	}
	isEdit: boolean = false;
	lineupTitle: string = '';
	typeOfLineup: string = 'formation' || 'depthChart' || 'event';
	openCreateLineupDialog(createFrom: string): void {
		this.showAddLineup = false;
		this.showLineupsDialog = true;
		this.lineupName = null;
		this.lineup = null;
		this.unsavedSelectDepthChartModel = null;
		if (createFrom === 'formation'){
			this.typeOfLineup = 'formation';
			this.lineupTitle = 'Create Lineup From Formation';
		} else if (createFrom === 'depthChart'){
			this.typeOfLineup = 'depthChart';
			this.lineupTitle = 'Create Lineup From Depth Chart';
		} else if (createFrom === 'previousEvent'){
			this.typeOfLineup = 'event'
			this.lineupTitle = 'Create Lineup From Previous Event'
		}
	}
	openLineupsDialog(): void {
		this.showAddLineup = false;
		this.showLineupsDialog = true;
		if (this.savedSelectDepthChartModel){
			this.unsavedSelectDepthChartModel = this.savedSelectDepthChartModel.copy();
		}
		const substitutes = this.formValue.gameDetails.lineup.substitutes;
		const formation = this.unsavedSelectDepthChartModel.copy();
		formation.autoFillPositions(this.CurrentTeamPlayers);
		this.lineup = LineupModel.GenerateLineup(this.unsavedSelectDepthChartModel.formation, formation);
		this.lineup.type = this.unsavedSelectDepthChartModel.type;
		this.lineup.substitutes = substitutes;
		if (this.lineup.type){
			if (this.lineup.type === 'formation'){
				this.typeOfLineup = 'formation';
				this.lineupTitle = 'Create Lineup From Formation';
			} else if (this.lineup.type === 'depthChart'){
				this.typeOfLineup = 'depthChart';
				this.lineupTitle = 'Create Lineup From Depth Chart';
			}
		}
		if (!this.lineup.name){
			this.lineupName = this.lineup.formation;
			this.lineup.name = this.lineupName;
		} else {
			this.lineupName = this.lineup.name;
		}
	}
	cancelCreateLineup(): void{
		this.showLineupsDialog = false;
		this.isEdit = false;
	}
	openAddLineupDialog(): void {
		this.showAddLineup = true;
		this.showLineupsDialog = true;
	}
	showLineupsDialog: boolean = false;
	showAddLineup: boolean = false;
	lineup: LineupModel | null = null;

	get LineupHidden(): boolean {
		return (
			this.AthleteMode &&
			this.EditGameResultShareLineup === false
		);
	}
	get EditGameResultShareLineup(): boolean{
		if(this.editGameResult === null) return false;
		return this.editGameResult.shareLineup;
	}
	get FormHasLineup(): boolean {
		return this.formValue.gameDetails.lineup !== null;
	}
	unsavedSelectDepthChartModel: DepthChartModel | null = null;
	savedSelectDepthChartModel: DepthChartModel | null = null;
	lineupName: string = '';
	depthChartEdit(goalie: DepthChartPosition): void{
		if (goalie.position === "Goalkeeper"){
			this.unsavedSelectDepthChartModel.positions[10] = goalie
		} 
		this.lineup.positions = this.unsavedSelectDepthChartModel.positions
	}
	selectFormation(team: TeamModel, template: DepthChartModel): void{
		for (let i = 0; i < template.positions.length; i++){
			template.positions[i].playerIds = []
		}
		const formation = template.copy();
		formation.autoFillPositions(team.players);
		this.lineup = LineupModel.GenerateLineup('', formation);
		this.lineup.formation = formation.name;
		if (this.lineup.name === ''){
			this.lineupName = this.lineup.formation
			this.lineup.name = this.lineupName
		}
		this.unsavedSelectDepthChartModel.formation = this.lineup.formation
		this.unsavedSelectDepthChartModel.positions = this.lineup.positions;
	}
	
	selectDepthChart(depthChart: DepthChartModel): void{
		const newLineup = new LineupModel();
		newLineup.name = depthChart.name;
		newLineup.positions = depthChart.positions;
		newLineup.team = this.CurrentTeam;
		newLineup.formation = depthChart.grid.name;
		newLineup.setSubstitutes();
		this.lineupName = depthChart.name;
		this.lineup = newLineup;
	}

	get IsGame(): boolean {
		return this.formValue.eventType === TeamEventType.Game;
	}
	get IsPractice(): boolean {
		return this.formValue.eventType === TeamEventType.Practice;
	}
	get IsSocial(): boolean {
		return this.formValue.eventType === TeamEventType.Social;
	}
	get IsGameOrPractice(): boolean {
		return this.IsGame || this.IsPractice;
	}
	get CreateLineupOptions(): ContextMenuItem[] {
		const items = [
			{
				text: 'Create new lineup from formation',
				icon: mdiFormatListBulleted,
				click: () => this.openCreateLineupDialog('formation'),
			},
			{
				text: 'Create new lineup from depth chart',
				icon: mdiPlus,
				click: () => this.openCreateLineupDialog('depthChart'),
			},
			// {
			// 	text: 'Generate from previous event',
			// 	icon: mdiPlus,
			// 	click: () => this.openCreateLineupDialog('previousEvent'),
			// }
		];
		return items;
	}

	getDurationLabel(value: { hour: string; minute: string }): string {
		const { endDate } = this.formValue.eventInfo;
		const diff = getDifferenceString(
			this.EventStart,
			new Date(
				`${formatDateSlashesYYYYMMDD(new Date(endDate), true)} ${
					value.hour
				}:${value.minute}`
			),
			["days", "hours", "minutes"]
		);
		if (diff.length > 0) {
			return `(${diff})`;
		}
		return "";
	}
	
	defaultForm = (): Record<string, any> => ({
		valid: false,
		eventName: "",
		eventType: TeamEventType.Game,
		eventInfo: {
			startDate: this.DefaultStartDateUTC,
			startTime: "",
			endDate: this.DefaultStartDateUTC,
			endTime: ""
		},
		eventLocation: {
			venue: "",
			address: "",
			venueNotes: ""
		},
		gameDetails: {
			homeOrAway: "",
			opponent: "",
			uniformCombination: "",
			lineup: null,
			shareLineup: false,
			homeScore: null,
			opponentScore: null,
			gameOccurence: [],
		},
		participants: [],
	});
	formValue = this.defaultForm();
	formRecurrenceRule: RecurrenceRuleModel | null = null;
	eventAttachment: boolean = false;

	submitLoading: boolean = false;
	submitClicked: boolean = false;

	gameResultApi(eventRootId: string):GameResultApi{
		if (eventRootId === null) return;
		return new GameResultApi('team', this.teamId, this.calendarId, eventRootId);
	}

	get GameResultId(): string{
		if (this.EventRootId){
			return `${this.EventRootId}_${formatDateHyphensYYYYMMDD(this.FocusDate)}`;
		}
		return this.DocumentUploadId;
	}
	originalRecurrenceRule: RecurrenceRuleModel | null = null;

	async recurrenceRuleUpdated(newRule: RecurrenceRuleModel): Promise<void> {
		this.formRecurrenceRule = newRule;
		this.isRecurrenceRuleModified();
	}
	isRecurrenceRuleModified(): boolean {
		if (this.originalRecurrenceRule === null) {
			return false;
		}

		this.EagerRecurrenceRuleModified = !this.originalRecurrenceRule.isEqual(this.formRecurrenceRule);
		return this.EagerRecurrenceRuleModified;
	}
	EagerRecurrenceRuleModified: boolean = false;
	get RecurrenceRuleModified(): boolean {
		return this.EagerRecurrenceRuleModified;
	}

	async submit(opts: CalendarEventCreateOptions = {
		notifyParticipants: false,
		sourceCoachId: this.CoachId,
		message: ""
	}): Promise<void> {
		this.submitLoading = true;
		const event = new CalendarEventModel();
		if(this.documentUploadId !== null){
			event.id = this.documentUploadId;
		}

		event.name = this.formValue.eventName;
		event.parentId = this.calendarId;
		event.eventType = this.formValue.eventType;
		event.start = this.EventStart;
		event.end = this.EventEnd;
		event.venue = this.formValue.eventLocation.venue;
		event.venueNotes = this.formValue.eventLocation.venueNotes;
		event.address = this.formValue.eventLocation.address;
		event.participants = this.formValue.participants;
		event.recurrenceRule = this.formRecurrenceRule;

		const gameResult = new GameResultModel();
		gameResult.id = this.GameResultId;
		gameResult.parentId = this.calendarId;
		gameResult.start = this.longDate(this.EventStart);
		gameResult.end = this.longDate(this.EventEnd);
		gameResult.homeOrAway = this.formValue.gameDetails.homeOrAway;
		gameResult.uniformCombination = this.formValue.gameDetails.uniformCombination;
		gameResult.lineup = this.formValue.gameDetails.lineup;
		gameResult.shareLineup = this.formValue.gameDetails.shareLineup;
		gameResult.homeScore = this.formValue.gameDetails.homeScore;
		gameResult.opponent = this.formValue.gameDetails.opponent;
		gameResult.opponentScore = this.formValue.gameDetails.opponentScore;
		gameResult.gameOccurence = this.formValue.gameDetails.gameOccurence;
	
		const attachmentsAreaComponent: AttachmentsArea = this.$refs[
			"attachmentsArea"
		];
		
		try {
			if (!this.IsEditMode) {
				event.rootId = event.id;
				const savedEvent = await this.calendarEventsApi.insert(event);
				await this.gameResultApi(savedEvent.rootId).insert(gameResult);
				await attachmentsAreaComponent.uploadAllFiles(this.EventRootId);
			} else {
				// Check if the user is editting a recurring event
				let savedEvent: CalendarEventModel;
				if (event.recurrenceRule !== null) {
					if (this.calendarEditMode === CalendarEditMode.ThisEventOnly) {
						const updatedEvent = this.editEvent;
						updatedEvent.exceptionDates.push(this.FocusDate);
						savedEvent = await this.calendarEventsApi.save(updatedEvent, { params: opts });

						delete event['id'];
						event.recurrenceRule = null;
						event.inclusionDates.push(this.FocusDate);
						event.rootId = this.rootEvent !== null ? this.rootEvent.id : savedEvent.id;
						event.previousEventId = savedEvent.id;
						savedEvent = await this.calendarEventsApi.insert(event);
					}
					else if (this.calendarEditMode === CalendarEditMode.ThisAndFollowingEvents) {
						/**
						 * End the orginal event and create a seperate one for continuation
						 */
						const excludeRule = new RecurrenceRuleModel(RecurrenceRuleFrequency.Daily);
						excludeRule.startDate = new Date(this.FocusDate);
						excludeRule.until = null;
						this.editEvent.exceptionRules.push(excludeRule);
						const savedEditEvent = await this.calendarEventsApi.save(this.editEvent, { params: opts });

						// If there is a previous event, then exclude the dates from that as well
						if (this.previousEvent !== null) {
							this.previousEvent.exceptionRules.push(excludeRule);
							this.calendarEventsApi.save(this.previousEvent)
						}

						// Get the root id
						if (this.rootEvent !== null) {
							event.rootId = this.rootEvent.id;
						}
						else if (savedEditEvent.rootId !== undefined && savedEditEvent.rootId !== null) {
							event.rootId = savedEditEvent.rootId;
						}
						else{
							event.rootId = savedEditEvent.id;
						}
						// Chain link to the previous evnet
						event.previousEventId = savedEditEvent.id;

						event.recurrenceRule.startDate = excludeRule.startDate;
						event.recurrenceRule.until = this.formRecurrenceRule.until;
						
						await this.calendarEventsApi.pruneByRootId(event.rootId, event.recurrenceRule.startDate);

						event.rootId = this.editEvent.rootId;
						// Need for proper calculation of difference
						event.start = this.EventStart;
						event.end = this.EventEnd;
						
						savedEvent = await this.calendarEventsApi.insert(event);
					}
					else if (this.calendarEditMode === CalendarEditMode.AllEvents) {
						/**
						 * Override the original event with the new event details
						 * Restore the original recurrence rule and remove any exceptions
						 */					
						if (this.editEvent.rootId !== null && this.rootEvent !== null) {
							event.id = this.editEvent.rootId;
							event.recurrenceRule.startDate = this.RootEventStart;
							// event.recurrenceRule = this.rootEvent.recurrenceRule;
						}
						else {
							event.id = this.editEvent.id;
						}
						event.rootId = event.id;
						event.previousEventId = null;
						event.exceptionDates = [];
						event.exceptionRules = [];
						const rootId = this.editEvent.rootId !== null ? this.editEvent.rootId : this.editEvent.id; 
						
						// Save the new updated event and delete the rest of the events 
						await this.calendarEventsApi.deleteByRootId(rootId);
						savedEvent = await this.calendarEventsApi.save(event, { params: opts });
					}
					else {
						const savedEvent = await this.calendarEventsApi.save(event, { params: opts });
						event.id = savedEvent.id;
					}
				}
				else {
					event.id = this.editEvent.id;
					savedEvent = await this.calendarEventsApi.save(event, { params: opts });
				}

				// If the recurrence rule date was modified then update the date
				if (this.isRecurrenceRuleModified()){
					await this.calendarEventsApi.setRecurrenceUntilByRootId(this.rootEvent.id, this.formRecurrenceRule.until);
				}
				this.calendarConfirmDialogVisible = false;
				await this.gameResultApi(this.EventRootId).save(gameResult)
				await attachmentsAreaComponent.uploadAllFiles(this.EventRootId);
			}
		} catch (e) {
			// console.error("Failed to Save or Update", e);
		} finally {
			this.submitClicked = true;
			this.submitLoading = false;
		}
		this.goBack();
	}

	showDeleteDialog: boolean = false;
	deleteEventLoading: boolean = false;
	async deleteEvent(): Promise<CalendarEventModel | null> {
		try {
			const teamEvent = new CalendarEventModel();
			teamEvent.id = this.teamEventId;
			teamEvent.rootId = teamEvent.id;
			this.deleteEventLoading = true;
			if (this.editEvent.rootId){
				await this.calendarEventsApi.deleteByRootId(this.editEvent.rootId);
			} else {
				await this.calendarEventsApi.delete(this.editEvent)
			}
			this.deleteEventLoading = false;
			this.goBack();
			return teamEvent;
		} catch (e) {
			this.deleteEventLoading = false;
			console.log("Failed to delete event", e);
			return null;
		}
	}
	goToEventView(): void {
		this.$router.push({
			name: Routes.TeamEventView,
			params:{
				teamId: this.teamId,
				teamEventId: this.EventId,
			},
			query: {
				focusDate: this.EventStart.toISOString(),
			}
		})
	}
}
