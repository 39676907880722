import { AxiosError } from "axios"

export const errorIsOneOf = (e: AxiosError, statusCodes: number[] = []): boolean => {
	return statusCodes.includes(e?.response?.status);
};

export const errorIs401 = (e: AxiosError): boolean => {
	return e?.response?.status === 401;
};

export const errorIs403 = (e: AxiosError): boolean => {
	return e?.response?.status === 403;
};

export const errorIs404 = (e: AxiosError): boolean => {
	return e?.response?.status === 404;
};