import { Component, Vue } from 'vue-property-decorator';
import { AthleteMetric, AthleteMetricValues } from '../../types/enums';
import { getMetricIcon } from '../pipes/metric-icon.pipe';
import { getMetricColor } from '../pipes/metric-color.pipe';
import { getMetricUnitLabel } from '../pipes/metric-unit-label.pipe';
import { getMetricUnit } from '../pipes/metric-unit.pipe';

@Component
export class AthleteMetricsMixin extends Vue {
	get AllAthleteMetrics(): AthleteMetric[]{
		return AthleteMetricValues;
	}
	get AthleteMetricSelfAssessmentVideoEmbedUrl(): Record<AthleteMetric, string>{
		return {
			[AthleteMetric.Power]: "https://www.youtube.com/embed/2TU4lmjA464",
			[AthleteMetric.Acceleration]: "https://www.youtube.com/embed/Qs29-ITNvjg",
			[AthleteMetric.Speed]: "https://www.youtube.com/embed/mMtVvAUewjk",
			[AthleteMetric.Agility]: "https://www.youtube.com/embed/ziN758tyiik",
			[AthleteMetric.Recovery]: "https://www.youtube.com/embed/reX3zra1bpw",
		};
	}
	getMetricIcon = getMetricIcon;
	getMetricColor = getMetricColor;
	getMetricUnitLabel = getMetricUnitLabel;
	getMetricUnit = getMetricUnit;
}
