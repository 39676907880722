import './store';
import { getModule } from 'vuex-module-decorators'

import ActionUrlModule from './actions/actionUrl.store';
import AssessmentCreateModule from './dataEntry/AssessmentCreate.store'
import AthleteProfileModule from './athletes/AthleteProfile.store'
import CoachResumeCreateModule from './coaches/CoachResumeCreate.store';
import CoachProfile from './coaches/CoachProfile.store'
import ComparativeDataModule from './athletes/ComparativeData.store'
import ComparativeDataV2Module from './athletes/ComparativeDataV2.store'
import ComparativeDataV2SampleDataModule from './athletes/ComparativeDataV2SampleData.store'
import CoursesModule from './courses/courses.store';
import CourseCreateModule from './courses/CourseCreate.store';
import CourseDashboardModule from './courses/CourseDashboard.store'
import ClipCreationToolModule from './videoEditorPlayer/ClipCreationTool.store';
import DepthChartsModule from './depthCharts/DepthCharts.store';
import EventsAdminModule from './bestAthletesEvents/EventsAdmin.store';
import EventDetailsModule from './bestAthletesEvents/EventDetails.store';
import ImagesModule from './images/Images.store';
import NotificationModule from './notifications/Notification.store';
import NavigationModule from './user/Navigation.store';
import StudentCourses from './students/StudentCourses.store'
import TeamsModule from './teams/Teams.store';
import TeamCreateModule from './teams/TeamCreate.store';
import TeamDashboardModule from './teams/TeamDashboard.store';
import UserModule from './user/User.store';
import VideoEditorModule from './videoEditorPlayer/VideoEditor.store';
import VideoModule from "./videoStudio/Video.store";
import VideoClipModule from './videoStudio/VideoClips.store';
import SchoolModule from './school/School.store';

export {PatchPlayerParams} from './teams/TeamDashboard.store';

export const actionUrlStore = getModule(ActionUrlModule);
export const assessmentCreateStore = getModule(AssessmentCreateModule);
export const athleteProfile = getModule(AthleteProfileModule);
export const coachResumeCreateStore = getModule(CoachResumeCreateModule);
export const coachProfile = getModule(CoachProfile);
export const comparativeDataStore = getModule(ComparativeDataModule);
export const comparativeDataV2Store = getModule(ComparativeDataV2Module);
export const comparativeDataV2SampleDataStore = getModule(ComparativeDataV2SampleDataModule);
export const coursesStore = getModule(CoursesModule);
export const courseDashboardStore = getModule(CourseDashboardModule);
export const courseCreateStore = getModule(CourseCreateModule);
export const clipCreationToolStore = getModule(ClipCreationToolModule);
export const depthChartsStore = getModule(DepthChartsModule);
export const eventsAdminStore = getModule(EventsAdminModule);
export const eventDetailsStore = getModule(EventDetailsModule);
export const imagesStore = getModule(ImagesModule);
export const navigationStore = getModule(NavigationModule);
export const notificationStore = getModule(NotificationModule);
export const studentCoursesStore = getModule(StudentCourses);
export const teamsStore = getModule(TeamsModule);
export const teamCreateStore = getModule(TeamCreateModule);
export const teamDashboardStore = getModule(TeamDashboardModule);
export const userStore = getModule(UserModule);
export const videoEditorStore = getModule(VideoEditorModule);
export const videoStore = getModule(VideoModule)
export const videoClipStore = getModule(VideoClipModule)
export const schoolStore = getModule(SchoolModule);