
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';
import CircledAvatar from '@/components/ui/CircledAvatar.vue';
import { NotificationModel } from '@/models/notification/NotificationModel';
import { getDifference } from '@/helpers/date';
import { toFixed } from '@/pipes';
import { NotificationStatus } from '@/../types/enums';
import { mdiEmail, mdiEmailOpen, mdiClose } from '@mdi/js';

@Component({
	components: { CircledAvatar },
})
export default class NotificationMenuItem extends Mixins(VuetifyMixin){
	mdiClose = mdiClose
	@Prop({ required: true }) notification: NotificationModel;

	get Now(): Date{
		return new Date();
	}
	get NotificationTime(): Date{
		return this.notification.createdAt;
	}

	get IsRead(): boolean{
		return this.notification.status === NotificationStatus.READ;
	}

	get NotificationIcon(): string{
		if(this.notification.icon === null || this.notification.icon === undefined){
			if(this.notification.IsRead){
				return mdiEmailOpen;
			}
			return mdiEmail;
		}
		return this.notification.icon;
	}
	get ReadToggleText(): string{
		if(this.notification.IsRead){
			return 'Mark as unread';
		}
		return 'Mark as read';
	}

	get TimePast(): string{
		const durations: any[] = ['days', 'hours', 'minutes'];
		const durationSingular: any[] = ['day', 'hour', 'minute'];
		const dur = getDifference(this.NotificationTime, this.Now, durations);
		const firstDurationIndex = durations.map(d => dur[d]).findIndex(d => d >= 1);
		const firstDurationUnit = durations[firstDurationIndex];
		const firstDurationValue = dur[durations[firstDurationIndex]];
		if(firstDurationValue >= 2){
			return `${toFixed(firstDurationValue, 0)} ${firstDurationUnit} ago`;
		}
		if(firstDurationValue >= 1){
			return `${toFixed(firstDurationValue, 0)} ${durationSingular[firstDurationIndex]} ago`;
		}
		return 'Just now';
	}

	toggleRead(): void{
		this.$emit('toggle-read');
	}
	deleteNotification(): void{
		this.$emit('delete');
	}
}
