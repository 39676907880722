import { PlanFeature } from '../interfaces/PlanFeature';

export const PLAN_FEATURES: PlanFeature[] = [
	{
		id: 1,
		html: "Player & Recruitment Profile",
	},
	{
		id: 2,
		html: "Self Assessment",
	},
	{
		id: 3,
		html: "Team Features",
		description: "Connect with Team, Calendar, Roster & Video"
	},
	{
		id: 4,
		html: "Video Suite storage space",
	},
	{
		id: 5,
		html: "Profile video clip & highlight reel",
	},
	{
		id: 6,
		html: "College & University Search",
	},
	{
		id: 7,
		html: "Share Recruitment profile",
	},
	{
		id: 8,
		html: "Sports Cognitive Test",
		description: "(13yrs+ 12 page report)"
	},
	{
		id: 9,
		html: "Verified Physical Assessment",
	},
	{
		id: 10,
		html: "Comparative data analysis",
	},
	{
		id: 11,
		html: "Skills Assessment by Scout",
	},
	{
		id: 12,
		html: "Reverse Search by Coaches",
	},
];