import { Component, Vue } from 'vue-property-decorator';
import { localforage } from '../plugins/localforage';
@Component
export class DarkModeMixin extends Vue {
	get DarkModeEnabled(): boolean {
		return this.$vuetify.theme.dark;
	}
	async initDarkMode(): Promise<void> {
		const dark = await localforage.getItem<boolean>('ba_theme_dark_mode');
		this.setDarkMode(dark);
	}
	async setDarkMode(value: boolean): Promise<void> {
		this.$vuetify.theme.dark = value ? true : false;
		await localforage.setItem<boolean>('ba_theme_dark_mode', this.$vuetify.theme.dark);
	}
}
