
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiChevronDown, mdiBell, mdiAccount, mdiAccountCircle, mdiBrightness6, mdiFlagOutline, mdiCog } from '@mdi/js';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { RoleName } from '@/../types/enums';
import { AuthMixin, VuetifyMixin, AppHostnameMixin } from '@/mixins';
import { userStore, notificationStore } from '@/store';
import AppSwitcher from '@/components/navigation/AppSwitcher.vue';
import CircledAvatar from '@/components/ui/CircledAvatar.vue';
import ContextMenu from '@/components/ui/ContextMenu.vue';
import NotificationMenu from '@/components/ui/NotificationMenu.vue';
import { NotificationModel } from '@/models/notification/NotificationModel';
import MyProfilesProvider from '@/components/hoc/MyProfilesProvider.vue';

@Component({
	components: { AppSwitcher, CircledAvatar, ContextMenu, MyProfilesProvider, NotificationMenu },
})
export default class UserMenu extends Mixins(AuthMixin, AppHostnameMixin, VuetifyMixin){
	mdiAccountCircle = mdiAccountCircle;
	mdiChevronDown = mdiChevronDown;
	mdiBell = mdiBell;
	mdiBrightness6 = mdiBrightness6;
	mdiFlagOutline = mdiFlagOutline;
	mdiAccount = mdiAccount;
	mdiCog = mdiCog;

	@Prop({ type: Boolean, default: false }) dark: boolean;
	@Prop({ type: Boolean, default: false }) mobile: boolean;
	@Prop({ type: Boolean, default: false }) loading: boolean;
	@Prop({ type: Boolean, default: false }) athlete: boolean;
	@Prop({ type: Boolean, default: false }) coach: boolean;

	avatarSize: number = 43;

	height: number = 1000;
	onResize(): void{
		this.height = window.innerHeight;
	}
	get ProfilesScrollWrapperStyle(): Record<string,any>{
		return {
			'max-height': `${this.height - 500}px`
		};
	}

	get Loading(): boolean{
		return this.loading;
	}

	get IconColor(): string{
		if(this.dark) return 'white';
		return 'baColorVibrantBlue'
	}

	get Notifications(): NotificationModel[]{
		return notificationStore.OrderedNotifications;
	}
	
	get Context(): RoleName{
		if(this.athlete === true) return RoleName.Athlete;
		return RoleName.Coach;
	}

	goToSettings(): void{
		switch (this.getAppType) {
		case "coach":
			this.$router.push({
				path: '/c/settings',
				query: { u: this.CurrentUserProfileId }
			}).catch(() => {});
			break;
		default:
			this.$router.push({
				name: Routes.AccountSettingsPersonalInfo,
				query: { u: this.CurrentUserProfileId }
			}).catch(() => {});
			break;
		}
	}

	get PictureUrl(): string | undefined{
		if(!userStore.PictureUrl) return undefined;
		return userStore.PictureUrl;
	}

	get FullName(): string{
		return userStore.FullName;
	}

	logout(): void{
		this.$auth.logout();
	}

	addProfile(): void{
		userStore.startOnboarding();
	}

}
