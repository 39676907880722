import { Component, Vue } from 'vue-property-decorator';

@Component
export class PollingMixin extends Vue {
	protected pollIntervalMs: number = 30000;
	private pollInterval: number | null = null;
	private pollCallbacks: Array<() => any> = [];
	pollCallback(cb: () => any): void{
		if(this.pollInterval === null){
			this.initPollInterval();
		}
		this.pollCallbacks.push(cb);
	}
	removeCallback(cb: () => any): boolean{
		const index = this.pollCallbacks.findIndex(c => c === cb);
		if(index > -1){
			this.pollCallbacks.splice(index, 1);
			return true;
		}
		return false;
	}
	private initPollInterval(): void{
		// console.log("Initializing Poll interval");
		this.pollInterval = setInterval(this.runPolledCallbacks.bind(this), this.pollIntervalMs);
	}
	private runPolledCallbacks(): void{
		// console.log("runPolledCallbacks");
		this.pollCallbacks.forEach(cb => cb.apply(null));
	}
	beforeDestroy(): void{
		// console.log("Destroying Poll interval");
		if(this.pollInterval){
			clearInterval(this.pollInterval);
		}
	}
}
