import { SoccerGameTiming } from "types/enums";
import { GameOccurence } from "types/interfaces";
import { FrontEndModel } from "../FrontEndModel";
import { LineupModel } from "../lineup";

export class GameResultModel extends FrontEndModel{
	constructor(parentId: string | null = null){
		super();
		this.parentId = parentId;
	}

	parentId: string | null;
	uniformCombination: string = '';
	homeOrAway: string = '';
	opponent: string = '';
	lineup: LineupModel | null = null;
	shareLineup: boolean = false;
	homeScore: number | null;
	opponentScore: number | null;
	gameOccurence: GameOccurence[] = [];
	attendanceFinished: boolean = true;
	gameTiming: SoccerGameTiming;
	showTimeline: boolean = false;
	
	/**Identifies which GameResult to show for a certain event
	 * Should be the same start and end date as the calendar event
	 */
	start: string = '';
	end: string = '';

	/**
	 * `@ Opponent Name` - Away games  
	 * `vs. Opponent Name` - Home Games  
	 */
	get OpponentText(): string | null{
		if(!this.opponent) return null;
		if(this.homeOrAway === 'away'){
			return `@ ${this.opponent}`;
		}
		return `vs. ${this.opponent}`;
	}
	get Outcome(): string | null{
		if(this.homeScore === null || this.opponentScore === null) return null;
		if(this.homeScore > this.opponentScore) return 'W';
		if(this.homeScore < this.opponentScore) return 'L';
		return 'T';
	}
}