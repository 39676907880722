import { AxiosError } from 'axios';
import { PageState, PageStates } from '../PageState';

export type ComparativeDataPageStates = PageStates | "NoAssessment" | "NotEnoughData"; 

export class ComparativeDataPageState extends PageState<ComparativeDataPageStates>{
	get IsNotEnoughData(): boolean{
		return this.state === "NotEnoughData";
	}
	get IsNoAssessment(): boolean{
		return this.state === "NoAssessment";
	}

	static getComparativeDataPageState(e: AxiosError | null): ComparativeDataPageState {
		return new ComparativeDataPageState(PageState.getPageState<ComparativeDataPageStates>(e).State);
	}
}