
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';
import { VDialog } from 'vuetify/lib';

@Component
export default class Dialog extends Mixins(VuetifyMixin){
	get Component(): any{
		if(this.inline){
			return 'div';
		}
		return VDialog;
	}
	@Prop() show: boolean = false;
	updateShow(show: boolean): void{
		this.$emit('update:show', show);
	}

	@Prop({ default: 'baColorDeepBlue' }) color: string;
	@Prop({ default: false, type: Boolean }) inline: boolean;
	@Prop({ default: true, type: Boolean }) dark: boolean;
	@Prop({ default: false, type: Boolean }) fullscreen: boolean;
	@Prop({ default: false, type: Boolean }) hideActions: boolean;
	@Prop({ default: false, type: Boolean }) hideTitle: boolean;
	@Prop({ default: 'pa-8' }) titlePadding: string;
	@Prop({ default: () => {}, type: Object }) customHeaderStyles: Record<string, string>
	@Prop({ default: true, type: Boolean }) controlsRuler: boolean;

	get HeaderStyle(): Record<string, any>{
		return {
			'font-weight': '500',
			'font-size': '16px',
			'color': this.getColor(this.dark ? 'white' : 'baColorPrimaryText'),
			'background-color': this.getColor(this.color),
			...this.customHeaderStyles
		};
	}

	get UseFullScreen(): boolean{
		return !this.inline && (this.$vuetify.breakpoint.smAndDown || this.fullscreen);
	}
}
