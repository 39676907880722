
import { Component, Mixins, PropSync } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';
import { ExperienceItem } from '@/models/coach/CoachResumeModel';
import DatePicker from "@/components/forms/DatePicker.vue";

@Component({
	components: { 
		DatePicker
	}
})
export default class CoachSettingCoachingExperience extends Mixins(VuetifyMixin){
	@PropSync('experience', { required: true }) 
	Experience: ExperienceItem;
}
