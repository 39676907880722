/**
 * Returns false if the metric value is not available.  
 * A missing metric should be null or undefined but NaN and "--" and "" will also return false.  
 * This ensures that a true result of `0` is not identified as false.
 */
export function metricHasValue(value?: number | string | null): boolean {
	if (value === null || value === undefined || Number.isNaN(value) || value === "--" || value === ""){
		return false;
	}
	return true;
}
