
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';

@Component
export default class EditOverlay extends Mixins(VuetifyMixin){

	private isHovered: boolean = false;
	@Prop({ default: false, type: Boolean }) private small: boolean;
	@Prop({ default: 'white' }) private color: string;
	@Prop({ default: 'baColorVibrantBlue' }) private backgroundColor: string;

	mouseOver(){
		this.isHovered = true;
		this.$emit('edit-overlay:mouseover');
	}
	mouseOut(){
		this.isHovered = false;
		this.$emit('edit-overlay:mouseout');
	}

	get TextColorClass(): string{
		return `${this.color}--text`;
	}
	get OverlayStyle(){
		return {
			'color': `${this.getColor(this.color)} !important`,
			'background-color': this.getColor(this.backgroundColor),
		};
	}
}
