
import { VuetifyMixin } from "@/mixins";
import { Mixins, Component, Prop } from "vue-property-decorator";
import Dialog from "@/components/ui/Dialog.vue";
import ExcelDataTable from "@/components/ui/ExcelDataTable.vue";
import { CSVUploadMixin } from "@/mixins/CSVUploadMixin";


@Component({
	components: { ExcelDataTable, Dialog }
})
export default class CSVHelpDialog extends Mixins(VuetifyMixin, CSVUploadMixin) {
	@Prop({default: false}) visible: boolean;

	sampleTemplateLocation = "/download/SampleTemplate.csv";

	sampleCSVData = [
		{
			row: 1,
			number: 52,
			firstName: "Abigail",
			lastName: "Armstrong",
			email: "abby101@yten.nl",
			position: "Striker",
			gradYear: "2019"
		},
		{
			row: 2,
			number: 35,
			firstName: "Danica",
			lastName: "Donald",
			email: "aanny101@yten.nl",
			position: "Left Midfielder",
			gradYear: "2019"
		},
		{
			row: 3,
			number: 90,
			firstName: "Ellen",
			lastName: "Eist",
			email: "ellie101@yten.nl",
			position: "Right Midfielder",
			gradYear: "2020"
		},
		{
			row: 4,
			number: 42,
			firstName: "Gabrielle",
			lastName: "Grimaldi",
			email: "gabby101@yten.nl",
			position: "Center Midfielder",
			gradYear: "2021"
		}
	];

	get dialogVisbility() {
		return this.visible
	}
	set dialogVisbility(value: boolean) {
		this.$emit('update:visible', value)
	}
}
