import { getAnalytics, logEvent, EventNameString, setUserId, setUserProperties } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { ENV_NAME } from '../router/router';
import { CrudApi } from '@/api/CrudApi'
import { mixpanelTrack } from '@/plugins/mixpanel'

// Your web app's Firebase configuration
const firebaseConfig = getFirebaseConfig();

function getFirebaseConfig(){
	if(ENV_NAME === "production"){
		return {
			apiKey: "AIzaSyBBs61Iba7uUiGvGVEwZaVaNIU6GB6PxtQ",
			authDomain: "prod-hinder.firebaseapp.com",
			databaseURL: "https://prod-hinder.firebaseio.com",
			projectId: "prod-hinder",
			storageBucket: "prod-hinder.appspot.com",
			messagingSenderId: "612401938245",
			appId: "1:612401938245:web:cc7ce2cc4601a5ef75482c",
			measurementId: "G-KGQL5QL8BH"
		};
	}else if(ENV_NAME === "stage" || ENV_NAME === "staging"){
		return {
			apiKey: "AIzaSyA10ViGPvEQCsYwoX0jv2LcmCjOrqDFIXw",
			authDomain: "stage-hinder.firebaseapp.com",
			databaseURL: "https://stage-hinder.firebaseio.com",
			projectId: "stage-hinder",
			storageBucket: "stage-hinder.appspot.com",
			messagingSenderId: "531625184105",
			appId: "1:531625184105:web:1d437402da72729d3b8bf8",
			measurementId: "G-YGLT8JQE9N"
		};
	}else if(ENV_NAME === "testing"){
		return {
			apiKey: "AIzaSyA0w0YcrT7KFQMbcO-SIWoydNuIDQiu8ho",
			authDomain: "test-hinder.firebaseapp.com",
			databaseURL: "https://test-hinder.firebaseio.com",
			projectId: "test-hinder",
			storageBucket: "test-hinder.appspot.com",
			messagingSenderId: "409709831036",
			appId: "1:409709831036:web:8f97a08301bcca178a3af9"
		};
	}
	// Dev/Local
	return {
		apiKey: "AIzaSyB81NZOtD34-yvU8aPHXG_DrpNnmWeMaMM",
		authDomain: "hinder-33db4.firebaseapp.com",
		databaseURL: "https://hinder-33db4.firebaseio.com",
		projectId: "hinder",
		storageBucket: "hinder.appspot.com",
		messagingSenderId: "493258914376",
		appId: "1:493258914376:web:7b286d61dc1d3732a2f67d"
	};
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

export const analytics = getAnalytics();

export function logGAEvent(eventName: BAAnalyticsEvent, eventParams?: Record<string, any>): void {
	CrudApi.Api(api => api.put('/tracking/event', {
		event: eventName
	})).catch(e => {});

	// Mixpanel Tracking
	try {
		mixpanelTrack(eventName, eventParams);
	} catch (e) {
		console.error(`Mixpanel tracking error: `)
		console.error(e)
	}
	
	// Google Analytics Tracking
	try {
		logEvent(analytics, eventName as string, eventParams);
	} catch (e) {
		console.error(`Google analytics error: `)
		console.error(e)
	}
}
export function setGAUserId(userId: string): void {
	return setUserId(analytics, userId);
}
export function setGAUserProperties(params: Partial<Record<BAAnalyticsUserProperty, unknown>>): void {
	return setUserProperties(analytics, params);
}

type BAAnalyticsEvent =
	"scouting_report_average_checked" |
	"scouting_report_code_checked" |
	"scouting_report_claimed" |
	"verified_assessment_code_checked" |
	"verified_assessment_claimed" |
	"spq_code_checked" |
	"spq_claimed" |
	"team_created" |
	"create_depth_chart" |
	"confirm_purchase" |
	"video_uploaded" |
	"video_clip_created" |
	"upgrade_now_clicked" |
	"upgrade_plan_selected" |
	"referral" |
	EventNameString;

type BAAnalyticsUserProperty =
	"has_self_assessment" |
	"has_spq" |
	"has_verified_assessment" |
	"is_coach" |
	"is_parent" |
	"is_athlete" |
	"plan_purchased" |
	"onboarding_from_organization"