
import { Component, Mixins, PropSync} from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';
import { CoachProfileModel } from '@/models/coach/CoachProfileModel';
import ProfilePictureUpload from '@/components/forms/ProfilePictureUpload.vue';

@Component({
	components: {
		ProfilePictureUpload,
	}
})
export default class CoachSettingPersonalInfo extends Mixins(VuetifyMixin){
	@PropSync('coachProfile', { required: true, type: CoachProfileModel }) CoachProfile: CoachProfileModel;

	showImageUploadDialog = false;
	valid = false;
}
