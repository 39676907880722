import { EBCost, EBOrderAnswer, EBOrderQuestion } from '../../../types/interfaces/EventbriteTypes';
import { FrontEndModel } from '../FrontEndModel';
import { BAEventWaiverModel } from './BAEventWaiverModel';

export class BAEventOrderModel extends FrontEndModel {
	orderIdBase64: string;
	eventbriteId: string;
	eventbriteEventId: string;
	costs: EBCost;
	created: Date;
	changed: Date;
	name: string;
	firstName: string;
	lastName: string;
	email: string;
	status: string;
	promoCode?: string;
	questions?: EBOrderQuestion[];
	answers?: EBOrderAnswer[];
	userId: string | null = null;
	/**
	 * List of **signed** waivers for this order
	 */
	waivers: Array<BAEventWaiverModel | string> = [];
	get PopulatedWaivers(): BAEventWaiverModel[] {
		const populated = this.waivers.filter(doc => (typeof doc !== 'string'));
		return <BAEventWaiverModel[]>populated;
	}

	load<BAEventOrderModel>(obj: Partial<BAEventOrderModel>): this {
		Object.assign(this, obj);
		if (obj['created']) this.created = new Date(obj['created']);
		if (obj['changed']) this.changed = new Date(obj['changed']);
		return this;
	}

	waiverIsSigned(templateId: string): boolean{
		const waiver = this.PopulatedWaivers.find(w => w.smartwaiverTemplateId === templateId);
		return waiver !== undefined;
	}
}