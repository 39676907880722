import { Currency } from '@/../types/enums/currency';
import { IntentStatus } from '@/../types/enums/intent-status';
import { FrontEndModel } from '@/models/FrontEndModel';
import { logGAEvent, setGAUserProperties } from '@/plugins/firebase';
import { stripe } from '@/plugins/stripe';
import { ConfirmCardPaymentData, PaymentIntentResult } from '@stripe/stripe-js';
import { LineItemModel } from './LineItemModel';
import { TaxLineItemModel } from './TaxLineItemModel';

export class CheckoutIntentModel<M = unknown> extends FrontEndModel {
	title: string = "";
	dateCreated: Date = new Date();
	parentId: string = "";
	currency: Currency = Currency.USD;
	lineItems: LineItemModel<M>[] = [];
	status: IntentStatus | null = null;
	stripePaymentMethodId: string | null = null;
	stripeClientSecret: string | null = null;
	stripeIntentId: string | null = null;
	stripeInvoiceId: string | null = null;
	stripeCheckoutUrl: string | null = null;

	ComputedSubTotal: number;
	ComputedTotal: number;
	TaxesAndFees: TaxLineItemModel[] = [];

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if(obj['dateCreated']) this.dateCreated = new Date(obj['dateCreated']);
		this.lineItems = this.lineItems.map(item => new LineItemModel<M>().load(item));
		this.TaxesAndFees = this.TaxesAndFees.map(item => new TaxLineItemModel().load(item));
		return this;
	}

	async confirmPurchase({ paymentData }: { paymentData: ConfirmCardPaymentData}): Promise<PaymentIntentResult>{
		const result = await (await stripe).confirmCardPayment(this.stripeClientSecret, paymentData);
		if (result.paymentIntent) {
			this.setStripeDetails(result.paymentIntent);
		}
		logGAEvent("confirm_purchase");
		return result;
	}

	setStripeDetails(stripeDetails: PaymentIntentResult['paymentIntent']): CheckoutIntentModel {
		this.stripeIntentId = stripeDetails.id;
		this.stripeClientSecret = stripeDetails.client_secret;
		this.status = stripeDetails.status as IntentStatus;
		return this;
	}
}