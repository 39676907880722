import { AccessControlledModel } from '../AccessControlledModel';
import { BAEventAttendeeModel } from './BAEventAttendeeModel';
import { BAEventTicketClassModel } from './BAEventTicketClassModel';
import { BAEventOrderModel } from './BAEventOrderModel';
import { EBPrice, EBVenue } from '../../../types/interfaces/EventbriteTypes';
import { SWTemplate } from '../../../types/interfaces/SmartwaiverTypes';

export interface GrossAccumulator {
	[currency: string]: number;
}
interface EventWaiverModel {
	noWaivers: boolean;
	waiverTemplates: SWTemplate[];
}
export class BestAthletesEventModel extends AccessControlledModel {

	eventbriteId: string;
	name: string;
	description: string;
	descriptionHtml: string;
	summary: string;
	url: string;
	start?: Date;
	end?: Date;
	status: string;
	capacity: number;
	currency: string;
	onlineEvent: boolean;
	isLocked: boolean;
	isFree: boolean;
	logo?: string;
	originalLogo?: string;
	isSeries?: boolean;
	isSeriesParent?: boolean;

	venue: EBVenue | null = null;

	attendees: Array<BAEventAttendeeModel | string> = [];
	get PopulatedAttendees(): BAEventAttendeeModel[] {
		const populated = this.attendees.filter(doc => (typeof doc !== 'string'));
		return <BAEventAttendeeModel[]>populated;
	}
	ticketClasses: Array<BAEventTicketClassModel | string> = [];
	get PopulatedTicketClassses(): BAEventTicketClassModel[]{
		const populated = this.ticketClasses.filter(doc => (typeof doc !== 'string'));
		return <BAEventTicketClassModel[]>populated;
	}
	orders: Array<BAEventOrderModel | string> = [];
	get PopulatedOrders(): BAEventOrderModel[] {
		const populated = this.orders.filter(doc => (typeof doc !== 'string'));
		return <BAEventOrderModel[]>populated;
	}

	get IsRecurringEventParent(): boolean {
		return this.isSeriesParent ?? false;
	}

	waivers: EventWaiverModel = {
		noWaivers: false,
		waiverTemplates: [],
	};

	get WaiversNeeded(): boolean{
		if(this.waivers.noWaivers == true){
			return false;
		}
		if (this.waivers.waiverTemplates.length > 0){
			return false;
		}
		return true;
	}

	static AllRefs: string[] = [
		'attendees',
		'ticketClasses',
		'orders',
		'orders.waivers',
	];


	get TotalTicketsSold(): number{
		return this.PopulatedTicketClassses.filter(t => t.IsAdmission).map(tc => tc.quantitySold).reduce((a,b) => a + b, 0);
	}
	get TotalTickets(): number{
		return this.PopulatedTicketClassses.filter(t => t.IsAdmission).map(tc => tc.quantityTotal).reduce((a, b) => a + b, 0);
	}

	/**
	 * This is a rough total of the Gross currency list. Used for Sorting the Gross in the table.
	 * 
	 * **Currency conversion is NOT considered here.**
	 */
	get GrossTotal(): number{
		const currencies = Object.keys(this.Gross);
		if (currencies.length === 0) return -1;
		return currencies.map(currency => this.Gross[currency]).reduce((a,b) => a+b, 0);
	}
	/**
	 * Returns a total of the order values which have been populated keyed by Currency.
	 * 
	 * **Requires populating `orders`**
	 */
	get Gross(): GrossAccumulator{
		return this.PopulatedOrders.filter(order => order.status === 'placed').map(order => order.costs.gross)
			.reduce((accumulator: GrossAccumulator, orderGross: EBPrice) => {
				if(accumulator[orderGross.currency] === undefined){
					accumulator[orderGross.currency] = 0
				}
				accumulator[orderGross.currency] += orderGross.value;
				return accumulator;
			}, {});
	}

	load<T = this>(obj: Partial<T>): this {
		Object.assign(this, obj);
		if(obj['start']) this.start = new Date(obj['start']);
		if(obj['end']) this.end = new Date(obj['end']);
		this.attendees = this.attendees.map(doc => {
			if(typeof doc === 'string') return doc;
			return new BAEventAttendeeModel().load(doc);
		});
		this.ticketClasses = this.ticketClasses.map(doc => {
			if (typeof doc === 'string') return doc;
			return new BAEventTicketClassModel().load<BAEventTicketClassModel>(doc);
		});
		this.orders = this.orders.map(doc => {
			if (typeof doc === 'string') return doc;
			return new BAEventOrderModel().load(doc);
		});
		return this;
	}

}