import { getEnumValues } from './get-enum-values';
export enum RoleName{
  Admin='Admin',
  ClubAdmin='ClubAdmin',
  UserAdmin='UserAdmin',
  EventAdmin='EventAdmin',
  FreeUser='FreeUser',
  InstructorAdmin='InstructorAdmin',

  Athlete='Athlete',
  Coach='Coach',
  Parent='Parent',

  PaidRole='PaidRole',
}
export const RoleNameValues = getEnumValues<RoleName>(RoleName);
