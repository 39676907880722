import { FrontEndModel } from "../FrontEndModel";

export class PaymentHistoryModel extends FrontEndModel {
	intentId: string;
	parentId: string;
	paymentCardBrand: string | null;
	paymentCardLast4: string | null;
	amount: number;
	dateCreated: Date;
	status: string;
	text: string;

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if(obj['dateCreated']) this.dateCreated = new Date(obj['dateCreated']);
		return this;
	}
}