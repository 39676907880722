
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mdiPlus, mdiAccount, mdiShieldAccount, mdiAccountMultiple } from '@mdi/js'
import SideNavSectionTitle from './SideNavSectionTitle.vue';
import BestAthletesLogo from '../ui/BestAthletesLogo.vue';
import { Route } from 'vue-router';
import * as OrgAdminRoutes from '@/../types/constants/org-admin.routes';

@Component({
	components: {
		BestAthletesLogo,
		SideNavSectionTitle,
	},
})
export default class OrgAdminSideNav extends Vue{
	mini = false;
	mdiPlus = mdiPlus;
	mdiAccount = mdiAccount;
	mdiAccountMultiple = mdiAccountMultiple;
	mdiShieldAccount = mdiShieldAccount;

	@Prop({ default: true, type: Boolean }) value: boolean;
	@Prop({ default: 'baColorDeepBlue' }) color: string;

	get Color(): string{
		return this.color;
	}

	get OrganizationId(): string | null{
		if(this.$route.params.organizationId){
			return this.$route.params.organizationId;
		}
		return null;
	}

	get OrgTeamPage(): Partial<Route> | undefined{
		if(this.OrganizationId === null) return undefined;
		return {
			name: OrgAdminRoutes.OrgAdminManageTeams,
			params:{
				organizationId: this.OrganizationId,
			}
		}
	}
	get OrgPlayersPage(): Partial<Route> | undefined{
		if(this.OrganizationId === null) return undefined;
		return {
			name: OrgAdminRoutes.OrgAdminManagePlayers,
			params:{
				organizationId: this.OrganizationId,
			}
		}
	}
	get OrgStaffPage(): Partial<Route> | undefined{
		if(this.OrganizationId === null) return undefined;
		return {
			name: OrgAdminRoutes.OrgAdminManageStaff,
			params:{
				organizationId: this.OrganizationId,
			}
		}
	}

	createTeam(): void{
		this.$router.push({
			name: OrgAdminRoutes.TeamCreate,
		});
	}
}
