import { CrudApi } from './CrudApi';
import { FileModel } from '@/models/file/FileModel';

class FileApi extends CrudApi<FileModel> {
	constructor() {
		super('file', (obj) => new FileModel().load(obj));
	}
	async upload(file: Blob, prefix: string, onUploadProgress): Promise<FileModel> {
		const formData = new FormData();

		formData.append('file', file);
		formData.append('prefix', prefix);

		const newObj = await CrudApi.Api((c) => c.post(`/${this.resource}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress: onUploadProgress
		}));
		return new FileModel().load(newObj);
	}
}

export const fileApi = new FileApi();