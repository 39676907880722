import { AssignmentModel } from './AssignmentModel';
import { FrontEndModel } from '../FrontEndModel';

export class StudentModel extends FrontEndModel{
	courseId: string;
	coachProfileId: string | null = null;
	email: string = "";
	assignments: AssignmentModel[] = [];

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		this.assignments = this.assignments.map(p => new AssignmentModel().load(p));
		return this;
	}
}

