import { CrudApi } from './CrudApi';
import { CoachProfileModel } from '../models/coach/CoachProfileModel';
import { PublicProfileInfo } from 'types/interfaces';

class CoachApi extends CrudApi<CoachProfileModel>{
	constructor(){
		super('coach', (obj) => new CoachProfileModel().load(obj));
	}

	async getBasicProfile(id: string): Promise<PublicProfileInfo<CoachProfileModel>> {
		return await CrudApi.Api(c => c.get(`/${this.resource}/public/${id}/profile-basic`));
	}

	async getProfilePicture(id: string): Promise<PublicProfileInfo<CoachProfileModel>> {
		return await CrudApi.Api(c => c.get(`/${this.resource}/public/${id}/profile-picture`));
	}
}
export const coachApi = new CoachApi();