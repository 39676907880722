import { CrudApi } from './CrudApi';
import { NotificationModel } from '../models/notification/NotificationModel';
import { NotificationStatus } from '@best-athletes/ba-types';

class NotificationApi extends CrudApi<NotificationModel>{
	constructor(){
		super('notification', (obj) => new NotificationModel().load(obj));
	}

	async findByRecipientId(id: string): Promise<NotificationModel[]> {
		const objs = await CrudApi.Api(c => c.get('/notification/all?recipientId=' + id))
		return objs.map((obj: any) => this.create(obj));
	}

	async deleteNotification(notificationId: string): Promise<void> {
		await CrudApi.Api(c => c.delete('/notification/' + notificationId));
	}
	
	async setNotificationStatus(notificationId: string, status: NotificationStatus): Promise<void> {
		await CrudApi.Api(c => c.post('/notification/' + notificationId + '/status/' + status))
	}
}
export const notificationApi = new NotificationApi();