
import { Component, Mixins } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { AuthMixin, AppHostnameMixin, CurrentAthleteMixin, FeatureFlagMixin } from "@/mixins";
import {
	AccordionSettings,
	AccordionAccountSettingHeaderDetail
} from "@/components/ui/AccordionSettings.vue";
import EditRecruitingProfileBasic from "@/components/profile/recruiting/EditRecruitingProfileBasic.vue";
import EditRecruitingProfilePrivacy from "@/components/profile/recruiting/EditRecruitingProfilePrivacy.vue";
import EditRecruitingProfileBio from "@/components/profile/recruiting/EditRecruitingProfileBio.vue";
import { EditRecruitingProfileSports } from "@/components/profile/recruiting/EditRecruitingProfileSports.vue";
import { EditRecruitingProfileEducation } from "@/components/profile/recruiting/EditRecruitingProfileEducation.vue";
import AthleteSettingsPaymentForm from "@/components/profile/settings/AthleteSettingsPaymentForm.vue";
import AthleteSettingsSubscriptionForm from "@/components/profile/settings/AthleteSettingsSubscriptionForm.vue";
import AthleteAccessControlFormSlim from "@/components/profile/settings/AthleteAccessControlFormSlim.vue";
import { AthleteProfileModel } from '@/models';
import convert from 'convert-units';
import RoundedButton from "@/components/forms/RoundedButton.vue";
import { athleteProfile, notificationStore } from '@/store';
import { PatchAthleteProfileOptions } from '@/store/athletes/AthleteProfile.store';
import { NotificationPreferenceSetting, NotificationToPreferenceSettingMapping } from "@/components/profile/settings/NotificationPreferenceSetting.vue";
import AccountSettingsChangePassword from "@/components/profile/settings/AccountSettingsChangePassword.vue";
import { mdiKey } from '@mdi/js';

@Component({
	components: {
		Page,
		AccordionSettings,
		EditRecruitingProfileBasic,
		EditRecruitingProfileSports,
		EditRecruitingProfileEducation,
		EditRecruitingProfileBio,
		EditRecruitingProfilePrivacy,
		AthleteSettingsPaymentForm,
		AthleteSettingsSubscriptionForm,
		AthleteAccessControlFormSlim,
		AccountSettingsChangePassword,
		RoundedButton,
		NotificationPreferenceSetting,
	},
})
export class AthleteAccordionSettings extends Mixins(
	AuthMixin,
	AppHostnameMixin,
	CurrentAthleteMixin,
	AuthMixin,
	FeatureFlagMixin
) {
	mdiKey = mdiKey

	accordionHeadersOpen: number = -1;

	savedAthleteProfile: AthleteProfileModel;
	unsavedAthleteProfile: AthleteProfileModel;

	loading: boolean = false;

	get UnsavedProfile(): AthleteProfileModel {
		return this.unsavedAthleteProfile;
	}
	set UnsavedProfile(profile: AthleteProfileModel) {		
		this.unsavedAthleteProfile = new AthleteProfileModel().load(profile);
	}
	profileLoaded: boolean = false;
	profileLoadingId: number;

	notificationSettings: NotificationToPreferenceSettingMapping[] = [
		{
			label: "Core Account Emails",
			value: true,
			mapping: ["verifiedAssessments", "spqResults", "scoutingReports", "accessGranted", "athleteScoutingReport", "athleteTeamInvitations"]
		},
		{
			label: "Team Calendar Event Notifications",
			value: true,
			mapping: ["athleteTeamCalendarEvents"]
		},
		{
			label: "Marketing Emails",
			value: true,
			mapping: ["newsAndUpdates"]
		}
	];

	get AccordionHeaders(): AccordionAccountSettingHeaderDetail[] {
		return [
			{
				title: "Personal Information",
				description: "Manage your name, birthday and more.",
				icon: '$personalInfo',
				enabled: true,
			},
			{
				title: "Sports",
				description: "Manage the sports you play.",
				icon: '$soccerball',
				enabled: true,
			},
			{
				title: "My Family",
				description: "Control who has access to this athlete profile.",
				icon: '$family',
				enabled: true,
			},
			{
				title: "Bio",
				description: "Tell your profile viewers a little about yourself.",
				icon: '$bio',
				enabled: true,
			},
			{
				title: "Education",
				description: "Manage information about your educational career.",
				icon: '$education',
				enabled: true,
			},
			{
				title: "Profile Visibility",
				description: "Control which parts of your profile can be seen.",
				icon: '$visibility',
				enabled: true,
			},
			{
				title: "Password & Security",
				description: "Change your password and manage 2FA settings.",
				icon: "$key",
				enabled: this.feature<string>('HIN-1584-password-change') !== 'disabled',
			},
			{
				title: "Notification Preferences",
				description: "Choose which types of notifications you will receive.",
				icon: '$settingmail',
				enabled: this.feature<boolean>('HIN-1583-notification-frontend'),
			},
			{
				title: "Payments",
				description: "Manage your payment methods and view payment history.",
				icon: '$payment',
				enabled: true,
			},
			{
				title: "Subscription",
				description: "View and manage your Best Athletes subscription.",
				icon: '$subscription',
				enabled: true,
			},
		];
	}

	async created(): Promise<void>{
		await this.initProfile()
		
	}
	async initProfile(): Promise<void> {		
		await athleteProfile.getAthleteProfile(this.CurrentUserProfileId);
		
		if (this.CurrentAthleteId) {		
			this.unsavedAthleteProfile = new AthleteProfileModel().load(athleteProfile.athleteProfile);
			this.unsavedAthleteProfile.height = this.unsavedAthleteProfile.height
				? parseFloat(
					convert(this.unsavedAthleteProfile.height)
						.from("cm")
						.to(this.unsavedAthleteProfile.heightUnit)
						.toFixed(2)
				)
				: null;
			this.unsavedAthleteProfile.weight = this.unsavedAthleteProfile.weight
				? parseFloat(
					convert(this.unsavedAthleteProfile.weight)
						.from("kg")
						.to(this.unsavedAthleteProfile.weightUnit)
						.toFixed(2)
				)
				: null;
			this.savedAthleteProfile = new AthleteProfileModel().load(this.CurrentAthleteProfile);
			this.savedAthleteProfile.height = this.unsavedAthleteProfile.height;
			this.savedAthleteProfile.weight = this.unsavedAthleteProfile.weight;
			this.profileLoaded = true;
		}
	}

	set Loading(newLoading: boolean) {
		if (newLoading === false)
			setTimeout(() => this.loading = false, 1000);
		else
			this.loading = true;
	}
	get Loading(): boolean {
		return this.loading;
	}

	async saveProfile(validate: () => Promise<{ isValid, errors, flags, fields }>, invite: boolean = false, ): Promise<void> {
		const { isValid } = await validate();
		if (!isValid) {
			notificationStore.snackbarShowError("Some required information is missing");
			return;
		}
		
		// Convert from the user's unit of preference to metric
		let height = this.unsavedAthleteProfile.height
			? convert(this.unsavedAthleteProfile.height)
				.from(this.unsavedAthleteProfile.heightUnit)
				.to("cm")
			: null;
		let mass = this.unsavedAthleteProfile.weight
			? convert(this.unsavedAthleteProfile.weight)
				.from(this.unsavedAthleteProfile.weightUnit)
				.to("kg")
			: null;
		this.unsavedAthleteProfile.recruitingProfileComplete = true;
		this.Loading = true;
		let patchOptions: PatchAthleteProfileOptions = { patch: { ...this.unsavedAthleteProfile, weight: mass, height: height } }
		if (invite){
			patchOptions = {
				...patchOptions,
				options: { withAcls: true },
				updateOptions: { sourceUserId: this.UserId, inviteNewRelationshipUsers: true }
			}
		}
		await athleteProfile.patchAthleteProfile(patchOptions)
		this.savedAthleteProfile = new AthleteProfileModel().load(this.unsavedAthleteProfile);
		this.Loading = false;
		notificationStore.snackbarChangesSavedSuccess();
	}
}
export default AthleteAccordionSettings;
