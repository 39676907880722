import { Component, Mixins, Vue } from 'vue-property-decorator';
import UpgradeNowButton from '@/components/forms/UpgradeNowButton.vue';
import { UserSubscriptions } from '@/../types/interfaces';
import { SubscriptionModel } from '@/models/stripe/SubscriptionModel';
import { userApi } from '@/api/UserApi';
import { logGAEvent } from '@/plugins/firebase';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { AppHostnameMixin } from '.';

@Component({
	components:{
		UpgradeNowButton
	}
})
export class UpgradeNowMixin extends Mixins(AppHostnameMixin) {
	get ShowUpgradeNowButton(): boolean {
		if(this.$route.name === Routes.UpgradeSubscriptionPage) return false;
		if(this.$route.name === Routes.AthleteCheckoutPage) return false;
		if(this.AthleteAppActive === false) return false;
		if(this.userSubscriptions === null){
			this.loadUserSubscriptions();
		}else if(this.UserPaidSubscriptions.length === 0){
			return true;
		}
		return false;
	}
	get UserPaidSubscriptions(): SubscriptionModel[] | null {
		if (this.userSubscriptions === null) return null;
		return this.userSubscriptions.athleteProfiles.subscriptions.filter(sub => !sub.plan.free);
	}

	goToUpgrade(): void {
		logGAEvent("upgrade_now_clicked");
		this.$router.push('/upgrade').catch(() => { });
	}

	userSubscriptions: UserSubscriptions<SubscriptionModel> | null = null;
	async loadUserSubscriptions(): Promise<void> {
		this.userSubscriptions = await userApi.getUserSubscriptions();
	}
}
