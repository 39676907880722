import Vue from 'vue'
import Vuex from 'vuex'
import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';

const logrocketPlugin = createPlugin(LogRocket);

import discussions from './modules/discussions'
import featureFlags from './modules/featureFlags'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		discussions,
		featureFlags,
	},
	plugins: [logrocketPlugin]
})
