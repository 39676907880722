import { FrontEndModel } from '../../models/FrontEndModel';

export class FileRecordModel extends FrontEndModel {
	parentId: string = "";
	name: string = "";
	description?: string;
	mimetype: string = "";
	objectStorageBucket: string = "";
	objectStorageKey: string = "";

	public: boolean = false;

	/** Fontend only, local file in memory */
	file: File | null = null;

	get IsUploaded(): boolean{
		if(this.id && this.objectStorageKey && this.objectStorageKey.length > 0){
			return true;
		}
		return false;
	}

	public static create({ parentId, file }:{ parentId?: string, file: File }): FileRecordModel{
		return new FileRecordModel().load({
			parentId: parentId,
			name: file.name,
			mimetype: file.type,
			file: file,
		});
	}
}