import { CrudApi } from './CrudApi';
import { MarkupTagClipMuxModel } from '../models/markup-tag-clip-mux/MarkupTagClipMuxModel';


class MarkupTagClipMuxModelApi extends CrudApi<MarkupTagClipMuxModel>{
	constructor() {
		super('markupTagClipMux', (obj) => new MarkupTagClipMuxModel().load(obj));
	}

	async getAllClipsForSVWithId(svId: string): Promise<MarkupTagClipMuxModel[]> {
		return await CrudApi.Api(c => c.get(`/markupTagClipMux/sourcevideo/${svId}`));
	}

	async getAllClipsForTeamEventWithId(teamEventId: string): Promise<MarkupTagClipMuxModel[]> {
		return await CrudApi.Api(c => c.get(`/markupTagClipMux/teamevent/${teamEventId}`));
	}
  
	async getAllClipsForTeamWithId(teamId: string): Promise<MarkupTagClipMuxModel[]> {
		return await CrudApi.Api(c => c.get(`/markupTagClipMux/team/${teamId}`));
	}

	async deleteClipWithId(clipId: string): Promise<void> {
		return await CrudApi.Api(c => c.delete(`/markupTagClipMux/${clipId}`));

	}
}
export const markupTagClipMuxModelApi = new MarkupTagClipMuxModelApi();