import { AthleteAssessmentDataModel } from '@/models'
import { AnalyticsAssessmentFilter } from '../../../../../../../libs/ba-types/src/interfaces'
import assessmentPaginationJSON from './assessmentPagination.json'
import spiderChartDataJSON from './spiderChartData.json'
import spiderChartFiltersJSON from './spiderChartFilters.json'
import statisticsJSON from './statistics.json'
import lineChartPercentilesJSON from './lineChartPercentiles.json'
import { StatisticModel } from '@/models/statistic'

export const assessmentPagination = {
	...assessmentPaginationJSON,
	docs: assessmentPaginationJSON.docs.map(d => new AthleteAssessmentDataModel().load(d)),
};

export const spiderChartData = spiderChartDataJSON;
export const spiderChartFilters = spiderChartFiltersJSON as AnalyticsAssessmentFilter[];
export const statistics = statisticsJSON.map(d => new StatisticModel().load(d));
export const lineChartPercentiles = lineChartPercentilesJSON;