import { CrudApi } from './CrudApi';
import { VideoModel } from '@/models/video/VideoModel';

export class VideoApi extends CrudApi<VideoModel>{
	constructor(apiPrefix: string = 'video'){
		super(apiPrefix, (obj) => new VideoModel().load(obj));
	}

	async createMuxUploadURL(parentId: string): Promise<{video: VideoModel, url: string} | null> {
		return await CrudApi.Api(c => c.post('/video/createMuxUploadLink', { parentId }))
	}
}

export const videoApi = new VideoApi();