import { PlayerOnTeam } from './PlayerOnTeam';
import { StaffMember } from './StaffMember';
import { AccessControlledModel } from '../AccessControlledModel';
import { StaffRole } from '../../../types/enums/staff-role';
import { DepthChartModel } from '../depthChart';
import { grantUserAcls } from '@/services/AccessControlledService';
import { TeamPermission } from '../../../types/permissions/team-permissions';
import { TeamWTLInfo } from 'types/interfaces';

export class TeamModel extends AccessControlledModel{
	name: string = "";
	country: string = "";
	city: string = "";
	gender: string = "";
	organizationName: string = "";
	organizationLocation: string = "";
	organizationTypeId: string = "";
	organizationTypes: string = "";
	sportId: string = "";
	seasonFormat: string = "";
	logoUrl: string = "";
	staffMembers: StaffMember[] = [];
	players: PlayerOnTeam[] = [];
	depthCharts: DepthChartModel[] = [];
	wtlSummary: TeamWTLInfo | null = null;

	setStaffMember(userId: string, staffMember: StaffMember): void{
		// figure out default role
		// if (staffRole === undefined) {
		// 	userRelation.role = AthleteProfileModel.Roles.find(role => role.name === userRelation.relationship.defaultRole);
		// }
		if (staffMember.role !== undefined) {
			// handle different staff members
			// revokeUserAcls(this, TeamPermissionValues, { userId: staffMember.id });
			grantUserAcls(this, [TeamPermission.InviteAthlete], { userId });
		}

		const userIndex = this.staffMembers.findIndex(u => u.id === staffMember.id);
		if(userIndex > -1){
			this.staffMembers.splice(userIndex, 1, staffMember);
		}else{
			this.staffMembers.push(staffMember);
		}
	}

	get HeadCoachId(): string | undefined {
		const headCoach = this.staffMembers.find(staff => staff.role === StaffRole.HeadCoach);
		if (headCoach){
			return headCoach.coachId;
		}
		return undefined;
	}

	get AssistantCoachIds(): string[]{
		return this.staffMembers.filter(staff => staff.role === StaffRole.AssistantCoach).map(c => c.coachId);
	}

	get AdminCoachIds(): string[]{
		return this.staffMembers.filter(staff => staff.role === StaffRole.Admin).map(c => c.coachId);
	}

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		this.depthCharts = this.depthCharts.map(dc => new DepthChartModel().load(dc));
		return this;
	}

}