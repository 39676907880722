import Vue from 'vue'
import vuetify from './plugins/vuetify'
import './plugins/firebase';
import './plugins/vue-meta';
import { LocalForagePlugin } from './plugins/localforage'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import './registerServiceWorker'
import '@mdi/font/css/materialdesignicons.css'
import 'typeface-rubik/index.css'
import { config } from './config/config';
const { domain, clientId, audience } = config.Auth0Config;

import { requireRoleDirective } from './directives/v-require-role';
import './directives/v-mask';
import './components/register';
import { Auth0Plugin } from './auth/auth0Plug';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate/dist/vee-validate.full.esm';
import { StripePlugin } from './plugins/stripe';
import "videojs-youtube/dist/Youtube";
import { youtubeParser } from './helpers';
import { capitalize } from 'lodash';
import { capitalizeCamelCase } from './pipes/capitalizeCamelCase.pipe';

Vue.config.productionTip = false

// Global filters
Vue.filter('title', function (value: string) {
	if (!value) return ''
	const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	if (email_regex.test(value)) return value
	// value = value.toString()
	// return value.charAt(0).toUpperCase() + value.slice(1)
	value = value.toString().toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	return value
})

Vue.filter('underscoreToSpace', function (value: string) {
	if (!value) return ''
	const valWithSpace = value.replace(/_/g, " ")
	return valWithSpace
})

Vue.filter('n2br', value => value.replace('\n', '<br/>'));
Vue.filter('n2brbr', value => value.replace('\n', '<br/><br/>'));
Vue.filter('capitalize', capitalize)
Vue.filter('capitalize-from-camel-case', capitalizeCamelCase)

Vue.use( Auth0Plugin, {
	domain,
	clientId,
	audience,
	onRedirectCallback: (appState: any) => {
		router.push(
			appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
		);
	},
	store
});

Vue.use(LocalForagePlugin);
Vue.use(StripePlugin)

Vue.directive('role', requireRoleDirective);

const app = new Vue(<any>{
	el: '#app',
	router,
	store,
	vuetify,
	render: (h:any) => h(App)
}).$mount('#app')

/**
 * Silence warnings for v-calendar: https://github.com/vuetifyjs/vuetify/issues/9999
 */
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg: string | null, vm: Vue | null, trace: string | null) {
	// `trace` is the component hierarchy trace
	if (msg === ignoreWarnMessage) {
		msg = null;
		vm = null;
		trace = null;
	}
}

// Regex Reference: https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url#3809435
const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g;
extend('url', {
	validate(value) {
		return value.match(urlRegex) ? true : 'The {_field_} field must be a URL';
	},
	message: 'The {_field_} field must URL'
})
extend('youtubelink', {
	validate(value) {
		return youtubeParser(value) !== false
	},
	message: 'The {_field_} must be valid'
})
extend('password', {
	params: ['target'],
	validate(value, { target }) {
		return value === target;
	},
	message: 'Password confirmation does not match'
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);