
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { FormRulesMixin } from '../../../mixins';
import { mdiPencil } from '@mdi/js'; 
import EditOverlay from '@/components/ui/EditOverlay.vue'
import ImageUploadDialog from '@/components/profile/ImageUploadDialog.vue'

@Component({
	components:{
		EditOverlay,
		ImageUploadDialog,
	}
})
export default class AccountSettingsProfilePicture extends Mixins(FormRulesMixin){
	mdiPencil = mdiPencil;
	valid: boolean = false;
	showImageUploadDialog: boolean = false;
	@Prop({ required: true }) value: string;
	@Prop({ required: true }) fullName: string;
	@Prop({ type: Boolean, default: false }) saveLoading: boolean;

	async setProfilePhoto(pictureUrl: string): Promise<void>{
		console.log("setProfilePhoto", pictureUrl);
	}

	onSave(): void{
		this.$emit('save', this.value);
	}
	onCancel(): void{
		this.$emit('cancel');
	}
}
