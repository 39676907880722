var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-avatar',{style:({
		'border': `${this.borderSize}px solid ${_vm.getColor(this.color)} !important`,
		'overflow': 'hidden',
	}),attrs:{"size":_vm.size,"color":_vm.backgroundColor},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"image","height":_vm.size,"width":_vm.size}}):[_vm._t("default",function(){return [(_vm.src)?_c('v-img',{attrs:{"aspect-ratio":"1","src":_vm.src,"alt":_vm.alt,"contain":_vm.contain}}):(_vm.ShowInitials)?_c('span',{class:{
					'white--text': _vm.Dark,
					'baColorDeepBlue--text': !_vm.Dark,
				},style:({
					'font-size': `${this.FontSize}px`
				})},[_vm._v(_vm._s(_vm.Placeholder))]):_c('v-icon',{attrs:{"size":_vm.IconSize,"color":_vm.iconColor}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])]})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }