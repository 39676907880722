import { Component, Vue, Prop } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import SortIcon from '@/components/ui/SortIcon.vue';
/**
 * Provides methods which sort lists
 */
@Component({
	components: {
		SortIcon,
	}
})
export class DataTableSortMixin extends Vue {
	@Prop({
		default: () => ({
			page: 0,
			itemsPerPage: -1,
			sortBy: [],
			sortDesc: [false],
			groupBy: [],
			groupDesc: [false],
			multiSort: false,
			mustSort: false,
		})
	}) tableOptions: DataOptions;
	updateOptions(options: DataOptions): void {
		this.$emit('update:table-options', options);
	}
	updatePage(page: number): void {
		this.updateOptions({
			...this.tableOptions,
			page,
		});
	}
	get TableSortBy(): string {
		const [value] = this.tableOptions.sortBy;
		return value;
	}
	get TableSortDesc(): boolean {
		const [value] = this.tableOptions.sortDesc;
		return value;
	}
	numericSort<T = Record<string, any>>(key: string, items: T[], desc?: boolean): T[] {
		return items.sort((a, b) => {
			if (+a[key] > +b[key]) {
				return desc ? -1 : 1;
			} else if (+a[key] < +b[key]) {
				return desc ? 1 : -1;
			} else {
				return 0;
			}
		});
	}
	genericSort<T = Record<string, any>>(key: string, items: T[], desc?: boolean): T[] {
		return items.sort((a, b) => {
			if (a[key] > b[key]) {
				return desc ? -1 : 1;
			} else if (a[key] < b[key]) {
				return desc ? 1 : -1;
			} else {
				return 0;
			}
		});
	}
}
