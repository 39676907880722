import { CrudApi } from './CrudApi';
import { FileRecordModel } from '../models/file/FileRecordModel';

export class FileRecordApi extends CrudApi<FileRecordModel> {
	constructor(path: string) {
		super(path, (obj) => new FileRecordModel().load(obj));
	}
	async downloadFileById(id: string): Promise<Blob> {
		const blob = await CrudApi.Api((c) => c.request({
			method: 'GET',
			url: `/${this.resource}/${id}/download`,
			responseType: 'blob',
		}));
		return blob;
	}
	async upload(file: Blob, onUploadProgress: (progressEvent: ProgressEvent) => void): Promise<FileRecordModel> {
		const formData = new FormData();

		formData.append('file', file);

		const newObj = await CrudApi.Api((c) => c.post(`/${this.resource}/upload`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress: onUploadProgress
		}));
		return new FileRecordModel().load(newObj);
	}
}