import { getEnumValues } from './get-enum-values';
/**
 * BYDAY= values from:  
 * https://icalendar.org/iCalendar-RFC-5545/3-8-5-3-recurrence-rule.html
 */
export enum RecurrenceRuleWeekday{
	Sunday="SU",
	Monday="MO",
	Tuesday="TU",
	Wednesday="WE",
	Thursday="TH",
	Friday="FR",
	Saturday="SA",
}
export const RecurrenceRuleWeekdayValues = getEnumValues<RecurrenceRuleWeekday>(RecurrenceRuleWeekday)