import { capitalize } from "lodash";

/**
 * Turns appleBananaCorn into Apple Banana Corn
 * @param value camel case string
 * @returns Capitalize and Space seperated on camelcase 
 */
export function capitalizeCamelCase(value: string): string {
	if (!value) return "";
	// appleBananaCorn matches to [apple, Banana, Corn]
	const capitalRegex = /([A-Z]?[a-z]*)/g;
	return Array.from(value.matchAll(capitalRegex)).map(matchgroup => capitalize(matchgroup[0])).join(' ');
}