import { Component, Vue } from 'vue-property-decorator';
import { coursesStore } from '../../store';

@Component
export class RubricMixin extends Vue {
	reloadRubrics: boolean = false;
	created() {
		this.initRubrics();
	}

	initRubrics() {
		if (this.reloadRubrics || !coursesStore.rubricsInitialized) {
			coursesStore.loadRubrics();
		}
	}
	get RubricsInitialized(){
		return coursesStore.rubricsInitialized;
	}
	get RubricTemplates(){
		return coursesStore.rubrics;
	}
}
