
import { Component, Prop, Vue} from 'vue-property-decorator';
import ProfilePictureUpload from '@/components/forms/ProfilePictureUpload.vue';
import { CoachProfileModel } from '@/models/coach/CoachProfileModel';

@Component({
	components: {
		ProfilePictureUpload,
	}
})
export default class CoachSettingCoachingBio extends Vue{

	@Prop({ required: true }) value: CoachProfileModel;

	showImageUploadDialog = false;
	valid = false;

	submit() {
		this.$emit('submit');
	}
	update(key, value) {
		this.$emit('input', {...this.value, [key]: value});
	}
}
