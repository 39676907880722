var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Page',{attrs:{"loading":_vm.PageLoading},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('PageTitle',[_vm._v(" Team Dashboard ")])]},proxy:true}])},[_c('TeamDashboardInfoProvider',{attrs:{"teamId":_vm.teamId,"load-team":"","load-team-events":""},scopedSlots:_vm._u([{key:"default",fn:function({
			CurrentTeamReady,
			CurrentTeam,
			CurrentTeamId,
			teamEvents,
			PageState
		}){return [(PageState.IsError)?_c('FailureStateCard',{attrs:{"state":PageState}}):[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"lg":"6","cols":"12"}},[_c('v-row',{staticClass:"flex-shrink-1 flex-grow-0"},[_c('v-col',[_c('TeamDetailsCard',{staticClass:"soft-shadow",attrs:{"flat":"","team":CurrentTeam,"readOnly":_vm.AthleteMode,"loading":_vm.PageLoading}})],1)],1),_c('CalendarProvider',{staticClass:"flex-grow-1 d-flex flex-column",attrs:{"load-events":"","resource":"team","parentId":CurrentTeamId,"readOnly":_vm.AthleteMode},scopedSlots:_vm._u([{key:"default",fn:function({ pageState, MonthViewEventList, focusDate, updateFocusDate, CalendarUrls }){return [_c('v-row',{staticClass:"flex-grow-1 flex-shrink-0"},[_c('v-col',{staticClass:"d-flex"},[_c('Calendar',{staticClass:"flex-grow-1 soft-shadow",attrs:{"loading":pageState.IsLoading || pageState.IsInitial,"events":MonthViewEventList,"view":_vm.calendarMode,"focusDate":focusDate,"flat":"","calendarUrls":CalendarUrls},on:{"update:focus-date":updateFocusDate,"update:view":_vm.updateCalendarMode,"click:add-event":_vm.addEvent,"click:event":_vm.eventClicked,"expand:Calendar":_vm.expandCalendar}})],1)],1)]}}])})],1),_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('CalendarProvider',{staticClass:"flex-grow-1 d-flex flex-column",attrs:{"load-events":"","resource":"team","parentId":CurrentTeamId,"readOnly":_vm.AthleteMode},scopedSlots:_vm._u([{key:"default",fn:function({ pageState, MonthViewEventList }){return [_c('v-row',[_c('v-col',[_c('PlayerManagement',_vm._b({attrs:{"teamEvents":MonthViewEventList},on:{"click:add-event":_vm.addEvent,"click:event":_vm.eventClicked}},'PlayerManagement',_vm.PlayerManagementProps,false))],1)],1)]}}])})],1)],1)]]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }