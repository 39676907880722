import { TeamModel } from '@/models/team';
import { title } from '@/pipes';
import { getFlagValue } from '@/feature-flags';
import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * Provides computed props to display information on a team
 */
@Component
export class TeamDetailsMixin extends Vue {
	@Prop({ required: true }) team: TeamModel;

	get WTLSummary(): string {
		if (getFlagValue('HIN-1642-connect-team-wlt-summary-to-gam')){
			if(this.team.wtlSummary){
				return `${this.team.wtlSummary.W}-${this.team.wtlSummary.T}-${this.team.wtlSummary.L}`;
			}
		}
		return `0-0-0`;
	}
	get DetailsTitle(): string {
		let gender: string;
		switch (this.team.gender.toLowerCase()){
		case 'male':
			gender = "Mens";
			break;
		case 'female':
			gender = "Womens";
			break;
		default:
			gender = "Coed";
			break;
		}
		const city = this.TeamLocation !== null ? ` | ${this.TeamLocation}` : '';
		return `${gender} ${title(this.team.sportId)}${city}`;
	}
	get TeamName(): string {
		return this.team.name;
	}
	get TeamLocation(): string | null {
		if (!this.team['city'] && !this.team['country']) return null;
		if (!this.team['city']) return this.team.country;
		return `${this.team['city']}, ${this.team.country}`;
	}
	get TeamCountry(): string | null {
		if (!this.team['country']) return null;
		return this.team['country'];
	}
}
