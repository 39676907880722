import { AthleteMetric } from '../../types/enums';
export function getMetricUnit(value: AthleteMetric, { selfAssessment = false }: { selfAssessment?: boolean } = {}): string {
	switch (value) {
	case AthleteMetric.Acceleration:
		return 's';
	case AthleteMetric.Speed:
		return 's';
	case AthleteMetric.Power:
		return 'cm';
	case AthleteMetric.Agility:
		return selfAssessment ? 's' : '';
	case AthleteMetric.Recovery:
		return '';
	default:
		return '';
	}
}
