import { NavigationGuard } from 'vue-router';
import { authGuard } from '@/auth/authGuard';
import { userStore } from '@/store';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { RoleName } from '@best-athletes/ba-types';

/**
 * Runs authGuard, then checks if the user's Athlete Profile exists and redirects to setup otherwise.
 */
export const requireRolesGuard: (roles: RoleName[], op: 'any' | 'all') => NavigationGuard = (roles, op = 'all') => {
	return (to, from, next) => {
		return authGuard(to, from, async () => {
			const allow = userStore.UserRoles.length > 0 && userStore.UserRoles.map(role => roles.includes(role)).reduce(
				op === 'all' ?
					(a,b) => a && b :
					(a,b) => a || b
			);
			if (allow){
				next();
			}else{
				console.error("[requiredRolesGuard] Not Authorized to access route");
				next({
					name: Routes.Unauthorized
				});
			}
		});
	}
}