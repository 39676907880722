import { Component, Vue, Watch } from 'vue-property-decorator';
import { teamsStore, athleteProfile } from '../store';
import { TeamModel } from '../models/team';

@Component
export class MyTeamsMixin extends Vue {
	reloadMyTeams: boolean = false;
	$auth: {
		athleteId: string,
		coachId: string,
	};
	get MyTeamsInitialized(): boolean{
		return teamsStore.myTeamsInitialized;
	}
	get MyTeamsLoading(): boolean{
		return teamsStore.myTeamsLoading;
	}
	get MyTeams(): TeamModel[]{
		return teamsStore.myTeams;
	}

	get MyAthleteTeamsInitialized(): boolean{
		return athleteProfile.athleteProfileInitialized;
	}
	get MyAthleteTeamsLoading(): boolean{
		return athleteProfile.loading;
	}
	get HasAthleteTeams(): boolean {
		return this.MyAthleteTeams.length > 0;
	}
	get MyAthleteTeams(): TeamModel[]{
		return athleteProfile.athleteProfileTeams;
	}

	created(): void{
		this.initMyTeams();
	}

	@Watch('$auth.athleteId')
	reloadTeams(): void{
		this.reloadMyTeams = true;
		this.initMyTeams();
	}

	initMyTeams(): void{
		if (this.reloadMyTeams || !teamsStore.myTeamsInitialized && this.$auth.coachId) {
			teamsStore.loadMyTeams(this.$auth.coachId);
		}
		if (this.reloadMyTeams || !athleteProfile.athleteProfileInitialized && this.$auth.athleteId) {
			athleteProfile.getAthleteProfile(this.$auth.athleteId);
		}
	}

}
