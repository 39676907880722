import { ViewTrackingRecordView } from "@/../types/interfaces";
import { UserProfileModel } from "../UserProfileModel";

export class ViewTrackingRecordViewModel implements ViewTrackingRecordView {
	id: string;
	_id: string;

	date: Date;
	count: number = 0;
	anonymous: boolean = true;

	dateSlug: string;
	viewerId: string;
	parentId: string;
	viewer: Pick<UserProfileModel, 'firstName' | 'lastName' | 'email' | 'pictureUrl'> | null = null;

	get Name(): string | null{
		if (this.viewer === null) return null;
		return `${this.viewer.firstName} ${this.viewer.lastName}`
	}
	get Email(): string | null{
		if(this.viewer === null) return null;
		return this.viewer.email;
	}
	get PictureUrl(): string | null{
		if(this.viewer === null) return null;
		return this.viewer.pictureUrl;
	}

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (obj['date']) this.date = new Date(obj['date']);
		return this;
	}
}