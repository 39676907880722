import { PaymentMethod } from "@stripe/stripe-js";
import { FrontEndModel } from "../FrontEndModel";

export class PaymentMethodModel extends FrontEndModel {
	parentId: string = "";
	stripeCustomerId: string = "";
	stripePaymentMethodId: string = "";
	stripePaymentMethodDetails: PaymentMethod = {} as any;

	get BillingDetailsName(): string | null{
		if(!this.stripePaymentMethodDetails) return null;
		return this.stripePaymentMethodDetails.billing_details.name;
	}
	get BillingDetailsEmail(): string | null{
		if(!this.stripePaymentMethodDetails) return null;
		return this.stripePaymentMethodDetails.billing_details.email;
	}
	get BillingDetailsPhone(): string | null{
		if(!this.stripePaymentMethodDetails) return null;
		return this.stripePaymentMethodDetails.billing_details.phone;
	}
	get BillingAddressCity(): string | null{
		if(!this.stripePaymentMethodDetails) return null;
		return this.stripePaymentMethodDetails.billing_details.address.city;
	}
	get BillingAddressLine1(): string | null{
		if(!this.stripePaymentMethodDetails) return null;
		return this.stripePaymentMethodDetails.billing_details.address.line1;
	}
	get BillingAddressPostalCode(): string | null{
		if(!this.stripePaymentMethodDetails) return null;
		return this.stripePaymentMethodDetails.billing_details.address.postal_code;
	}
	get BillingAddressCountry(): string | null{
		if(!this.stripePaymentMethodDetails) return null;
		return this.stripePaymentMethodDetails.billing_details.address.country;
	}
	get BillingAddressState(): string | null{
		if(!this.stripePaymentMethodDetails) return null;
		return this.stripePaymentMethodDetails.billing_details.address.state;
	}

	get Last4(): string | null {
		if (!this.stripePaymentMethodDetails) return null;
		if (!this.stripePaymentMethodDetails.card) return null;
		if (this.stripePaymentMethodDetails.card.last4 === undefined) return null;
		return this.stripePaymentMethodDetails.card.last4;
	}

	setPaymentMethodDetails(stripeDetails: PaymentMethod): PaymentMethodModel{
		this.stripeCustomerId = stripeDetails.customer || "";
		this.stripePaymentMethodId = stripeDetails.id;
		this.stripePaymentMethodDetails = stripeDetails;
		return this;
	}
}