import { FeatureMap } from '../interfaces/FeatureMap'

export const FREE_PLAN: FeatureMap = {
	ATHLETE_VIDEO_MINUTES: 0,
	ATHLETE_COMPARITIVE_DATA: false,
	YOUTUBE_VIDEO_LIMIT: 1,
	VIDEO_CLIP_LIMIT: 5,
	YEARLY_VERIFIED_ASSESSMENTS: 0,
	YEARLY_SPQ_ASSESSMENTS: 0,
	SCHOOL_SEARCH: false,
	PROFILE_SHARE_LIMIT: 0,
};

export const BASIC_PLAN: FeatureMap = {
	ATHLETE_VIDEO_MINUTES: 20,
	ATHLETE_COMPARITIVE_DATA: false,
	YOUTUBE_VIDEO_LIMIT: 1,
	VIDEO_CLIP_LIMIT: 10,
	YEARLY_VERIFIED_ASSESSMENTS: 0,
	YEARLY_SPQ_ASSESSMENTS: 0,
	SCHOOL_SEARCH: false,
	PROFILE_SHARE_LIMIT: 6,
};

/** Showcase Plan */
export const COMPLETE_PLAN: FeatureMap = {
	ATHLETE_VIDEO_MINUTES: 60 * 5, // 5 Hours
	ATHLETE_COMPARITIVE_DATA: true,
	YOUTUBE_VIDEO_LIMIT: 1,
	VIDEO_CLIP_LIMIT: null,
	YEARLY_VERIFIED_ASSESSMENTS: 1,
	YEARLY_SPQ_ASSESSMENTS: 1,
	SCHOOL_SEARCH: true,
	PROFILE_SHARE_LIMIT: null,
};
/** Showcase Plan United States */
export const COMPLETE_PLAN_US: FeatureMap = {
	...COMPLETE_PLAN,
	ATHLETE_COMPARITIVE_DATA: false,
	YEARLY_VERIFIED_ASSESSMENTS: 0,
};

export const PROFESSIONAL_PLAN: FeatureMap = {
	ATHLETE_VIDEO_MINUTES: 60 * 7, // 7 Hours
	ATHLETE_COMPARITIVE_DATA: true,
	YOUTUBE_VIDEO_LIMIT: 1,
	VIDEO_CLIP_LIMIT: null,
	YEARLY_VERIFIED_ASSESSMENTS: 2,
	YEARLY_SPQ_ASSESSMENTS: 1,
	SCHOOL_SEARCH: true,
	PROFILE_SHARE_LIMIT: null,
};
export const PROFESSIONAL_PLAN_US: FeatureMap = {
	...PROFESSIONAL_PLAN,
};