
import { Component, Prop, Mixins } from "vue-property-decorator";
import { mdiChevronRight } from "@mdi/js";
import { VuetifyMixin } from "@/mixins";
@Component
export default class MultiStepNavItem extends Mixins(VuetifyMixin) {
	mdiChevronRight = mdiChevronRight;
	@Prop({ default: false, required: false, type: Boolean }) isActive: boolean;
	@Prop({ default: 0, required: false, type: Number }) hints: number;
	@Prop({ default: 'error', required: false, type: String}) hintColor: string;

	get Color(): string {
		if (this.isActive) {
			return this.getColor("baColorVibrantBlueText");
		}
		return this.getColor("baColorSecondayText");
	}

	get Style(): Record<string, any> {
		if (this.isActive) {
			return {
				"font-weight": "500",
				color: this.Color,
			};
		}
		return {
			"font-weight": "400",
		};
	}
}
