
import { Component, Prop, Vue } from "vue-property-decorator";
import { DiscussionModel } from '@/models/discussion/DiscussionModel';
import { CommentModel } from '@/models/discussion/CommentModel';
import { DiscussionApi } from '@/api/DiscussionApi';
import { CommentCreatedEvents } from 'types/interfaces/CommentCreateMetadata';
import { DomainObjectChangeOptions } from 'types/interfaces';

@Component({
	render(h) {
		return h(
			this.as,
			this.$slots.default
				? this.$slots.default
				: this.$scopedSlots.default(this.SlotProps)
		);
	}
})

export default class DiscussionProvider extends Vue {
	@Prop({ default: "div" }) private as: string;
	@Prop({ default: "/" }) private apiPrefix: string;
	@Prop({ default: null, required: true, }) discussionId: string;
	@Prop({ default: null, required: true, }) profileId: string;
	@Prop({ default: null, required: true, }) authId: string;
	loading: boolean = false;
	discussion: DiscussionModel | null = null;
	newComment: CommentModel | null = null;

	get AllComments(): CommentModel[]{
		if (this.discussion === null){
			return null
		}
		return this.discussion.comments;
	}

	get DiscussionApi(): DiscussionApi {
		return new DiscussionApi();
	}

	async lookupDiscussion(): Promise<void> {
		this.discussion = await this.DiscussionApi.findById(this.discussionId);
		if (this.discussion === null){
			this.discussion = new DiscussionModel().load({id: this.discussionId, createdAt: new Date(), });
			await this.DiscussionApi.save(this.discussion);
		}
	}

	async addComment(comment: CommentModel, params?: CommentCreatedEvents & DomainObjectChangeOptions): Promise<void>{
		this.newComment = this.createNewComment();
		const newComment = await this.DiscussionApi.addComment({ discussionId: this.discussionId, comment, meta: params });
		this.discussion.setComment(newComment)
	}
	createNewComment(): CommentModel{
		const comment = new CommentModel().load({userId: this.authId, profileId: this.profileId, body:null, createdAt: new Date(), lastModified: new Date()});
		return comment;
	}

	created(): void {
		this.lookupDiscussion();
		this.newComment = this.createNewComment();
	}

	get SlotProps(): {
		loading: boolean;
		discussion: DiscussionModel;
		allComments: CommentModel[];
		submitComment: (newComment: CommentModel, params?: CommentCreatedEvents & DomainObjectChangeOptions) => Promise<void>;
		newComment: CommentModel;
	} {
		return {
			loading: this.loading,
			discussion: this.discussion,
			allComments: this.AllComments,
			submitComment: async (newComment, params) =>
				this.addComment(newComment, params),
			newComment: this.newComment,
		};
	}
}
