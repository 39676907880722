
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class RoundedButton extends Mixins(VuetifyMixin){

	@Prop({ default: null }) icon: string | null;
	@Prop({ default: 'baColorVibrantBlueText' }) color: string;
	/** sm, md, lg, xl, circle */
	@Prop({ default: 'md' }) rounded: string | null;
	@Prop({ default: false, type: Boolean }) dark: boolean;
	@Prop({ default: false, type: Boolean }) smallIcon: boolean;
	@Prop({ default: false, type: Boolean }) disabled: boolean;
	@Prop({ default: false, type: Boolean }) dense: boolean;
	@Prop({ default: true, type: Boolean }) lightText: boolean;
	@Prop({ default: true, type: Boolean }) outlined: boolean;
	@Prop({ default: false, type: Boolean }) upperCase: boolean;
	@Prop({ default: 'baColorPrimaryText--text', type: String}) textColor: string;
	@Prop({ default: 'white', type: String}) loaderColor: string;
	@Prop({ default: 30, type: Number }) loaderSize: number;
	@Prop({ default: 3, type: Number }) loaderWidth: number;


	get Rounded(): boolean{
		return this.rounded === null; // Use default rounding if not overridden
	}
	get RoundedClass(): string | undefined{
		if(this.rounded === null) return undefined;
		return `rounded-${this.rounded}`;
	}

	get AddButtonStyle(): Record<string, any>{
		if(this.disabled){
			return {
				'color': `${this.getColor('baColorSecondaryText', this.dark)} !important`,
				'border': `1px solid ${this.getColor('baColorSecondaryText', this.dark)} !important`,
				'pointer-events': 'none',
			}
		}
		return {
			'color': `${this.getColor('baColorPrimaryText', this.dark)} !important`,
			'border': `1px solid ${this.getColor(this.color, this.dark)} !important`,
		};
	}
}
