import { getEnumValues } from './get-enum-values';
export enum EventbriteEventStatus {
	Draft='draft',
	Live='live',
	Started='started',
	Ended='ended',
	Completed='completed',
	Canceled='canceled',
	Deleted='deleted',
}
export const EventbriteEventStatusValues = getEnumValues<EventbriteEventStatus>(EventbriteEventStatus)