
import Vue from 'vue'
import { EnvironmentName } from '@/../types/constants/environments';
import { config } from '@/config/config';
import { getFlagValue } from '@/feature-flags';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import imgLogo from '@/assets/img/BA_Logo_Horizontal_White.svg'
export default Vue.extend({
	data(){
		return {
			imgLogo,
		}
	},
	props:{
		beta: {
			type: Boolean,
			default: true,
		},
		size: {
			type: Number,
			default: 45,
		}
	},
	computed:{
		Width(){
			if(!this.beta){
				return this.size * (495.85/109.97);
			}
			return this.size * 3.067;
		},
		UseEnvName(){
			return this.EnvName !== "production" && getFlagValue('environment-name-label');
		},
		EnvName(): EnvironmentName{
			return config.BA_ENV;
		},
		EnvDesc(){
			switch(this.EnvName){
			case 'local':
				return "Environment is LOCAL. You probably know what you're doing";
			case 'dev':
				return "Environment is DEV. New features are deployed daily";
			case 'testing':
				return "Environment is TESTING. This is a good place to test out new features.";
			case 'stage':
			case 'staging':
				return `Environment is STAGING. Identical to the live environment except all the data is for testing purposes only.`;
			}
			return "";
		}
	}
});
