<template>
  <div v-if="hasReset">
    <video class="video-js" ref="video">
      <track v-for="(crtTrack,index) in trackList"
             :key="index"
             :kind="crtTrack.kind" :label="crtTrack.label"
             :src="crtTrack.src" :srcLang="crtTrack.srcLang"
             :default="crtTrack.default"/>
      <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
      </p>
    </video>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import _videojs from 'video.js';
const videojs = window.videojs || _videojs;
export default {
	name: 'BAVideoJSPlayer',
	props: {
		showLogo: {
			type: Boolean,
			default: false
		},
		crossOrigin: {
			type: String,
			default: ''
		},
		playsinline: {
			type: Boolean,
			default: false
		},
		customEventName: {
			type: String,
			default: 'statechanged'
		},
		options: {
			type: Object,
			required: true
		},
		events: {
			type: Array,
			default: () => []
		},
		globalOptions: {
			type: Object,
			default: () => ({
				autoplay: true,
				controls: true,
				language: 'en',
				inactivityTimeout: 0,
				preload: 'auto',
				fluid: false,
				techOrder: ['html5','youtube'],
				plugins: {},
				youtube: {
					controls: 0,
					modestbranding: 1,
					disablekb: 1,
					ytControls: 0
				}
			})
		},
		globalEvents: {
			type: Array,
			default: () => []
		},
		trackList: {
			type: Array,
			default: () => []
		}
	},
	data () {
		return {
			player: null,
			hasReset: true
		}
	},
	methods: {
		initialize: function () {
			// eslint-disable-next-line @typescript-eslint/no-this-alias
			const self = this
			const videoObj = this.$refs.video
			// videojs options
			const videoOptions = Object.assign({}, this.globalOptions, this.options)
			// ios fullscreen
			if (this.playsinline) {
				videoObj.setAttribute('playsinline', this.playsinline)
				videoObj.setAttribute('webkit-playsinline', this.playsinline)
				videoObj.setAttribute('x5-playsinline', this.playsinline)
				videoObj.setAttribute('x5-video-player-type', 'h5')
				videoObj.setAttribute('x5-video-player-fullscreen', false)
			}
			// cross origin
			if (this.crossOrigin !== '') {
				videoObj.crossOrigin = this.crossOrigin
				videoObj.setAttribute('crossOrigin', this.crossOrigin)
			}
			// avoid error "VIDEOJS: ERROR: Unable to find plugin: __ob__"
			if (videoOptions.plugins) {
				delete videoOptions.plugins.__ob__
			}
			// emit event
			const emitPlayerState = (event, value) => {
				if (event) {
					// console.log('emitting a regular event !! the event is: ', event)
					this.$emit(event, this.player)
				}
				if (value) {
					// console.log('emitting a value, the event is:' + event + ', the value is: ' + value + ', and the customEventName is: ' + this.customEventName)
					this.$emit(this.customEventName, { [event]: value })
				}
			}
			// player
			this.player = videojs(videoObj, videoOptions, function () {
				// events
				const DEFAULT_EVENTS = [
					'loadstart',
					'loadedmetadata',
					'loadeddata',
					'canplay',
					'canplaythrough',
					'play',
					'pause',
					'waiting',
					'playing',
					'ended',
					'error',
					// 'timeupdate',
					// 'durationchange',
					'seeking',
					'seeked',
					'resize',
					'playerresize',
					// 'keydown'
				]
				const events = DEFAULT_EVENTS.concat(self.events).concat(
					self.globalEvents
				)
				// watch events
				const onEdEvents = {}
				for (let i = 0; i < events.length; i++) {
					if (
						typeof events[i] === 'string' &&
              onEdEvents[events[i]] === undefined
					) {
						(event => {
							onEdEvents[event] = null
							this.on(event, () => {
								// console.log('in the actual onEvent and it is: ', event)
								emitPlayerState(event, true)
							})
						})(events[i])
					}
				}

				// watch timeupdate
				this.on('timeupdate', () => {
					// console.log('About to spout the timeUpdate !! it is this.currentTime()', this.currentTime())
					emitPlayerState('timeupdate', this.currentTime())
				})
				this.on('durationchange', () => {
					// console.log('durationchange from videojs')
					emitPlayerState('durationchange')
				})

				// watch keydown
				this.on('keydown', (e) => {
					// console.log('About to spout the keydown !! it is: ', e)
					emitPlayerState('keydown', e)
				})

				// player readied
				self.$emit('ready', this)
			})
		},
		dispose (callback) {
			if (this.player && this.player.dispose) {
				if (this.player.techName_ !== 'Flash') {
					this.player.pause && this.player.pause()
				}
				this.player.dispose()
				this.player = null
				this.$nextTick(() => {
					this.hasReset = false
					this.$nextTick(() => {
						this.hasReset = true
						this.$nextTick(() => {
							callback && callback()
						})
					})
				})
			}
		}
	},
	watch: {
		options: {
			deep: true,
			handler (options, oldOptions) {
				this.dispose(() => {
					if (options && options.sources && options.sources.length) {
						this.initialize()
					}
				})
			}
		}
	},
	mounted () {
		if (!this.player) {
			this.initialize()
		}
	},
	beforeDestroy () {
		if (this.player) {
			this.dispose()
		}
	}
}
</script>

<style scoped>
</style>
