import { useLaunchDarkly } from './launch-darkly';
import store from '../store/store';

/**
 * Receives a list of feature flags to patch in a key-value store.  
 * Accepted formats:  
 * `{ [name] : value }`  
 * `{ [name] : { current: value, previous: value } }`
 * @param {Object} flags 
 */
export const setFlags = (flags) => {
	Object.keys(flags).forEach(key => {
		store.commit('featureFlags/SET_FLAG', {
			key,
			value: flags[key].current !== undefined ? flags[key].current : flags[key],
		});
	});
}

/**
 * Returns the value of the feature flag from the store.
 * Uses the default value if none is present.
 * @param {string} flag
 */
export const getFlagValue = (flag) => {
	if (store.state.featureFlags.flags[flag]){
		return store.state.featureFlags.flags[flag].value;
	}else{
		return store.state.featureFlags.defaultValue;
	}
}

export const initializeFeatureFlags = async () => {
	await useLaunchDarkly();
}