
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PublicProfileInfo } from '../../../types/interfaces';
import { athleteApi } from '../../api/AthleteApi';
import { coachApi } from '../../api/CoachApi';
import { AthleteProfileModel } from '../../models/athlete/AthleteProfileModel';
import { CoachProfileModel } from '../../models/coach/CoachProfileModel';
import { UserProfileModel } from '../../models/UserProfileModel';

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class ProfileProvider extends Vue{
	@Prop({ default: 'div' }) private as: string;

	@Prop() private profileId: string;
	@Prop({ type: Boolean, default: false }) private isCoach: boolean;
	@Prop({ type: Boolean, default: false }) private isAthlete: boolean;

	hasPicture: boolean = false;
	pictureUrl: string = "";
	fullName: string = "default";
	profile: PublicProfileInfo<UserProfileModel> = undefined;
	athleteProfile: PublicProfileInfo<AthleteProfileModel> = undefined;
	coachProfile: PublicProfileInfo<CoachProfileModel> = undefined;

	// SlotProps returns the properties provided by ProfileProvider
	get SlotProps(): {fullName: string, 
		profile: PublicProfileInfo<UserProfileModel>, 
		athleteProfile: PublicProfileInfo<AthleteProfileModel>, 
		coachProfile: PublicProfileInfo<CoachProfileModel>} {
		return {
			profile: this.profile,
			athleteProfile: this.athleteProfile,
			coachProfile: this.coachProfile,
			fullName: this.fullName,
		};
	}

	// when the object is created, load the profile
	created(): void {
		this.loadProfile();
	}

	// watch the profileId prop. if it changes, load the profile
	@Watch('profileId')
	loadProfile(): void{
		if (!this.profileId) return;

		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.lookupProfile(this.profileId).catch(() => {});
		
	}

	// lookup the profile from the store
	async lookupProfile(profileId: string): Promise<void>{
		if (!profileId) return;

		// TODO: Add caching
		if (this.isAthlete) {
			this.profile = await this.lookupAthlete(profileId);
		}
		else if (this.isCoach) {
			this.profile = await this.lookupCoach(profileId);
		}
		else {
			// coach or athlete not specified
			// try to find an athlete with this profileId
			this.profile = await this.lookupAthlete(profileId);
			if (!this.profile) {
				// no athlete found with this profileId, look for a coach
				this.profile = await this.lookupCoach(profileId);
			}
		}

		// update profile information
		this.fullName = this.profile.FullName;
	}

	async lookupAthlete(profileId: string): Promise<PublicProfileInfo<AthleteProfileModel> | undefined>{
		this.athleteProfile = await athleteApi.getBasicProfile(profileId);
		return this.athleteProfile;
	}

	async lookupCoach(profileId: string): Promise<PublicProfileInfo<CoachProfileModel> | undefined>{
		this.coachProfile = await coachApi.getBasicProfile(profileId);
		return this.coachProfile;
	}
}
