import { InvitationStatus, InvitationType } from "../../../types/enums";
import { FrontEndModel } from '../FrontEndModel';

export class InvitationModel extends FrontEndModel{
	type: InvitationType;
	status: InvitationStatus;
	senderId: string;
	teamId: string;
	athleteId: string | null;
	playerId: string;
}