import { AthleteRelationship, AthleteProfileRole } from '../interfaces';
import { AthleteProfileRoleName, AthleteProfileRelationshipName } from '../enums';
import { AclPermission } from '../permissions';

export const Roles: AthleteProfileRole[] = [
	{
		name: AthleteProfileRoleName.Owner,
		permissions: [AclPermission.Owner],
	},
	{
		name: AthleteProfileRoleName.ReadOnly,
		permissions: [AclPermission.Read],
	},
];

export const Relationships: AthleteRelationship[] = [
	{
		name: AthleteProfileRelationshipName.Athlete,
		defaultRole: AthleteProfileRoleName.Owner,
	},
	{
		name: AthleteProfileRelationshipName.Parent,
		defaultRole: AthleteProfileRoleName.Owner,
	},
	{
		name: AthleteProfileRelationshipName.Other,
		defaultRole: AthleteProfileRoleName.ReadOnly,
	},
]