import { Component, Vue } from 'vue-property-decorator';
import { studentCoursesStore } from '../../store';
import { CourseModel, AssignmentModel, StudentModel, RubricModel } from '../../models/course';

/**
 * Provides a list of courses that the current user is a student in.
 */
@Component
export class MyStudentCoursesMixin extends Vue {
	reloadMyStudentCourses: boolean = false;
	$auth: {
		coachId?: string,
	};

	created(): void{
		this.initMyStudentCourses();
	}

	initMyStudentCourses(): void{
		if (this.reloadMyStudentCourses || !studentCoursesStore.myCoursesInitialized && this.$auth.coachId){
			studentCoursesStore.loadMyCourses(this.$auth.coachId);
		}
	}

	get MyStudentCurrentCourse(): CourseModel | undefined {
		// console.log("course ready: ", this.MyStudentCoursesReady)
		if (this.MyStudentCoursesReady) {
			// console.log("My student Courses: ", this.MyStudentCourses)
			// console.log("CourseID from route params: ", this.$route.params.courseId);
			return this.MyStudentCourses.find(course => course.id === this.$route.params.courseId);
		}
		return undefined;
	}
	
	get MyCoachId(): string | undefined {
		if (this.$auth.coachId) {
			return this.$auth.coachId;
		}
		return undefined;
	}
	get MyStudent(): StudentModel {
		if(this.MyStudentCurrentCourse === undefined) return new StudentModel();
		return this.MyStudentCurrentCourse.students.find(s => s.coachProfileId == this.MyCoachId);
	}
	get MyStudentId(): string {
		if(!this.MyStudent) return "";
		return this.MyStudent.id;
	}

	get MyStudentCurrentAssignments(): AssignmentModel[] {
		if(this.MyStudent === undefined) return [];
		return this.MyStudent.assignments;
	}

	get MyStudentCoursesReady(): boolean{
		return studentCoursesStore.myCoursesInitialized && !studentCoursesStore.myCoursesLoading;
	}
	get MyStudentCourses(): CourseModel[]{
		if (!Array.isArray(studentCoursesStore.myCourses)) return [];
		return studentCoursesStore.myCourses;
	}
	get MyStudentCurrentCourseId(): string{
		return this.$route.params.courseId;
	}
	get MyStudentCurrentAssignmentId():  string{
		return this.$route.params.assignmentId;
	}

	get MyStudentCurrentAssignment(): AssignmentModel | undefined {
		if (!this.MyStudentCoursesReady) return undefined;
		return this.MyStudentCurrentAssignments.find(a => a.id === this.MyStudentCurrentAssignmentId);
	}
	get MyStudentCurrentAssignmentDetails(): string | null {
		if (this.MyStudentCurrentAssignment === undefined) return null;
		return this.MyStudentCurrentAssignment.details;
	}
	get AssignmentRubric(): RubricModel | undefined {
		if(this.MyStudentCurrentAssignment === undefined) return undefined;
		return this.MyStudentCurrentAssignment.rubric;
	}
}
