import { getEnumValues } from './get-enum-values';
export enum SocialMedia {
	Facebook = "Facebook",
	Instagram = "Instagram",
	LinkedIn = "LinkedIn",
	Twitter = "Twitter",
	TikTok = "TikTok",
	Website = "Website",
}
export type SocialMediaKeys = keyof typeof SocialMedia;
export const SocialMediaValues = getEnumValues<SocialMedia>(SocialMedia);