/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Route, RouteConfig } from 'vue-router';
import { coachAuthGuard } from './routeGuards/coachAuthGuard';
import { CoachSideNavLayout } from '../layouts';
import * as Routes from '../../types/constants/web_client_user.routes';
import { HinderRouteMetadata } from './router';
import { SettingsChildRouteConfigs } from './SettingsChildRouteConfigs';
import { StudentRouteConfigs } from './StudentRouteConfigs';
import { InstructorRouteConfigs } from './InstructorRouteConfigs';

const CoachDashboard = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachDashboard.vue');
const TeamDashboardView = () => import(/* webpackChunkName: "CoachModule" */ '@/views/TeamDashboardView.vue');
const FullScreenCalendar = () => import(/* webpackChunkName: "CoachModule" */ '@/views/FullScreenCalendar.vue');
const CoachProfile = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachProfile.vue');
const AthleteSearch = () => import(/* webpackChunkName: "CoachModule" */ '@/views/AthleteSearch.vue');
const TeamCreate = () => import(/* webpackChunkName: "CoachModule" */ '@/views/TeamCreate.vue');
const TeamEventCreate = () => import(/* webpackChunkName: "CoachModule" */ '@/views/TeamEventCreate.vue');
const TeamEventView = () => import(/* webpackChunkName: "CoachModule" */ '@/views/TeamEventView.vue');
const CoachCreateResume = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachCreateResume.vue');
const AthleteRecruitingProfileView = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteRecruitingProfileView.vue')
const AthleteRecruitingProfileVideosView = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteRecruitingProfileVideosView.vue')
const CoachRecruitingSummary = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachRecruitingSummary.vue')
const CoachScoutingSummary = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachScoutingSummary.vue')
const CoachScoutingReportForm = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachScoutingReportForm.vue')
const ViewCoachScoutingReport = () => import(/* webpackChunkName: "CoachModule" */ '@/views/ViewCoachScoutingReport.vue')
const CalendarV2 = () => import(/* webpackChunkName: "CoachModule" */ '@/components/calendar/CalendarV2.vue');
const DepthChartV2 = () => import(/* webpackChunkName: "CoachModule" */ '@/components/teams/DepthChart/DepthChartV2.vue');
const TeamRosterV2 = () => import(/* webpackChunkName: "CoachModule" */ '@/components/teams/TeamRosterV2.vue');
const TeamGamesProvider = () => import(/* webpackChunkName: "CoachModule" */ '@/components/teams/TeamGamesProvider.vue');

export const CoachRouteConfigs: RouteConfig[] = [
	{
		beforeEnter: coachAuthGuard,
		path: '/c',
		component: CoachSideNavLayout,
		props: true,
		children: [
			{
				path: '',
				name: Routes.CoachDashboard,
				component: CoachDashboard,
				meta:<HinderRouteMetadata>{
					pageTitle: 'Coach Dashboard'
				}
			},
			{
				path: 'team/dashboard/:teamId',
				name: Routes.TeamDashboard,
				component: TeamDashboardView,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Team Dashboard',
				},
				children:[
					{
						path: 'roster',
						name: Routes.TeamDashboardRoster,
						component: TeamRosterV2,
						meta: <HinderRouteMetadata>{
							pageTitle: 'Team Dashboard',
						},
					},
					{
						path: 'depth-chart',
						name: Routes.TeamDashboardDepthCharts,
						component: DepthChartV2,
						meta: <HinderRouteMetadata>{
							pageTitle: 'Team Dashboard',
						},
					},
					{
						path: 'calendar',
						name: Routes.TeamDashboardCalendar,
						component: CalendarV2,
						meta: <HinderRouteMetadata>{
							pageTitle: 'Team Dashboard',
						},
					},
					{
						path: 'games',
						name: Routes.TeamDashboardGames,
						component: TeamGamesProvider,
						meta: <HinderRouteMetadata>{
							pageTitle: 'Team Dashboard',
						},
					}
				]
			},
			{
				path: 'team/dashboard/:teamId/calendar/fullscreen',
				name: Routes.FullScreenCalendar,
				component: FullScreenCalendar,
				props: true,
				meta: <HinderRouteMetadata>{
					hideAppBar: true,
					showOnMobile: true,
				}
			},
			{
				path: 'team/dashboard/:teamId/event/create',
				name: Routes.TeamEventCreate,
				component: TeamEventCreate,
				props: true,
				meta:{
					hideAppBar: true,
				}
			},
			{
				path: 'team/dashboard/:teamId/event/:teamEventId/edit',
				name: Routes.TeamEventEdit,
				component: TeamEventCreate,
				props: true,
				meta:{
					hideAppBar: true,
				}
			},
			{
				path: 'team/dashboard/:teamId/event/:teamEventId/view',
				name: Routes.TeamEventView,
				component: TeamEventView,
				props: true,
				meta:{
					hideAppBar: true,
				}
			},
			{
				path: 'coachprofile/:coachId',
				name: Routes.CoachProfile,
				component: CoachProfile,
				props: true,
			},
			{
				path: 'athlete/search',
				name: Routes.AthleteSearch,
				component: AthleteSearch
			},
			{
				path: 'athlete/:athleteId/:sharingUrlId?',
				name: Routes.CoachViewAthlete,
				component: AthleteRecruitingProfileView,
				props: (to: Route) => {
					return {
						...to.params,
						...to.query,
					};
				},
				meta: {
					pageTitle: "Recruiting Profile"
				}
			},
			{
				path: 'athlete/:athleteId/videos/:sharingUrlId?',
				name: Routes.CoachViewAthleteVideos,
				component: AthleteRecruitingProfileVideosView,
				props: (to: Route) => {
					return {
						...to.params,
						...to.query,
					};
				},
				meta: {
					pageTitle: "All Videos"
				}
			},
			{
				path: 'teams/create/:currentStep?',
				name: Routes.TeamCreate,
				component: TeamCreate,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Create Team',
				}
			},
			{
				path: 'teams/:teamId/edit/:currentStep?',
				name: Routes.EditTeam,
				component: TeamCreate,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Edit Team',
				},
				props: (to: Route) => {
					return {
						editTeamId: to.params['teamId'],
						selfRoleOptional: false
					}
				},
			},
			{
				path: 'createResume/:currentStep?/edit',
				name: Routes.CoachEditResume,
				component: CoachCreateResume,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Edit Profile"
				}
			},
			{
				path: 'createResume/:currentStep?',
				name: Routes.CoachCreateResume,
				component: CoachCreateResume,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Setup Profile"
				}
			},
			{
				path: 'recruiting/:tab?',
				name: Routes.CoachRecruitingSummary,
				component: CoachRecruitingSummary,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Recruiting"
				}
			},
			{
				path: 'scouting',
				name: Routes.CoachScoutingSummary,
				component: CoachScoutingSummary,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Scouting Reports"
				}
			},
			{
				path: 'scouting/edit/:reportId?',
				name: Routes.CoachScoutingReportForm,
				component: CoachScoutingReportForm,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Scouting Report"
				}
			},
			{
				path: 'scouting/view/:reportId?',
				name: Routes.ViewCoachScoutingReport,
				component: ViewCoachScoutingReport,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Scouting Report"
				}
			},
			...SettingsChildRouteConfigs,
		],
	},
	...InstructorRouteConfigs,
	...StudentRouteConfigs
];