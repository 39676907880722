
import Vue from 'vue';
import OrgAdminSideNav from '../components/navigation/OrgAdminSideNav.vue';
import AppBar from '../components/navigation/AppBar.vue';
import Footer from '../components/navigation/Footer.vue';
export default Vue.extend({
	components: { AppBar, OrgAdminSideNav, Footer },
	data(){
		return {
			showSideNav: true,
		};
	},
	created(){
		if(this.CollapseSideNav) this.showSideNav = false;
	},
	computed: {
		CollapseSideNav(){
			return this.$vuetify.breakpoint.mobile;
		},
		ShowSystemBar(): boolean{
			return this.$slots['system-bar'] !== undefined;
		}
	}
});
