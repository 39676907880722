
import Vue from 'vue';
import { VChip } from 'vuetify/lib';
import { mdiChevronDown } from '@mdi/js';
export default Vue.extend({
	render(h) {
		return h(VChip, {
			attrs: {
				'x-small': true,
				'label': mdiChevronDown,
				// class: 'bachip',
				...this.$attrs,
			},
			on: this.$listeners,
			scopedSlots: this.$scopedSlots,
			style: {
				'font-weight': 400,
				'font-size': '11px',
				'line-height': '11px',
				'letter-spacing': '0.12em',
				'padding': '12px',
				'text-transform': 'uppercase'
			}
		}, Object.keys(this.$slots).map(slot => h('template', { slot }, this.$slots[slot])))
	}
})
