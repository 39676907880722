
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiMagnify, mdiChevronDown, mdiChevronRight } from '@mdi/js';
import CircledAvatar from '../ui/CircledAvatar.vue';
import ContextMenu from '../ui/ContextMenu.vue';
import { DataTableHeader } from 'vuetify';
import { VuetifyMixin } from '../../mixins';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { formatDatePretty } from '@/helpers/date';

@Component({
	components: { CircledAvatar, ContextMenu },
})
export default class TeamLineupSummary extends Mixins(VuetifyMixin) {
	mdiMagnify = mdiMagnify;
	mdiChevronDown = mdiChevronDown;
	mdiChevronRight = mdiChevronRight;
	formatDatePretty = formatDatePretty;

	@Prop({ default: 'baColorDeepBlue' }) color: string;
	@Prop({ default: true, type: Boolean }) headerPadding: boolean;
	@Prop({ default: 660 }) tableHeight: number;
	@Prop({ default: [] }) teamEvents: CalendarEventModel[];

	searchLineups: string = "";

	headers: DataTableHeader<any>[] = [
		{
			text: 'Name',
			value: 'name',
		},
		{
			text: 'Date',
			value: 'date',
		},
		{
			text: 'Formation',
			value: 'FormationName',
		},
		{
			text: '',
			value: 'data-table-controls',
		},
	];


	get TeamEvents(): CalendarEventModel[]{
		return this.teamEvents
			.filter(e => e.name.match(new RegExp(`.*${this.searchLineups}.*`, 'i')))
			.sort((a,b) => +a.start - +b.start);
	}

	get HeaderStyle(): Record<string, string>{
		return {
			'border-top': this.color === 'baColorDeepBlue' ? `1px solid #074E69 !important` : undefined,
			'background-color': this.getColor(this.color),
		}
	}

	get TableStyle(): Record<string, string>{
		return {
			'overflow-y': 'auto',
			'max-height': `${this.tableHeight}px`,
		}
	}

}
