
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { DebounceMixin, VuetifyMixin } from '../../mixins';
import { getShortMonthName, getShortWeekdayName, getDate } from '../../helpers';
import EventItem from './EventItem.vue';
import { CalendarEventModel } from '../../models/calendar/CalendarEventModel';

interface EventBucket{
	label: string,
	events: CalendarEventModel[],
}

@Component({
	components: { EventItem },
})
export default class EventsAgenda extends Mixins(DebounceMixin, VuetifyMixin){
	@Prop({ default: false, type: Boolean }) includePast: boolean;
	@Prop({ default: 300 }) height: number;
	@Prop({ default: [] }) events: CalendarEventModel[];

	$refs:{
		agendaContainer: HTMLDivElement;
	}

	eventClicked(event: CalendarEventModel): void{
		this.$emit('click:event', {
			event, 
			day: {
				date: `${event.start.getUTCFullYear()}-${event.start.getUTCMonth() + 1}-${event.start.getUTCDate()}` 
			} 
		});
	}

	scrollContentPadding: number = 8;
	scrollContentHeight: number = 0;
	debounceResize(): void{
		this.debounceCallback('onResize', () => this.onResize(), 100);
	}
	onResize(): void {
		this.scrollContentHeight = this.$refs['agendaContainer'].clientHeight;
	}
	get ScrollContainerStyle(): Record<string, string>{
		return {
			padding: `${this.scrollContentPadding}px`,
		};
	}
	get ScrollContentHeightLessPadding(): number{
		return Math.max(0,this.scrollContentHeight - (this.scrollContentPadding*2));
	}
	get ScrollContentStyle(): Record<string, string>{
		return {
			'height': `${this.ScrollContentHeightLessPadding}px`,
			'overflow': 'hidden',
			'overflow-y': 'auto',
		};
	}
	mounted(): void{
		this.onResize();
	}

	formatDate(date: Date): string{
		return `${getShortWeekdayName(date, true)}, ${getShortMonthName(date, true)} ${getDate(date, true)}`;
	}

	get EventBuckets(): EventBucket[]{
		const events: CalendarEventModel[] = this.events.slice().sort((a,b) => {
			return +(a.start) - +(b.start);
		});
		return Array.from(events).map(event => {
			const label = this.formatDate(event.start);
			return {
				label,
				events: events.filter(e => this.formatDate(e.start) === label),
				$event: {
					day: { date: `${event.start.getUTCFullYear()}-${event.start.getUTCMonth()}-${event.start.getUTCDay()}` } 
				}
			}
		});
	}

}
