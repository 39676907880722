
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiPlus, mdiChevronLeft, mdiChevronRight, mdiFormatListBulleted, mdiCalendar, mdiArrowExpandAll, mdiMagnify, mdiCalendarMonth, mdiCheck, mdiGoogle, mdiMicrosoftWindows, mdiUpload } from '@mdi/js';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { getDefaultEventTypes } from '../../helpers/default-event-types';
import { getMonthName, formatDate, formatDateHyphensYYYYMMDD } from '../../helpers/date';
import EventTypesGroup from './EventTypesGroup.vue';
import EventsAgenda from './EventsAgenda.vue';
import { VuetifyMixin } from '../../mixins';
import { TeamEventTypeData } from '../../../types/interfaces';
import { CalendarMode, CalendarModeValues } from './types/CalendarMode';
import { VCalendarDay } from './types/VCalendarDay';
import { title } from '../../pipes/title.pipe';
import BATextField from '@/components/inputs/BATextField.vue';
import CopyToClipboard from '@/components/ui/CopyToClipboard.vue';
import Dialog from "@/components/ui/Dialog.vue";
import FormGroupHeading from "@/components/ui/FormGroupHeading.vue";
import { CalendarUrls } from '../hoc/CalendarProvider.vue';
import { ExportSvg } from "@/components/svg/ExportSvg.vue";
import SectionHeading from "@/components/ui/SectionHeading.vue";

@Component({
	components: { EventsAgenda, EventTypesGroup, Dialog, FormGroupHeading, BATextField, CopyToClipboard, ExportSvg, SectionHeading }
})
export default class Calendar extends Mixins(VuetifyMixin){
	mdiPlus = mdiPlus;
	mdiChevronLeft = mdiChevronLeft;
	mdiChevronRight = mdiChevronRight;
	mdiFormatListBulleted = mdiFormatListBulleted;
	mdiCalendar = mdiCalendar;
	mdiArrowExpandAll = mdiArrowExpandAll;
	mdiMagnify = mdiMagnify;
	mdiUpload = mdiUpload;
	mdiCalendarMonth = mdiCalendarMonth;
	mdiCheck = mdiCheck;
	mdiGoogle = mdiGoogle;
	mdiMicrosoftWindows = mdiMicrosoftWindows;
	title = title;
	$refs: {
		calendar: any
	};

	get AthleteMode(): boolean{
		if(this.$route.path.includes('/athlete')){
			return true;
		}
		return false;
	}

	@Prop({ type: Boolean, default: false }) loading: boolean;
	@Prop({ type: Boolean, default: false }) readOnly: boolean;
	@Prop({ default: [] }) events: CalendarEventModel[];

	view: CalendarMode = CalendarMode.Month;
	@Prop({ type: String, default: () => formatDateHyphensYYYYMMDD(new Date()) }) focusDate: string;
	updateFocusDate(focusDate: string): void{
		this.$emit('update:focus-date', focusDate);
	}

	@Prop({ type: Number, default: 480 }) height: number;
	@Prop({ type: Array, default: getDefaultEventTypes() }) eventTypes!: TeamEventTypeData[];
	@Prop({ type: String, default: 'baColorVibrantBlueText' }) defaultEventColor: string;
	@Prop({ type: String, default: 'baColorVibrantBlueText' }) accentColor: string;
	@Prop({ type: String, default: 'baColorAppForeground' }) color: string;
	@Prop({ type: Boolean, default: false }) largeCalendar: boolean;

	showAddEvent = false;
	focusEvent = {}
	focusDay = null;
	eventFilters = this.eventTypes.filter(event => event.disabled !== true).map(event => event.name);
	types = CalendarModeValues;

	addEvent(focusDate?: string): void{
		if(this.readOnly) return;
		this.$emit('click:add-event', { focusDate });
	}

	weekdayFmt(date: string): string{
		const weekdays = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
		return `${weekdays[new Date(date).getUTCDay()]}`;
	}

	get ShowDatePicker(): boolean{
		return ["day", "week", "4day"].includes(this.view) && this.$vuetify.breakpoint.lgAndUp;
	}

	eventClicked(calendarEvent: CalendarEventModel, $event: VCalendarDay<CalendarEventModel>): void{
		this.$emit('click:event', { calendarEvent, $event });
	}

	toggleEventView(): void{
		if(this.view !== CalendarMode.Agenda){
			
			this.updateEventView(CalendarMode.Agenda);
		}else{
			this.updateEventView(CalendarMode.Month);
		}
	}
	updateEventView(view: string): void{
		this.$emit('update:view', view);
	}

	get CalendarTimeTitle(): string{
		if (this.$refs.calendar && this.view !== CalendarMode.Agenda){
			return this.$refs.calendar.title ? this.$refs.calendar.title : this.CurrentMonthName + ' ' + this.CurrentYear
		} else {
			return this.CurrentMonthName + ' ' + this.CurrentYear
		}
	}

	get IsDesktop(): boolean{
		return this.$vuetify.breakpoint.mdAndUp;
	}

	get IsTablet(): boolean{
		return this.$vuetify.breakpoint.sm;
	}

	get IsMobile(): boolean{
		return this.$vuetify.breakpoint.xs;
	}

	get ShowMonthView(): boolean{
		return this.view === CalendarMode.Month;
	}
	get ShowAgendaView(): boolean{
		return this.view === CalendarMode.Agenda;
	}

	get CurrentDate(): Date{
		return new Date(this.focusDate);
	}
	get CurrentMonthName(): string{
		return getMonthName(this.CurrentDate);
	}
	get CurrentYear(): number{
		return this.CurrentDate.getUTCFullYear(); 
	}
	/**
	 * Returns only events who's type match the current filters. Events without a type are always displayed
	 */
	get FilteredEvents(): CalendarEventModel[]{
		return this.events.filter(
			event => event.eventType ? (this.eventFilters.find(
				type => type === event.eventType
			) !== undefined) : true
		);
	}
	get HeaderSheetStyle(): Record<string,any>{
		return {
			'border-bottom': `2px solid ${this.getColor('baColorGray12')} !important`,
		};
	}
	get Color(): string{
		return this.getColor(this.color);
	}
	async created(): Promise<void>{
		this.resetDate();
	}

	async removeCalendarEvent(calendarEvent: CalendarEventModel): Promise<void>{
		this.$emit('remove:event', { calendarEvent });
	}

	/** v-calendar events */
	getEventColor (event: CalendarEventModel): string {
		let color = this.defaultEventColor;
		if(event.eventType){
			let type = this.eventTypes.find(type => type.name === event.eventType);
			if(type && type.color) color = type.color;
		}
		return color;
	}
	onUpdateRange(): void{
		this.$emit('update:range');
	}
	showEvent($event: VCalendarDay<CalendarEventModel>): void{
		const event: CalendarEventModel = $event.event;
		this.focusEvent = $event;
		this.eventClicked(event, $event);
	}
	clickDay($event: VCalendarDay<CalendarEventModel>): void{
		this.focusDay = new Date($event.date);
		this.updateFocusDate(formatDate(new Date($event.date)));		
		this.addEvent($event.date);
	}
	clickMore($event: VCalendarDay<CalendarEventModel>): void{
		this.updateFocusDate(formatDate(new Date($event.date)));
		this.focusDay = new Date($event.date);
	}
	resetDate(override?: Date): void{
		if(override === undefined){
			this.updateFocusDate(formatDate(new Date()));
		}else{
			this.updateFocusDate(formatDate(override));
		}
	}
	prev (): void {
		if (this.view === CalendarMode.Month || this.view === CalendarMode.Agenda){
			const currentDate = new Date(this.focusDate)
			const newDate = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
			this.updateFocusDate(formatDate(newDate));
		} else if (this.view === CalendarMode.Week){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()-7
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.updateFocusDate(formatDate(newDate));
		} else if (this.view === CalendarMode.Day){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()-1
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.updateFocusDate(formatDate(newDate));
		} else if (this.view === CalendarMode.FourDay){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()-4
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.updateFocusDate(formatDate(newDate));
		}
	}
	next (): void {
		if (this.view === CalendarMode.Month || this.view === CalendarMode.Agenda){
			const currentDate = new Date(this.focusDate)
			const newDate = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth()+1, 1)
			this.updateFocusDate(formatDate(newDate));
		} else if (this.view === CalendarMode.Week){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()+7
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.updateFocusDate(formatDate(newDate));
		} else if (this.view === CalendarMode.Day){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()+1
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.updateFocusDate(formatDate(newDate));
		} else if (this.view === CalendarMode.FourDay){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()+4
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.updateFocusDate(formatDate(newDate));
		}
	}

	exportDialogVisible = false;
	copyClicked = false;
	@Prop({ default: null }) calendarUrls: CalendarUrls;
	async toggleExportDialogVisiblility(): Promise<void> {
		this.exportDialogVisible  = !this.exportDialogVisible;
	}
	get CalendarUrls(): CalendarUrls | null {
		return this.calendarUrls !== undefined && this.calendarUrls !== null ? this.calendarUrls : null;
	}
}
