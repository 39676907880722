import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { AssessmentEntry } from '../../../types/interfaces/PyhsicalAssessmentDataFormValue';
import { athleteAssessmentDataApi } from '@/api/AthleteAssessmentDataApi';

const AssessmentCreateMutationTypes = {
	CREATE_ASSESSMENT: 'CREATE_ASSESSMENT',
	CREATE_ASSESSMENT_SUCCESS: 'CREATE_ASSESSMENT_SUCCESS',
	CREATE_ASSESSMENT_FAILURE: 'CREATE_ASSESSMENT_FAILURE',
}

@Module({
	namespaced: true,
	dynamic: true,
	name: 'AssessmentCreateStore',
	store: store
})
export default class AssessmentCreateModule extends VuexModule {

	createAssessmentLoading: boolean = false;

	@Action({
		rawError: true,
		commit: AssessmentCreateMutationTypes.CREATE_ASSESSMENT_SUCCESS
	}) async createAssessments(assessments: AssessmentEntry[]){
		console.log(assessments);
		this.context.commit(AssessmentCreateMutationTypes.CREATE_ASSESSMENT, assessments);
		try{
			return await athleteAssessmentDataApi.bulkInsert(assessments);
		}catch(e){
			console.error("Failed to Create Assessments", e);
			this.context.commit(AssessmentCreateMutationTypes.CREATE_ASSESSMENT_FAILURE, e);
		}
	}

	@Mutation [AssessmentCreateMutationTypes.CREATE_ASSESSMENT](){
		this.createAssessmentLoading = true;
	}
	@Mutation [AssessmentCreateMutationTypes.CREATE_ASSESSMENT_SUCCESS](){
		this.createAssessmentLoading = false;
	}
	@Mutation [AssessmentCreateMutationTypes.CREATE_ASSESSMENT_FAILURE](error: any){
		this.createAssessmentLoading = false;
	}
}
