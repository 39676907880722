
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiPlayCircle } from '@mdi/js';
import { VuetifyMixin } from '../../../mixins';

@Component
export default class VideoThumbnail extends Mixins(VuetifyMixin){
	mdiPlayCircle = mdiPlayCircle;

	@Prop({ required: true }) thumbnailUrl: string;
	@Prop({ default: 'baColorPrimaryBlue' }) color: string;
	@Prop({ default: 35 }) iconSize: number;
}
