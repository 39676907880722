import { AccessControlledModel } from "../AccessControlledModel";
import { Tag } from "./Tag";

export class TaggableModel extends AccessControlledModel {
	tags: Tag[] = [];

	/**
	 * Returns true if the `subjectId` matches or there is no `subjectId` and the tag's `text` matches a tag on this video.
	 * 
	 * @param tag: Tag or array of tags.
	 * @param allTags Default: `false` If an array, all tags must match
	 */
	public hasTag(tag: Tag | Tag[], allTags: boolean = false): boolean {
		if (Array.isArray(tag)) {
			if(tag.length === 0) return true;
			if (allTags === true) {
				return tag.map(t => this._hasTag(t)).reduce((a, b) => a && b, true);
			}
			for (const t of tag) {
				if (this._hasTag(t)) {
					return true;
				}
			}
		} else {
			return this._hasTag(tag);
		}
	}
	// This has tag for internal use skips the `isArray` check.
	private _hasTag(tag: Tag): boolean {
		return this.tags.find(t => {
			if (t.subjectId) {
				return t.subjectId === tag.subjectId
			}
			return t.text === tag.text
		}) !== undefined;
	}

	/** Adds tags if they aren't already present */
	addTags(tags: Tag[]): void{
		this.tags.push(
			...tags.filter(tag => !this._hasTag(tag))
		);
	}
}