
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class NavSvg extends Mixins(VuetifyMixin){
	@Prop({ default: '#EDEDED' }) private color: string;
	@Prop({ default: 34 }) private size: string;
	get Color(): string{
		return this.getColor(this.color);
	}
}
