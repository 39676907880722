
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class PlusDiamondSvg extends Mixins(VuetifyMixin){
	@Prop({ default: '#C90000' }) private color: string;
	@Prop({ default: 'white' }) private plusColor: string;
	@Prop({ default: 24 }) private size: number;
	get Color(): string{
		return this.getColor(this.color);
	}
	get PlusColor(): string{
		return this.getColor(this.plusColor);
	}
}
