
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class AdminAppSvg extends Vue{
	@Prop({ default: false, type: Boolean }) active: boolean;
	get Opacity(): string{
		if(this.active) return '1';
		return '0.5';
	}
}
