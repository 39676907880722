
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SubscriptionPlanModel } from '@/models/subscriptionPlan/SubscriptionPlanModel';
import { subscriptionPlanApi } from '@/api/SubscriptionPlanApi';
import { notificationStore } from '@/store';
import { NotificationModel } from '@/models/notification/NotificationModel';
import { CustomerModel } from '@/models/customer/CustomerModel';
import { CustomerApi } from '@/api/CustomerApi';
import { Currency, CustomerCountry, Country } from '@/../types/enums';
import { PlanFeature } from '@/../types/interfaces';
import { PLAN_FEATURES } from '@/../types/constants/plan-features';
import { PageState } from '@/models/PageState';
@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class SubscriptionPlanInfoProvider extends Vue{
	@Prop({ default: 'div' }) as: string;
	/** Parent Resource of the Customer ie. 'athlete' */
	@Prop({ default: null }) resource: string | null;
	/** Parent ID of the Customer ie. athlete.id */
	@Prop({ default: null }) parentId: string | null;
	@Prop({ default: null }) country: CustomerCountry | null;

	get CountryCurrency(): Currency{
		if(this.country === CustomerCountry.CA){
			return Currency.CAD;
		}
		return Currency.USD;
	}

	get CustomerApi(): CustomerApi | null{
		if(this.resource === null || this.parentId === null){
			return null;
		}
		return new CustomerApi(`${this.resource}/${this.parentId}`);
	}

	get plansLoading(): boolean{
		return this.state.IsLoading;
	}
	get plansInitialized(): boolean{
		return !this.state.IsInitial;
	}
	plans: SubscriptionPlanModel[] = [];
	customer: CustomerModel | null = null;
	state: PageState = new PageState("Initial");

	get PlanPricesFormatted(): string[]{
		if(this.customer === null){
			// TODO: Review prices for new users. This will default to CAD prices when on the upgrade page
			return this.plans.map(plan => plan.getPriceFormatted(this.CountryCurrency));
		}
		return this.plans.map(plan => plan.getPriceFormatted(this.customer.currency));
	}
	get MonthlyPlanPricesFormatted(): string[]{
		if(this.customer === null){
			// TODO: Review prices for new users. This will default to CAD prices when on the upgrade page
			return this.plans.map(plan => plan.getMonthlyPriceFormatted(this.CountryCurrency));
		}
		return this.plans.map(plan => plan.getMonthlyPriceFormatted(this.customer.currency));
	}
	/** Prices to display slashed down, if any*/
	get SlashPricesFormatted(): Array<string | null>{
		if(this.customer === null){
			// TODO: Review prices for new users. This will default to CAD prices when on the upgrade page
			return this.plans.map(plan => plan.getSlashPriceFormatted(this.CountryCurrency));
		}
		return this.plans.map(plan => plan.getSlashPriceFormatted(this.customer.currency));
	}

	get Ready(): boolean{
		return this.plansLoading === false && this.plansInitialized === true;
	}

	async loadPlanInformation(): Promise<void>{
		this.state = new PageState('Loading');
		try{
			if(this.CustomerApi !== null){
				// If no resource/parent id is provided, only load plan info
				this.customer = await this.CustomerApi.getCustomerOrCreate();
			}
			this.plans = await subscriptionPlanApi.findAllWithAccess({ params: { populate: 'prices', country: this.country } })
			this.state = new PageState('Ready');
		}catch(error){
			console.error("Failed to load plans", error);
			this.state = PageState.getPageState(error);
			notificationStore.pushErrorNotification(new NotificationModel("Something went wrong. Please try again later"));
		}
	}

	created(): void{
		this.loadPlanInformation();
	}
	get PlanFeatures(): PlanFeature[]{
		return this.plans
			.map(plan => plan.featureList)
			.reduce((a,b) => [...a,...b])

	}

	get SlotProps(): {
		state: PageState,
		AllFeatures: PlanFeature[],
		plansLoading: boolean,
		plansInitialized: boolean,
		plans: SubscriptionPlanModel[],
		customer: CustomerModel,
		Ready: boolean,
		PlanPricesFormatted: string[],
		MonthlyPlanPricesFormatted: string[],
		SlashPricesFormatted: Array<string | null>,
	}{
		return {
			state: this.state,
			AllFeatures: PLAN_FEATURES,
			plansLoading: this.plansLoading,
			plansInitialized: this.plansInitialized,
			plans: this.plans,
			customer: this.customer,
			Ready: this.Ready,
			PlanPricesFormatted: this.PlanPricesFormatted,
			MonthlyPlanPricesFormatted: this.MonthlyPlanPricesFormatted,
			SlashPricesFormatted: this.SlashPricesFormatted,
		};
	}
}
