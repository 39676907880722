import { Component, Vue } from 'vue-property-decorator';
import { eventDetailsStore } from '../store';
import { BestAthletesEventModel } from '@/models/bestAthletesEvent';
import { clearTime } from '@/helpers';

/**
 * Provides computed properties for a user's registered events.
 */
@Component({
	async beforeRouteEnter(_, __, next): Promise<void>{
		console.log("MyEvents Before Route Enter")
		if (!eventDetailsStore.myEventsInitialized && !eventDetailsStore.myEventsLoading){
			await eventDetailsStore.loadMyEvents();
		}
		next();
	}
})
export class MyEventsMixin extends Vue {
	async created(): Promise<void>{
		if (!eventDetailsStore.myEventsInitialized && !eventDetailsStore.myEventsLoading) {
			await eventDetailsStore.loadMyEvents();
		}
	}
	get MyEventsReady(): boolean{
		return eventDetailsStore.myEventsInitialized && ! eventDetailsStore.myEventsLoading;
	}
	get MyEventsCutoff(): Date{
		return clearTime(new Date);
	}
	get MyEvents(): BestAthletesEventModel[]{
		return eventDetailsStore.myEvents
			.sort((a, b) => +a.start - +b.start)
			.filter(e => e.start >= this.MyEventsCutoff);
	}
	get NoEvents(): boolean{
		return eventDetailsStore.myEvents.length === 0;
	}

	get OutstandingWaivers(): number[]{
		return this.MyEvents.map(e => this.getOutstandingWaiversCount(e.id));
	}

	private getOutstandingWaiversCount(eventId: string): number{
		const waiverDetails = eventDetailsStore.myEventWaiverDetails[eventId];
		if(waiverDetails === undefined) return 0;
		return Math.max(waiverDetails.waiverTemplates.length - waiverDetails.signedWaivers.length, 0);
	}
}
