import { getInstance } from "@/auth/auth0Plug";
import { Auth0Plugin } from '@/auth/Auth0';
import { authGuard } from '@/auth/authGuard';
import { navigationStore, userStore } from "@/store";
import * as Routes from '@/../types/constants/web_client_user.routes';
import { NavigationGuard, Route } from "vue-router";

export function isCoachRoute(path: string): boolean{
	return path === '/c' || path.startsWith('/c/');
}

/**
 * Runs authGuard, then checks if the user's Coach Profile exists and redirects to setup otherwise.
 */
export const coachAuthGuard: NavigationGuard = (to, from, next) => {
	next = navigationStore.StashNavigationNext(to, next, { ignoreRoutes: [Routes.LoadingPage] });
	return authGuard(to, from, () => {
		const auth: Auth0Plugin = getInstance();
		const completedProfile = auth.HasCoachProfile;
		const routeToCreateUser = to.name === 'CoachProfileSetup'

		if (userStore.currentProfileData !== null && userStore.currentProfileData.type === 'athlete') {
			if (isCoachRoute(to.path)){
				// Check if the user is trying go the coach recruting profile view while as an athlete
				// redirect them to their own athlete recruiting profile view
				if (to.name === Routes.CoachViewAthlete) {
					return next({
						name: Routes.AthleteRecruitingProfileView,
						query: { ...to.params, ...to.query },
						params: to.params,
					})
				}
				else {
					const athletePath = to.path.replace('/c', '/');
					return next({
						path: athletePath,
						query: { ...to.params, ...to.query },
						params: to.params,
					});
				}
			}
		}

		// If the user does not have a profile create one.
		if (!completedProfile && !routeToCreateUser) {
			return next({ name: 'CoachProfileSetup', query: to.query, params: to.params });
		}
	
		// No point in setting up a profile if we have one.
		if (completedProfile && routeToCreateUser) {
			return next({ name: 'CoachDashboard', query: to.query, params: to.params });
		}

		return next()
	});
}