
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import { DatePickerAllowedDatesFunction } from 'vuetify';
import { getShortMonthName } from '../../helpers/date';

@Component
export default class MonthPicker extends Vue{

	@Prop({ default: 'baColorDeepBlue' }) private color: string;
	@Prop({ default: () => true, type: Function }) private allowedDates: DatePickerAllowedDatesFunction;

	@Prop() private value: Date;
	input(value: Date){
		this.$emit('input', value);
	}

	private date: Date = this.value;
	dateInput(value){
		this.show = false;
		this.value = new Date(value);
		this.input(this.value);
	}

	private show: boolean = false;

	get PrettyDate(){
		if(this.value){
			return `${getShortMonthName(this.value)} ${this.value.getUTCFullYear()}`;
		}
		return '';
	}

}
