
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
	mdiChevronDown,
	mdiPencil,
	mdiAccountArrowRightOutline,
	mdiClockOutline,
} from "@mdi/js";
import { title, role } from "../../pipes";
import CircledAvatar from "../ui/CircledAvatar.vue";
import ContextMenu from "../ui/ContextMenu.vue";
import { MyTeamsMixin, VuetifyMixin } from "../../mixins";
import { TeamDetailsMixin } from "../../mixins/TeamDetailsMixin";
import { ContextMenuItem, InviteStaffFormValue } from "../../../types/interfaces";
import { EditTeam } from "../../../types/constants/web_client_user.routes";
import { teamCreateStore, userStore, notificationStore } from "@/store";
import * as Routes from "@/../types/constants/web_client_user.routes";
import { NotificationModel } from '@/models/notification/NotificationModel';
import { StaffMember } from '@/models/team/StaffMember';
import { InvitationStatus } from '../../../types/enums';
import InviteStaffDialog from '@/components/teams/InviteStaffDialog.vue';

@Component({
	components: { CircledAvatar, ContextMenu, InviteStaffDialog },
	filters: { title, role }
})
export default class TeamDetailsCard extends Mixins(
	TeamDetailsMixin,
	MyTeamsMixin,
	VuetifyMixin
) {
	mdiChevronDown = mdiChevronDown;
	mdiClockOutline = mdiClockOutline;

	@Prop({ type: Boolean, default: false }) loading: boolean;
	@Prop({ type: Boolean, default: false }) readOnly: boolean;
	showConfirmLeaveTeam: boolean = false;
	showInviteDialog: boolean = false;
	staffInviteId: string = "";
	staffInvite: StaffMember = null;

	coachInviteForm: InviteStaffFormValue = {
		firstName: "",
		lastName: "",
		email: "",
		role: undefined,
	};

	inviteCoach(staff: StaffMember){
		this.staffInviteId = staff.id;
		this.coachInviteForm = {
			firstName: staff.firstName,
			lastName: staff.lastName,
			email: staff.email,
			role: staff.role,
		};
		this.staffInvite = staff;
		this.showInviteDialog = true;
	}

	CoachInvitationPending(staff: StaffMember): boolean {
		if (staff.coachId) return false
		return staff.invitationStatus === InvitationStatus.Pending && userStore.coachId !== staff.coachId;
	}

	isCoachInvited(staff: StaffMember): boolean{
		return staff.invitationStatus === InvitationStatus.None;
	}

	didCoachAccept(staff: StaffMember): boolean{
		return !!staff.coachId || staff.invitationStatus === InvitationStatus.Accepted
	}

	get showInviteOrResend(): boolean {
		return this.staffInvite && !this.staffInvite.coachId && this.staffInvite.invitationStatus === InvitationStatus.None;
	}

	get AthleteMenuItems(): ContextMenuItem[]{
		return [
			{
				icon: mdiAccountArrowRightOutline,
				text: "Leave Team",
				click: () => this.showLeaveTeamBox(),}
		]
	}

	get CoachMenuItems(): ContextMenuItem[] {
		return [
			{
				icon: mdiPencil,
				text: "Edit Team",
				click: () => {
					this.$router.push({
						name: EditTeam,
						params: {
							teamId: this.team.id
						}
					});
				}
			},
			{
				icon: mdiAccountArrowRightOutline,
				text: "Leave Team",
				click: () => this.showLeaveTeamBox()
			}
			// {
			// 	icon: mdiEmailOpen,
			// 	text: "Invite New Staff"
			// },
			// {
			// 	icon: mdiMagnify,
			// 	text: "Find players"
			// },
			// {
			// 	icon: mdiArrowLeft,
			// 	text: "Leave team"
			// },
		];
	}
	async leaveTeam(): Promise<void> {
		this.showConfirmLeaveTeam = false;
		await teamCreateStore.leaveTeam({ teamId: this.team.id });
		await notificationStore.pushSnackbarSuccess(new NotificationModel(`You have left ${this.team.name}!`));
		this.$router.push({
			path: '/'
		});
	}
	showLeaveTeamBox(): void {
		this.showConfirmLeaveTeam = true;
	}

	cancelLeaveTeam(): void {
		this.showConfirmLeaveTeam = false;
	}
}
