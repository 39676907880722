import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { TeamModel } from '../../models/team';
import { teamApi } from '@/api/TeamApi';
import { coachProfile } from "@/store";

const Mutations = {
	LOAD_MY_TEAMS: 'LOAD_MY_TEAMS',
	LOAD_MY_TEAMS_SUCCESS: 'LOAD_MY_TEAMS_SUCCESS',
	LOAD_MY_TEAMS_FAILURE: 'LOAD_MY_TEAMS_FAILURE',

	LOAD_TEAM: 'LOAD_TEAM',
	LOAD_TEAM_SUCCESS: 'LOAD_TEAM_SUCCESS',
	LOAD_TEAM_FAILURE: 'LOAD_TEAM_FAILURE',

	SPLICE_TEAM: 'SPLICE_TEAM',
}

const name = 'TeamsStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class TeamsModule extends VuexModule {

	myTeamsInitialized: boolean = false;
	myTeamsLoading: boolean = false;
	myTeams: TeamModel[] = [];

	@Action({
		rawError: true
	})
	async loadMyTeams(coachId: string): Promise<void>{
		this.context.commit(Mutations.LOAD_MY_TEAMS);
		try {
			const teams = await teamApi.findByCoachId({ coachId });
			this.context.commit(Mutations.LOAD_MY_TEAMS_SUCCESS, teams);
		} catch (e) {
			console.error("Failed to Load Team", e);
			this.context.commit(Mutations.LOAD_MY_TEAMS_FAILURE, e);
		}
	}

	@Action({
		rawError: true
	})
	async loadCoachTeams(): Promise<void>{
		this.loadMyTeams(coachProfile.coachProfile.id)
	}

	@Mutation [Mutations.LOAD_MY_TEAMS](): void {
		this.myTeamsLoading = true;
	}
	@Mutation [Mutations.LOAD_MY_TEAMS_SUCCESS](teams: TeamModel[]): void {
		this.myTeamsLoading = false;
		this.myTeams = teams;
		this.myTeamsInitialized = true;
	}
	@Mutation [Mutations.LOAD_MY_TEAMS_FAILURE](error: any): void {
		this.myTeamsLoading = false;
	}

	loadTeamByIdLoading: boolean = false;

	@Action({
		rawError: true
	})
	async loadTeamById(teamId: string): Promise<TeamModel>{
		this.context.commit(Mutations.LOAD_TEAM);
		try {
			const team = await teamApi.findById(teamId);
			this.context.commit(Mutations.SPLICE_TEAM, {team});
			this.context.commit(Mutations.LOAD_TEAM_SUCCESS, team);
			return team;
		} catch (e) {
			console.error("Failed to Load Team", e);
			this.context.commit(Mutations.LOAD_TEAM_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_TEAM](): void {
		this.loadTeamByIdLoading = true;
	}
	@Mutation [Mutations.LOAD_TEAM_SUCCESS](): void {
		this.loadTeamByIdLoading = false;
		this.myTeamsInitialized = true;
	}
	@Mutation [Mutations.LOAD_TEAM_FAILURE](error: any): void {
		this.loadTeamByIdLoading = false;
	}

	@Action({
		rawError: true
	})
	spliceTeam({ team }: {team: TeamModel}): void {
		this.context.commit(Mutations.SPLICE_TEAM, { team });
	}

	@Mutation [Mutations.SPLICE_TEAM]({ team } :{ team: TeamModel }): void {
		const existingIndex = this.myTeams.findIndex(t => t.id === team.id);
		if(existingIndex > -1){
			this.myTeams.splice(existingIndex, 1, team);
		}else{
			this.myTeams.push(team);
		}
	}
}
