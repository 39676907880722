/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfig } from 'vue-router';
import { authGuard } from '@/auth/authGuard';
import { OnboardingLayout } from '@/layouts';
import * as Routes from '@/../types/constants/web_client_user.routes';

const AthleteOnboarding = () => import(/* webpackChunkName: "OnboardingModule" */ '@/views/onboarding/AthleteOnboarding.vue');
const CoachOnboarding = () => import(/* webpackChunkName: "OnboardingModule" */ '@/views/onboarding/CoachOnboarding.vue');
const RootOnboarding = () => import(/* webpackChunkName: "OnboardingModule" */ '@/views/onboarding/RootOnboarding.vue')
const EmailVerification = () => import(/* webpackChunkName: "OnboardingModule" */ '@/views/onboarding/EmailVerification.vue')

export const OnboardingRouteConfigs: RouteConfig[] = [
	{
		beforeEnter: authGuard,
		path: '/onboarding',
		component: OnboardingLayout,
		children:[
			{
				path: '',
				name: Routes.RootOnboarding,
				component: RootOnboarding,
				props: true,
			},
			{
				path: 'athlete',
				name: Routes.AthleteProfileSetup,
				component: AthleteOnboarding,
				props: true,
			},
			{
				path: 'coach',
				name: Routes.CoachProfileSetup,
				component: CoachOnboarding,
				props: true,
			},
			{
				path: 'email-verification',
				name: Routes.EmailVerification,
				component: EmailVerification,
				props: true,
			},
		]
	}
];