
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mdiSoccer } from '@mdi/js';
@Component({
	components: {  },
})
export default class AppSwitcherIcon extends Vue{
	@Prop({ default: false, type: Boolean }) active: boolean;
	@Prop({ default: '/' }) url: string;
	@Prop({ default: mdiSoccer }) icon: string;
	@Prop({ default: 'baColorVibrantBlue' }) color: string;
	get Color(): string{
		if(this.active){
			return this.color;
		}
		return 'baColorSecondaryText';
	}
	get TextColorClass(): string{
		if(this.active){
			return `${this.color}--text`;
		}
		return 'baColorPrimaryText--text';
	}
	get HttpsUrl(): string{
		return `https://${this.url}`;
	}
}
