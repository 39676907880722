export class DepthChartPosition {
	playerIds: string[];
	position: string;
	label: string;
	number: string;
	row: number;
	col: number;

	constructor({ position, number, row, col }: { position?: string, number?: string, row?: number, col?: number} = {}){
		this.label = position;
		this.position = position;
		this.number = number;
		this.row = row;
		this.col = col;
	}

	get PositionId() {
		return `${this.position}.${this.row}${this.col}`;
	}

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		return this;
	}
}