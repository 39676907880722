

import { DataTableHeader, DataPagination, DataOptions, DataSortFunction, DataTableFilterFunction } from 'vuetify';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiMagnify, mdiChevronDown, mdiUnfoldMoreHorizontal, mdiSortAscending, mdiSortDescending, mdiPencil, mdiUploadOutline,mdiClose } from '@mdi/js';
import { DebounceMixin, DataTableSortMixin, SportsSelectorMixin, VuetifyMixin, AppHostnameMixin } from '@/mixins';
import { TeamModel, PlayerOnTeam } from '@/models/team';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue';
import InvitePlayerDialog from '@/components/teams/InvitePlayerDialog.vue';
import SortIcon from '@/components/ui/SortIcon.vue';
import { teamDashboardStore, PatchPlayerParams } from '@/store';
import { InvitationStatus } from '@/../types/enums';
import { InvitePlayerFormValue } from '@/../types/interfaces';
import { EditTeam, CoachViewAthlete } from '@/../types/constants/web_client_user.routes';
import { getFlagValue } from '@/feature-flags';
import Jersey from '../teams/Jersey.vue';

@Component({
	components: { ConfirmationDialog, InvitePlayerDialog, SortIcon, Jersey },
})
export default class TeamRoster extends Mixins(DebounceMixin, SportsSelectorMixin, DataTableSortMixin, VuetifyMixin, AppHostnameMixin){
	mdiPencil = mdiPencil;
	mdiUploadOutline = mdiUploadOutline;
	mdiMagnify = mdiMagnify;
	mdiChevronDown = mdiChevronDown;
	mdiUnfoldMoreHorizontal = mdiUnfoldMoreHorizontal;
	mdiSortAscending = mdiSortAscending;
	mdiSortDescending = mdiSortDescending;
	mdiClose = mdiClose;

	@Prop({ default: 'baColorDeepBlue' }) private color: string;
	@Prop({ required: true }) private team: TeamModel;
	@Prop({ default: 590 }) private tableHeight: number;
	@Prop({ type: Boolean, default: false }) private readOnly: boolean;

	showInviteDialog: boolean = false;
	playerInviteId: string = "";
	playerInviteForm: InvitePlayerFormValue = {
		number: "",
		firstName: "",
		lastName: "",
		email: "",
		position: undefined,
	};

	goToEditRoster(step?: string){
		this.$router.push({
			name: EditTeam,
			params:{
				teamId: this.team.id,
				currentStep: step,
			}
		});
	}
	goToPlayerProfile(athleteId: string): void {
		if(this.AthleteAppActive) return;
		this.$router.push({
			name: CoachViewAthlete,
			params: { athleteId }
		})
	}
	invitePlayer(player?: PlayerOnTeam){
		if(player){
			this.playerInviteId = player.id;
			this.playerInviteForm = {
				number: player.number,
				firstName: player.firstName,
				lastName: player.lastName,
				email: player.email,
				position: player.position,
			};
		}else{
			this.playerInviteId = "";
			this.playerInviteForm = {
				number: "",
				firstName: "",
				lastName: "",
				email: "",
				position: undefined,
			};
		}
		this.showInviteDialog = true;
	}

	showInviteOrResend(player: PlayerOnTeam){
		return !player.athleteId && player.invitationStatus === InvitationStatus.None;
	}

	showRemovePlayerDialog: boolean = false;
	selectedPlayerId: string = "";
	confirmRemovePlayer(player: PlayerOnTeam): void{
		this.selectedPlayerId = player.id;
		this.showRemovePlayerDialog = true;
	}
	async removeSelectedPlayer(): Promise<void>{
		this.showRemovePlayerDialog = false;
		await teamDashboardStore.removePlayer({
			teamId: this.team.id,
			playerId: this.selectedPlayerId,
		});
	}

	get TableStyle():  Record<string,string>{
		return {
			'overflow-y': 'auto',
			'max-height': `${this.tableHeight}px`,
		}
	}
	get TableLoading(): boolean{
		return teamDashboardStore.patchPlayerLoading;
	}

	tableOptions: DataOptions = {
		page: 0,
		itemsPerPage: -1,
		sortBy: [],
		sortDesc: [false],
		groupBy: [],
		groupDesc: [false],
		multiSort: false,
		mustSort: false,
	};
	get TableSortBy(): string{
		const [value] = this.tableOptions.sortBy;
		return value;
	}
	get TableSortDesc(): boolean{
		const [value] = this.tableOptions.sortDesc;
		return value;
	}

	patchPlayer(playerId: string, player: Partial<PlayerOnTeam>): void{
		this.debounceCallback(playerId, () => {
			const payload: PatchPlayerParams = {
				teamId: this.team.id,
				playerId: playerId,
				playerPatch: player,
			};
			this.$emit('patch:player', payload);
		});
	}

	sortDown(header, on: any): void{
		console.log("sortDown",header, on);
		on.sort(header.name);
	}

	search: string = "";
	pagination: DataPagination;
	headers: DataTableHeader<any>[] = [
		{
			text: 'No.',
			value: 'number',
		},
		{
			text: 'Name',
			value: 'firstName',
		},
		{
			text: 'Position',
			value: 'position',
		},
		{
			text: 'Grad Year',
			value: 'gradYear',
		},
	];
	expandedItems: any[] = [];
	toggleExpandRow(item: PlayerOnTeam, isExpanded: boolean, expand: (item, isExpanded) => void): void{
		if(this.readOnly) return;
		if(isExpanded){
			this.expandedItems = [];
		}else{
			expand(item, !isExpanded);
		}
	}
	playerFilter: DataTableFilterFunction = (value: any, search: string | null, item: PlayerOnTeam): boolean => {
		const query = new RegExp(search, 'ig');
		return query.test(item.firstName) ||
		query.test(item.lastName) ||
		query.test(item.position) ||
		query.test(item.number);
	}
	playerSort: DataSortFunction<PlayerOnTeam> = (items: PlayerOnTeam[], sortBy: string[], sortDesc: boolean[], locale: string) => {
		if(sortBy.length === 0) return items;
		const [field] = sortBy;
		const [desc] = sortDesc;
		switch(field){
		case 'number':
		case 'gradYear':
			return this.numericSort<PlayerOnTeam>(field, items, desc);
		default:
			return this.genericSort<PlayerOnTeam>(field, items, desc);
		}
	}


	get HeaderStyle(): Record<string,string> {
		return {
			'border-top': `1px solid #074E69 !important`,
			'background-color': this.getColor('baColorDeepBlue'),
		}
	}
	get SearchLabel(): string{
		return `Search ${this.team.players.length} players`;
	}


}
