import { CrudApi } from './CrudApi';
import { DepthChartModel } from '../models/depthChart/DepthChartModel';

class DepthChartApi extends CrudApi<DepthChartModel>{
	constructor(){
		super('depthChart', (obj) => new DepthChartModel().load(obj));
	}
	async loadGlobalTemplates() : Promise<DepthChartModel[]>{
		const templates = await CrudApi.Api(c => c.get('/depthChart/global-templates'));
		return templates.map((obj: any) => this.create(obj));
	}
}
export const depthChartApi = new DepthChartApi();