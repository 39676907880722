import { AthleteMetric } from '../../types/enums';
export function getMetricUnitLabel(value: AthleteMetric, {selfAssessment = false}: { selfAssessment?: boolean } = {}): string {
	switch (value) {
	case AthleteMetric.Acceleration:
		return '10M Sprint Time';
	case AthleteMetric.Speed:
		return '20-35M Split Time';
	case AthleteMetric.Power:
		return 'Vertical Jump Height';
	case AthleteMetric.Agility:
		return selfAssessment ? 'Time' : 'Reactive Strength Index';
	case AthleteMetric.Recovery:
		return 'YoYo Test';
	default:
		return '';
	}
}
