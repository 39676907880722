import { AgeGroup, Under13AgeGroup } from '@best-athletes/ba-types';

/**
 * Returns AgeGroup from an age
 */
export function ageToAgeGroup(ageYears: number, {allowUnder13 = false}: { allowUnder13?: boolean } = {}): AgeGroup | Under13AgeGroup | null {
	/** Playing Level, U14, U16, etc. */
	if (ageYears === null) return null;
	if (allowUnder13 && ageYears < 13){
		if(ageYears <= 6) return Under13AgeGroup.U6;
		if(ageYears <= 7) return Under13AgeGroup.U7;
		if(ageYears <= 8) return Under13AgeGroup.U8;
		if(ageYears <= 9) return Under13AgeGroup.U9;
		if(ageYears <= 10) return Under13AgeGroup.U10;
		if(ageYears <= 11) return Under13AgeGroup.U11;
		if(ageYears <= 12) return Under13AgeGroup.U12;
	}
	if (ageYears <= 13) return AgeGroup.U13;
	if (ageYears <= 14) return AgeGroup.U14;
	if (ageYears <= 15) return AgeGroup.U15;
	if (ageYears <= 16) return AgeGroup.U16;
	if (ageYears <= 17) return AgeGroup.U17;
	if (ageYears <= 18) return AgeGroup.U18;
	if (ageYears <= 19) return AgeGroup.U19;
	if (ageYears <= 20) return AgeGroup.U20;
	if (ageYears > 20) return AgeGroup.O20;
	return null;
}
