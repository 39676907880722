import { FrontEndModel } from '../FrontEndModel';

export class CommentModel extends FrontEndModel {
	public lastModified: Date = new Date();
	public createdAt: Date = new Date();
	public body: string = '';
	public userId: string = '';
	public profileId: string = '';

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		this.lastModified = new Date(obj['lastModified']);
		this.createdAt = new Date(obj['createdAt']);
		return this;
	}
}