import { getEnumValues } from './get-enum-values';
export enum Gender{
	Male="male",
	Female="female",
	Coed="coed",
}
export enum SportGender {
	MEN = "men",
	WOMEN = "women",
	COED = "coed",
}
export const TeamGenderValues = getEnumValues<Gender>(Gender)