// https://en.wikipedia.org/wiki/ISO_3166-2:CA
export const CA_PROVINCES = [
	{ value: 'AB', text:"Alberta" },
	{ value: 'BC', text:"British Columbia" },
	{ value: 'MB', text:"Manitoba" },
	{ value: 'NB', text:"New Brunswick" },
	{ value: 'NL', text:"Newfoundland and Labrador" },
	{ value: 'NS', text:"Nova Scotia" },
	{ value: 'ON', text:"Ontario" },
	{ value: 'PE', text:"Prince Edward Island" },
	{ value: 'QC', text:"Quebec" },
	{ value: 'SK', text:"Saskatchewan" },
	{ value: 'NT', text:"Northwest Territories" },
	{ value: 'NU', text:"Nunavut" },
	{ value: 'YT', text:"Yukon" },
];