
import { Mixins, Prop, Component } from 'vue-property-decorator';
import { VuetifyMixin, CurrentTeamMixin } from '@/mixins';
import { mdiCheckCircleOutline, mdiCheck, mdiChevronDown } from '@mdi/js';
import { PlayerOnTeam } from '@/models/team';

@Component({
	
})
export default class SoccerDepthChart extends Mixins(VuetifyMixin,CurrentTeamMixin){
	@Prop({ default: '' }) positionName: string;
	@Prop({ default: '' }) currentSelectedPlayer: string;
	mdiCheck = mdiCheck;
	mdiCheckCircleOutline = mdiCheckCircleOutline
	mdiChevronDown = mdiChevronDown
	menuOpen = false;
	active = true;

	get Players(): PlayerOnTeam[]{
		return this.CurrentTeamPlayers
	}
	get PositionSpecificPlayers(): PlayerOnTeam[] {
		return this.CurrentTeamPlayers.filter(player => player.position === this.positionName)
	}
	get OtherPlayers(): PlayerOnTeam[] {
		return this.CurrentTeamPlayers.filter(player => player.position !== this.positionName)
	}

	async clickedPlayer(player: PlayerOnTeam) {
		this.$emit('update:player', player);
	}
}
