/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfig } from 'vue-router';
import * as AdminRoutes from '@/../types/constants/admin.routes';
import { eventsAdminStore } from '@/store';

const EventAdminPage = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/views/EventAdminPage.vue');
const BestAthletesEventDashboard = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/components/events/BestAthletesEventDashboard.vue');
const BestAthletesEventAttendeeList = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/components/events/BestAthletesEventAttendeeList.vue');
const BestAthletesEventOrderAdmin = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/components/events/BestAthletesEventOrderAdmin.vue');

export const EventAdminRouteConfigs: RouteConfig[] = [
	{
		name: AdminRoutes.EventAdmin,
		path: '/event-admin',
		meta:{
			pageTitle: 'All Events',
		},
		component: EventAdminPage
	},
	{
		path: '/event-admin/:eventId',
		props: true,
		async beforeEnter(to, _, next){
			await eventsAdminStore.loadCurrentEventById(to.params.eventId);
			return next();
		},
		component: BestAthletesEventDashboard,
		children:[
			{
				path: '',
				name: AdminRoutes.EventDashboard,
				props: true,
				component: BestAthletesEventAttendeeList,
				meta: {
					pageTitle: 'Back to events',
					useBackButton: true,
				},
			},
			{
				path: 'order/:eventbriteOrderId',
				name: AdminRoutes.EventOrderAdmin,
				props: true,
				component: BestAthletesEventOrderAdmin,
				meta:{
					pageTitle: 'Back to events',
					useBackButton: true,
				}
			}
		]
	}
];