import { Component, Vue } from 'vue-property-decorator';

@Component
export class VideoOverlayLayoutMixin extends Vue {
	windowSize: { x: number, y: number } = null;
	onResize(): void {
		this.windowSize = { x: window.innerWidth, y: window.innerHeight }
	}
	get HeaderHeight(): number {
		return 66;
	}
	get ContentHeight(): number {
		if (this.windowSize === null) return 0;
		return this.windowSize.y - this.HeaderHeight;
	}
	get HeaderHeightStyle(): Record<string, any> {
		return {
			'height': `${this.HeaderHeight}px`,
		};
	}
	get ContentHeightStyle(): Record<string, any> {
		return {
			'height': `${this.ContentHeight}px`,
		};
	}
	get ContentHeightScrollableStyle(): Record<string, any> {
		return {
			'height': `${this.ContentHeight}px`,
			'overflow-y': 'auto',
		};
	}
}
