import store from '../store';
import { Module, VuexModule, Mutation, Action, } from 'vuex-module-decorators';
import { bestAthletesEventApi } from '../../api/BestAthletesEventApi';
import { BestAthletesEventModel } from '../../models/bestAthletesEvent/BestAthletesEventModel';
import { QueryOptions, PaginatedResponse, RepositoryQuery } from '../../../types/interfaces';
import { SWTemplate } from '../../../types/interfaces/SmartwaiverTypes';
import { BAEventOrderModel } from '@/models/bestAthletesEvent/BAEventOrderModel';

const Mutations = {
	GET_EVENTS: 'GET_EVENTS',
	GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
	GET_EVENTS_FAILURE: 'GET_EVENTS_FAILURE',

	GET_FULL_EVENT: 'GET_FULL_EVENT',
	GET_FULL_EVENT_SUCCESS: 'GET_FULL_EVENT_SUCCESS',
	GET_FULL_EVENT_FAILURE: 'GET_FULL_EVENT_FAILURE',

	LOAD_SMARTWAIVER_TEMPLATES: 'LOAD_SMARTWAIVER_TEMPLATES',
	LOAD_SMARTWAIVER_TEMPLATES_SUCCESS: 'LOAD_SMARTWAIVER_TEMPLATES_SUCCESS',
	LOAD_SMARTWAIVER_TEMPLATES_FAILURE: 'LOAD_SMARTWAIVER_TEMPLATES_FAILURE',

	SET_SMARTWAIVER_TEMPLATES: 'SET_SMARTWAIVER_TEMPLATES',
	SET_SMARTWAIVER_TEMPLATES_SUCCESS: 'SET_SMARTWAIVER_TEMPLATES_SUCCESS',
	SET_SMARTWAIVER_TEMPLATES_FAILURE: 'SET_SMARTWAIVER_TEMPLATES_FAILURE',
}

const name = 'EventsAdminStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class EventsAdminModule extends VuexModule {

	loading: boolean = false;
	initialized: boolean = true;

	get EventListReady(): boolean {
		return this.initialized && !this.loading;
	}

	totalEvents: number = 0;
	eventList: BestAthletesEventModel[] = [];

	@Action({
		rawError: true,
	}) async loadEventList({ query, options } : {
		query?: RepositoryQuery<BestAthletesEventModel>, options?: QueryOptions
	} = {}): Promise<void> {
		this.context.commit(Mutations.GET_EVENTS);
		try {
			const response = await bestAthletesEventApi.queryAll(
				{
					fields: ['name'],
					...query
				},
				{
					populate: ['ticketClasses', 'orders'],
					...options,
				}
			);
			this.context.commit(Mutations.GET_EVENTS_SUCCESS, response);
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.GET_EVENTS_FAILURE, e);
		}
	}

	@Mutation [Mutations.GET_EVENTS](): void {
		this.loading = true;
	}
	@Mutation [Mutations.GET_EVENTS_SUCCESS](events: PaginatedResponse<BestAthletesEventModel>): void {
		this.loading = false;
		this.initialized = true;
		this.totalEvents = events.total;
		this.eventList = events.docs;
	}
	@Mutation [Mutations.GET_EVENTS_FAILURE](error: any): void {
		this.loading = false;
	}


	currentEventLoading: boolean = false;
	currentEvent: BestAthletesEventModel | null = null;

	get CurrentEventOrders(): BAEventOrderModel[]{
		if(this.currentEvent === null) return [];
		return this.currentEvent.PopulatedOrders;
	}

	@Action({
		rawError: true,
	}) async loadCurrentEventById(eventId: string): Promise<void> {
		this.context.commit(Mutations.GET_FULL_EVENT);
		try {
			const event = await bestAthletesEventApi.findById(eventId, {
				populate: BestAthletesEventModel.AllRefs
			});
			this.context.commit(Mutations.GET_FULL_EVENT_SUCCESS, event);
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.GET_FULL_EVENT_FAILURE, e);
		}
	}

	@Mutation [Mutations.GET_FULL_EVENT](): void {
		this.currentEventLoading = true;
	}
	@Mutation [Mutations.GET_FULL_EVENT_SUCCESS](event: BestAthletesEventModel): void {
		this.currentEventLoading = false;
		this.currentEvent = event;
	}
	@Mutation [Mutations.GET_FULL_EVENT_FAILURE](error: any): void {
		this.currentEventLoading = false;
	}

	smartWaiverTemplates: SWTemplate[] = [];
	smartWaiverTemplatesLoading: boolean = false;
	@Action({
		rawError: true,
	}) async loadSmartWaiverTemplates(): Promise<void> {
		this.context.commit(Mutations.LOAD_SMARTWAIVER_TEMPLATES);
		try {
			const templates = await bestAthletesEventApi.getSmartwaiverTemplates();
			this.context.commit(Mutations.LOAD_SMARTWAIVER_TEMPLATES_SUCCESS, templates);
		} catch (e) {
			this.context.commit(Mutations.LOAD_SMARTWAIVER_TEMPLATES_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_SMARTWAIVER_TEMPLATES](): void {
		this.smartWaiverTemplatesLoading = true;
	}
	@Mutation [Mutations.LOAD_SMARTWAIVER_TEMPLATES_SUCCESS](templates: SWTemplate[]): void {
		this.smartWaiverTemplatesLoading = false;
		this.smartWaiverTemplates = templates;
	}
	@Mutation [Mutations.LOAD_SMARTWAIVER_TEMPLATES_FAILURE](error: any): void {
		this.smartWaiverTemplatesLoading = false;
	}

	setWaiverTemplatesLoading: boolean = false;
	@Action({
		rawError: true,
	}) async setWaiverTemplates({ waiverTemplates, noWaivers }:{ waiverTemplates: SWTemplate[], noWaivers: boolean }): Promise<void> {
		this.context.commit(Mutations.SET_SMARTWAIVER_TEMPLATES);
		try {
			const eventPatch = this.currentEvent.copy<BestAthletesEventModel>();
			eventPatch.waivers.waiverTemplates = waiverTemplates;
			eventPatch.waivers.noWaivers = noWaivers;
			const event = await bestAthletesEventApi.patch(eventPatch);
			this.context.commit(Mutations.SET_SMARTWAIVER_TEMPLATES_SUCCESS, event);
		} catch (e) {
			this.context.commit(Mutations.SET_SMARTWAIVER_TEMPLATES_FAILURE, e);
		}
	}

	@Mutation [Mutations.SET_SMARTWAIVER_TEMPLATES](): void {
		this.setWaiverTemplatesLoading = true;
	}
	@Mutation [Mutations.SET_SMARTWAIVER_TEMPLATES_SUCCESS](event: BestAthletesEventModel): void {
		this.setWaiverTemplatesLoading = false;
		this.currentEvent = event;
	}
	@Mutation [Mutations.SET_SMARTWAIVER_TEMPLATES_FAILURE](error: any): void {
		this.setWaiverTemplatesLoading = false;
	}
}
