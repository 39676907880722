/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { notificationApi } from '@/api/NotificationApi';
import { NotificationModel } from '@/models/notification/NotificationModel';
import { NotificationStatus, NotificationType, NotificationUIType, NotificationErrorStatus } from '@/../types/enums';
import Vue from 'vue';

const Mutations = {
	SET_INVITATIONS: 'SET_INVITATIONS',
	SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
	PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
	PUSH_NOTIFICATION_TOP: 'PUSH_NOTIFICATION_TOP',
	REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',

	PUSH_SNACKBAR: 'PUSH_SNACKBAR',
	SET_SHOW_SNACKBAR: 'SET_SHOW_SNACKBAR',

	PUSH_ERROR_NOTIFICATION: 'PUSH_ERROR_NOTIFICATION',
	REMOVE_ERROR_NOTIFICATION: 'REMOVE_ERROR_NOTIFICATION',
	CLEAR_ERROR_NOTIFICATIONS: 'CLEAR_ERROR_NOTIFICATIONS',
};

type SnackbarParams = { message: string };

const name = 'NotificationStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name: name,
	store: store
})
export default class NotificationModule extends VuexModule {

	notificationsLoading: boolean = false;
	notifications: NotificationModel[] = [];
	localNotifications: NotificationModel[] = [];

	get OrderedNotifications(): NotificationModel[]{
		return this.notifications.sort((a,b) => (+b.createdAt) - (+a.createdAt));
	}

	@Action({rawError: true})
	async fetchNotifications(recipientId: string) {
		const localNotifications = this.notifications.filter(notification => notification.IsLocal);
		const serverNotifications = await notificationApi.findByRecipientId(recipientId);
		const notifications = [...localNotifications, ...serverNotifications];
		this.context.commit(Mutations.SET_NOTIFICATIONS, notifications);
	}
	
	
	@Action({rawError: true})
	async markNotificationUnread(notificationId: string) {
		const notification = this.notifications.find(n => n.id === notificationId);
		if(notification === undefined) return;
		notification.status = NotificationStatus.UNREAD;
		if(!notification.IsLocal){
			await notificationApi.setNotificationStatus(notificationId, NotificationStatus.UNREAD);
		}
	}
	
	@Action({rawError: true})
	async markNotificationRead(notificationId: string) {
		const notification = this.notifications.find(n => n.id === notificationId);
		if(notification === undefined) return;
		notification.status = NotificationStatus.READ;
		if(!notification.IsLocal){
			await notificationApi.setNotificationStatus(notificationId, NotificationStatus.READ);
		}
	}
	
	
	@Action({rawError: true})
	async removeNotification(notificationId: string): Promise<void> {
		const notification = this.notifications.find(n => n.id === notificationId);
		if(!notification || notification.type === NotificationType.Server){
			await notificationApi.deleteNotification(notificationId);
		}
		this.context.commit('REMOVE_NOTIFICATION', notificationId);
	}
	
	@Mutation [Mutations.SET_NOTIFICATIONS](notifications: NotificationModel[]): void {
		this.notifications = notifications.slice();
	}
	@Mutation[Mutations.PUSH_NOTIFICATION](notification: NotificationModel): void {
		const index = this.notifications.findIndex(n => n.id === notification.id);
		if(index > -1) return;
		this.notifications.push(notification);
	}
	@Mutation[Mutations.PUSH_NOTIFICATION_TOP](notification: NotificationModel): void {
		const index = this.notifications.findIndex(n => n.id === notification.id);
		if(index > -1) return;
		this.notifications.unshift(notification);
	}
	@Mutation[Mutations.REMOVE_NOTIFICATION](notificationId: string): void {
		const index = this.notifications.findIndex(n => n.id === notificationId);
		this.notifications.splice(index, 1);
	}

	@Action({ rawError: true })
	async pushLocalNotification(notification: NotificationModel): Promise<void> {
		notification.id = `local_notification_${new Date().getUTCMilliseconds()}`;
		notification.type = NotificationType.Local;
		this.context.commit(Mutations.PUSH_NOTIFICATION, notification);
	}
	@Action({ rawError: true })
	async pushLocalNotificationMessage(message: string): Promise<void> {
		this.context.commit(Mutations.PUSH_NOTIFICATION, new NotificationModel(message));
	}
	
	errorNotifications: NotificationModel[] = [];
	@Action({ rawError: true })
	async pushErrorNotificationMessage(errorMessage: string): Promise<void> {
		this.context.commit(Mutations.PUSH_ERROR_NOTIFICATION, new NotificationModel(errorMessage));
	}
	@Action({ rawError: true })
	async pushErrorNotification(notification: NotificationModel): Promise<void> {
		this.context.commit(Mutations.PUSH_ERROR_NOTIFICATION, notification);
	}
	@Mutation[Mutations.PUSH_ERROR_NOTIFICATION](notification: NotificationModel): void {
		const index = this.errorNotifications.findIndex(n => n.id === notification.id);
		if(index > -1) return;
		this.errorNotifications.push(notification);
	}
	@Action({ rawError: true })
	async removeErrorNotification(): Promise<void> {
		const [notification] = this.errorNotifications;
		if (notification !== undefined) {
			this.context.commit(Mutations.REMOVE_ERROR_NOTIFICATION, notification.id);
		}
	}
	@Mutation[Mutations.REMOVE_ERROR_NOTIFICATION](notificationId: string): void {
		const index = this.errorNotifications.findIndex(n => n.id === notificationId);
		this.errorNotifications.splice(index, 1);
	}
	@Action({ rawError: true })
	async clearErrorNotifications(): Promise<void> {
		this.context.commit(Mutations.CLEAR_ERROR_NOTIFICATIONS);
	}
	@Mutation[Mutations.CLEAR_ERROR_NOTIFICATIONS](): void {
		this.errorNotifications = [];
	}

	showSnackbar: boolean = false;
	snackbarSuccess: NotificationModel | null = null;
	/**
	 * Snackbars are a temporary, clearable notification
	 */
	@Action({ rawError: true })
	async pushSnackbarSuccess({ message }: SnackbarParams): Promise<void> {
		const notification = new NotificationModel(message);
		notification.id = `local_notification_${new Date().getUTCMilliseconds()}`;
		notification.type = NotificationType.Local;
		notification.uiType = NotificationUIType.Snackbar;
		notification.errorStatus = NotificationErrorStatus.Success;
		this.context.commit(Mutations.PUSH_SNACKBAR, notification);
	}
	/**
	 * Snackbars are a temporary, clearable notification
	 */
	@Action({ rawError: true })
	async pushSnackbarWarning({ message }: SnackbarParams): Promise<void> {
		const notification = new NotificationModel(message);
		notification.id = `local_notification_${new Date().getUTCMilliseconds()}`;
		notification.type = NotificationType.Local;
		notification.uiType = NotificationUIType.Snackbar;
		notification.errorStatus = NotificationErrorStatus.Warning;
		this.context.commit(Mutations.PUSH_SNACKBAR, notification);
	}
	/**
	 * Snackbars are a temporary, clearable notification
	 */
	@Action({ rawError: true })
	async pushSnackbarError({ message }: SnackbarParams): Promise<void> {
		const notification = new NotificationModel(message);
		notification.id = `local_notification_${new Date().getUTCMilliseconds()}`;
		notification.type = NotificationType.Local;
		notification.uiType = NotificationUIType.Snackbar;
		notification.errorStatus = NotificationErrorStatus.Error;
		this.context.commit(Mutations.PUSH_SNACKBAR, notification);
	}
	@Mutation [Mutations.PUSH_SNACKBAR](notification: NotificationModel): void {
		this.snackbarSuccess = notification;
		this.showSnackbar = true;
	}
	@Action({ rawError: true })
	async setSnackbarSuccess(val: boolean): Promise<void> {
		this.context.commit(Mutations.SET_SHOW_SNACKBAR, val);
	}
	@Mutation [Mutations.SET_SHOW_SNACKBAR](val: boolean): void {
		this.showSnackbar = val;
		if(val === false){
			this.snackbarSuccess = null;
		}
	}

	/**
	 * Pushes success snackbar with "Your changes have been saved."
	 */
	@Action({ rawError: false })
	async snackbarChangesSavedSuccess(): Promise<void> {
		const args: SnackbarParams = { message: "Your changes have been saved." };
		this.context.dispatch('pushSnackbarSuccess', args);
	}

	@Action({ rawError: true })
	async snackbarShowError(msg: string): Promise<void> {
		const args: SnackbarParams = {message: msg};
		this.context.dispatch('pushSnackbarError', args);
	}
}
