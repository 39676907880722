import { render, staticRenderFns } from "./ButtonToggle.vue?vue&type=template&id=36af7afa&"
import script from "./ButtonToggle.vue?vue&type=script&lang=ts&"
export * from "./ButtonToggle.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports