import { getInstance } from '@/auth/auth0Plug';
import { Auth0Plugin } from '@/auth/Auth0';

interface ParamResolverConstructorParams{
	pattern?: ParamResolver['pattern'],
}
interface ParamResolverMap{
	[param: string]: () => string;
}

export class ParamResolver{
	private pattern: { start: string, end: string };
	constructor({
		pattern = {
			start: '{{',
			end: '}}',
		}
	}: ParamResolverConstructorParams){
		this.pattern = pattern;
	}

	private getParamRegExp(param: string){
		if(this.regExpMap[param]) return this.regExpMap[param];
		return new RegExp(`${this.pattern.start}\\ *${param}*\\ *${this.pattern.end}`, 'g');
	}
	private regExpMap: {[key: string]: RegExp} = {};

	resolverMap: ParamResolverMap = {
		'email': (): string | null => {
			const auth: Auth0Plugin = getInstance();
			if (auth.user && auth.user.email) return auth.user.email;
			return null;
		},
		'userId': (): string | null => {
			const auth: Auth0Plugin = getInstance();
			if (auth.user && auth.user.id) return auth.user.id;
			return null;
		},
		'athleteId': (): string | null => {
			const auth: Auth0Plugin = getInstance();
			if (auth.athleteId) return auth.athleteId;
			return null;
		},
		'coachId': (): string | null => {
			const auth: Auth0Plugin = getInstance();
			if (auth.coachId) return auth.coachId;
			return null;
		}
	};

	/**
	 * Search and replace instances of {{ param }} in a given string
	 */
	replaceAllInString(templateString: string, customResolvers: ParamResolverMap = {}): string{
		let newString: string = templateString;
		const resolverMap = { ...this.resolverMap, ...customResolvers };
		for(const param of Object.keys(resolverMap)){
			const resolvedValue = resolverMap[param]();
			// console.log(`[ParamResolver replaceAllInString] ${param} => ${resolvedValue}`);
			if (resolvedValue === null) continue;
			newString = newString.replace(this.getParamRegExp(param), resolvedValue);
		}
		return newString;
	}

	replaceAllInObject<T = Record<string, any>>(obj: T, customResolvers: ParamResolverMap = {}): T{
		const newObj = {...obj};
		for (const key of Object.keys(newObj)){
			if (typeof newObj[key] === 'string'){
				newObj[key] = this.replaceAllInString(newObj[key], customResolvers);
			}
		}
		return newObj;
	}
}

export default new ParamResolver({});