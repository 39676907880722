
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class CircledAvatar extends Mixins(VuetifyMixin){

	@Prop() src?: string;
	@Prop() private placeholder: string;
	@Prop({ default: "mdi-account" }) icon: string;
	@Prop() alt: string;
	@Prop({ default: false }) loading: boolean;
	@Prop({ default: false }) private useInitials: boolean;
	@Prop({ default: 50 }) size: number;
	@Prop({ default: 2 }) private borderSize: number;
	@Prop({ default: "baColorOffWhite" }) backgroundColor: string;
	@Prop({ default: "baColorGray1" }) private color: string;
	@Prop({ default: "baColorGray7" }) iconColor: string;
	@Prop({ default: false }) private dark: boolean;
	@Prop({ default: false }) contain: boolean;

	get Dark(): boolean{
		return this.dark;
	}

	get ShowInitials(): boolean{
		return this.useInitials && this.Placeholder !== undefined;
	}

	get FontSize(): number{
		return this.size / 3;
	}

	get Placeholder(): string{
		if(this.useInitials && this.placeholder){
			return this.placeholder.split(' ').map(c => c.charAt(0).toLocaleUpperCase()).join('');
		}
		return this.placeholder;
	}

	get IconSize(): number{
		return this.size * 0.8;
	}

}
