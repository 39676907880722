
import { Component, Mixins } from "vue-property-decorator";
import { mdiEye, mdiEyeOff, mdiLockOutline } from "@mdi/js";
import { userApi } from "@/api/UserApi";
import LinkText from "@/components/ui/LinkText.vue";
import Dialog from "@/components/ui/Dialog.vue";
import { FeatureFlagMixin, AuthMixin } from '@/mixins';

@Component({
	components: {
		LinkText,
		Dialog
	}
})
export default class AccountSettingsChangePassword extends Mixins(FeatureFlagMixin, AuthMixin, FeatureFlagMixin) {
	mdiEye = mdiEye;
	mdiEyeOff = mdiEyeOff;
	mdiLockOutline = mdiLockOutline;

	currentPasswordShow: boolean = false;
	newPasswordShow: boolean = false;

	loading = true;

	async created(): Promise<void> {
		this.loading = false;
	}

	currentPassword: "";
	newPassword: "";
	newPasswordConfirm: "";

	saveLoading: boolean = false;
	submitValid: boolean | null = null;

	twoFactorHelpDialog: boolean = false;

	passwordRequestEmailLoading: boolean = false;

	async onSave(validate: () => Promise<boolean>, reset: () => Promise<void>): Promise<void> {
		const isValid = await validate();

		if (isValid) {
			this.saveLoading = true;
			const valid = await userApi.changePassword(
				this.currentPassword,
				this.newPassword
			);
			this.submitValid = valid;
			this.saveLoading = false;

			if (valid) {
				this.onCancel();
				reset();
				this.submitValid = true
				setTimeout(() => {
					this.submitValid = null
				}, 7000);
			}
		}
	}
	async requestPasswordEmail(): Promise<void> {
		this.passwordRequestEmailLoading = true;
		await userApi.requestPasswordEmail();

		setTimeout(() => this.passwordRequestEmailLoading = false, 3000)
	}

	async onCancel(): Promise<void> {
		this.currentPassword = "";
		this.newPassword = "";
		this.newPasswordConfirm = "";
		this.$emit("cancel");
	}

	async currentPasswordChanged(): Promise<void> {
		this.submitValid = null;
	}

	get passwordChangeFlag(): "email" | "setting" | "disabled" {
		return this.feature<"email" | "setting" | "disabled">('HIN-1584-password-change')
	}
}
