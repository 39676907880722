import { NotificationStatus, NotificationUIType, NotificationType, NotificationErrorStatus } from "../../../types/enums";
import { AccessControlledModel } from '../AccessControlledModel';
import { ActionableModel } from "../actions/ActionableModel";

export interface NotificationAction{
	buttonText: string,
	buttonColor: string
	action: Partial<ActionableModel>
}


export class NotificationModel extends AccessControlledModel {
	constructor(message?: string, actions?: NotificationAction[]){
		super();
		if(message !== undefined) this.message = message
		if (actions !== undefined) this.actions = NotificationModel.InstantiateNotifications(actions);
	}
	public uiType: NotificationUIType = NotificationUIType.Banner;
	public actions: NotificationAction[] = [];
	public senderId: string = "";
	public recipientId: string | null = null;
	/** mdi icon string to be placed inside `v-icon`. null to have no icon */
	public icon: string | null = null;
	public message: string = "";
	/** Timeout before message auto clears */
	public timeoutMs: number | null = null;
	public status: NotificationStatus = NotificationStatus.UNREAD;
	public type: NotificationType = NotificationType.Local;
	public errorStatus: NotificationErrorStatus | null = null;
	public createdAt: Date = new Date();

	load(obj: Partial<NotificationModel>): this{
		Object.assign(this, obj);
		this.createdAt = obj['createdAt'] ? new Date(obj['createdAt']) : new Date();
		if(Array.isArray(obj['actions'])){
			this.actions = NotificationModel.InstantiateNotifications(obj['actions']);
		}
		return this;
	}

	setIcon(icon: string): NotificationModel{
		this.icon = icon;
		return this;
	}

	/**
	 * This notification is local only and shouldn't be persisted to the backend.
	 */
	get IsLocal(): boolean{
		return this.type === NotificationType.Local;
	}

	get IsRead(): boolean{
		return this.status === NotificationStatus.READ;
	}

	static InstantiateNotifications(actions: NotificationAction[]): NotificationAction[]{
		return actions.map(notificationAction => ({
			...notificationAction,
			action: new ActionableModel().load({action: notificationAction.action}),
		}));
	}
}