
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { Tag } from '@/models/tag/Tag';
import { TeamModel } from '@/models/team';
import ProfilePictureProvider from '@/components/hoc/ProfilePictureProvider.vue';

@Component({
	components:{
		ProfilePictureProvider,
	}
})
export default class PlayerTagger extends Mixins(VuetifyMixin){
	@Prop({ default: false, type: Boolean }) dark: boolean;
	@Prop({ required: true }) team: TeamModel;
	get PlayerTags(): Tag<{athleteId: string}>[]{
		return this.team.players.map(player => {
			return new Tag<{athleteId: string}>(`${player.firstName} ${player.lastName}`, player.id).setMetadata({
				athleteId: player.athleteId,
			});
		});
	}

	@Prop() value: Tag[] = [];
	input(selection: Tag[]): void{
		this.$emit('input', selection);
	}

	remove(item: Tag<{athlete: string}>): void{
		const index = this.value.findIndex(t => t.subjectId === item.subjectId);
		if(index > -1){
			this.value.splice(index, 1);
		}
		this.input(this.value);
	}

	search: string = ""
	onChange(): void{
		this.search = "";
	}
}
