
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PaymentHistoryModel } from '@/models/stripe/PaymentHistoryModel';
import { CustomerApi } from '@/api/CustomerApi';
import { notificationStore } from '@/store';

/**
 * Provides a list of saved payment methods for a given parentId.
 * 
 * @parentId: The ID of the parent domain object you're querying payment info for.
 */
@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class PaymentHistoryInfoProvider extends Vue{
	@Prop({ default: 'div' }) as: string;
	@Prop({ required: true }) resource: string;
	@Prop({ required: true }) parentId: string;

	loading: boolean = false;
	initialized: boolean = false;
	paymentHistory: PaymentHistoryModel[] = [];

	async loadPaymentHistory(): Promise<void>{
		this.loading = true;
		try{
			const paymentHistory = await CustomerApi.getPaymentHistory(this.resource, this.parentId);
			this.paymentHistory = paymentHistory;
			this.initialized = true;
		}catch(e){
			console.error(e);
			notificationStore.pushErrorNotificationMessage("Something went wrong, please try again later");
		}finally{
			this.loading = false;
		}
	}

	created(): void{
		this.loadPaymentHistory();
	}


	get SlotProps(): Record<string, any>{
		return {
			loading: this.loading,
			initialized: this.initialized,
			paymentHistory: this.paymentHistory,
		};
	}
}
