
import { Component, Vue, Prop } from 'vue-property-decorator';
import NotFoundSvg from '@/components/svg/NotFoundSvg.vue';
import ForbiddenSvg from '@/components/svg/ForbiddenSvg.vue';
import ServerErrorSvg from '@/components/svg/ServerErrorSvg.vue';
import { PageState } from '@/models/PageState';
@Component({
	components:{
		NotFoundSvg,
		ForbiddenSvg,
		ServerErrorSvg,
	}
})
export default class FailureStateCard extends Vue{
	@Prop({ required: true }) state: PageState;
	@Prop({ default: false, type: Boolean }) hideActions: boolean;
	@Prop({ default: false, type: Boolean }) useRequestAccess: boolean;
	/** Component is being used inside a page so we don't want to add extra margin and padding */
	@Prop({ default: false, type: Boolean }) inline: boolean;
	/**
	 * Size of the error icon, text will be scaled proportionally if `fontSize`  is not provided.
	 * 
	 * Default: 178px but `inline` prop  will automatically set the default to 75px
	 */
	@Prop({ default: null, type: Number }) size: number |  null;
	/**
	 * Font size will be computed proportional to icon size with a lower limit. This prop will set font size directly on the message, and the title will be proportionally larger
	 */
	@Prop({ default: null, type: Number }) fontSize: number | null;

	get Size(): number{
		if(this.size === null){
			if(this.inline) return 75;
			else return 178;
		}
		return this.size;
	}

	get TitleFontSize(): number{
		if(this.fontSize !== null){
			return this.fontSize * (32/18);
		}
		return Math.max(this.Size / (178/32),  18);
	}
	get TitleLineHeight(): number{
		return this.fontSize + 2;
	}
	get MessageFontSize(): number{
		if(this.fontSize !== null){
			return this.fontSize;
		}
		return Math.max(this.Size / (178/18), 12);
	}
	get MessageLineHeight(): number{
		return this.fontSize - 2;
	}

	refresh(): void{
		window.location.reload();
	}

	goHome(): void{
		window.location.replace('/');
	}
	reloadPage(): void{
		window.location.reload();
	}
	get IconComponent(): Vue.Component{
		if(this.state.IsForbidden){
			return ForbiddenSvg;
		}
		if(this.state.IsNotFound){
			return NotFoundSvg;
		}
		return ServerErrorSvg;
	}

	get TitleStyle(): Record<string, any>{
		return {
			'font-size': `${this.TitleFontSize}px`,
			'line-height': `${this.TitleLineHeight}px`,
		}
	}
	get MessageStyle(): Record<string, any>{
		return {
				'font-size': `${this.MessageFontSize}px`,
				'line-height': `${this.MessageLineHeight}px`,
		}
	}
}
