
import { VuetifyMixin } from "@/mixins";
import { Mixins, Component, Prop } from "vue-property-decorator";
import Dialog from "@/components/ui/Dialog.vue";
import ExcelDataTable from "@/components/ui/ExcelDataTable.vue";
import RoundLoader from '@/components/ui/RoundLoader.vue';
import RoundedButton from '@/components/forms/RoundedButton.vue';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { mdiUpload } from "@mdi/js";
import { CSVUploadMixin } from "@/mixins/CSVUploadMixin";
import { CSVPlayerRoster } from "@/../types/CSVRosterFile";
import { BaseSoccerPosition, BaseSoccerPositionValues, SoccerPositionToBasePositionMapping } from '@/../types/enums/sports';
import { stringToAlphaLower } from '@/pipes/stringToAlpha.pipe';

@Component({
	components: { ExcelDataTable, Dialog, FileUploadArea, RoundLoader, RoundedButton }
})
export default class CSVUploadDialog extends Mixins(VuetifyMixin, CSVUploadMixin) {
	@Prop({ default: false }) visible: boolean;
	@Prop({ default: false, type: Boolean }) hideSampleDownload: boolean;

	mdiUpload = mdiUpload;

	@Prop({ default: () => ([
		{
			row: 1,
			number: 52,
			firstName: "Abigail",
			lastName: "Armstrong",
			email: "abby101@yten.nl",
			position: BaseSoccerPosition.Forward,
			gradYear: "2019"
		},
	]) }) sampleCSVData: Array<Record<string, any>>;

	get dialogVisbility() {
		return this.visible
	}
	set dialogVisbility(value: boolean) {
		this.$emit('update:visible', value)
	}

	uploadCSVLoading = false;
	unconfirmedPlayers:CSVPlayerRoster[] = [];

	@Prop({ default: () => (csvString: string): CSVPlayerRoster[] => {
		const csvRoster = csvString.split('\n').map(line => line.split(',') );
		/** Tries to match user uploaded position name to one of our base positions.  */
		const findBasePosition = (val: string): BaseSoccerPosition | undefined => {
			const pos = BaseSoccerPositionValues.find(position => stringToAlphaLower(val).indexOf(stringToAlphaLower(position)) > -1);
			if(pos === undefined && SoccerPositionToBasePositionMapping[val] !== undefined){
				return SoccerPositionToBasePositionMapping[val];
			}
			return pos;
		}
		return csvRoster.slice(1).map(([number, firstName, lastName, email, position, gradYear], index) => {
			return { row:index + 1, number: parseInt(number), firstName, lastName, email, position: findBasePosition(position), gradYear: parseInt(gradYear), eligibleToPlay: true, injured: false };
		});
	}}) csvParseFn: (csvData: string) => any;

	/**
	 * This uploader will break if you upload a CSV with CRLF line endings. (Looking at you, windows users)
	 */
	uploadCSV(csv: File){
		this.uploadCSVLoading = true;
		const fileReader = new FileReader()
		fileReader.onload = ($event: ProgressEvent<FileReader>) => {
			// Chance of being an invalid file and an arraybuffer instead
			const csvString = $event.target.result as string;
			
			this.unconfirmedPlayers = this.csvParseFn(csvString);

			this.uploadCSVLoading = false;
			this.$emit('input', this.unconfirmedPlayers);
			this.$emit('update:visible', false);
		}
		this.unconfirmedPlayers = [];
		fileReader.readAsText(csv);
	}
}
