import { UserAcl, GroupAcl, UserIdentity } from '@/../types/interfaces';
import { AccessControlledModel } from '@/models/AccessControlledModel';


/**
 * Returns true if the user has the specified permission either directly or through a group.
 */
export function hasAccess<T extends AccessControlledModel, U extends UserIdentity>(
	accessControlled: T,
	permission: string,
	user: U,
): boolean {
	const { userId, groups } = user;
	console.log('hasAccess: Checking for %s', permission);
	const userAcl = accessControlled.users.find(acl => acl.id === userId);
	if (userAcl !== undefined) {
		console.log('hasAccess: %s found on user', permission);
		if (userAcl.permissions.includes(permission)) {
			return true;
		}
	}
	const groupAcls = accessControlled.groups.filter(group => groups.includes(group.name));
	for (const group of groupAcls) {
		if (group.permissions.includes(permission)) {
			console.log('hasAccess: %s found on group %s', permission, group.name);
			return true;
		}
	}
	return false;
}

/**
 * Adds permissions to a UserAcl. Creates UserAcl if it didn't exist.
 */
export function grantUserAcls<T extends AccessControlledModel, U extends UserIdentity>(
	accessControlled: T,
	permissions: string[],
	user: U,
): UserAcl {
	const userAcl = accessControlled.users.find(u => u.id === user.userId);
	if (userAcl === undefined) {
		accessControlled.users.push({
			id: user.userId,
			permissions,
		});
		return accessControlled.users[accessControlled.users.length - 1];
	}
	for (const permission of permissions) {
		if (!userAcl.permissions.includes(permission)) {
			userAcl.permissions.push(permission)
		}
	}
	return userAcl;
}
/**
 * Removes the permissions from a UserAcl. Returns null if the UserAcl doesn't exist.
 */
export function revokeUserAcls<T extends AccessControlledModel, U extends UserIdentity>(
	accessControlled: T,
	permissions: string[],
	user: U,
): UserAcl | null {
	const userAcl = accessControlled.users.find(u => u.id === user.userId);
	if (userAcl === undefined) {
		return null;
	}
	userAcl.permissions = userAcl.permissions.filter(permission => !permissions.includes(permission));
	return userAcl;
}
/**
 * Removes a user from the accessControlled if it exists.
 */
export function removeUserAcl<T extends AccessControlledModel, U extends UserIdentity>(
	accessControlled: T,
	user: U,
): UserAcl | null {
	const index = accessControlled.users.findIndex(acl => acl.id === user.userId);
	if (index === -1) {
		return null;
	}
	const [userAcl] = accessControlled.users.splice(index, 1);
	return userAcl;
}


/**
 * Adds permissions to a GroupAcl. Creates GroupAcl if it didn't exist.
 */
export function grantGroupAcls<T extends AccessControlledModel>(
	accessControlled: T,
	permissions: string[],
	groupName: string,
): GroupAcl {
	const groupAcl = accessControlled.groups.find(acl => acl.name === groupName);
	if (groupAcl === undefined) {
		accessControlled.groups.push({
			name: groupName,
			permissions,
		});
		return accessControlled.groups[accessControlled.groups.length - 1];
	}
	for (const permission of permissions) {
		if (!groupAcl.permissions.includes(permission)) {
			groupAcl.permissions.push(permission);
		}
	}
	return groupAcl;
}
/**
 * Adds permissions to a GroupAcl. Creates GroupAcl if it didn't exist.
 */
export function revokeGroupAcls<T extends AccessControlledModel>(
	accessControlled: T,
	permissions: string[],
	groupName: string,
): GroupAcl | null {
	const groupAcl = accessControlled.groups.find(acl => acl.name === groupName);
	if (groupAcl === undefined) {
		return null;
	}
	groupAcl.permissions = groupAcl.permissions.filter(permission => !permissions.includes(permission));
	return groupAcl;
}
/**
 * Removes a group from the accessControlled if it exists.
 */
export function removeGroupAcl<T extends AccessControlledModel>(
	accessControlled: T,
	groupName: string,
): GroupAcl | null {
	const index = accessControlled.groups.findIndex(acl => acl.name === groupName);
	if (index === -1) {
		return null;
	}
	const [groupAcl] = accessControlled.groups.splice(index, 1);
	return groupAcl;
}