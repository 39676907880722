import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { CourseModel, AssignmentModel } from '../../models/course';
import { courseApi } from '@/api/CourseApi';

const Mutations = {
	LOAD_COURSE: 'LOAD_COURSE',
	LOAD_COURSE_SUCCESS: 'LOAD_COURSE_SUCCESS',
	LOAD_COURSE_FAILURE: 'LOAD_COURSE_FAILURE',
	
	ASSIGN_TO_ALL: 'ASSIGN_TO_ALL',
	ASSIGN_TO_ALL_SUCCESS: 'ASSIGN_TO_ALL_SUCCESS',
	ASSIGN_TO_ALL_FAILURE: 'ASSIGN_TO_ALL_FAILURE',
}

const name = 'CourseDashboardStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class CourseDashboardModule extends VuexModule {

	courseInitialized: boolean = false;
	courseLoading: boolean = false;
	course: CourseModel = new CourseModel();
	studentJoinUrl: string | null = null;
	staffJoinUrl: string | null = null;

	@Action({
		rawError: true
	})
	async loadCourse(courseId: string): Promise<void> {
		this.context.commit(Mutations.LOAD_COURSE);
		try {
			const course = await courseApi.findById(courseId);
			const studentJoinUrl = await courseApi.getStudentJoinUrl({ courseId });
			const staffJoinUrl = await courseApi.getStaffJoinUrl({ courseId });
			this.context.commit(Mutations.LOAD_COURSE_SUCCESS, { course, studentJoinUrl, staffJoinUrl });
		} catch (e) {
			console.error("Failed to Load Course", e);
			this.context.commit(Mutations.LOAD_COURSE_FAILURE, e);
		}
	}
	@Mutation [Mutations.LOAD_COURSE](): void {
		this.courseLoading = true;
	}
	@Mutation [Mutations.LOAD_COURSE_SUCCESS]({ course, studentJoinUrl, staffJoinUrl }: { course: CourseModel, studentJoinUrl?: string, staffJoinUrl?: string }): void {
		this.courseLoading = false;
		this.course = course;
		this.courseInitialized = true;
		if(studentJoinUrl !== undefined){
			this.studentJoinUrl = studentJoinUrl;
		}else{
			this.studentJoinUrl = null;
		}
		if(staffJoinUrl !== undefined){
			this.staffJoinUrl = staffJoinUrl;
		}else{
			this.staffJoinUrl = null;
		}
	}
	@Mutation [Mutations.LOAD_COURSE_FAILURE](error: any): void {
		this.courseLoading = false;
	}
	
	assignToAllLoading: boolean = false;
	assignToAllError: string | null = null;

	@Action({
		rawError: true
	})
	async assignToAll({ assignment }: { assignment: AssignmentModel}){
		this.context.commit(Mutations.ASSIGN_TO_ALL, { assignment });
		// now all students in course in this store have the assignment assigned to them
		// need to update the db
		try{
			// await courseApi.patch(this.course);
			await courseApi.assignToAll(this.course.id, assignment);
		} catch(e){
			console.log("Failed to Assign Assignments to All Students", e);
			this.context.commit(Mutations.ASSIGN_TO_ALL_FAILURE, e);
		}
		this.context.commit(Mutations.ASSIGN_TO_ALL_SUCCESS);
	}
	@Mutation [Mutations.ASSIGN_TO_ALL]({ assignment }: {assignment: AssignmentModel}){
		this.assignToAllLoading = true;
		assignment.active = true;
		this.course.students.map(student => {
			// find all assignments that are "active"
			// there should only be one, but just in case, we use a filter
			// and make them not active
			const oldActiveassignments = student.assignments.filter(assignment => assignment.active);
			oldActiveassignments.map(assignment => {
				assignment.active = false;
			})
			student.assignments.push(assignment);
		})
	}
	@Mutation [Mutations.ASSIGN_TO_ALL_SUCCESS] (): void {
		this.assignToAllLoading = false;
	}
	@Mutation [Mutations.ASSIGN_TO_ALL_FAILURE](errorMessage: string): void {
		this.assignToAllLoading = false;
		this.assignToAllError = errorMessage;
	}
}
