export function filesize(value: number, precision: number = 0) {
	if (value === 0) {
		return '0 bytes';
	}
	if (!isFinite(value)) {
		return '-';
	}
	// console.log('Here in the filesize pipe');
	const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
	const numberValue = Math.floor(Math.log(value) / Math.log(1024));
	return (value / Math.pow(1024, Math.floor(numberValue))).toFixed(precision) + ' ' + units[numberValue];
}
