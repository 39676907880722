import * as UpChunk from '@mux/upchunk';
import { Component, Vue } from 'vue-property-decorator';
import { clipCreationToolStore } from "@/store";
import { videoApi } from '@/api/VideoApi';
import { VideoModel } from "@/models/video/VideoModel";
import { VideoSource } from '@/../types/enums';
import { MuxVideoDetail, YoutubeVideoDetail } from '@/../types/interfaces';
import { videoClipStore } from "@/store";
import { ClipCategoryModel } from '@/models/video-clip-categories/ClipCategoryModel';

@Component
export class VideoUploadMixin extends Vue {

	/**
	 * Returns a direct upload URL
	 */
	async createMuxUploadURL(parentID: string): Promise<{video: VideoModel, url: string}> {
		return videoApi.createMuxUploadURL(parentID);
	}

	/**
	 * Starts uploading video file using upchunk and returns webhook 
	 * @param file File from input tag. e.g.: input.files[0]
	 * @param toUrl Location to upload to
	 */
	async uploadVideo(file: File, toUrl: string): Promise<UpChunk.UpChunk> {
		const upload = UpChunk.createUpload({
			endpoint: toUrl,
			file: file,
			chunkSize: 1024,
		  });
		
		  return upload;
	}

	async cancelUpload(muxUploadId: string): Promise<any> {
		return clipCreationToolStore.cancelSVUpload(
			muxUploadId
		);
	}

	/**
	 * Takes a video object and returns a playback URL
	 * @param video A video object
	 */
	async createVideoURL(video: VideoModel): Promise<string>{
		if (video.source == VideoSource.Mux) {
			// There will be only be 1 playback ID
			// TODO: Signed URLs
			const playbackID = (video.detail as MuxVideoDetail).playback_ids[0].id;
			return`https://stream.mux.com/${playbackID}.m3u8`
		}
		else if (video.source == VideoSource.Youtube) {
			return `https://www.youtube.com/watch?v=${(video.detail as YoutubeVideoDetail).embedded_id}`
		}
	}

	get clipCategories(): ClipCategoryModel[] {
		return videoClipStore.clipCategories;
	}

}