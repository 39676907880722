import { StaffRole } from '../../types/enums';

export function role(role: StaffRole) {
	switch(role){
	case StaffRole.Admin: return 'Administrator';
	case StaffRole.HeadCoach: return 'Head Coach';
	case StaffRole.AssistantCoach: return 'Assistant Coach';
	default:
		return "Staff Member";
	}
}