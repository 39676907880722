import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';
import { RecipientTargetedSharingApi } from '@/api/RecipientTargetedSharingApi';
import { PaginatedResponse, QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { RecruitingResponse } from '@/../types/enums/recruiting-response';
import { RecruitingProfileModel } from '@/models/athlete/RecruitingProfileModel';
import { VideoModel } from '@/models/video/VideoModel';
import { VideoClipModel } from '@/models/video/VideoClipModel';

const Mutations = {
	QUERY_RECRUITING_DATA: 'QUERY_RECRUITING_DATA',
	QUERY_RECRUITING_DATA_SUCCESS: 'QUERY_RECRUITING_DATA_SUCCESS',
	QUERY_RECRUITING_DATA_FAILURE: 'QUERY_RECRUITING_DATA_FAILURE',

	SET_RESPONSE: 'SET_RESPONSE',
	SET_RESPONSE_SUCCESS: 'SET_RESPONSE_SUCCESS',
	SET_RESPONSE_FAILURE: 'SET_RESPONSE_FAILURE',
};

const name = 'CoachRecruitingStore';
if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class CoachRecruitingModule extends VuexModule {

	@Action({rawError: true})
	async getVideosIfShareExists({ coachId, athleteId }: { coachId: string, athleteId: string }): Promise<VideoModel[] | null>{
		const share = await this.findShareForAthleteId({ coachId, athleteId });
		if(share === null) return null;
		return await (new RecipientTargetedSharingApi(coachId)).getAthleteProfileVideos(share.id);
	}
	@Action({rawError: true})
	async getVideoClipsIfShareExists({ coachId, athleteId }: { coachId: string, athleteId: string }): Promise<VideoClipModel[] | null>{
		const share = await this.findShareForAthleteId({ coachId, athleteId });
		if(share === null) return null;
		return await (new RecipientTargetedSharingApi(coachId)).getAthleteProfileVideoClips(share.id);
	}
	@Action({rawError: true})
	async getRecruitingProfileIfShareExists({ coachId, athleteId }: { coachId: string, athleteId: string }): Promise<RecruitingProfileModel | null>{
		const share = await this.findShareForAthleteId({ coachId, athleteId });
		if(share === null) return null;
		return await (new RecipientTargetedSharingApi(coachId)).getAthleteProfile(share.id);
	}
	@Action({rawError: true})
	async findShareForAthleteId({ coachId, athleteId }: { coachId: string, athleteId: string }): Promise<TargetedSharingModel | null>{
		const searchResult = await (new RecipientTargetedSharingApi(coachId)).queryAll({
			$match: {
				parentId: athleteId
			}
		},{
			limitPerPage: 1,
		});
		const [ share ] = searchResult.docs;
		if (share === undefined){
			return null;
		}
		return new TargetedSharingModel().load(share);
	}

	queryRecruitingDataInitialized: boolean = false;
	queryRecruitingDataLoading: boolean = false;
	totalSharingRecords: number = 0;
	sharingRecords: TargetedSharingModel[] = [];
	totalLikedRecords: number = 0;
	likedRecords: TargetedSharingModel[] = [];
	totalUnseenRecords: number = 0;
	unseenRecords: TargetedSharingModel[] = [];
	@Action({
		rawError: true,
	}) async queryRecruitingData({ coachId, query, options }: { coachId: string, query: RepositoryQuery<TargetedSharingModel>, options: QueryOptions  }): Promise<void> {
		this.context.commit(Mutations.QUERY_RECRUITING_DATA)
		try {
			const api = new RecipientTargetedSharingApi(coachId);
			const shareData = await api.queryAll(query,options)
			const unseenData = await api.queryAll({$match:{
				'seen': false,
			}},{ limitPerPage: 5 });
			const likedData = await api.queryAll({$match:{
				'recipientData.response': RecruitingResponse.Yes,
			}} as any,{ limitPerPage: 5 });
			this.context.commit(Mutations.QUERY_RECRUITING_DATA_SUCCESS, { shareData, unseenData, likedData } );
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.QUERY_RECRUITING_DATA_FAILURE, e);
		}
	}
	@Mutation [Mutations.QUERY_RECRUITING_DATA](): void {
		this.queryRecruitingDataLoading = true;
	}

	@Mutation [Mutations.QUERY_RECRUITING_DATA_SUCCESS]({
		shareData,
		likedData,
		unseenData,
	}: {
		shareData: PaginatedResponse<TargetedSharingModel>,
		likedData: PaginatedResponse<TargetedSharingModel>,
		unseenData: PaginatedResponse<TargetedSharingModel>,
	} ): void {
		this.totalSharingRecords = shareData.total;
		this.sharingRecords = shareData.docs;
		this.totalLikedRecords = likedData.total;
		this.likedRecords = likedData.docs;
		this.totalUnseenRecords = unseenData.total;
		this.unseenRecords = unseenData.docs;
		this.queryRecruitingDataLoading = false;
		this.queryRecruitingDataInitialized = true;
	}

	@Mutation [Mutations.QUERY_RECRUITING_DATA_FAILURE](error: any): void {
		this.queryRecruitingDataLoading = false;
	}

	setResponseLoading: boolean = false;
	@Action({
		rawError: true,
	}) async setResponse({ coachId, id, response }: { coachId: string, id: string, response: RecruitingResponse  }): Promise<void> {
		this.context.commit(Mutations.SET_RESPONSE)
		try {
			const api = new RecipientTargetedSharingApi(coachId);
			const share = await api.setResponse(id, response);
			this.context.commit(Mutations.SET_RESPONSE_SUCCESS, { share } );
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.SET_RESPONSE_FAILURE, e);
		}
	}
	@Mutation [Mutations.SET_RESPONSE](): void {
		this.setResponseLoading = true;
	}

	@Mutation [Mutations.SET_RESPONSE_SUCCESS](): void {
		this.setResponseLoading = false;
	}

	@Mutation [Mutations.SET_RESPONSE_FAILURE](error: any): void {
		this.setResponseLoading = false;
	}


}