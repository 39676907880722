import { FrontEndModel } from '../FrontEndModel';
import { CommentModel } from '../discussion/CommentModel';

export class DiscussionModel extends FrontEndModel {
	public comments: Array<CommentModel> = [];
	public createdAt: Date = new Date();

	setComment(comment: CommentModel): void{
		this.comments.push(comment);
	}
	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (Array.isArray(obj['comments'])) this.comments = obj['comments'].map(c => new CommentModel().load(c));
		if(obj['createdAt']) this.createdAt = new Date(obj['createdAt']);
		return this;
	}

}