
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { formatDateSlashes } from '@/helpers/date';
import PaymentMethodIcon from '@/components/payments/PaymentMethodIcon.vue';
import { PaymentHistoryModel } from '@/models/stripe/PaymentHistoryModel';
import { DataTableHeader, DataOptions } from 'vuetify';
import { formatPrice } from '@/pipes';
import { CustomerModel } from '@/models/customer/CustomerModel';
const DEFAULT_OPTIONS = {
	page: 1,
	itemsPerPage: 5,
	sortBy: [],
	sortDesc: [true],
	groupBy: [],
	groupDesc: [false],
	multiSort: false,
	mustSort: false,
};
@Component({
	components: {
		PaymentMethodIcon,
	}
})
export default class PaymentHistoryTable extends Mixins(VuetifyMixin){
	formatDateSlashes = formatDateSlashes;
	formatPrice = formatPrice;
	@Prop({ default: [] }) paymentHistory: PaymentHistoryModel[];
	@Prop({ default: null }) customer: CustomerModel;
	tableOptions: DataOptions = DEFAULT_OPTIONS;
	headers: DataTableHeader<any>[] = [
		{
			text: 'Purchase',
			value: 'purchase',
		},
		{
			text: 'Amount',
			value: 'amount',
		},
		{
			text: 'Date',
			value: 'date',
		},
		{
			text: 'Payment Method',
			value: 'payment_method',
		},
	];

	get PaymentHistory(): PaymentHistoryModel[]{
		return this.paymentHistory.filter(transaction => {
			return transaction.status !== 'requires_payment_method';
		}).sort((a,b) => (+b.dateCreated) - (+a.dateCreated));
	}

	get TotalPages(): number{
		return Math.ceil(this.PaymentHistory.length / this.tableOptions.itemsPerPage)
	}
}
