
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { mdiEmailSend, mdiChevronDown } from '@mdi/js';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';
import { SportsSelectorMixin } from '../../mixins/SelectorMixins';
import { FormRulesMixin } from '../../mixins/FormRulesMixin';

import { InvitePlayerFormValue } from '../../../types/interfaces';
import { TeamModel, PlayerOnTeam } from '../../models/team';
import { teamDashboardStore } from '../../store';

@Component
export default class InvitePlayerDialog extends Mixins(FormRulesMixin, SportsSelectorMixin, VuetifyMixin){
	mdiEmailSend = mdiEmailSend;
	mdiChevronDown = mdiChevronDown;
	$refs:{
		form: HTMLFormElement
	}

	@Prop({ default: false }) value: boolean ;
	input(value){ this.$emit('input', value); }
	@Watch('value')
	resetForm(newValue) {
		// Reset validation state when showing the form
		if(newValue && this.$refs.form && this.formValue.number === ""){
			this.$refs.form.reset();
		}
	}
	
	@Prop({ required: true }) team: TeamModel;
	@Prop() playerId: string;
	@Prop({ default: (): Partial<PlayerOnTeam> => ({
		number: "",
		firstName: "",
		lastName: "",
		email: "",
		position: undefined,
	}) }) formValue: InvitePlayerFormValue;
	@Prop({ default: false }) isResend: boolean;
	updateForm(){
		this.$emit('update:formValue', this.formValue);
	}

	get manualInviteLoading(){
		return teamDashboardStore.manualInviteLoading;
	}

	formValid = false;

	submit($event: Event){
		$event.preventDefault();
		this.invite();
	}

	async invite(){
		const player: InvitePlayerFormValue = {
			number: this.formValue.number,
			firstName: this.formValue.firstName,
			lastName: this.formValue.lastName,
			email: this.formValue.email,
			position: this.formValue.position,
		};
		if(this.playerId) player.id = this.playerId;
		// invite and add if email exists, otherwise only add athlete to team
		this.formValue.email ? 
			await teamDashboardStore.invitePlayerByEmail({
				teamId: this.team.id,
				email: this.formValue.email,
				player,
			}) : 
			await teamDashboardStore.addPlayerToTeam({
				teamId: this.team.id,
				player
			})
		this.close();
	}
	cancel(){
		this.close();
	}

	close(){
		this.value = false;
		this.input(this.value);
	}
}
