import { Component, Prop, Vue } from 'vue-property-decorator';
import 'localforage';
/**
 * Provide a list of data option fields to load from LocalForage to `localForagePersistFields`
 * 
 * ```
 * localForagePersistFields = [
 *     'search', // Will load the 'search' field from localstorage on created
 *     ['config', { options: '123' }], // Will load the 'config' field, and fallback to {options:'123'} if it's not found
 * ]
 * ```
 * 
 * Call `persistField(fieldName)` to save the field. The field will be populated on `created` next time the component initializes
 * 
 * Provide `lf-key` prop with a unique value to a component if there will be multiple instances of the same component with different data
 * ```
 * <li v-for="i of 3">
 *   <MyPersistentComponent :lf-key="i"/>
 * </li>
 * ```
 * 
 */
@Component
export class LocalForageMixin extends Vue {
	$localforage: LocalForage;

	@Prop({ default: null }) private lfKey: string | null = null;
	localForagePersistFields: Array<string | [string, any]> = [];
	get LocalForagePersistFieldKeys(): string[]{
		return this.localForagePersistFields.map(fieldDef => {
			if(Array.isArray(fieldDef)){
				return fieldDef[0];
			}
			return fieldDef;
		});
	}

	created(): void{
		this.loadFields();
	}
	async loadFields(): Promise<void>{
		await Promise.all(
			this.localForagePersistFields
				.map(fieldDef => this.loadField(fieldDef))
		);
	}
	
	private getFieldKey(field: string){
		if (this.lfKey !== null){
			return `${this.$options.name}_${field}_${this.lfKey}`;
		}
		return `${this.$options.name}_${field}`;
	}
	async persistField<T = any>(fields: string | string[]): Promise<void>{
		const persistFields = Array.isArray(fields) ? fields : [fields];
		for (const field of persistFields){
			const value = this[field];
			// console.log(`persistField "${field}": ${this[field]}`);
			await this.$localforage.setItem<T>(this.getFieldKey(field), value);
		}
	}
	private async loadField<T = any>(fields: string | [string, T]): Promise<void>{
		let field : string;
		let defaultValue: T;
		if(Array.isArray(fields)){
			[field, defaultValue] = fields;
		}else{
			field = fields;
		}
		let value = await this.$localforage.getItem<T>(this.getFieldKey(field));
		// console.log(`loadingField "${this.getFieldKey(field)}"`, value);
		if((value === null || value === undefined) && defaultValue !== undefined){
			value = defaultValue;
		}
		this[field] = value;
	}
	async clearPersistedFields(fields?: string[] | string): Promise<void>{
		let persistFields: string[];
		if(persistFields === undefined){
			persistFields = this.LocalForagePersistFieldKeys;
		}else if(Array.isArray(fields)){
			persistFields = fields;
		}else{
			persistFields = [fields];
		}
		await Promise.all(
			persistFields.map(
				field => this.$localforage.removeItem(this.getFieldKey(field))
			)
		);
	}
}
