
import Vue from 'vue';
import { VSelect } from 'vuetify/lib';
import { mdiChevronDown } from '@mdi/js';
export default Vue.extend({
	props:{
		'offset-y':{
			type: Boolean,
			default: false,
		}
	},
	render(h) {
		return h(VSelect, {
			attrs: {
				'outlined': true,
				'append-icon': mdiChevronDown,
				...this.$attrs,
				'menuProps':{
					...this.$attrs.menuProps,
					'offset-y': this.offsetY,
				}
			},
			on: this.$listeners,
			scopedSlots: this.$scopedSlots,
		}, Object.keys(this.$slots).map(slot => h('template', { slot }, this.$slots[slot])))
	}
})
