import mixpanel from 'mixpanel-browser';
import { User, UserTypeMap } from "@/../types/interfaces";
import { getFlagValue } from "@/feature-flags";

export function mixpanelTrack(event: string, data: any){
	if (getFlagValue('mixpanel-tracking')) {
		mixpanel.track(event, data);
	}
}

export function useMixpanelTracking(user: User, map: Partial<UserTypeMap> = {}, debug: boolean = false): void{
	if (getFlagValue('mixpanel-tracking')){
		mixpanel.init('46ae2bbe5e9a3dbdad084c868af1d197', { debug });
		mixpanel.identify(user.id);
		mixpanel.register({
			email: user.email,
			email_verified: user.email_verified,
			pictureUrl: user.pictureUrl,
			firstName: user.firstName,
			lastName: user.lastName,
			...map,
		});
	}
}
