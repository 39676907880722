
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { RecurrenceRuleModel } from '@/models/calendar/RecurrenceRuleModel';
import { RecurrenceRuleWeekday as Weekday, RecurrenceRuleWeekdayValues } from '@/../types/enums/recurrence-rule-weekday';

type RRuleItem = {
	text: string;
	value: RecurrenceRuleModel | null;
	openDialog?: boolean;
}

@Component
export default class WeekdaySelect extends Mixins(VuetifyMixin){
	@Prop({ default: () => [] }) value: Weekday[];
	input(): void{
		this.$emit('input', this.value);
	}

	get WeekdayItems(): { text: string, value: Weekday }[]{
		return RecurrenceRuleWeekdayValues.map(d => ({
			text: `${d.charAt(0).toUpperCase()}`,
			value: d,
		}))
	}
}
