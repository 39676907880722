
import { Mixins, Component, Prop, PropSync } from 'vue-property-decorator';
import { VuetifyMixin, FormRulesMixin, AuthMixin } from '@/mixins';
import { AthleteProfileModel } from '@/models';
import { AthleteProfileRoleName, AthleteProfileRelationshipName } from '@/../types/enums';
import { AthleteUserRelationship, AthleteRelationship, UserAcl } from '@/../types/interfaces';
import { athleteProfile } from "@/store";
import { mdiChevronDown, mdiInformationOutline } from '@mdi/js';
import ConfirmationDialog from "@/components/ui/ConfirmationDialog.vue";
import { capitalize } from '@/pipes';



@Component({
	components: {
		ConfirmationDialog
	},
})
export default class AddAccessControlDialog extends Mixins(AuthMixin, FormRulesMixin, VuetifyMixin){
	@Prop({ required: true }) value: AthleteProfileModel;
	@Prop({ type: Boolean, default: true }) save: boolean;
	@PropSync('visible', { type: Boolean, default: false }) Visible: boolean;
	
	capitalize = capitalize;

	mdiChevronDown = mdiChevronDown;
	mdiInformationOutline = mdiInformationOutline;

	addUserValid: boolean = false;
	addUserForm: AthleteUserRelationship = {
		role: AthleteProfileModel.Roles.find(r => r.name === AthleteProfileRoleName.Owner),
		relationship: undefined,
		inviteEmail: "",
		userId: "",
	};


	get AthleteUserExists(): boolean{
		return this.AthleteUser !== null;
	}

	get AthleteUser(): UserAcl | null{
		if(!this.value) return null;
		const athleteRelation = this.value.userRelationships.find(rel => rel.relationship.name === AthleteProfileRelationshipName.Athlete);
		if(athleteRelation === undefined) return null;
		const athleteUser = this.value.users.find(u => u.id === athleteRelation.userId)
		if(athleteUser === undefined) return null;
		return athleteUser;
	}
	get AvailableRelationships(): AthleteRelationship[]{
		if(this.AthleteUserExists){
			return AthleteProfileModel.Relationships.filter(rel => rel.name !== AthleteProfileRelationshipName.Athlete);
		}
		return AthleteProfileModel.Relationships;
	}

	get ShowAthleteMessage(): boolean{
		if(this.addUserForm.relationship && this.addUserForm.relationship.name === AthleteProfileRelationshipName.Athlete){
			return true;
		}
		return false;
	}

	get AthleteFullName(): string{
		if(!this.value) return '';
		return this.value.FullName;
	}

	get ShowOwnerWarning(): boolean{
		if(this.addUserForm.role && this.addUserForm.role.name === AthleteProfileRoleName.Owner){
			return true;
		}
		return false;
	}
	private getTempUserId(): string{
		return `tmp_${~~(Math.random() * 10000)}`;
	}
	async addTempUser(): Promise<void>{
		const tempUserRelation: AthleteUserRelationship = {
			...this.addUserForm,
			role: AthleteProfileModel.Roles.find(role => role.name === AthleteProfileRoleName.Owner),
			userId: this.getTempUserId(),
		};
		const updatedUser: AthleteProfileModel = new AthleteProfileModel().load(this.value);
		updatedUser.setUser(this.addUserForm.userId, tempUserRelation)
		this.Visible = false;
		
		if (this.save) {
			this.saveAccessControl(updatedUser);
		}
		this.onSave(updatedUser);
	}

	async onSave(athlete: AthleteProfileModel): Promise<void>{
		this.$emit('input', athlete);
	}
	async onCancel(): Promise<void>{
		this.$emit('cancel');
	}
	async saveAccessControl(athlete: AthleteProfileModel): Promise<void>{
		await athleteProfile.patchAthleteProfile({
			patch: athlete,
			options: { withAcls: true },
			updateOptions: { sourceUserId: this.UserId, inviteNewRelationshipUsers: true }
		});
	}
}
