
import { Component, Mixins, PropSync } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';
import { AthleteCoachedItem } from '@/models/coach/CoachResumeModel';
import DatePicker from "@/components/forms/DatePicker.vue";
import { mdiCloseCircle } from '@mdi/js';
import { YearPicker } from "@/components/forms/YearPicker.vue";

@Component({
	components: { 
		DatePicker,
		YearPicker
	}
})
export default class CoachPlayersCoachedRow extends Mixins(VuetifyMixin){
	@PropSync('athlete', { required: true }) 
	Athlete: AthleteCoachedItem;

	mdiCloseCircle = mdiCloseCircle;
}
