import { AccessControlledModel } from '../AccessControlledModel';
import { MuxVideoUploadStatus, SourceVideoUsageType } from '../../../types/enums/sourcevideos';

export class SourceVideoMuxModel extends AccessControlledModel {

	muxVideoUploadStatus: MuxVideoUploadStatus = MuxVideoUploadStatus.Uploading;
	muxVideoUploadId: string = '';
	muxVideoUploadUrl: string = '';
	muxVideoPlaybackId: string = '';
	muxVideoPlaybackUrl: string = '';
	muxVideoAssetId: string = '';
	originalFileName: string = '';
	dateVideoUploadStarted: Date = new Date('1-1-1970');
	dateVideoUploadCompleted: Date = new Date('1-1-1970');
	videoFileSize: number = 0;
	videoLengthInSeconds: number = 0;

	videoUserEnteredName: string = '';
	videoUserEnteredDescription: string = '';

	originalFileMetadataCreationTime = new Date('1-1-1970');
	originalFileMetadataModificationTime = new Date('1-1-1970');
	eventTimestampOfRecordingSetAs = new Date('1-1-1970');
	eventTimestampWasManuallySet: boolean = false;

	uploadedByFullUserId: string = '';

	videoUsageType: SourceVideoUsageType = SourceVideoUsageType.TeamEvent;
	teamEventParentId: string = '';
	teamParentId: string = '';
	evaluationAssignmentParentId: string = '';
	atheleteUserParentId: string = '';
	isDeletable: boolean = true;
	indexInTeamEvent: number = 0;

	thumbnailUrl: string = '';

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		this.dateVideoUploadStarted = obj['dateVideoUploadStarted'] ? new Date(obj['dateVideoUploadStarted']) : new Date();
		this.dateVideoUploadCompleted = obj['dateVideoUploadCompleted'] ? new Date(obj['dateVideoUploadCompleted']) : new Date();
		this.originalFileMetadataCreationTime = obj['originalFileMetadataCreationTime'] ? new Date(obj['originalFileMetadataCreationTime']) : new Date();
		this.originalFileMetadataModificationTime = obj['originalFileMetadataModificationTime'] ? new Date(obj['originalFileMetadataModificationTime']) : new Date();
		this.eventTimestampOfRecordingSetAs = obj['eventTimestampOfRecordingSetAs'] ? new Date(obj['eventTimestampOfRecordingSetAs']) : new Date();
		return this;
	}
}