
import Vue from 'vue';
import { VTextField } from 'vuetify/lib';
export default Vue.extend({
	render(h) {
		return h(VTextField, {
			attrs: {
				'outlined': true,
				...this.$attrs,
			},
			on: this.$listeners,
			scopedSlots: this.$scopedSlots,
		}, Object.keys(this.$slots).map(slot => h('template', { slot }, this.$slots[slot])))
	}
})
