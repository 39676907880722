import { CrudApi } from './CrudApi';
import { AthleteAssessmentDataModel } from '../models/athlete/AthleteAssessmentDataModel';

import { AssessmentEntry } from 'types/interfaces/PyhsicalAssessmentDataFormValue';
import { logGAEvent } from '@/plugins/firebase';

class AthleteAssessmentDataApi extends CrudApi<AthleteAssessmentDataModel>{
	constructor(){
		super('athleteAssessmentData', (obj) => new AthleteAssessmentDataModel().load(obj));
	}

	async bulkInsert(assessments: AssessmentEntry[]) {
		return await CrudApi.Api(c => c.post(`/athleteAssessmentData/assessmentBulk`, {assessments: assessments}));
	}

	async bulkInsertV3({ assessments, fixDuplicates, notify }: { assessments: AssessmentEntry[], notify: boolean, fixDuplicates: boolean }) {
		return await CrudApi.Api(c => c.post(`/athleteAssessmentData/assessmentBulk/v3`, {assessments: assessments}, {
			params:{
				fixDuplicates,
				notify,
			}
		}));
	}

	async findLatestByAthleteId({ athleteId }: { athleteId: string }): Promise<AthleteAssessmentDataModel | null>{
		try{
			const obj = await CrudApi.Api(c => c.get(`${this.resource}/athlete/${athleteId}/latest`));
			return this.create(obj);
		} catch (e) {
			if (e.response.status === 404) {
				return null;
			}
			throw e;
		}
	}
	async findAllByAthleteId({ athleteId }: { athleteId: string }): Promise<AthleteAssessmentDataModel | null>{
		const objs = await CrudApi.Api(c => c.get(`${this.resource}/athlete/${athleteId}`));
		return objs.map((obj: any) => this.create(obj));
	}
	async verifyClaimCode({ code }: { code: string }): Promise<{ valid: boolean, token: { id: string, name: string } }>{
		const result = await CrudApi.Api(c => c.get(`${this.resource}/claim-code/verify`, { params: { code } }));
		logGAEvent("verified_assessment_code_checked", { valid: result.valid });
		return {
			valid: result.valid,
			token: result.token,
		};
	}
	async associateFromClaimCode({ code, athleteId }: { code: string, athleteId: string }): Promise<AthleteAssessmentDataModel>{
		const result = await CrudApi.Api(c => c.post(`${this.resource}/claim-code/associate`, {}, { params: { code, athleteId } }));
		logGAEvent("verified_assessment_claimed");
		return this.create(result);
	}
}
export const athleteAssessmentDataApi = new AthleteAssessmentDataApi();