/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfig, NavigationGuard, Route } from 'vue-router';
import { athleteAuthGuard } from './routeGuards/athleteAuthGuard';
import { AthleteSideNavLayout } from '../layouts';
import { athleteProfile, userStore } from '../store';
import * as Routes from '../../types/constants/web_client_user.routes';
import { EventChildRouteConfigs } from './EventChildRouteConfigs';
import { HinderRouteMetadata } from './router';

const AthleteProfile = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteProfile.vue');
const AthleteRecruitingProfileView = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteRecruitingProfileView.vue')
const AthleteRecruitingProfileEdit = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteRecruitingProfileEdit.vue')
const SharingSummary = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/SharingSummary.vue')
const AthleteSchoolSearch = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteSchoolSearch.vue');
const AthleteMindsetReport = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteMindsetReport.vue');
const AthleteScoutingReportsView = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteScoutingReportsView.vue');
const AthleteVerifiedAssessmentSummary = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteVerifiedAssessmentSummary.vue');
const AthleteSelfReportSummary = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteSelfReportSummary.vue');
const AthleteSelfAssessmentPage = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteSelfAssessmentPage.vue');
const AthleteSelfAssessmentIntro = () => import(/* webpackChunkName: "AthleteModule" */ '@/components/selfassessment/AthleteSelfAssessmentIntro.vue');
const AthleteSelfAssessmentForm = () => import(/* webpackChunkName: "AthleteModule" */ '@/components/selfassessment/AthleteSelfAssessmentForm.vue');
const AccountSettings = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AccountSettings.vue');
const TeamDashboardView = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/TeamDashboardView.vue');
const AthleteComparativeDataView = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteComparativeDataView.vue');
const AthleteComparativeDataSummary = () => import(/* webpackChunkName: "AthleteModule" */ '@/components/profile/athlete/AthleteComparativeDataSummary.vue');
const AthleteComparativeDataPerformanceSummary = () => import(/* webpackChunkName: "AthleteModule" */ '@/components/profile/athlete/AthleteComparativeDataPerformanceSummary.vue');
const AthleteComparativeDataCompare = () => import(/* webpackChunkName: "AthleteModule" */ '@/components/profile/athlete/AthleteComparativeDataCompare.vue');
const AthleteComparativeDataMyProgress = () => import(/* webpackChunkName: "AthleteModule" */ '@/components/profile/athlete/AthleteComparativeDataMyProgress.vue');
const AthleteComparativeDataPerformance = () => import(/* webpackChunkName: "AthleteModule" */ '@/components/profile/athlete/AthleteComparativeDataPerformance.vue');
const AthleteAccessControlForm = () => import(/* webpackChunkName: "AthleteModule" */ '@/components/profile/settings/AthleteAccessControlForm.vue');
const AthleteSettingsPaymentForm = () => import(/* webpackChunkName: "AthleteModule" */ '@/components/profile/settings/AthleteSettingsPaymentForm.vue');
const AthleteSettingsSubscriptionForm = () => import(/* webpackChunkName: "AthleteModule" */ '@/components/profile/settings/AthleteSettingsSubscriptionForm.vue');
const TeamEventView = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/TeamEventView.vue');
const TeamJoinForm = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/TeamJoinForm.vue');
const ClaimAssessmentForm = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/ClaimAssessmentForm.vue');
const ClaimSPQAssessmentForm = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/ClaimSPQAssessmentForm.vue');
const ClaimScoutingReportForm = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/ClaimScoutingReportForm.vue');
const AthleteVideoStudio = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteVideoStudio.vue');
const AthleteCheckoutPage = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteCheckoutPage.vue');
const SchoolDetailPage = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/SchoolDetailPage.vue');
const FullScreenCalendar = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/FullScreenCalendar.vue');
const AthleteScoutingReport = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteScoutingReport.vue');

const CalendarV2 = () => import(/* webpackChunkName: "CoachModule" */ '@/components/calendar/CalendarV2.vue');
const TeamRosterV2 = () => import(/* webpackChunkName: "CoachModule" */ '@/components/teams/TeamRosterV2.vue');
const TeamGamesProvider = () => import(/* webpackChunkName: "CoachModule" */ '@/components/teams/TeamGamesProvider.vue');

const loadAthleteProfile: NavigationGuard = (to, _, next) => {
	athleteProfile.getAthleteProfile(userStore.athleteId);
	next();
};

export const AthleteRouteConfigs: RouteConfig[] = [
	{
		beforeEnter: athleteAuthGuard,
		path: '/',
		component: AthleteSideNavLayout,
		props: true,
		meta: {
			pageTitle: 'Athlete Profile',
		},
		children: [
			{
				path: 'checkout/:checkoutIntentId?',
				name: Routes.AthleteCheckoutPage,
				component: AthleteCheckoutPage,
				props: (to: Route) => {
					return {
						checkoutIntentId: to.params.checkoutIntentId ?? null,
						checkoutAction: to.query.step ?? null,
					}
				},
				meta: <HinderRouteMetadata>{
					pageTitle: "Checkout"
				}
			},
			{
				path: 'assessment/claim',
				name: Routes.AthleteClaimAssessmentForm,
				component: ClaimAssessmentForm,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Add Verified Assessment"
				}
			},
			{
				path: 'scouting-report/claim',
				name: Routes.AthleteClaimScoutingReportForm,
				component: ClaimScoutingReportForm,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Add Scouting Report"
				}
			},
			{
				path: 'spq-assessment/claim',
				name: Routes.AthleteClaimSPQAssessmentForm,
				component: ClaimSPQAssessmentForm,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Add SPQ Assessment"
				}
			},
			{
				path: 'team/join',
				name: Routes.AthleteTeamJoin,
				component: TeamJoinForm,
				props: (to: Route) => {
					return {
						...to.params,
						athleteMode: true,
					}
				},
				meta: <HinderRouteMetadata>{
					pageTitle: "Join Team"
				}
			},
			{
				path: 'team/dashboard/:teamId/event/:teamEventId/view',
				name: Routes.AthleteTeamEventView,
				component: TeamEventView,
				props: (to: Route) => {
					return {
						...to.params,
						readOnly: true,
						athleteMode: true,
					}
				},
				meta: {
					hideAppBar: true,
				}
			},
			{
				path: 'team/dashboard/:teamId/calendar/fullscreen',
				name: Routes.FullScreenCalendar,
				component: FullScreenCalendar,
				props: (to: Route) => {
					return {
						athleteMode: true,
						...to.params,
					}
				},
				meta: <HinderRouteMetadata>{
					hideAppBar: true,
					showOnMobile: true,
				}
			},
			{
				path: 'team/dashboard/:teamId',
				name: Routes.AthleteTeamDashboard,
				component: TeamDashboardView,
				props: (to: Route) => {
					return {
						athleteMode: true,
						...to.params,
					}
				},
				meta: {
					pageTitle: 'Team Dashboard',
				},
				children: [
					{
						path: 'roster',
						name: Routes.AthleteTeamDashboardRoster,
						component: TeamRosterV2,
						meta: <HinderRouteMetadata>{
							pageTitle: 'Team Dashboard',
						},
						props: {
							readOnly: true,
						}
					},
					{
						path: 'calendar',
						name: Routes.AthleteTeamDashboardCalendar,
						component: CalendarV2,
						meta: <HinderRouteMetadata>{
							pageTitle: 'Team Dashboard',
						},
					},
					{
						path: 'games',
						name: Routes.AthleteTeamDashboardGames,
						component: TeamGamesProvider,
						meta: <HinderRouteMetadata>{
							pageTitle: 'Team Dashboard',
						},
					}
				]
			},
			{
				path: 'settings',
				component: AccountSettings,
				meta: {
					pageTitle: 'Settings',
				},
				children: [
					{
						path: '',
						redirect: {
							name: Routes.AccountSettingsPersonalInfo
						}
					},
					{
						path: 'profile-picture',
						redirect: {
							name: Routes.AccountSettingsProfilePicture,
						}
					},
					{
						path: 'change-password',
						redirect: {
							name: Routes.AccountSettingsChangePassword,
						}
					},

					{
						path: 'access-control',
						name: Routes.AthleteSettingsAccessControlForm,
						meta: {
							pageTitle: 'Settings',
						},
						component: AthleteAccessControlForm,
					},
					{
						path: 'payments',
						name: Routes.AthleteSettingsPaymentForm,
						meta: {
							pageTitle: 'Settings',
						},
						component: AthleteSettingsPaymentForm,
					},
					{
						path: 'subscription',
						name: Routes.AthleteSettingsSubscriptionForm,
						meta: {
							pageTitle: 'Settings',
						},
						component: AthleteSettingsSubscriptionForm,
					},
				],
			},
			{
				path: '',
				component: AthleteProfile,
				name: Routes.AthleteProfile,
				beforeEnter: loadAthleteProfile,
				props: true,
				meta: {
					pageTitle: 'Athlete Profile',
				}
			},
			{
				path: 'recruiting-profile/sharing/:tab?',
				component: SharingSummary,
				name: Routes.AthleteSharingSummary,
				props: (to: Route) => {
					return {
						sharingId: to.params.athleteId,
						...to.params,
					}
				},
				meta: {
					pageTitle: 'Recruiting Profile',
				},
			},
			{
				path: 'recruiting-profile',
				component: AthleteRecruitingProfileView,
				name: Routes.AthleteRecruitingProfileView,
				props: true,
				meta: {
					pageTitle: 'Recruiting Profile',
				},
			},
			{
				path: 'recruiting-profile/edit/:currentStep?',
				component: AthleteRecruitingProfileEdit,
				name: Routes.AthleteRecruitingProfileEdit,
				props: true,
				meta: {
					pageTitle: 'Edit Recruiting Profile',
				}
			},
			{
				path: 'school/search',
				component: AthleteSchoolSearch,
				props: true,
				name: Routes.AthleteSchoolSearch,
				meta: {
					pageTitle: "School Search",
				}
			},
			{
				path: 'mindset',
				component: AthleteMindsetReport,
				name: Routes.AthleteMindsetReport,
				props: true,
				beforeEnter: loadAthleteProfile,
				meta: <HinderRouteMetadata>{
					pageTitle: "Ryzer Mindset",
				}				
			},
			{
				path: 'scouting',
				component: AthleteScoutingReportsView,
				name: Routes.AthleteScoutingReportsView,
				props: true,
				beforeEnter: loadAthleteProfile,
				meta: <HinderRouteMetadata>{
					pageTitle: "Scouting Reports",
				}				
			},
			{
				path: 'verified',
				component: AthleteVerifiedAssessmentSummary,
				props: true,
				beforeEnter: loadAthleteProfile,
				name: Routes.AthleteVerifiedAssessmentSummary,
				meta: <HinderRouteMetadata>{
					pageTitle: "Verified Assessments",
				}
			},
			{
				path: 'self-report',
				component: AthleteSelfReportSummary,
				props: true,
				beforeEnter: loadAthleteProfile,
				name: Routes.AthleteSelfReportSummary,
				meta: <HinderRouteMetadata>{
					pageTitle: "Self Reported Assessments",
				}
			},
			{
				path: 'self-report/assessment',
				component: AthleteSelfAssessmentPage,
				props: true,
				beforeEnter: loadAthleteProfile,
				meta: <HinderRouteMetadata>{
					pageTitle: "Self Reported Assessments",
				},
				children: [
					{
						name: Routes.AthleteSelfAssessmentIntro,
						path: '',
						component: AthleteSelfAssessmentIntro,
						meta: <HinderRouteMetadata>{
							pageTitle: "Self Reported Assessments",
						},
					},
					{
						name: Routes.AthleteSelfAssessmentForm,
						path: ':selfAssessmentId?/test/:metric?',
						component: AthleteSelfAssessmentForm,
						meta: <HinderRouteMetadata>{
							pageTitle: "Self Reported Assessments",
						},
						props: true,
					}
				]
			},
			{
				path: 'comparative',
				component: AthleteComparativeDataView,
				name: Routes.AthleteComparativeData,
				props: true,
				beforeEnter: loadAthleteProfile,
				children: [
					{
						path: 'compare',
						name: Routes.AthleteComparativeDataCompare,
						component: AthleteComparativeDataCompare,
						props: true,
						meta: {
							pageTitle: 'My Physical Performance',
						},
					},
					{
						path: 'my-progress',
						name: Routes.AthleteComparativeDataMyProgress,
						component: AthleteComparativeDataMyProgress,
						props: true,
						meta: {
							pageTitle: 'Physical Performance Comparison',
						},
					},
					{
						path: 'performance',
						component: AthleteComparativeDataPerformance,
						props: true,
						meta: {
							pageTitle: 'Physical Performance Comparison',
						},
						children: [
							{
								path: ':metric',
								name: Routes.AthleteComparativeDataPerformanceSummary,
								component: AthleteComparativeDataPerformanceSummary,
								props: true,
								meta: {
									pageTitle: 'Physical Performance Comparison',
								},
							}
						]
					},
					{
						path: ':metric',
						name: Routes.AthleteComparativeDataSummary,
						component: AthleteComparativeDataSummary,
						props: true,
						meta: {
							pageTitle: 'Physical Performance Comparison',
						},
					}
				]
			},
			{
				path: 'school/search/:schoolId?/detail',
				component: SchoolDetailPage,
				name: Routes.SchoolDetails,
				props: true,
				meta: {
					pageTitle: 'School Detail',
				}
			},
			{
				path: 'scouting-report/:reportId',
				component: AthleteScoutingReport,
				name: Routes.AthleteScoutingReport,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Scouting Report Details',
				}
			},
			{
				path: 'video-studio',
				component: AthleteVideoStudio,
				name: Routes.AthleteVideoStudio,
				props: true,
				meta: {
					pageTitle: 'Video Studio',
				}
			},
			...EventChildRouteConfigs(Routes.AthleteProfile),
		]
	}
];