import { FrontEndModel } from '@/models/FrontEndModel';
import { TargetedSharingRecruitingRecord } from '@/../types/interfaces';

export class TargetedSharingModel extends FrontEndModel{
	date: Date;
	email: string;
	parentId: string;
	seen: boolean = false;
	recipientData: TargetedSharingRecruitingRecord;

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if(obj['date']) this.date = new Date(obj['date']);
		return this;
	}
}