
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mdiClockOutline } from '@mdi/js';

interface TimeSelection{
	hour: string,
	minute: string,
}

@Component
export default class TimePickerDialog extends Vue{
	mdiClockOutline = mdiClockOutline;

	@Prop() value: string;
	dropdownValue: TimeSelection = undefined;
	@Prop({ type: Boolean, default: false }) dialog: boolean;
	@Prop({ type: Boolean, default: false }) twentyFourHour: boolean;
	@Prop({ default: () => [ "00","05","10","15","20","25","30","35","40","45","50","55" ] }) intervals: string[];
	/** Selected time will be on this date */
	@Prop({ default: null, type: Date }) date: Date | null;
	/** Selected time must be after this time */
	@Prop({ default: null, type: Date }) afterTime: Date | null;
	/** Selected time must be before this time */
	@Prop({ default: null, type: Date }) beforeTime: Date | null;

	showDialog = false;

	$refs: {
		dialog: any
	}

	mounted(): void{
		if(this.value){
			this.setValue(this.value);
		}
	}

	@Watch('value')
	setValue(value: string): void{
		if(value === undefined) return;
		let [hh,mm] = value.split(':');

		if (hh.charAt(0) === '0'){
			hh = hh.charAt(1)
		}
		const option = this.TimePickerItems.find(x => x.value.hour === hh && x.value.minute === mm);
		
		if(option !== undefined){
			this.dropdownValue = option.value;
		}
	}

	searchTime(item: TimeSelection, queryText: string, itemText: string): boolean{
		if (queryText.includes(':')){
			return itemText.includes(queryText)
		} 
		return itemText.replace(':', "").includes(queryText)
	}
	
	updateDropdownValue(value: TimeSelection): void{
		this.dropdownValue = value;
		this.input(`${value.hour}:${value.minute}`);
	}
	input(value: string): void{
		this.value = value;
		this.$emit('input', this.value);
	}
	accept(): void{
		this.$refs.dialog.save(this.value);
		this.$emit('input', this.value);
	}

	/**
	 * The date we're picking a time for. Optional, used for filtering time options
	 */
	get Date(): Date | null{
		if(this.date === null) return null;
		return this.date;
	}
	/**
	 * Is the Date we're picking a time for after the `afterTime` date?
	 */
	get TimeIsFutureDay(): boolean | null{
		if(this.Date === null) return null;
		return this.afterTime.getDate() < this.Date.getUTCDate();
	}
	get AfterHour(): number | null{
		if(this.afterTime === null) return null;
		return this.afterTime.getHours();
	}
	get AfterMinute(): number | null{
		if(this.afterTime === null) return null;
		return this.afterTime.getMinutes();
	}
	get BeforeHour(): number | null{
		if(this.beforeTime === null) return null;
		return this.beforeTime.getHours();
	}

	private generatePickerItems(hours: number, pm: boolean = false): {text: string, value: TimeSelection}[]{
		return new Array(hours).fill(null).map((_, index) => {
			return new Array(this.intervals.length)
				.fill(index)
				.map((hour, intervalIndex) => {
					if(this.AfterHour !== null && !this.TimeIsFutureDay){
						let compareHour: number = hour;
						if(hours === 12 && pm === true){
							compareHour = hour + 12;
						}
						if(compareHour === this.AfterHour && this.AfterMinute >= +this.intervals[intervalIndex]){
							return undefined;
						}else if(compareHour < this.AfterHour){
							return undefined;
						}
					}
					if(this.BeforeHour !== null && hour > this.BeforeHour){
						return undefined;
					}
					if(hours === 12){
						if(hour === 0 && pm === true){
							hour = 12;
						}
						if(pm === true){
							return {
								text: `${hour}:${this.intervals[intervalIndex]}PM`,
								value: { hour: `${hour !== 12 ? (+hour + 12) : hour}`, minute: this.intervals[intervalIndex] },
							};
						}
						return {
							text: `${hour === 0 ? 12 : hour}:${this.intervals[intervalIndex]}AM`,
							value: { hour: `${hour}`, minute: this.intervals[intervalIndex] },
						};
					}
					return {
						text: `${hour}:${this.intervals[intervalIndex]}`,
						value: { hour: `${hour}`, minute: this.intervals[intervalIndex] },
					};
				}).filter(x => x !== undefined);
		}).reduce((a,b) => [...a,...b], []);
	}

	get TimePickerItems(): {text: string, value: TimeSelection}[]{
		if(this.twentyFourHour){
			return this.generatePickerItems(24);
		}else{
			return [
				...this.generatePickerItems(12, false),
				...this.generatePickerItems(12, true),
			];
		}
	}
}
