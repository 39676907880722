import store from '../store';
import { Mutations as CourseMutations } from '../courses/courses.store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { CourseModel } from '../../models/course';
import { CourseCreateOptions } from '@/../types/interfaces/CourseCreateOptions';
import { courseApi } from '@/api/CourseApi';

const Mutations = {
	CREATE_COURSE: 'CREATE_COURSE',
	CREATE_COURSE_SUCCESS: 'CREATE_COURSE_SUCCESS',
	CREATE_COURSE_FAILURE: 'CREATE_COURSE_FAILURE',

	PATCH_COURSE: 'PATCH_COURSE',
	PATCH_COURSE_SUCCESS: 'PATCH_COURSE_SUCCESS',
	PATCH_COURSE_FAILURE: 'PATCH_COURSE_FAILURE',

	LOAD_TEMPLATES: 'LOAD_TEMPLATES',
	LOAD_TEMPLATES_SUCCESS: 'LOAD_TEMPLATES_SUCCESS',
	LOAD_TEMPLATES_FAILURE: 'LOAD_TEMPLATES_FAILURE',
}

@Module({
	namespaced: true,
	dynamic: true,
	name: 'CourseCreateStore',
	store: store
})
export default class CourseCreateModule extends VuexModule {

	createCourseLoading: boolean = false;

	@Action({
		rawError: true,
	}) async createCourse({ course, opts }: { course: CourseModel, opts: CourseCreateOptions }): Promise<CourseModel> {
		this.context.commit(Mutations.CREATE_COURSE, course);
		try {
			const newCourse = await courseApi.insertWithOwnership(course, { params: opts });
			this.context.commit(Mutations.CREATE_COURSE_SUCCESS);
			this.context.commit(`CoursesStore/${CourseMutations.ADD_COURSE}`, newCourse, { root: true });
			return newCourse;
		} catch (e) {
			console.error("Failed to Create Course", e);
			this.context.commit(Mutations.CREATE_COURSE_FAILURE, e);
		}
	}

	@Mutation [Mutations.CREATE_COURSE](): void {
		this.createCourseLoading = true;
	}
	@Mutation [Mutations.CREATE_COURSE_SUCCESS](): void {
		this.createCourseLoading = false;
	}
	@Mutation [Mutations.CREATE_COURSE_FAILURE](error: any): void {
		this.createCourseLoading = false;
	}

	@Action({
		rawError: true,
	}) async patchCourse({ course, opts }: { course: CourseModel, opts: CourseCreateOptions }): Promise<CourseModel> {
		this.context.commit(Mutations.PATCH_COURSE, course);
		try {
			const newCourse = await courseApi.patch(course, { params: opts });
			this.context.commit(Mutations.PATCH_COURSE_SUCCESS);
			this.context.commit(`CoursesStore/${CourseMutations.ADD_COURSE}`, newCourse, { root: true });
			return newCourse;
		} catch (e) {
			console.error("Failed to Create Course", e);
			this.context.commit(Mutations.PATCH_COURSE_FAILURE, e);
		}
	}

	@Mutation [Mutations.PATCH_COURSE](): void {
		this.createCourseLoading = true;
	}
	@Mutation [Mutations.PATCH_COURSE_SUCCESS](): void {
		this.createCourseLoading = false;
	}
	@Mutation [Mutations.PATCH_COURSE_FAILURE](error: any): void {
		this.createCourseLoading = false;
	}

	courseTemplatesInitialized: boolean = false;
	courseTemplatesLoading: boolean = false;
	courseTemplates: CourseModel[] = [];

	@Action({
		rawError: true,
	}) async loadTemplates(): Promise<boolean> {
		this.context.commit(Mutations.LOAD_TEMPLATES);
		try {
			const templates = await courseApi.loadTemplates();
			this.context.commit(Mutations.LOAD_TEMPLATES_SUCCESS, templates);
			return true;
		} catch (e) {
			console.error("Failed to Create Course", e);
			this.context.commit(Mutations.LOAD_TEMPLATES_FAILURE, e);
			return false;
		}
	}

	@Mutation [Mutations.LOAD_TEMPLATES](): void {
		this.courseTemplatesLoading = true;
	}
	@Mutation [Mutations.LOAD_TEMPLATES_SUCCESS](templates: CourseModel[]): void {
		this.courseTemplates = templates;
		this.courseTemplatesLoading = false;
		this.courseTemplatesInitialized = true;
	}
	@Mutation [Mutations.LOAD_TEMPLATES_FAILURE](error: any): void {
		this.courseTemplatesLoading = false;
	}
}
