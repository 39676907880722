
import { Component, Prop, Vue} from 'vue-property-decorator';
import ProfilePictureUpload from '@/components/forms/ProfilePictureUpload.vue';
import { CoachProfileModel } from '@/models/coach/CoachProfileModel';

@Component({
	components: {
		ProfilePictureUpload,
	}
})
export default class CoachSettingCoachingRecord extends Vue{
	@Prop({ required: true }) value: CoachProfileModel;

	showImageUploadDialog = false;
	valid = false;

}
