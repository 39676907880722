<template>
	<v-app>
		<router-view />
	</v-app>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { initializeFeatureFlags } from './feature-flags';
import { DarkModeMixin } from './mixins';
export default {
	mixins: [ DarkModeMixin ],
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Best Athletes',
		// all titles will be injected into this template
		// titleTemplate: '%s | Best Athletes'
	},
	props: {
		appType: {
			type: String,
			default: 'main'
		}
	},
	data () {
		return {
			authOUnwatch: null,
		}
	},
	created() {
		if (this.$auth) {
			this.authOUnwatch = this.$auth.$watch("loading", loading => {
				if(loading === false){
					this.onAuth0Initialized();
				}
			});
		}
		this.initDarkMode();
	},
	methods:{
		onAuth0Initialized(){
			initializeFeatureFlags();
			if(this.authOUnwatch !== null) this.authOUnwatch();
		}
	}
}
</script>

<style lang="scss">
.btn-primary{
	@extend .upper-case;
	letter-spacing: 0;
	font-weight: 500 !important;
}
.v-btn__content{
	letter-spacing: 0 !important;
}
.side-nav-activator{
	position: fixed;
	top: 0px;
	left: 5px;
	z-index: 5;
}
	::-webkit-scrollbar-thumb {
		background-color: $ba-color-primary-blue;
	}

	::-webkit-scrollbar {
		background: $ba-color-off-white;
		width: 9px;
	}

	.fab-container {
			position: fixed;
			right: 16px;
			bottom: 16px;
			display: flex;
			flex-direction: column-reverse;
		}

	.bestie-container {
			position: fixed;
			right: 40px;
			bottom: 40px;
			display: flex;
			flex-direction: column-reverse;
			border: 1px solid #303030;
			z-index: 10;
		}

	.z9 {
		z-index: 9;
	}

	.z10{
		z-index: 10;
	}

	.v-footer span {
		font-size: 12px;
		color: $ba-color-secondary-text;
	}

	body {
		font-family: Rubik, sans-serif, Tahoma, Verdana, serif;
		color: $ba-color-primary-text;
	}

// Inner page title like Dashboard
h1 {
	font-size: 26px;
	font-family: Rubik, sans-serif, Tahoma, Verdana, serif;
	font-weight: bold;
	line-height: 106.65%;
	letter-spacing: -0.01em;
	color: $ba-color-primary-text;
}

// Currently used for Dashboard Section Label
h2 {
	font-family: Rubik, sans-serif, Tahoma, Verdana, serif;
	font-style: normal;
	font-weight: normal;
	// font-size: 16px;
	line-height: 106.65%;
	letter-spacing: -0.02em;
	color: $ba-color-gray-2;
}

// Section Heading
h3 {
	font-family: Rubik, sans-serif, Tahoma, Verdana, serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 106.65%;
}

	// for vertically aligning "or signin/up" text hr
	hr.middle {
		margin: 0.5em 0;
		border-color: #C6C6C6;
	}

	hr{
		border: 1px solid #EDEDED;
	}

	// // Custom text field element (Vuetify Regular transitions + Outline border)
	// .v-text-field.v-input--has-state > .v-input__control > .v-input__slot,
	// .v-text-field.v-input--is-focused > .v-input__control > .v-input__slot {
	//   border-color: currentColor;
	// }
	// .v-text-field {
	//   .v-input__slot {
	//     padding: 0 12px;
	//     height: 100%;
	//     min-height: 56px;
	//     border-radius: 4px;
	//     .v-label--active {
	//       max-width: 133%;
	//       -webkit-transform: translateY(-10px) scale(0.75);
	//       transform: translateY(-10px) scale(0.75);
	//     }
	//     input {
	//       padding: 14px 0 0;
	//     }
	//   }
	//   > .v-input__control > .v-input__slot {
	//     border: 2px solid $ba-color-gray-5;
	//   }
	//   .v-input__append-inner{
	//     margin: auto;
	//   }
	// }
	// .v-input__slot{
	//   .v-select__slot{
	//     input{
	//       padding: 0;
	//     }
	//   }
	// }
	// //
	// .v-select__selection--comma {
	//   margin: 7px 4px -10px 0;
	// }
	// Override the default large button style
	button.v-btn.v-size--large {
		text-align: center;
		font-size: 16px;
		line-height: 19px;
		text-transform: none;
		font-weight: normal;
		.v-icon {
			color: rgba(255, 255, 255, 0.79);
			padding-bottom: 4px;
		}
	}
	
	.theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
		color: $ba-color-primary-text;
	}

	.v-skeleton-loader__list-item{
		background-color: rgba(0, 0, 0, 0) !important;
	}

	.position-relative {
		position: relative;
	}
	.upper-case {
		text-transform: uppercase !important;
	}
	.cursor-pointer {
		cursor: pointer;
	}
	.no-select {
		-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
				-moz-user-select: none; /* Old versions of Firefox */
					-ms-user-select: none; /* Internet Explorer/Edge */
							user-select: none; /* Non-prefixed version, currently
																		supported by Chrome, Edge, Opera and Firefox */
	}

	.word-break-keep-all{
		word-break: keep-all;
	}

	.left-divider{
		border-left: 1px solid $ba-color-gray-4;
	}

	.flip-icon-180{
		transition: transform 0.4s ease;
		&.v-icon{
			transform: rotateZ(180deg);
		}
	}

	.width-100{
		width: 100%;
	}

.soft-shadow{
	filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.14)) !important;
}

.overflow-visible{
	overflow: visible;
}
/** useful to make a table row have no height when margin/padding don't work */
.collapse-height{
	height: 0px !important;
}
</style>