/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfig, NavigationGuard } from 'vue-router';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { eventDetailsStore } from '@/store';
import router, { HinderRouteMetadata } from './router';

const EventDetails = () => import(/* webpackChunkName: "PublicEventModule" */ '@/views/EventDetails.vue');
const RegisterForAssessment = () => import(/* webpackChunkName: "PublicEventModule" */ '@/views/RegisterForAssessment.vue');

const loadEvent: NavigationGuard = (to, _, next) => {
	eventDetailsStore.loadEvent({ eventId : to.params.eventId });
	next();
};

export const EventChildRouteConfigs: (namePrefix?: string) => RouteConfig[] = (namePrefix: string = "") => ([
	{
		path: 'event/register',
		component: RegisterForAssessment,
		props: true,
		name: `${namePrefix}${Routes.RegisterForAssessment}`,
		meta:<HinderRouteMetadata>{
			pageTitle: 'Home',
			useBackButton: true,
			onClickTitle: () => {
				router.push('/');
			}
		}
	},
	{
		beforeEnter: loadEvent,
		path: 'event/:eventId',
		component: EventDetails,
		props: true,
		name: `${namePrefix}${Routes.EventDetails}`,
		meta:<HinderRouteMetadata>{
			pageTitle: 'Home',
			useBackButton: true,
			onClickTitle: () => {
				router.push('/');
			}
		}
	}
]);