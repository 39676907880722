import { CrudApi } from './CrudApi';
import { SourceVideoMuxModel } from '../models/source-video/SourceVideoMuxModel';
import { SourceVideoFileDetails } from '../models/source-video/SourceVideoMuxUpload';

class SourceVideoMuxModelApi extends CrudApi<SourceVideoMuxModel>{
	constructor() {
		super('sourceVideoMux', (obj) => new SourceVideoMuxModel().load(obj));
	}

	async getDirectUploadURL(theSvFileDetail: SourceVideoFileDetails): Promise<SourceVideoMuxModel> {
		return await CrudApi.Api(c => c.post(`/sourceVideoMux/newupload`, theSvFileDetail));
	}

	async cancelCurrentUpload(theUploadId: string): Promise<any> {
		const tempBody = {"theUploadId": theUploadId}
		return await CrudApi.Api(c => c.post(`/sourceVideoMux/cancelupload`, tempBody));
	}

	async getSourceVideoForTeamEvent(teamEvetnId: string): Promise<SourceVideoMuxModel[]> {
		const objs = await CrudApi.Api(c => c.get(`/sourceVideoMux/teamevent/${teamEvetnId}`));
		return objs.map((obj: any) => this.create(obj));
	}

	async getSourceVideoForEvaluationAssignment(assignmentId: string): Promise<SourceVideoMuxModel[]> {
		const objs = await CrudApi.Api(c => c.get(`/sourceVideoMux/assignment/${assignmentId}`));
		return objs.map((obj: any) => this.create(obj));
	}
}
export const sourceVideoMuxModelApi = new SourceVideoMuxModelApi();