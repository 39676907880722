import { AgeGroup, Under13AgeGroup, BaseSoccerPosition } from "../enums";
import { AnalyticsGender } from "./analytics-gender";

export const AnalyticsAssessmentFilterCategoriesMap = {
	'gender': 1,
	'age_group': 1,
	'age': 1,
	'position': 1,
	'team': 1,
	'organization': 1,
};

export type AnalyticsAssessmentFilterCategory = keyof typeof AnalyticsAssessmentFilterCategoriesMap;

export const AnalyticsAssessmentFilterCategories = 
	Object.keys(AnalyticsAssessmentFilterCategoriesMap) as AnalyticsAssessmentFilterCategory[];

const AnalyticsAssessmentFilterOperatorsMap = {
	'=': 1,
	'>': 1,
	'<': 1,
	'>=': 1,
	'<=': 1,
};

export type AnalyticsAssessmentFilterOperator = keyof typeof AnalyticsAssessmentFilterOperatorsMap;

export const AnalyticsAssessmentFilterOperators = 
	Object.keys(AnalyticsAssessmentFilterOperatorsMap) as AnalyticsAssessmentFilterOperator[];

type AnalyticsAssessmentGenderFilter = {
	category: "gender",
	operator: "=",
	value: AnalyticsGender,
}
type AnalyticsAssessmentAgeFilter = {
	category: "age";
	operator: AnalyticsAssessmentFilterOperator;
	value: number;
};
type AnalyticsAssessmentAgeGroupFilter = {
	category: "age_group";
	operator: "=";
	value: AgeGroup | Under13AgeGroup;
};
type AnalyticsAssessmentPositionFilter = {
	category: "position";
	operator: "=";
	value: BaseSoccerPosition;
};
type AnalyticsAssessmentTeamFilter = {
	category: "team";
	operator: "=";
	value: string;
};
type AnalyticsAssessmentOrganizationFilter = {
	category: "organization";
	operator: "=";
	value: string;
};
export type AnalyticsAssessmentFilter = AnalyticsAssessmentGenderFilter
	| AnalyticsAssessmentAgeFilter
	| AnalyticsAssessmentAgeGroupFilter
	| AnalyticsAssessmentPositionFilter
	| AnalyticsAssessmentTeamFilter
	| AnalyticsAssessmentOrganizationFilter;