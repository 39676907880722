import { Component, Vue } from 'vue-property-decorator';

/**
 * Allow a component to 'debounce' a function call.
 * 
 * Provide debounceCallback() with a unique id for this operation, a callback function and
 */
@Component
export class DebounceMixin extends Vue {
	private debounceCallbackTimeouts: { [key:string]: number } = {};
	private debounceCallbackHandlers: { [key:string]: () => void } = {};
	/**
	 * Calls the provided callback after a short time.  
	 * Call this method again before the callback runs to reset the timeout.
	 * @param key Unique Id for this action
	 * @param cb The callback to run
	 * @param timeoutMs Default: 1000ms. Delay in ms before the callback is run
	 */
	debounceCallback(key: string, cb: () => void, timeoutMs: number = 1000): void{
		if(this.debounceCallbackTimeouts[key]) clearTimeout(this.debounceCallbackTimeouts[key]);
		this.debounceCallbackHandlers[key] = cb;
		this.debounceCallbackTimeouts[key] = window.setTimeout(() => this.debounceCallbackExecAll(), timeoutMs);
	}
	/**
	 * Cancels a pending debounced callback by key. This will clear the timeout and stop the execution from happening.
	 */
	debounceCancelCallback(key: string): void{
		if(this.debounceCallbackTimeouts[key]){
			clearTimeout(this.debounceCallbackTimeouts[key]);
			delete this.debounceCallbackHandlers[key];
		}
	}

	private debounceCallbackExecAll(): void{
		for(const key of Object.keys(this.debounceCallbackHandlers)){
			this.debounceCallbackExec(key);
		}
	}
	private debounceCallbackExec(key: string, args?: any): void{
		// console.log(`[DebounceMixin exec] Running callback: ${key}`);
		this.debounceCallbackHandlers[key].apply(this, args);
		delete this.debounceCallbackHandlers[key];
	}

	beforeDestroy(): void{
		// console.log(`[DebounceMixin beforeDestroy] Running all callbacks`);
		this.debounceCallbackExecAll();
	}
}
