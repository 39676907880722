import { Component, Vue } from 'vue-property-decorator';
import { getFlagValue } from '@/feature-flags';

/**
 * Allows easily up Feature Flag Values in template
 */
@Component
export class FeatureFlagMixin extends Vue {
	feature<T = boolean>(flag: string): T{
		return getFlagValue(flag);
	}
}
