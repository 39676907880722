import { AxiosError } from "axios";
import { errorIs404, errorIsOneOf } from '@/helpers/error-handler';

export type PageStates = "Initial" |
	"Loading" |
	"Ready" |
	"PlanUpgrade" |
	"NotFound" |
	"Forbidden" |
	"ServerError";

export class PageState<S = unknown> {
	constructor(protected state: PageStates | S){ }
	get State(): PageStates | S{
		return this.state;
	}
	get IsInitial(): boolean {
		return this.state === "Initial";
	}
	get IsLoading(): boolean {
		return this.state === "Loading";
	}
	get IsReady(): boolean {
		return this.state === "Ready";
	}
	get IsPlanUpgrade(): boolean {
		return this.state === "PlanUpgrade";
	}
	get IsError(): boolean {
		return this.IsForbidden || this.IsNotFound || this.IsServerError;
	}
	get IsNotFound(): boolean {
		return this.state === "NotFound";
	}
	get IsForbidden(): boolean {
		return this.state === "Forbidden";
	}
	get IsServerError(): boolean {
		return this.state === "ServerError";
	}

	/**
	 * @param e: AxiosError: Takes a network response and returns a PageState with the appropriate status.
	 * `null`: Resolves to NotFound state.
	 * 
	 * When handling errors note that `CrudApi` returns `null` instead of throwing the error when a 404 status is returned
	*/
	static getPageState<S = unknown>(e: AxiosError | null): PageState<S>{
		if (e === null) {
			return new PageState<S>("NotFound");
		}
		if(e === undefined || e.response === undefined){
			/** An incorrect error type must have been passed. Probably a runtime js error client-side */
			return new PageState<S>("ServerError");
		}
		if(errorIs404(e)) {
			return new PageState<S>("NotFound");
		}
		if (errorIsOneOf(e, [401, 403])) {
			return new PageState<S>("Forbidden");
		}
		return new PageState<S>("ServerError");
	}
}