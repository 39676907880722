
import { Component, Mixins, PropSync } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins";
import { AthleteProfileModel } from "@/models/athlete/AthleteProfileModel";
import { mdiPlus, mdiChevronDown } from "@mdi/js";
import HintText from "@/components/ui/HintText.vue";
import LinkText from "@/components/ui/LinkText.vue";

@Component({
	components: {
		HintText,
		LinkText
	}
})
export class EditRecruitingProfileEducation extends Mixins(VuetifyMixin) {
	@PropSync('editAthleteProfile', { required: true }) EditAthleteProfile: AthleteProfileModel;
	mdiPlus = mdiPlus;
	mdiChevronDown = mdiChevronDown;

	get years(): number[] {
		/**
		 * Returns a array of 70 years from 10 years in the future to 60 years back
		 */
		let current_year = new Date().getFullYear();

		let years: number[] = [];

		for (let i = 0; i < 70; i++) {
			years.push(current_year + 10 - i);
		}

		return years;
	}
}

export default EditRecruitingProfileEducation;
