export class ExperienceItem {
	title: string;
	team: string;
	from: Date;
	to: Date;
	description: string;

	public load(obj: Record<string ,any>): ExperienceItem {
		Object.assign(this, obj);
		this.from = new Date(obj['from']);
		this.to = new Date(obj['to']);

		return this;
	}
}

export interface AwardItem {
	description: string;
	year: number[];
}

export interface ChampionshipItem {
	description: string;
	year: number[];
}

export interface AthleteCoachedItem {
	athleteName: string;
	team: string;
	year: number[];
}

export interface CertificationItem {
	description: string;
}

export class CoachResumeModel {
	wins: number = 0;
	losses: number = 0;
	ties: number = 0;
	nccp: string = null;
	csa: string = null;
	summary: string = "";
	philosophy: string = "";
	experience: ExperienceItem[] = [];
	awards: AwardItem[] = [];
	championships: ChampionshipItem[] = [];
	athletesCoached: AthleteCoachedItem[] = [];
	certifications: CertificationItem[] = [];

	public load(obj: Record<string, any>): CoachResumeModel {
		Object.assign(this, obj);

		if (obj['experience'] && Array.isArray(obj['experience']))
			this.experience = obj['experience'].map(exp => new ExperienceItem().load(exp))

		return this;
	}
}