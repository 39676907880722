
import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import { VuetifyMixin, SportsSelectorMixin } from "@/mixins";
import { AthleteProfileModel } from "@/models/athlete/AthleteProfileModel";
import HintText from "@/components/ui/HintText.vue";
import LinkText from "@/components/ui/LinkText.vue";
import { Mass, Length, BaseSoccerPosition, DominantSide } from "@/../types/enums";
import ProfilePictureUpload from "@/components/forms/ProfilePictureUpload.vue";
import { mdiChevronDown } from "@mdi/js";
import SectionHeading from "@/components/ui/SectionHeading.vue";

type Item<T> = {
	text: string;
	value: T;
};

@Component({
	components: {
		HintText,
		LinkText,
		ProfilePictureUpload,
		SectionHeading
	}
})
export default class EditRecruitingProfileBasic extends Mixins(VuetifyMixin, SportsSelectorMixin) {
	@PropSync('editAthleteProfile', { required: true }) EditAthleteProfile: AthleteProfileModel;
	@Prop() clicked: DominantSide;
	
	mdiChevronDown = mdiChevronDown;
	requiredRule = [(v: any): boolean | string => !!v || "Required"];

	get secondaryPositionItems(): Item<BaseSoccerPosition>[] {
		/**
		 * Returns an array of enum keys as text and enum values as values for vuetify items
		 */
		let items: Item<BaseSoccerPosition>[] = [];

		Object.keys(BaseSoccerPosition)
			.filter(x => !(parseInt(x) >= 0))
			.forEach(position => {
				items.push({
					text: position as keyof typeof BaseSoccerPosition,
					value: BaseSoccerPosition[position] 
				});
			});
		return items;
	}
	get primaryPositionItems(): Item<BaseSoccerPosition>[] {
		// Primary options cannot be None
		return this.secondaryPositionItems
	}

	get weightUnitText(): "kg" | "lb" {
		return this.EditAthleteProfile.weightUnit == Mass.KILOGRAM ? "kg" : "lb";
	}
	async toggleWeight(): Promise<void> {
		this.EditAthleteProfile.weightUnit =
			this.EditAthleteProfile.weightUnit == Mass.KILOGRAM
				? Mass.POUND
				: Mass.KILOGRAM;
	}

	get heightUnitText(): "cm" | "in" {
		return this.EditAthleteProfile.heightUnit == Length.CENTIMETER
			? "cm"
			: "in";
	}
	async toggleHeight(): Promise<void> {
		this.EditAthleteProfile.heightUnit =
			this.EditAthleteProfile.heightUnit == Length.CENTIMETER
				? Length.INCH
				: Length.CENTIMETER;
	}
}
