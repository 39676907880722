import { SubscriptionStatus } from "@/../types/enums";
import { AvailableFeatures, FeatureDescription, FeatureMap } from "@/../types/interfaces";
import { FrontEndModel } from "../FrontEndModel";
import { SubscriptionPlanModel } from "../subscriptionPlan/SubscriptionPlanModel";
import { SubscriptionHistoryModel } from "./SubscriptionHistoryModel";
import * as ProductKeys from '@/../types/constants/product-keys';

export class SubscriptionModel extends FrontEndModel {
	parentId: string = "";
	plan: SubscriptionPlanModel = null;
	stripeSubscriptionId: string | null = null;
	usageHistory: SubscriptionHistoryModel[] = [];
	orderHistory: SubscriptionHistoryModel[] = [];
	inheritedSubscriptions: SubscriptionModel[] = [];
	created: Date = new Date();
	currentPeriodStart: Date = new Date();
	currentPeriodEnd: Date | null = null;
	stripeCustomerId: string = "";
	status: SubscriptionStatus = SubscriptionStatus.Active;

	get AvailableFeatureMap(): Partial<FeatureMap>{
		const orderMap = this.aggregateFeatureMap(this.orderHistory);
		// const usageMap = this.aggregateFeatureMap(this.usageHistory);
		return orderMap;
	}

	private aggregateFeatureMap(subscriptionHistory: SubscriptionHistoryModel[]): Partial<FeatureMap>{
		const map: Partial<FeatureMap> = subscriptionHistory.map(hist => hist.feature).reduce((map, feature) => {
			const entries = Object.entries(feature);
			for(const [key, value] of entries){
				if (value === true){
					map[key] = true;
				}else if(!Number.isNaN(value)){
					map[key] += value;
				}
			}
			return map;
		}, {});
		return map;
	}

	/**
	 * 
	 * @param features List of available resources for this subscription from the Available features API call
	 * @param productKey the product to return the available count for
	 * @returns number
	 */
	public static getAvailableProductCount(features: AvailableFeatures, productKey: string): number {
		if (!features) return 0;
		let featureValue: FeatureDescription<any> | null = null;
		let available: number = 0;
		switch (productKey) {
		case ProductKeys.VERIFIED_ASSESSMENT:
			featureValue = features['YEARLY_VERIFIED_ASSESSMENTS'];
			available = featureValue.total - featureValue.used;
			break;
		case ProductKeys.SPORTS_PERSONALITY_QUESTIONAIRE:
			featureValue = features['YEARLY_SPQ_ASSESSMENTS'];
			available = featureValue.total - featureValue.used;
			break;
		case ProductKeys.COMPARATIVE_DATA_ADDON:
			featureValue = features['ATHLETE_COMPARITIVE_DATA'];
			available = (featureValue.total && featureValue.used) ? 1 : 0;
			break;
		case ProductKeys.VIDEO_HOURS_5_ADDON: 
			featureValue = features['ATHLETE_VIDEO_MINUTES'];
			available = featureValue.total - 20;
			break;
		case ProductKeys.VIDEO_HOURS_1_ADDON: 
			featureValue = features['ATHLETE_VIDEO_MINUTES'];
			available = featureValue.total - 20;
			break;
		}
		return available;
	}

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);

		if(obj['plan']) {
			this.plan = new SubscriptionPlanModel().load(obj['plan'])
		}

		if (obj['usageHistory']) {
			this.usageHistory = obj['usageHistory'].map((hist: any) => new SubscriptionHistoryModel().load(hist))
		}

		if (obj['orderHistory']) {
			this.orderHistory = obj['orderHistory'].map((hist: any) => new SubscriptionHistoryModel().load(hist))
		}

		if(obj['inheritedSubscriptions'] && obj['inheritedSubscriptions'].length) {
			this.inheritedSubscriptions = obj['inheritedSubscriptions'].map((sub: any) => {
				if (typeof(sub) === 'object'){
					return new SubscriptionModel().load(sub)
				}
			})
		}

		if (obj['created']) {
			this.created = new Date(obj['created'])
		}

		if(obj['currentPeriodStart']) {
			this.currentPeriodStart = new Date(obj['currentPeriodStart']);
		}

		if(obj['currentPeriodEnd'] && obj['currentPeriodEnd'] !== null) {
			this.currentPeriodEnd = new Date(obj['currentPeriodEnd']);
		}

		return this;
	}
}