import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { depthChartApi } from '@/api/DepthChartApi';
import { DepthChartModel } from '@/models/depthChart';

const DepthChartMutationTypes = {
	LOAD_GLOBAL_TEMPLATES: 'LOAD_GLOBAL_TEMPLATES',
	LOAD_GLOBAL_TEMPLATES_SUCCESS: 'LOAD_GLOBAL_TEMPLATES_SUCCESS',
	LOAD_GLOBAL_TEMPLATES_FAILURE: 'LOAD_GLOBAL_TEMPLATES_FAILURE',
};

@Module({
	namespaced: true,
	dynamic: true,
	name: 'DepthChartStore',
	store: store
})
export default class DepthChartModule extends VuexModule {

	initialized: boolean = false;
	
	templatesInitialized: boolean = false;
	loadingTemplates: boolean = false;
	globalTemplates: DepthChartModel[] = [];

	@Action({
		rawError: true,
	}) async loadGlobalTemplates() {
		if (this.templatesInitialized === true) return;
		this.context.commit(DepthChartMutationTypes.LOAD_GLOBAL_TEMPLATES);
		try {
			const templates = await depthChartApi.loadGlobalTemplates();
			this.context.commit(DepthChartMutationTypes.LOAD_GLOBAL_TEMPLATES_SUCCESS, templates);
		} catch (e) {
			console.error("Failed to Create Team", e);
			this.context.commit(DepthChartMutationTypes.LOAD_GLOBAL_TEMPLATES_FAILURE, e);
		}
	}

	@Mutation [DepthChartMutationTypes.LOAD_GLOBAL_TEMPLATES]() {
		this.loadingTemplates = true;
	}
	@Mutation [DepthChartMutationTypes.LOAD_GLOBAL_TEMPLATES_SUCCESS](templates: DepthChartModel[]) {
		this.loadingTemplates = false;
		this.templatesInitialized = true;
		this.globalTemplates = templates;
	}
	@Mutation [DepthChartMutationTypes.LOAD_GLOBAL_TEMPLATES_FAILURE](error: any) {
		this.loadingTemplates = false;
		this.templatesInitialized = false;
	}

}
