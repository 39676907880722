import { AccessControlledModel } from '../AccessControlledModel';
import { DepthChartPosition } from './DepthChartPosition';
import { DepthChartGrid } from '../team/DepthChartOnTeam';
import { PlayerOnTeam } from '../team';

export class DepthChartModel extends AccessControlledModel {
	name: string = "";
	type: string = "";
	formation: string = "";
	positions: DepthChartPosition[] = [];
	grid: DepthChartGrid = {
		name: 'Default',
		rows: []
	};

	async autoFillPositions(roster: PlayerOnTeam[]){
		// Clear out the old positions
		this.positions.map(p => p.playerIds = [])

		// Sort the players with preferred positions (numbered positions) first
		const playersWithPreferredPositions = roster.filter(
			p =>
				p.preferredPositionNumbers !== undefined &&
				p.preferredPositionNumbers !== null &&
				p.preferredPositionNumbers.length > 0
		)
		const playersWithoutPreferredPositions = roster.filter(
			p => 
				p.preferredPositionNumbers === undefined || 
				p.preferredPositionNumbers === null ||
				p.preferredPositionNumbers.length === 0
		)

		// Just maps over where the numbered positions are in the array; in case the order isn't sequential
		const numberedPositionToPositionInArrayMapping = new Map<number,number>();
		this.positions.forEach((position, i) => numberedPositionToPositionInArrayMapping.set(Number(position.number), i))

		// Loop over all the players that need a preferred number position
		playersWithPreferredPositions.map(player => {
			// Flag used to ensure the player is starter player only once
			let playerIsStarter = false;

			// Loop over all their numbered position
			for (const position of player.preferredPositionNumbers!) {

				// Check if the position exists first
				if (numberedPositionToPositionInArrayMapping.has(position)){
					// Extract; by reference
					const playerIds = this.positions[numberedPositionToPositionInArrayMapping.get(position)].playerIds

					// Check if the first position is filled is empty -> playedIds[0] will be null if empty
					if (playerIds.length > 0 && playerIds[0] === null && playerIsStarter === false) {
						// Replace first position which would be null with the player id
						playerIds.splice(0, 1, player.id!);
						playerIsStarter = true;
					}

					// Only set player as start player if currently aren't one
					else if (playerIds.length === 0 && !playerIsStarter) {
						playerIds.push(player.id!);
						playerIsStarter = true;
					}

					// Only push player as sub player if there is a starter player for this position
					else if(playerIds.length > 0) {
						playerIds.push(player.id!)
					}


					// If players should appear only once - can break after a position
					// break;
				}
			}
		})
		
		
		// Sort the base positions
		const positionSet: Set<string> = this.positions.reduce((a, b) => a.add(b.position), new Set<string>());
		positionSet.forEach(position => {
			// console.groupCollapsed(`autoFillPositions: ${position}`);
			const positions = this.positions.filter(p => p.position === position);
			const playersForPosition = playersWithoutPreferredPositions.filter(p => p.position === position);
			// console.log(`autoFillPositions: Spreading ${playersForPosition.length} players over ${positions.length} positions`);
			for(let i = 0; i < playersForPosition.length; i++){
				const bucket = i % positions.length;
				// console.log(`autoFillPositions: Player ${playersForPosition[i].id} => Position[${bucket}]`);
				positions[bucket].playerIds.push(playersForPosition[i].id);
			}
			// console.groupEnd();
		});
		return this;
	}

	getPosition(positionId: string): DepthChartPosition | undefined{
		return this.positions.find(p => p.PositionId === positionId);
	}

	/**
	 * Returns a deep copy of this instance.
	 */
	copy(): DepthChartModel{
		return new DepthChartModel().load({
			...this,
			positions: this.positions.map(p => ({
				...p,
				playerIds: p.playerIds.slice(),
			})),
			grid: {
				...this.grid,
			}
		});
	}
	/**
	 * Returns a deep copy of this instance with id removed
	 */
	copyAsTemplate(): DepthChartModel{
		const newTemplate = this.copy();
		delete (<any>newTemplate)._id;
		delete newTemplate.id;
		return newTemplate;
	}

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		this.positions = this.positions.map(p => new DepthChartPosition().load(p));
		return this;
	}
}