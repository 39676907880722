import { Component, Vue } from 'vue-property-decorator';
import { userStore } from '../store';
import { AthleteProfileModel } from '../models/athlete/AthleteProfileModel';
import { Auth0Plugin } from '@/auth/Auth0';

/**
 * Provides computed properties: 
 * - MyAthleteIsReady
 * - MyAthleteId
 * - MyAthleteProfile
 */
@Component({
	async beforeRouteEnter(_, __, next): Promise<void>{
		if (!userStore.athleteProfilesInitialized && !userStore.loadAthleteProfilesLoading){
			await userStore.loadAthleteProfiles();
		}
		next();
	},
})
export class MyAthleteMixin extends Vue {
	$auth: Auth0Plugin;

	get MyAthleteIsReady(): boolean{
		return userStore.athleteProfilesInitialized && !userStore.loadAthleteProfilesLoading;
	}

	get MyAthleteId(): string | undefined{
		if (userStore.athleteId){
			return userStore.athleteId;
		}
		return undefined;
	}

	get MyAthleteProfile(): AthleteProfileModel | undefined{
		if(this.MyAthleteIsReady){
			return userStore.athlete;
		}
		return undefined;
	}

	get MyAthleteFullName(): string{
		return userStore.FullName;
	}


	get ShowMindset(): boolean{
		// TODO: currently in demo mode only
		return this.MyAthleteProfile.demo ?? false;
	}
	get ShowPersonality(): boolean{
		// TODO: remove temporarily in preparation of removal
		return !this.MyAthleteProfile.demo ?? true;
	}

}
