
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class LightningSvg extends Mixins(VuetifyMixin){
	@Prop({ default: 32 }) private size: number;
	@Prop({ default: 'baColorPrimaryBlue' }) private color: string;
	get Color(){
		return this.getColor(this.color);
	}
}
