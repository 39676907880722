import { Component, Vue } from 'vue-property-decorator';
import { teamDashboardStore } from '../store';
import { PlayerOnTeam, TeamModel } from '../models/team';
import { Route } from 'vue-router';

@Component({
	beforeRouteEnter(to: Route, _, next){
		const { teamId } = to.params;
		if (!teamDashboardStore.initialized || teamDashboardStore.team === null){
			teamDashboardStore.loadTeam(teamId);
		}
		next();
	},
	beforeRouteUpdate(to: Route, from: Route, next){
		const { teamId } = to.params;
		const { teamId: fromTeamId } = from.params;
		if (teamId !== fromTeamId || !teamDashboardStore.initialized || teamDashboardStore.team === null){
			teamDashboardStore.loadTeam(teamId);
		}
		next();
	}
})
export class CurrentTeamMixin extends Vue {

	get CurrentTeamReady(): boolean{
		return !teamDashboardStore.loadingTeam && teamDashboardStore.initialized && teamDashboardStore.team !== null;
	}

	get CurrentTeam(): TeamModel | null{
		return teamDashboardStore.team;
	}
	get CurrentTeamId(): string{
		if (teamDashboardStore.team && teamDashboardStore.team.id !== '' && teamDashboardStore.team.id !== undefined && teamDashboardStore.team.id !== null){
			return teamDashboardStore.team.id;
		}
		return this.$route.params.teamId;
	}
	get CurrentTeamPlayers(): PlayerOnTeam[]{
		if(this.CurrentTeam === null) return [];
		return teamDashboardStore.team.players;
	}

}
