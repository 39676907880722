export enum NotificationType {
  /** Notification was created within the frontend and doesn't exist on the server */
  Local = "Local",
  /** Notification comes from an actionable definition and doesn't exist on the server */
  Action = "Action",
  /** Notification exists on the server and can be marked as read / deleted */
  Server = "Server",
}
export enum NotificationUIType {
  Message = "Message",
  Banner = "Banner",
  Snackbar = "Snackbar",
}
export enum NotificationStatus {
  UNREAD = "UNREAD",
  READ = "READ",
}

export enum NotificationErrorStatus {
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
}