
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiCloseCircle, mdiPlus, mdiUploadOutline, mdiInformationOutline, mdiInformation, mdiUpload, mdiChevronDown } from '@mdi/js';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { SportsSelectorMixin } from '@/mixins';
import {AthleteProfileModel} from '@/models/athlete/AthleteProfileModel';
import {uniqBy} from 'lodash';
import AthleteSearchSuggestion from '@/components/search/AthleteSearchSuggestion.vue';
import { TeamCreateFormValue } from '@/../types/interfaces';
import Dialog from "@/components/ui/Dialog.vue";
import CSVUploadDialog from "@/components/teams/CSVUploadDialog.vue";
import CSVHelpDialog from "@/components/teams/CSVHelpDialog.vue";
import CSVUploadPreview from "@/components/teams/CSVUploadPreview.vue";

@Component({
	components: { 
		FileUploadArea,
		AthleteSearchSuggestion,
		Dialog,
		CSVUploadDialog,
		CSVHelpDialog,
		CSVUploadPreview,
	}
})
export default class TeamCreateTeamRoster extends Mixins(SportsSelectorMixin){
	mdiCloseCircle = mdiCloseCircle;
	mdiPlus = mdiPlus;
	mdiUpload = mdiUpload;
	mdiUploadOutline = mdiUploadOutline;
	mdiInformationOutline = mdiInformationOutline;
	mdiInformation = mdiInformation;
	mdiChevronDown = mdiChevronDown;

	@Prop({ default: () => ({
		valid: true,
		players: [],
		invitePlayers: [],
		sendPlayerInvitations: false,
	}) }) value: TeamCreateFormValue['step3'];
	@Prop({ default: false, type: Boolean}) isEdit:boolean;
	input(): void{
		this.$emit('input', this.value);
	}

	unconfirmedInvitePlayers = this.value.invitePlayers;

	$refs:{
		form: HTMLFormElement;
		addPlayerForm: HTMLFormElement;
	};

	async addPlayer(athlete: AthleteProfileModel) {
		this.value.players.push(athlete);
		this.value.players = uniqBy(this.value.players, 'id');
	}

	addInvitePlayer(){
		this.value.invitePlayers.push(this.defaultPlayer());
		this.showInvitePlayers = true;
	}
	removeInvitePlayer(index: number){
		this.value.invitePlayers.splice(index,1);
		if(this.value.invitePlayers.length === 0){
			this.showInvitePlayers = false;
		}
	}
	defaultPlayer(){
		return {
			firstName: "",
			lastName: "",
			position: "",
			email: "",
			injured: false,
			eligibleToPlay: true,
		}
	}
	showInvitePlayers: boolean = false;

	get ShowAddPlayerArea(){
		return this.value.invitePlayers.length === 0;
	}
	addPlayerFormValid: boolean = false;
	addPlayerDialog = this.defaultPlayer();
	removePlayer(index){
		this.value.players.splice(index, 1);
	}

	rulesOptionalEmail = [
		v => (v.length > 1 ? /.+@.+\.[a-z,A-Z]/.test(v) : true) || 'E-mail must be valid'
	];
	rulesRequired = [
		(v) => !!v || 'Required',
	];


	async submit($event: Event) {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}

	CSVHelpModalVisible = false;
	CSVUploadDialogVisible = false;
	CSVUploadPreviewModalVisible = false;

	unconfirmedPlayers:Array<Record<string, unknown>> = [];
	previewPlayerData(data: Array<Record<string, unknown>>) {
		this.unconfirmedPlayers = data;
		this.CSVUploadPreviewModalVisible = true
	}
	confirmPlayerData(data:  Array<Record<string, unknown>>) {
		this.value.invitePlayers = data;
		this.showInvitePlayers = true;
	}
}
