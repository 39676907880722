import Vue from 'vue';
import { VNumber } from "@maxflex/v-number";
Vue.component('v-number', VNumber);

import Page from '../views/Page';
import PageTitle from './ui/PageTitle';
import PageSubTitle from './ui/PageSubTitle';
import SectionHeading from './ui/SectionHeading';
import SectionLabel from './ui/SectionLabel';
import DashboardSectionLabel from './ui/DashboardSectionLabel';
import FormTitle from './ui/FormTitle';
import FormGroupHeading from './ui/FormGroupHeading';
import FormAddArea from './forms/FormAddArea';
import RoundedButton from './forms/RoundedButton';
import RoundLoader from './ui/RoundLoader';
import UserChip from './ui/UserChip';
import CopyToClipboard from './ui/CopyToClipboard';
import FailureStateCard from './ui/FailureStateCard';

import Alert from './ui/Alert';
import CircledAvatar from './ui/CircledAvatar';
import Dialog from './ui/Dialog';
import ConfirmationDialog from './ui/ConfirmationDialog';
import ContextMenu from './ui/ContextMenu';
import PrimaryText from './ui/PrimaryText';
import SubPrimaryText from './ui/SubPrimaryText';
import SecondaryText from './ui/SecondaryText';

import Feature from './ui/Feature';
import DatePicker from './forms/DatePicker';

import ProfileProvider from './hoc/ProfileProvider';
import ProfilePictureProvider from './hoc/ProfilePictureProvider';

import SpeedometerSvg from '../components/svg/SpeedometerSvg.vue';
import StopwatchSvg from '../components/svg/StopwatchSvg.vue';
import LightningSvg from '../components/svg/LightningSvg.vue';
import AgilitySvg from '../components/svg/AgilitySvg.vue';
import HeartVitalSvg from '../components/svg/HeartVitalSvg.vue';
import RulerSvg from '../components/svg/RulerSvg.vue';

import BAChip from '../components/inputs/BAChip.vue';
import BAAutocomplete from '../components/inputs/BAAutocomplete.vue';
import BASelect from '../components/inputs/BASelect.vue';
import BACombobox from '../components/inputs/BACombobox.vue';
import BATextField from '../components/inputs/BATextField.vue';

Vue.component('Page', Page);
Vue.component('PageTitle', PageTitle);
Vue.component('PageSubTitle', PageSubTitle);
Vue.component('SectionHeading', SectionHeading);
Vue.component('SectionLabel', SectionLabel);
Vue.component('DashboardSectionLabel', DashboardSectionLabel);
Vue.component('FormTitle', FormTitle);
Vue.component('FormGroupHeading', FormGroupHeading);
Vue.component('FormAddArea', FormAddArea);
Vue.component('RoundedButton', RoundedButton);
Vue.component('RoundLoader', RoundLoader);
Vue.component('UserChip', UserChip);
Vue.component('CopyToClipboard', CopyToClipboard);
Vue.component('FailureStateCard', FailureStateCard);

Vue.component('Alert', Alert);
Vue.component('CircledAvatar', CircledAvatar);
Vue.component('Dialog', Dialog);
Vue.component('ConfirmationDialog', ConfirmationDialog);
Vue.component('ContextMenu', ContextMenu);
Vue.component('PrimaryText', PrimaryText);
Vue.component('SubPrimaryText', SubPrimaryText);
Vue.component('SecondaryText', SecondaryText);

Vue.component('Feature', Feature);
Vue.component('DatePicker', DatePicker);

Vue.component('ProfileProvider', ProfileProvider);
Vue.component('ProfilePictureProvider', ProfilePictureProvider);

Vue.component('SpeedometerSvg', SpeedometerSvg);
Vue.component('StopwatchSvg', StopwatchSvg);
Vue.component('LightningSvg', LightningSvg);
Vue.component('AgilitySvg', AgilitySvg);
Vue.component('HeartVitalSvg', HeartVitalSvg);
Vue.component('RulerSvg', RulerSvg);

Vue.component('BAChip', BAChip);
// Inputs 
Vue.component('BAAutocomplete', BAAutocomplete);
Vue.component('BASelect', BASelect);
Vue.component('BACombobox', BACombobox);
Vue.component('BATextField', BATextField);
