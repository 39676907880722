import { NavigationGuard } from 'vue-router';
import { getInstance } from "@/auth/auth0Plug";
import { Auth0Plugin } from '@/auth/Auth0';
import { authGuard } from '@/auth/authGuard';
import { userStore, navigationStore } from "@/store";
import * as Routes from '@/../types/constants/web_client_user.routes';

/**
 * Runs authGuard, then checks if the user's Athlete Profile exists and redirects to setup otherwise.
 */
export const profileSetupGuard: NavigationGuard = (to, from, next) => {
	if (navigationStore.Initialized === false){
		navigationStore.loadState();
	}
	next = navigationStore.StashNavigationNext(to, next, {ignoreRoutes: [ Routes.AthleteProfilesManagement, Routes.LoadingPage ]});
	return authGuard(to, from, async () => {
		const auth: Auth0Plugin = getInstance();
		const completedProfile = auth.HasAthleteProfile;
		const routeToCreateUser = to.name === Routes.RootOnboarding || to.name === Routes.AthleteProfileSetup;

		if(!userStore.athleteProfilesInitialized){
			await userStore.loadAthleteProfiles();
		}

		/**
		 * If code and state are present then the user just logged in and there could be a pending redirect from auth0's login with redirect.
		 * 
		 * If we would otherwise send them to the onboarding, we can forward them to the "LoadingPage" to allow any actionables to run before they're sent to onboarding.
		 * 
		 */
		if(to.query.code && to.query.state && !routeToCreateUser && !completedProfile && !userStore.HasAthleteProfiles){
			return next({
				name: Routes.LoadingPage
			});
		}

		// If the user does not have a profile create one.
		if (!routeToCreateUser && !completedProfile && !userStore.HasAthleteProfiles) {
			console.log("profileSetupGuard forwarding to RootOnboarding: Has no profiles", { to });
			return next({ name: Routes.RootOnboarding, query: to.query, params: to.params });
		}

		return next();
	});
}