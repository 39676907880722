/* CurrentAthleteMixin.tx */
/* Provides properties pertaining to the current athlete */

import { Component, Vue } from 'vue-property-decorator';
import { athleteProfile as athleteProfileStore, userStore } from '../store';
import { AthleteProfileModel } from '../models/athlete/AthleteProfileModel';
import { Auth0Plugin } from '@/auth/Auth0';

/**
 * Provides computed properties: 
 * - CurrentAthleteIsReady
 * - CurrentAthleteId
 * - CurrentAthleteProfile
 */
@Component({
	async beforeRouteEnter(to, _, next) {
		const athleteId = userStore.athleteId;
		await athleteProfileStore.getAthleteProfile(athleteId);
		next();
	},
})
export class CurrentAthleteMixin extends Vue {
	$auth: Auth0Plugin;

	get CurrentAthleteIsReady(): boolean{
		return userStore.athleteId !== null;
	}

	get CurrentAthleteId(): string | undefined{
		return userStore.athleteId ?? undefined;
	}
	
	get CurrentAthleteProfile(): AthleteProfileModel | undefined{
		return userStore.athlete ?? undefined;
	}
}
