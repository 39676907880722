import { RawLocation, RedirectOption } from 'vue-router';
import { Auth0Plugin } from './Auth0';
import { getInstance } from './auth0Plug';
import Cookies from 'js-cookie';
import { logGAEvent } from '@/plugins/firebase';

export const redirectToAuth0Login: RedirectOption = (): RawLocation => {
	const auth: Auth0Plugin = getInstance();
	const fn = () => {
		auth.loginWithRedirect({
			appState:{
				targetUrl: '/'
			}
		});
		return '/';
	}
	if(!auth.loading) {
		return fn();
	}
	auth.$watch("loading", (loading: boolean) => {
		// console.log("Loading Finished")
		if (loading === false) {
			return fn();
		}
	});
}
export const redirectToAuth0Signup: RedirectOption = (to): RawLocation => {
	const auth: Auth0Plugin = getInstance();
	const fn = () => {
		const referral = to.query.referral;
		const email = Array.isArray(to.query.email) ? to.query.email[0] : to.query.email;
		if(referral !== undefined){
			Cookies.set('referral', referral, {
				expires: 3650,
			});
			logGAEvent("referral", { referral });
		}
		auth.loginWithRedirect({
			action: 'signup',
			referral: referral,
			login_hint: email,
			appState: {
				targetUrl: '/'
			}
		});
		return '/';
	}
	if (!auth.loading) {
		return fn();
	}
	auth.$watch("loading", (loading: boolean) => {
		// console.log("Loading Finished")
		if (loading === false) {
			return fn();
		}
	});
}