import { getEnumValues } from './get-enum-values';

export enum CourseType {
	Standard = 'standard',
	Template = 'Template',
}

export enum AssignmentStatus {
	Completed = 'Completed',
	InProgress = 'In Progress',
	ReadyForReview = 'Ready For Review',
	NotStarted = 'Not Started'
}

export const CourseTypeValues = getEnumValues <CourseType>(CourseType)
export const AssignmentStatusValues = getEnumValues <AssignmentStatus>(AssignmentStatus)