import { getEnumValues } from './get-enum-values';
export enum EventbriteOrderQuestionType {
	Text='text',
	Url='url',
	Email='email',
	Date='date',
	Number='number',
	Address='address',
	Dropdown='dropdown',
}
export const EventbriteOrderQuestionTypeValues = getEnumValues<EventbriteOrderQuestionType>(EventbriteOrderQuestionType)