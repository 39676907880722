/**
 * Returns only alpha numeric chars from a string lowercased, with whitespace removed.
 * 
 * Useful when trying to determine if a user entered string matches an enum
 */
export function stringToAlphaLower(value: string): string {
	if (!value) return "";
	return value.toLowerCase().trim().replace(/[^a-zA-Z]/g, '');
}
/**
 * Returns only alphabet chars from a string lowercased, with whitespace removed.
 * 
 * Useful when trying to determine if a user entered string matches an enum
 */
export function stringToAlphaLowerNum(value: string): string {
	if (!value) return "";
	return value.toLowerCase().trim().replace(/[^a-zA-Z0-9]/g, '');
}