import { Component, Vue } from 'vue-property-decorator';
import { coursesStore } from '../../store'
import { CourseModel } from '../../models/course';

/**
 * Provides computed properties:
 * - MyInstructorCourses
 */
@Component
export class MyInstructorCoursesMixin extends Vue {
	reloadMyCourses: boolean = false;
	$auth: {
		coachId: string,
	}
	get MyCoursesReady(): boolean{
		return this.MyCoursesInitialized && !this.MyCoursesLoading;
	}
	get MyCoursesInitialized(): boolean{
		return coursesStore.myCoursesInitialized;
	}
	get MyCoursesLoading(): boolean{
		return coursesStore.myCoursesLoading;
	}
	get MyCourses(): CourseModel[]{
		return coursesStore.myCourses;
	}
	get MyCoursesSideNav(): CourseModel[]{
		return coursesStore.MyCoursesSideNav;
	}

	created(): void{
		this.initMyCourses();
	}

	initMyCourses(): void{
		// if (!hasRole(RoleName.InstructorAdmin) && !hasRole(RoleName.Admin)) return;
		if(this.reloadMyCourses || !coursesStore.myCoursesInitialized && this.$auth.coachId){
			coursesStore.loadMyCourses(this.$auth.coachId)
		}
	}

	get InstructorCurrentCourseId(): string{
		return this.$route.params.courseId;
	}
	get InstructorCurrentCourse(): CourseModel | undefined {
		return this.MyCourses.find(c => c.id === this.InstructorCurrentCourseId);
	}
}