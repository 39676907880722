
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin } from "../../mixins/VuetifyMixin";

@Component
export default class LinkText extends Mixins(VuetifyMixin) {
	@Prop({ default: "" }) href: string = "";
	@Prop({ default: false, type: Boolean }) hrefDisabled: boolean;

	get HREFAttribute(): string | null {
		return this.hrefDisabled ? null : 'href'
	}
}
