import { AthleteProfileRole, AthleteRelationship, Position, AthleteUserRelationship, SocialMediaField, ProfileVisibilitySetting } from '@/../types/interfaces';
import { AclPermissionValues } from '../../../types/permissions';
import { Roles, Relationships } from '../../../types/constants/athlete';
import { AthleteProfileRelationshipName, CountryCode, BaseSoccerPosition, LanguageCode, Length, Mass, DominantSide } from '@/../types/enums';
import { AgeGroup, Gender, Under13AgeGroup } from '@best-athletes/ba-types';
import { ageToAgeGroup } from "@/../types";
import { AthleteProfileStatus } from '@/../types/enums/athlete-profile-status.ts';
import { getDifference, getTodayUTC } from '@/helpers/date';

import { UserProfileModel } from '../UserProfileModel';
import { grantUserAcls, revokeUserAcls } from '@/services/AccessControlledService';
import { RecruitingProfileModel } from './RecruitingProfileModel';
import { VideoModel } from '@/models/video/VideoModel';
import { VideoClipModel } from '../video/VideoClipModel';

export class AthleteProfileModel extends UserProfileModel {
	organizationId?: string | null = null;
	profileStatus?: AthleteProfileStatus | null = null;
	birthDate: Date | null = null;
	city: string = "";
	country: string = "";
	phone: number = null;
	demo: boolean = false;						// indicates if this is a demo athlete profile
	gender: Gender | null = null;
	gradYear: string = "";
	height: number = 0;
	heightUnit: Length = Length.CENTIMETER;
	weight: number = 0;
	weightUnit: Mass = Mass.KILOGRAM;
	sports: Position[] = [];
	tosAgreed: boolean = false;
	dominantSide: DominantSide; 

	// Recruiting Profile Settings
	recruitingProfileComplete: boolean = false;
	primaryPosition: BaseSoccerPosition | null = null;
	secondaryPosition: BaseSoccerPosition | null = null;
	shortBio: string = "";
	highestLevelPlayed: string = "";
	currentLevel: string = "";
	previousClubs: string = "";
	languagesSpoken: LanguageCode[] = [];
	citizenships: CountryCode[] = [];
	socialMedia: SocialMediaField[] = [];
	highschoolGPA: number = null;
	highschoolName: string = "";
	highschoolGradYear: number =  null;
	SATScore: number = null;
	anticipatedStudy: string = "";

	videos: VideoModel[] = [];
	clips: VideoClipModel[] = [];

	sharingUrlId: string;

	get ProfileHasVisibility(): boolean{
		const atLeastOneVisible = [
			this.profileVisibility['recruiter'].firstName,
			this.profileVisibility['recruiter'].lastName,
			this.profileVisibility['recruiter'].gradYear,
			this.profileVisibility['recruiter'].email,
			this.profileVisibility['recruiter'].pictureUrl,
			this.profileVisibility['recruiter'].birthDate,
			this.profileVisibility['recruiter'].city,
			this.profileVisibility['recruiter'].country,
			this.profileVisibility['recruiter'].phone,
			this.profileVisibility['recruiter'].gender,
			this.profileVisibility['recruiter'].height,
			this.profileVisibility['recruiter'].dominantSide,
			this.profileVisibility['recruiter'].weight,
			this.profileVisibility['recruiter'].sports,
		].reduce((a,b) => a || b);
		return atLeastOneVisible;
	}
	profileVisibility: ProfileVisibilitySetting<RecruitingProfileModel> = null;
	get Age(): number | undefined{
		if(!this.birthDate) return;
		const age = getDifference(this.birthDate, getTodayUTC(), "years");
		return Math.floor(age.years);
	}
	get AgeGroup(): AgeGroup | Under13AgeGroup{
		return ageToAgeGroup(this.Age, { allowUnder13: true });
	}
	get Position(): string | undefined{
		if(!Array.isArray(this.sports)) return;
		const [ sport ] = this.sports;
		if(!sport) return;
		return sport.primaryPosition;
	}

	public readonly userRelationships: AthleteUserRelationship[] = [];

	isAthlete(userId: string): boolean{
		const rel = this.userRelationships.find(u => u.userId === userId);
		if(rel === undefined) return false;
		if(rel.relationship.name === AthleteProfileRelationshipName.Athlete) return true;
		return false;
	}
	isParent(userId: string): boolean{
		const rel = this.userRelationships.find(u => u.userId === userId);
		if(rel === undefined) return false;
		if(rel.relationship.name === AthleteProfileRelationshipName.Parent) return true;
		return false;
	}
	getUser(userId: string): AthleteUserRelationship{
		return this.userRelationships.find(u => u.userId === userId);
	}
	/**
	 * Sets a user's relationship to athlete and applies the correct UserACLs from the role.
	 */
	setUser(userId: string, userRelation: AthleteUserRelationship): void{
		if (userRelation.relationship && userRelation.role === undefined) {
			userRelation.role = AthleteProfileModel.Roles.find(role => role.name === userRelation.relationship.defaultRole);
		}
		if (userRelation.role !== undefined) {
			revokeUserAcls(this, AclPermissionValues, { userId: userRelation.userId });
			grantUserAcls(this, userRelation.role.permissions, { userId: userRelation.userId });
		}

		// Update the existing relationship if there is one between the users
		const userIndex = this.userRelationships.findIndex(u => u.userId === userId);
		if(userIndex > -1){
			this.userRelationships.splice(userIndex, 1, userRelation);
		}
		// Otherwise insert a new relationship
		else{
			this.userRelationships.push(userRelation);
		}
	}
	removeUser(userId: string): void{
		const userAclIndex = this.users.findIndex(u => u.id === userId);
		if (userAclIndex > -1){
			this.users.splice(userAclIndex, 1);
		}
		const userIndex = this.userRelationships.findIndex(u => u.userId === userId);
		if(userIndex > -1){
			this.userRelationships.splice(userIndex, 1);
		}
	}

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (obj['birthDate'] !== undefined && obj['birthDate'] !== null) 
			this.birthDate = new Date(obj['birthDate']);
		if (obj['videos']) {
			this.videos = obj['videos'].map((video: any) =>  new VideoModel().load(video));
		}
		if (obj['clips']) {
			this.clips = obj['clips'].map((clip: any) =>  new VideoClipModel().load(clip));
		}
		return this;
	}

	get PublicSharingUrl(): string {
		return `/athlete/${this.id}/${this.sharingUrlId}`
	}

	public static Roles: AthleteProfileRole[] = Roles;
	public static Relationships: AthleteRelationship[] = Relationships;
}
