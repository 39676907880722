import { CrudApi } from './CrudApi';


class RecurringNotificationApi extends CrudApi<any>{
	constructor(){
		super('recurringNotification', null);
	}

	async declineInvitation(key: string): Promise<void> {
		const res = await CrudApi.Api(c => c.post('/recurringNotification/stop/' + key, undefined, { validateStatus: () => true }))
		console.log(res)
	}
}
export const recurringNotificationApi = new RecurringNotificationApi();