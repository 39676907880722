// List of actions available from the Eventbrite the webhook
export enum EventbriteActionType {
	AttendeeUpdated = 'attendee.updated',
	BarcodeCheckedIn = 'barcode.checked_in',
	BarcodeUnCheckedIn = 'barcode.un_checked_in',
	EventCreated = 'event.created',
	EventPublished = 'event.published',
	EventUnpublished = 'event.unpublished',
	EventUpdated = 'event.updated',
	OrderPlaced = 'order.placed',
	OrderRefunded = 'order.refunded',
	OrderUpdated = 'order.updated',
	OrganizerUpdated = 'organizer.updated',
	TicketClassCreated = 'ticket_class.created',
	TicketClassDeleted = 'ticket_class.deleted',
	TicketClassUpdated = 'ticket_class.updated',
	VenueUpdated = 'venue.updated',
}