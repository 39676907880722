
import { Component, Mixins } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { AuthMixin, AppHostnameMixin } from "@/mixins";

import { AthleteAccordionSettings } from "@/views/settings/AthleteAccordionSettings.vue";
import { CoachAccordionSettings } from "@/views/settings/CoachAccordionSettings.vue";

@Component({
	components: {
		Page,
		AthleteAccordionSettings,
		CoachAccordionSettings
	}
})
export class AccountSettings extends Mixins(
	AuthMixin,
	AppHostnameMixin,
) {
	get SettingComponent(): typeof AthleteAccordionSettings | typeof CoachAccordionSettings{
		switch (this.getAppType) {
		case "coach":
			return CoachAccordionSettings
		default:
			return AthleteAccordionSettings;
		}
	}
}
export default AccountSettings;
