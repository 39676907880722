
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mdiVideo } from '@mdi/js';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import { formatDurationForTimeline } from '@/helpers';
import { Tag } from '@/models/tag/Tag';

@Component({
	components: { },
})
export default class ClipPlayerClipDetails extends Vue{
	mdiVideo = mdiVideo;
	@Prop() value: VideoClipModel;
	valid = false;

	get ClipName(): string{
		if(!this.value) return '--';
		return this.value.name;
	}
	get ClipDurationStr(): string{
		if(!this.value) return '0:00';
		return formatDurationForTimeline(this.value.duration);
	}
	get ClipCategories(): string[]{
		if(!this.value) return [];
		return this.value.category.filter(c => c.trim() !== '');
	}
	get VideoTags(): Tag[]{
		if(!this.value) return [];
		return this.value.tags;
	}
	get ClipDescription(): string{
		if(!this.value) return '--';
		return this.value.text;
	}
	get HasClip(): boolean{
		return this.value !== null && this.value !== undefined;
	}
}
