/* scouting-report-metrics.ts */
/* Defines metrics used in scouting reports */

import { getEnumValues } from './get-enum-values';

export enum FourCornersMetric {
	Technical='technical',
	Tactical='tactical', 
	Physical='physical',
	Mental='mental',
}
export const FourCornersMetricValues = getEnumValues<FourCornersMetric>(FourCornersMetric);

export enum SixMomentsMetric {
	AttackingOrganization='Attacking Organization', 
	AttackingTransition='Attacking Transition', 
	AttackingSetPlays='Attacking Set Plays', 
	DefendingOrganization='Defending Organization', 
	DefendingTransition='Defending Transition', 
	DefendingSetPlays='Defending Set Plays',
}
export const SixMomentsMetricValues = getEnumValues<SixMomentsMetric>(SixMomentsMetric);

export enum CrispMetric {
	Competitive='competitive', 
	Resilience='resilience', 
	Intelligence='intelligence', 
	Speed='speed', 
	Presence='presence',
}
export const CrispMetricValues = getEnumValues<CrispMetric>(CrispMetric);