
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { CalendarApi, CalendarEventsApi } from '@/api/CalendarApi';
import { CalendarModel } from '@/models/calendar/CalendarModel';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { PageState } from '@/models/PageState';
import { getDateInNDays, getBeginningOfMonth, formatDateHyphensYYYYMMDD, dateIsBetween } from '@/helpers/date';
import { RRule, RRuleSet, Weekday } from 'rrule';
import { GameResultApi } from '@/api/GameResultApi';
import { GameResultModel } from '@/models/calendar/GameResultModel';
import { DateTime } from "luxon";
import { FeatureFlagMixin } from '@/mixins';
import { BehaviorSubject } from 'rxjs';

export enum ModifyMode{
	All,
	ThisAndFollowing,
	ThisOnly,
}
export interface CalendarUrls {
	ics: string | null,
	webcal: string | null,
	google: string | null,
	outlook: string | null,
}

export type GetGameResultIdFn = (event: CalendarEventModel, focusDate: string | Date) => string;

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class CalendarProvider extends Mixins(FeatureFlagMixin){
	@Prop({ default: 'div' }) as: string;

	@Prop({ type: Boolean, default: false }) private loadEvents: boolean;
	@Prop({ default: null }) private eventId: string | null;
	@Prop({ type: Boolean, default: false }) private readOnly: boolean;
	@Prop({ required: true }) private resource: string;
	@Prop({ required: true }) private parentId: string;
	@Watch('parentId', { immediate: true }) parentIdChanged(parentId: string): void{
		if(parentId !== undefined) this.loadCalendar();
	}
	get Today(): Date{
		return new Date();
	}
	@Prop({ default: () => getBeginningOfMonth() }) private start: Date;
	@Prop({ default: () => getDateInNDays(30) }) private end: Date;

	@Prop({ default: () => new BehaviorSubject<CalendarEventModel[]>([])}) monthViewEventList$: BehaviorSubject<CalendarEventModel[]>

	pageState: PageState = new PageState('Initial');
	get SlotProps(): Record<string, any>{
		return {
			pageState: this.pageState,
			calendar: this.calendar,
			events: this.events,
			event: this.event,
			gameResult: this.gameResult,
			CalendarApi: this.CalendarApi,
			CalendarEventsApi: this.CalendarEventsApi,
			GameResultApi: this.GameResultApi,
			getGameResultId: this.getGameResultId,
			updateGameResult: (...args: [any]) => this.updateGameResult(...args),
			loadCalendar: () => this.loadCalendar(),
			focusDate: this.focusDate,
			updateFocusDate: (val: string) => this.updateFocusDate(val),
			MonthViewEventList: this.MonthViewEventList,
			FocusMonthEvents: this.FocusMonthEvents,
			CalendarUrls: this.CalendarUrls,
		};
	}

	@Prop({ default: () => formatDateHyphensYYYYMMDD(new Date()) }) focusDate: string;
	updateFocusDate(val: string): void{
		this.focusDate = val;
	}
	/**
	 * Month Start & End form a range of the current month +/- 7 days to fill in the Calendar's Month view
	 */
	get MonthViewRange(): { start: Date, end: Date}{
		const startOfMonthUTC = new Date(this.focusDate);
		startOfMonthUTC.setUTCDate(-7);
		const endOfMonthUTC = new Date(this.focusDate);
		endOfMonthUTC.setUTCMonth(endOfMonthUTC.getUTCMonth() + 1);
		endOfMonthUTC.setUTCDate(7);
		return {
			start: startOfMonthUTC,
			end: endOfMonthUTC,
		}
	}
	get MonthViewEventList(): any [] | CalendarEventModel[]{
		const eventList =  this.computeEventsBetween(this.events, this.MonthViewRange.start, this.MonthViewRange.end);
		this.monthViewEventList$.next(eventList);
		return eventList;
	}
	get FocusMonthRange(): { start: Date, end: Date}{
		const startOfMonthUTC = new Date(this.focusDate);
		startOfMonthUTC.setUTCDate(1);
		const endOfMonthUTC = new Date(this.focusDate);
		endOfMonthUTC.setUTCMonth(endOfMonthUTC.getUTCMonth() + 1);
		endOfMonthUTC.setUTCDate(1);
		return {
			start: startOfMonthUTC,
			end: endOfMonthUTC,
		}
	}
	get FocusMonthEvents(): any [] | CalendarEventModel[]{
		return this.computeEventsBetween(this.events, this.FocusMonthRange.start, this.FocusMonthRange.end);
	}

	computeEventsBetween(eventList: CalendarEventModel[], start: Date, end: Date): CalendarEventModel[]{
		return eventList
			// Convert each event into a list of recurrences
			.map(calendarEvent => {

				// Return empty list if there is no recurrence rule set
				if(calendarEvent.recurrenceRule === null && calendarEvent.inclusionDates.length === 0){
					return [ calendarEvent ];
				}

				// Create new rule set
				const ruleSet = new RRuleSet();

				if (calendarEvent.recurrenceRule !== null) {
					const ruleOptions = calendarEvent.recurrenceRule.RRuleOptions;

					// Ensure there is a start and end date
					if (ruleOptions.dtstart === undefined) {
						ruleOptions['dtstart'] = start;
					}
					if (ruleOptions.until === undefined) {
						ruleOptions['until'] = end;
					}

					
					if (this.feature('HIN-1412-weekday-timezone-shift') && ruleOptions.byweekday) {
						const currentTimezoneOffset = new Date().getTimezoneOffset();
						const eventStartWeekday = DateTime.fromJSDate(calendarEvent.start).toUTC().weekday;
						// The number of minutes returned by getTimezoneOffset() is positive 
						// if the local time zone is behind UTC, and negative if the local time zone is ahead of UTC.
						// Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
						const timezoneDifferenceWeekday = DateTime.fromJSDate(calendarEvent.start).plus(currentTimezoneOffset * -1).toUTC().weekday;
						
						/**
						 * When days are selected they are not timezone shifted so they need to be adjusted when output.
						 * Example:
						 *  - With an EST timezone (-05:00)
						 *  - The date stored in the db is next day when selecting 7:00PM because
						 * 	  7:00PM + 300 minutes = 12:00AM, everything good ; which is not the issue
						 *  - Now when the recurrence rule is calculating days it selects days on the UTC calendar
						 *    because we stored it in UTC format
						 *  - Now lets say we have selected the recurrence rule to be on Every Friday
						 *  - So our 12:00AM on a Friday (in UTC) is output as 7PM in the current calendar the previous day
						 *  - 12AM was adjusted for timezone shift but Friday wasn't adjusted
						 */

						// Shift up by a day
						if (eventStartWeekday > timezoneDifferenceWeekday) {
							// Shift all the days up by one
							ruleOptions.byweekday = calendarEvent.recurrenceRule.weekdays.map(
								dayOfWeek => Weekday.fromStr(dayOfWeek).weekday === 6 ? 0 : (new Weekday(Weekday.fromStr(dayOfWeek).weekday + 1))
							)
						}
					}
					
					ruleSet.rrule(new RRule(ruleOptions));
				}

				// Add included dates
				for (const includeDate of calendarEvent.inclusionDates) {
					ruleSet.rdate(new Date(Date.UTC(includeDate.getUTCFullYear(), includeDate.getUTCMonth(), includeDate.getUTCDate())));
				}
				// Add excluded dates
				for (const excludeDate of calendarEvent.exceptionDates) {
					ruleSet.exdate(new Date(Date.UTC(excludeDate.getUTCFullYear(), excludeDate.getUTCMonth(), excludeDate.getUTCDate())));
				}
				// Add the exclude rules
				for (const excludeRule of calendarEvent.exceptionRules) {
					ruleSet.exrule(excludeRule.RRule);
				}

				return ruleSet.between(start, end, true).map(start => {
					const eventOccurence = new CalendarEventModel().load(calendarEvent.copy());
					eventOccurence.start.setUTCMonth(start.getUTCMonth());
					eventOccurence.start.setUTCFullYear(start.getUTCFullYear());
					eventOccurence.start.setUTCDate(start.getUTCDate());
					eventOccurence.end.setUTCFullYear(start.getUTCFullYear());
					eventOccurence.end.setUTCMonth(start.getUTCMonth());
					eventOccurence.end.setUTCDate(start.getUTCDate());
					return eventOccurence;
				});
			})
			// Combine lists into one
			.reduce((a, b) => [...a, ...b], [])
			// Return only events starting within the range
			.filter(event => dateIsBetween(event.start, start, end));
	}

	async loadCalendar(): Promise<void>{
		if(!this.pageState.IsInitial){
			this.pageState = new PageState('Loading');
		}
		try{
			await this.initCalendar();
			if(this.loadEvents === true){
				await this.findEventsForRange(this.start, this.end);
			}
			if(this.eventId !== null){
				await this.loadEvent();
			}
			this.pageState = new PageState('Ready');
		}catch(e){
			this.pageState = PageState.getPageState(e);
		}
	}

	@Prop({ default: null }) calendar: CalendarModel | null;
	inputCalendar(calendar: CalendarModel): void{
		this.calendar = calendar;
		this.$emit('update:calendar', calendar);
	}
	events: CalendarEventModel[] = [];
	event: CalendarEventModel | null = null;
	gameResult: GameResultModel | null = null;

	async updateGameResult(gameResult: GameResultModel | null): Promise<void>{
		if(gameResult !== null){
			gameResult.id = this.GameResultId;
			gameResult.parentId = this.CalendarId;
			this.gameResult = gameResult;
		}else{
			this.gameResult = new GameResultModel().load({
				id: this.GameResultId,
				parentId: this.CalendarId,
			});
		}
		await this.GameResultApi.save(this.gameResult);
	}

	get CalendarId(): string | null{
		if(this.calendar) return this.calendar.id;
		return null;
	}

	async initCalendar(): Promise<void>{
		if(this.readOnly){
			const response = await this.CalendarApi.findAllWithAccess();
			// REVIEW: How to handle multiple calendars when read only? 
			const calendar = response[0] ?? null;
			this.inputCalendar(calendar);
		}else{
			const calendar = await this.CalendarApi.findOrCreateCalendarByName();
			this.inputCalendar(calendar);
		}
	}
	async findEventsForRange(start: Date, end: Date): Promise<void>{
		const events = await this.CalendarEventsApi.findAllWithAccess();
		this.events = events;
	}
	async loadEvent(): Promise<void>{
		this.event = await this.CalendarEventsApi.findById(this.eventId);
		this.gameResult = await this.GameResultApi.findById(this.GameResultId);
	}

	get CalendarApi(): CalendarApi{
		return new CalendarApi(this.resource, this.parentId);
	}
	get CalendarEventsApi(): CalendarEventsApi | null{
		if(this.CalendarId === null) return null;
		return new CalendarEventsApi(this.resource, this.parentId, this.CalendarId);
	}
	get GameResultApi():GameResultApi | null{
		if(this.CalendarId === null) return null;
		if(this.event === null) return null;
		return new GameResultApi('team', this.parentId, this.CalendarId, this.event.RootId);
	}
	get GameResultId(): string{
		return this.getGameResultId(this.event, this.focusDate);
	}
	getGameResultId: GetGameResultIdFn = (event, focusDate) => {
		return `${event.RootId}_${formatDateHyphensYYYYMMDD(new Date(focusDate))}`
	}

	get CalendarUrls(): CalendarUrls {
		return {
			ics: this.ICSUrl,
			webcal: this.WebCalUrl,
			google: this.GoogleCalendarURL,
			outlook: this.OutlookURL,
		}
	}

	get ICSUrl() : string | null {
		return this.CalendarId !== null ? this.CalendarApi.generateICSURL(this.CalendarId) : null; 
	}

	get WebCalUrl() : string | null {
		// Replaces HTTP(s) with webcal
		const regex = /https?/i
		return this.CalendarId !== null ? this.ICSUrl.replace(regex, 'webcal') : null; 
	}

	get GoogleCalendarURL(): string | null {
		return this.WebCalUrl !== null ? `https://calendar.google.com/calendar/u/0/r?cid=${this.WebCalUrl}` : null;
	}

	get OutlookURL(): string | null {
		return this.WebCalUrl !== null ? `https://outlook.live.com/calendar/0/addcalendar?url=${this.WebCalUrl}` : null
	}

}
