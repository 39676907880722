
import { Component, Prop, Vue } from 'vue-property-decorator';
import { subscriptionApi } from "@/api/SubscriptionApi";
import { notificationStore } from '@/store';
import { NotificationModel } from '@/models/notification/NotificationModel';
import { SubscriptionModel } from "@/models/stripe/SubscriptionModel";
import { SubscriptionPlanModel } from '@/models/subscriptionPlan/SubscriptionPlanModel';
import * as ProductKey from '@/../types/constants/product-keys';
import { AthleteSettingsSubscriptionForm } from '@/../types/constants/web_client_user.routes';
import { AvailableFeatures } from '@/../types/interfaces';
import { Route } from 'vue-router';
import { BASIC_PLAN_KEY, COMPLETE_PLAN_KEY, COMPLETE_PLAN_KEY_US, PREMIUM_PLAN_KEY, PREMIUM_PLAN_KEY_US, FREE_PLAN_KEY } from '@/../types/constants/plan-keys';

/**
 * This HOC is intended to provide all relevant subscription information (current plan, expiry, etc.) for a given parent id.
 * 
 * @parentId: The ID of the parent domain object you're querying subscription info for.
 */
@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class SubscriptionInfoProvider extends Vue{
	@Prop({ default: 'div' }) as: string;
	@Prop({ required: true }) parentId: string;
	@Prop({ required: true }) resource: string;

	planLoading: boolean = false;
	planInitialized: boolean = false;
	plan: SubscriptionPlanModel | null = null;
	subscription: SubscriptionModel | null = null;
	features: AvailableFeatures | null = null;

	/**
	 * Handing down a route like this allows the link to behave like a native html anchor.
	 * this allows behaviour like middle mouse clicking to open the link in a new tab
	 * but we need to know if it's an impersonation link or not for it to work correctly
	 */
	get GoToPlansRoute(): Partial<Route>{
		return {
			name: AthleteSettingsSubscriptionForm,
			params:{
				...this.$route.params,
			}
		};
	}

	get PlanIsFree(): boolean{
		if(this.plan === null) return false;
		return this.plan.free;
	}

	async loadPlanInformation(): Promise<void>{
		this.planLoading = true;
		const {subscription, features} = await subscriptionApi.getSubscriptionAndFeatures(this.resource, this.parentId).catch((error) => {
			console.error("Failed to load plans", error);
			notificationStore.pushErrorNotification(new NotificationModel("Something went wrong. Please try again later"));
			return {subscription: null, features: null};
		});

		this.subscription = subscription;
		this.features = features;
		this.plan = this.subscription === null ? null : this.subscription.plan;

		if(this.subscription !== null) this.planInitialized = true;
		this.planLoading = false;

		this.$emit('loaded', {subscription: this.subscription, features: this.features})
	}

	created(): void{
		this.loadPlanInformation();
	}


	get SlotProps(): Record<string, any>{
		return {
			planLoading: this.planLoading,
			planInitialized: this.planInitialized,
			plan: this.plan,
			PlanIsFree: this.PlanIsFree,
			ComparitiveDataFeatureAvailable: this.ComparitiveDataFeatureAvailable,
			SchoolSearchFeatureAvailable: this.SchoolSearchFeatureAvailable,
			VideoMinutesAvailable: this.VideoMinutesAvailable,
			UploadAvailable: this.UploadAvailable,
			SPQsAvailable: this.SPQsAvailable,
			SPQFeatureAvailable: this.SPQFeatureAvailable,
			VerifiedAssessmentsAvailable: this.VerifiedAssessmentsAvailable,
			VerifiedAssessmentFeatureAvailable: this.VerifiedAssessmentFeatureAvailable,
			ProfileSharesAvailable: this.ProfileSharesAvailable,
			YoutubeUploadsAvailable: this.YoutubeUploadsAvailable,
			VideoClipsAvailable: this.VideoClipsAvailable,
			VideoClipFeatureAvailable: this.VideoClipFeatureAvailable,
			subscription: this.subscription,
			features: this.features,
			loadPlanInformation: () => this.loadPlanInformation(),
			useFeature: (product: string) => this.useFeature(product),
			GoToPlansRoute: this.GoToPlansRoute,
			IsStarterPlan: this.IsStarterPlan,
			IsBasicPlan: this.IsBasicPlan,
			IsCompletePlan: this.IsCompletePlan,
			IsPremiumPlan: this.IsPremiumPlan,
		};
	}

	async useFeature(product: string): Promise<{ code?: string, url?: string }>{
		if(product === ProductKey.VERIFIED_ASSESSMENT){
			const result = await subscriptionApi.redeemVerifiedAssessment(this.resource, this.parentId);
			await this.loadPlanInformation();
			return result;
		}
		if(product === ProductKey.SPORTS_PERSONALITY_QUESTIONAIRE){
			const result = await subscriptionApi.redeemSPQ(this.resource, this.parentId);
			await this.loadPlanInformation();
			return result;
		}
	}

	/**
	 * Returns if the Comparitive Data Feature is available
	 */
	get ComparitiveDataFeatureAvailable(): boolean {
		// TODO[epic=payments] Re-enable payment checks for comparative data summary
		// return this.planInitialized && this.features !== null ? 
		// 	this.features.ATHLETE_COMPARITIVE_DATA.total : false;
		return this.planInitialized && true;	
	}

	/**
	 * Returns if the School Search Feature is available
	 */
	get SchoolSearchFeatureAvailable(): boolean {
		return this.planInitialized && this.features !== null ? 
			this.features.SCHOOL_SEARCH.total : false;
	}

	/**
	 * Returns number of Video Minutes Available
	 */
	get VideoMinutesAvailable(): number {
		if (this.planInitialized && this.features !== null) {
			if (this.features.ATHLETE_VIDEO_MINUTES.total === 0){
				return 0
			}
			const totalMinutes = this.features.ATHLETE_VIDEO_MINUTES.total;
			const usedMinutes = this.features.ATHLETE_VIDEO_MINUTES.used;
			
			return totalMinutes - usedMinutes;
		}
		return 0;
	}
	/**
	 * Returns if Athlete has any Video Minutes Available to them
	 */
	get UploadAvailable(): boolean {
		return this.VideoMinutesAvailable > 0 ? true : false;
	}

	/**
	 * Returns number of SPQs that can be redeemed
	 */
	get SPQsAvailable(): number {
		if (this.planInitialized && this.features !== null) {
			const total = this.features.YEARLY_SPQ_ASSESSMENTS.total;
			const used = this.features.YEARLY_SPQ_ASSESSMENTS.used;

			return total - used;
		}
		return 0;
	}
	/**
	 * Returns if Athlete has at least 1 SPQ that they can redeem
	 */
	get SPQFeatureAvailable(): boolean {
		return this.SPQsAvailable > 0 ? true : false;
	}

	/**
	 * Returns if user has at least 1 video clip available
	 */
	get VideoClipFeatureAvailable(): boolean {
		return this.VideoClipsAvailable === null || this.VideoClipsAvailable > 0;
	}
	

	/**
	 * Returns number of Verified Assessment(s) that can be redeemed
	 */
	get VerifiedAssessmentsAvailable(): number {
		if (this.planInitialized && this.features !== null) {
			const total = this.features.YEARLY_VERIFIED_ASSESSMENTS.total;
			const used = this.features.YEARLY_VERIFIED_ASSESSMENTS.used;

			return total - used;
		}

		return 0;
	}
	/**
	 * Returns if Athlte has at least 1 Verifed Assessment Available
	 */
	get VerifiedAssessmentFeatureAvailable(): boolean {
		return this.VerifiedAssessmentsAvailable > 0 ? true : false;
	}

	get ProfileSharesAvailable(): number | null {
		if (this.planInitialized && this.features !== null) {
			if (this.features.PROFILE_SHARE_LIMIT.total === null){
				return null
			} else if (this.features.PROFILE_SHARE_LIMIT.total === 0){
				return 0
			}
			const total = this.features.PROFILE_SHARE_LIMIT.total;
			const used = this.features.PROFILE_SHARE_LIMIT.used;
			return total - used;
		}
		return 0;
	}

	get YoutubeUploadsAvailable(): number {
		if (this.planInitialized && this.features !== null) {
			if (this.features.YOUTUBE_VIDEO_LIMIT.total === 0){
				return 0
			}
			const total = this.features.YOUTUBE_VIDEO_LIMIT.total;
			const used = this.features.YOUTUBE_VIDEO_LIMIT.used;
			return total - used;
		}
		return 0;
	}

	get VideoClipsAvailable(): number | null {
		if (this.planInitialized && this.features !== null) {
			if (this.features.VIDEO_CLIP_LIMIT.total === null){
				return null
			} else if (this.features.VIDEO_CLIP_LIMIT.total === 0){
				return 0
			}
			const total = this.features.VIDEO_CLIP_LIMIT.total;
			const used = this.features.VIDEO_CLIP_LIMIT.used;
			return total - used;
		}
		return 0;
	}

	get IsStarterPlan(): boolean {
		return (this.planInitialized && this.features !== null) ? 
			this.plan.key === FREE_PLAN_KEY : false
	}
	get IsBasicPlan(): boolean {
		return (this.planInitialized && this.features !== null) ? 
			this.plan.key === BASIC_PLAN_KEY : false
	}
	get IsCompletePlan(): boolean {
		return (this.planInitialized && this.features !== null) ? (
			this.plan.key === COMPLETE_PLAN_KEY) || (this.plan.key === COMPLETE_PLAN_KEY_US) : false
	}
	get IsPremiumPlan(): boolean {
		return (this.planInitialized && this.features !== null) ? 
			(this.plan.key === PREMIUM_PLAN_KEY) || (this.plan.key === PREMIUM_PLAN_KEY_US) : false
	}
}
