
import { Cropper, CircleStencil, Coordinates } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiImage, mdiCropLandscape, mdiPencil, mdiUpload } from '@mdi/js';
import { VuetifyMixin } from '../../mixins';
import { fileApi } from '../../api/FileApi';
import { ImageMimeTypesValues } from '../../../types/enums';

type UploadState = "ready" | "cropping" | "loading" | "completed" | "error";

@Component({
	components: {
		Cropper,
		CircleStencil,
	}
})
export default class ImageUploadDialog extends Mixins(VuetifyMixin) {
		mdiImage = mdiImage;
		mdiPencil = mdiPencil;
		mdiCropLandscape = mdiCropLandscape;
		mdiUpload = mdiUpload;
		@Prop({ type: String, default: "Crop Your Photo" }) title: string;
		@Prop({ type: Number, default: 427 }) height: number;
		@Prop({ type: Boolean, default: true }) hideButton: boolean;
		selectedFile: File = null;
		progress: number = 2;
		errorMessage: string = '';
		successMessage: string = '';
		showDragOver: boolean = false;
		get uploading(): boolean{
			return this.uploadState === 'loading';
		}
		uploadedImage: string = null;
		uploadState: UploadState = "ready";

		get ActivatorId(): string{
			return `image-upload-${this['_uid']}`;
		}
		get ActivatorId2(): string{
			return `image-upload-2-${this['_uid']}`;
		}

		get BtnStyle(): Record<string,any>{
			return {

			};
		}

		get HeaderStyles(): Record<string, any>{
			return {
				'font-weight': '500',
				'font-size': '16px',
				'color': this.getColor('baColorPrimaryText'),
				'background-color': this.getColor('baColorAppForeground'),
			};
		}

		isHovered: boolean = false;
		file: File | null = null;
		imagePreview: string | null = null;
		fileReader = new FileReader();
		dragover(): void { this.isHovered = true; }
		dragleave(): void { this.isHovered = false; }

		preventDefault = function(e: Event): void{
			// Allow drag & drop on our labels
			if(e.target['tagName'] !== "INPUT"){
				e.preventDefault();
			}
		};
		mounted(): void{
			window.addEventListener("dragover",this.preventDefault,false);
			window.addEventListener("drop",this.preventDefault,false);
		}
		beforeDestroy(): void{
			window.removeEventListener("dragover",this.preventDefault,false);
			window.removeEventListener("drop",this.preventDefault,false);
		}

		$refs:{
			cropper: Cropper;
		};

		cropResult = {
			coordinates: null,
			image: null,
		};

		onCropChange({ coordinates, image }: { coordinates: Coordinates, image: Record<string,number> }): void {
			this.cropResult = {
				coordinates,
				image
			};
		}

		async getCropResult(type: string = "image/png"): Promise<File>{
			const { canvas } = this.$refs.cropper.getResult();
			const croppedImg = await new Promise<Blob>((resolve) => canvas.toBlob(resolve, type))
			return new File([croppedImg], 'image.png', {
				type
			});
		}

		handleFileChange($event: any): void {
			this.isHovered = false;
			this.imagePreview = null;
			const files: FileList = $event.target.files;
			this.file = files.item(0);
			if (this.isAllowedMimetype(this.file.type)) {
				this.previewImage(this.file);
			}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.input(this.file);
			// Reset the file upload
			$event.target.value = ''
		}

		input(value: File): void{
			this.file = value;
			this.updateShow(true);
		}

		isAllowedMimetype(givenMIMEType: string): boolean{
			return this.AllowedMimeTypes.findIndex(allowed => givenMIMEType.includes(allowed)) !== -1;
		}
		previewImage(file): void{
			this.fileReader.onload = ($event: any) => {
				this.imagePreview = $event.target.result;
				this.uploadState = 'cropping';
			}
			this.fileReader.readAsDataURL(file);
		}
		reset(): void{
			this.file = null;
			this.imagePreview = null;
			this.progress = 0;
			this.uploadState = "ready";
		}

		get AllowedMimeTypes(): string[]{
			return ImageMimeTypesValues;
		}

		@Prop({ type: String, default: null }) show: boolean;
		updateShow(show: boolean): void{
			this.$emit('update:show', show);
			if(show === false){
				this.reset();
			}else{
				this.showDragOver = false;
			}
		}
		@Prop({ type: String, default: null }) fileUrl: string | null;
		@Prop({ type: String, default: '' }) prefix: string;

		onDrop(event: DragEvent): void {
			this.file = event.dataTransfer.files[0];
			this.showDragOver = false;
		}
		onDragOver(): void {
			this.showDragOver = true;
		}
		onDragLeave(): void {
			this.showDragOver = false;
		}

		async upload(): Promise<void> {
			this.progress = 0;
			this.uploadState = "loading";
			this.errorMessage = null;
			this.successMessage = null;

			try {
				const file = await this.getCropResult();
				const result = await fileApi.upload(file, this.prefix, event => {
					this.progress = Math.round((100 * event.loaded) / event.total);
				});

				this.uploadedImage = result.url;
				this.$emit('update:fileUrl', result.url);

				this.reset();
				setTimeout(() => this.updateShow(false), 300);
			} catch (e) {
				console.error('File Upload Error', e);
				this.uploadState = "error";
			} finally {
				this.uploadState = "completed";
			}
		}
}
