import { getEnumValues } from './get-enum-values';
export enum InvitationStatus {
  Accepted='Accepted',
  Declined='Declined',
  Pending='Pending',
  None='None',
}
export const InvitationStatusValues = getEnumValues<InvitationStatus>(InvitationStatus);

export enum InvitationType {
  Platform='Platform',
  Team='Team',
}
export const InvitationTypeValues = getEnumValues<InvitationType>(InvitationType);