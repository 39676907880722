
import { Component, Prop, Mixins } from "vue-property-decorator";
import CalendarProvider from '@/components/hoc/CalendarProvider.vue';
import { CurrentTeamMixin } from '@/mixins';
import TeamEventCreateForm from '@/components/teams/events/TeamEventCreateForm.vue';
import { CalendarModel } from '@/models/calendar/CalendarModel';

@Component({
	components: {
		CalendarProvider,
		TeamEventCreateForm,
	},
})
export default class TeamEventCreate extends Mixins(CurrentTeamMixin){
	@Prop() teamId: string;
	@Prop() teamEventId: string;
	@Prop({ default: null }) focusDate: string;
	@Prop({ type: String, default: ''}) largeCalendar;
	calendar: CalendarModel = null;

	mounted(): void {
		if (this.$route.query.focusDate !== undefined && this.$route.query.focusDate !== null) {
			this.focusDate = this.$route.query.focusDate as string;
		}
	}

	get CalendarId(): string | undefined{
		if(this.calendar === null) return undefined;
		return this.calendar.id;
	}
	get BackToTeamDashboard(): boolean{
		if (this.largeCalendar === 'true'){
			return true
		} else{
			return false
		}
	}
}
