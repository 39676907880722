
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, MyCoachMixin, MyAthleteMixin, AuthMixin } from '../../mixins';
import Comment from '@/components/courses/Comment.vue';
import DiscussionProvider from '../hoc/DiscussionProvider.vue';


@Component({
	components: {
		Comment,
		DiscussionProvider,
	}
})
export default class Discussion extends Mixins(VuetifyMixin, MyCoachMixin, MyAthleteMixin, AuthMixin) {
	@Prop({ type: Boolean, default: false }) private athlete: boolean;
	@Prop({ type: Boolean, default: false }) private coach: boolean;
	@Prop({ type: String, default: null }) profileId: string | null;
	@Prop({ type: String, default: null }) discussionId: string | null;
	@Prop({ type: Object, default: () => ({}) }) submitParams: Record<string, any>;
}
