import { RecurrenceRuleFrequency, TeamEventType } from '../../../types/enums';
import { CalendarEventParticipant } from '../../../types/interfaces';
import { LineupModel } from '../lineup';
import { FrontEndModel } from '../FrontEndModel';
import { RecurrenceRuleModel } from './RecurrenceRuleModel';
import { teameventtype } from '@/pipes/teameventtype.pipe';
export class CalendarEventModel extends FrontEndModel {
	constructor(parentId: string | null = null){
		super();
		this.parentId = parentId;
	}

	get EventName(): string{
		if(this.name) return this.name;
		return this.generateName();
	}
	get RootId(): string{
		if(this.rootId){
			return this.rootId;
		}
		return this.id;
	}
	rootId: string | null = null;
	previousEventId: string | null = null;

	get timed(): boolean{
		if (this.start) return true;
		return false;
	}

	createdAt!: Date;
	lastModified!: Date;
	parentId: string | null;
	name: string | null;
	start: Date = new Date();
	end: Date = new Date();
	venue: string = '';
	/**
	 * Renamed to description setter/getter kept for compatibility
	 */
	venueNotes: string = '';
	get description(): string{
		return this.venueNotes;
	}
	set description(description: string){
		this.venueNotes = description;
	}
	address: string = '';
	participants: CalendarEventParticipant[] = [];
	recurrenceRule: RecurrenceRuleModel | null = null;
	exceptionRules: RecurrenceRuleModel[] = [];
	inclusionDates: Date[] = [];
	exceptionDates: Date[] = [];
	eventType: TeamEventType;

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if(obj['createdAt']) this['createdAt'] = new Date(obj['createdAt']);
		if(obj['lastModified']) this['lastModified'] = new Date(obj['lastModified']);
		if(obj['start']) this['start'] = new Date(obj['start']);
		if(obj['end']) this['end'] = new Date(obj['end']);
		if (obj['lineup'] !== null) this['lineup'] = new LineupModel().load(obj['lineup']);
		if (obj['recurrenceRule'] !== null) this['recurrenceRule'] = new RecurrenceRuleModel(RecurrenceRuleFrequency.Daily).load(obj['recurrenceRule']);
		if (obj['exceptionRules'] !== null) this['exceptionRules'] = obj['exceptionRules'].map(rule => new RecurrenceRuleModel(RecurrenceRuleFrequency.Daily).load(rule));
		if (obj['inclusionDates'] !== null) this['inclusionDates'] = obj['inclusionDates'].map(date => new Date(date));
		if (obj['exceptionDates'] !== null) this['exceptionDates'] = obj['exceptionDates'].map(date => new Date(date));

		return this;
	}

	private generateName(): string{
		let name: string;
		if(this.eventType){
			name = `${teameventtype(this.eventType)}`;
		}else{
			name = `Event`;
		}
		return name;
	}

}