
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiPencil, mdiTrashCan } from '@mdi/js';
import { PaymentMethodFormValue } from '@/../types/interfaces/PaymentMethodFormValue';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import PaymentMethodIcon from '@/components/payments/PaymentMethodIcon.vue';
import { CountriesSelectorMixin, StripeMixin } from '@/mixins';
import { StripeElements, PaymentMethodResult, ConfirmCardPaymentData, PaymentMethod } from '@stripe/stripe-js';

@Component({
	components: {
		PaymentMethodIcon,
	}
})
export default class PaymentMethodForm extends Mixins(VuetifyMixin, CountriesSelectorMixin, StripeMixin){
	mdiPencil = mdiPencil;
	mdiTrashCan = mdiTrashCan;
	@Prop({ default: "Add new credit card" }) title: string;
	@Prop({ default: false, type: Boolean }) noBorder: boolean;
	@Prop({ default: false, type: Boolean }) hideControls: boolean;
	@Prop() paymentMethod: any;

	get AvailableProvinces(): {label: string, items: any[]}{
		return this.getProvincesOrStates((this.formValue.country.value as any) || 'CA')
	}

	_uid: string;
	elements: StripeElements;
	formValue: PaymentMethodFormValue<PaymentMethod.Card> = {
		makeDefault: false,
		name: "",
		email: "",
		addressLine1: "",
		addressLine2: "",
		country: {
			value: "",
			text: "",
		},
		city: "",
		province: {
			value: "",
			text: "",
		},
		postalCode: "",
	};
	formChanged(): void{
		this.$emit('change', this.formValue);
	}

	get CardNumberElementId(): string{
		return `cardnumber-${this._uid}`;
	}
	get ExpiryElementId(): string{
		return `expiry-${this._uid}`;
	}
	get CVCElementId(): string{
		return `cvc-${this._uid}`;
	}

	mounted(): void{
		this.initCardElements();
	}

	initCardElements(): void{
		this.elements = this.$stripe.elements();
		this.elements.create('cardNumber', { showIcon: true }).mount(`#${this.CardNumberElementId}`);
		this.elements.create('cardExpiry').mount(`#${this.ExpiryElementId}`);
		this.elements.create('cardCvc').mount(`#${this.CVCElementId}`);
	}

	submitting: boolean = false;
	cardErrorMsg: string | null = null;
	async submit(): Promise<PaymentMethodResult>{
		this.submitting = true;
		this.cardErrorMsg = null;
		const paymentMethodResult = await this.createPaymentMethod(this.formValue);
		if(paymentMethodResult.error !== undefined){
			this.cardErrorMsg = paymentMethodResult.error.message;
		}else{
			this.formValue.card = paymentMethodResult.paymentMethod.card;
			this.formValue.id = paymentMethodResult.paymentMethod.id
			this.$emit('submit', this.formValue);
		}
		this.submitting = false;
		return paymentMethodResult;
	}
	/**
	 * Use stripe-js to convert sensitive card data into stripe's PaymentMethod object
	 */
	async createPaymentMethod(formValue: PaymentMethodFormValue): Promise<PaymentMethodResult>{
		return this.$stripe.createPaymentMethod({
			type: 'card',
			card: this.elements.getElement('cardNumber'),
			billing_details:{
				name: formValue.name,
				address:{
					city: formValue.city,
					country: formValue.country.value,
					line1: formValue.addressLine1,
					line2: formValue.addressLine2,
					postal_code: formValue.postalCode,
					state: formValue.province.value,
				},
				email: formValue.email,
			},
		})
	}

	/**
	 * Use to confirm a card payment without saving the payment method.
	 * 
	 * The return value can be passed directly into `stripe.confirmCardPayment`
	 */
	async getConfirmCardPaymentData({ rememberCard }: { rememberCard?: boolean } = {}): Promise<ConfirmCardPaymentData>{
		return {
			save_payment_method: rememberCard,
			setup_future_usage: rememberCard === true ? 'off_session' : undefined,
			payment_method:{
				card: this.elements.getElement('cardNumber'),
				billing_details:{
					name: this.formValue.name,
					address:{
						city: this.formValue.city,
						country: this.formValue.country.value,
						line1: this.formValue.addressLine1,
						line2: this.formValue.addressLine2,
						postal_code: this.formValue.postalCode,
						state: this.formValue.province.value,
					},
					email: this.formValue.email,
				},
			}
		}
	}

	get IsEdit(): boolean{
		return false;
	}
	get HeadingText(): string{
		return this.title;
	}
	get BorderColor(): string{
		if(this.noBorder) return 'transparent';
		return this.getColor('baColorGray8');
	}
	get Style(): Record<string, any>{
		return {
			'max-width': '600px',
			border: `1px solid ${this.BorderColor}`,
		}
	}
}
