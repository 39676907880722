
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiMenu, mdiChevronLeft, mdiFlash } from '@mdi/js';
import BestAthletesLogo from '@/components/ui/BestAthletesLogo.vue';
import NavSvg from '@/components/svg/NavSvg.vue';
import UserMenu from '@/components/ui/UserMenu.vue';
import { VuetifyMixin, AppHostnameMixin } from '../../mixins';
import { HinderRouteMetadata } from '../../router/router';
import { UpgradeNowMixin } from '@/mixins/UpgradeNowMixin';

@Component({
	components: { BestAthletesLogo, NavSvg, UserMenu },
})
export default class AppBar extends Mixins(AppHostnameMixin, UpgradeNowMixin, VuetifyMixin){
	mdiChevronLeft = mdiChevronLeft;
	mdiMenu = mdiMenu;
	mdiFlash = mdiFlash;

	get HideAppBar(): boolean{
		return this.RouteMetadata.hideAppBar === true && !(this.RouteMetadata.showOnMobile === true && this.$vuetify.breakpoint.xs);
	}
	get IsCollapsed(): boolean{
		return this.collapseSideNav;
	}
	get HideTitle(): boolean{
		return this.$vuetify.breakpoint.xsOnly;
	}
	get OnUpgradePage(): boolean{
		return this.$route.name === 'UpgradeSubscriptionPage';
	}

	get NoTitle(): boolean{
		return this.RouteMetadata.showOnMobile === true && this.$vuetify.breakpoint.xs;
	}

	get RouteMetadata(): HinderRouteMetadata{
		return this.$route.meta;
	}

	@Prop({ type: Boolean, default: false }) athlete;
	@Prop({ type: Boolean, default: false }) coach;

	@Prop({ type: Boolean, default: false }) collapseSideNav;
	@Prop({ type: Boolean, default: true }) showSideNav;
	updateShowSideNav(value: boolean): void{
		this.$emit('update:show-side-nav', value);
	}

	goBack(): void{
		if(this.RouteMetadata.onClickTitle !== undefined){
			this.RouteMetadata.onClickTitle.apply(this);
		}else{
			window.history.back();
		}
	}

	get ShowBackButton(): boolean{
		return this.RouteMetadata.useBackButton === true;
	}
	get PageTitle(): string{
		if(this.RouteMetadata.pageTitle){
			return this.RouteMetadata.pageTitle;
		}
		return this.$route.name;
	}

	get Color(): string{
		if(this.IsMobile){
			return this.getColor('baColorDeepBlue');
		}
		return this.getColor('baColorAppForeground');
	}
}
