
import { getEnumValues } from './get-enum-values';

export enum PersonalityMetric{
	MentalToughnes='Mental Toughness',
	Coachability='Coachability',
	MentalExecution='Mental Execution',
	Grit='Grit',
	MotivationalDrive='Motivational Drive',
	Composure='Composure',
	Confidence='Confidence',
	GrowthMindset='Growth Mindset',
	AttentionToDetail='Attention To Detail',
	Conventionality='Conventionality',
	FocusSpeed='Focus Speed',
	Decisiveness='Decisiveness',
	Adaptability='Adaptability',
}
export const PersonalityMetricValues = getEnumValues<PersonalityMetric>(PersonalityMetric);