import { Component, Vue } from 'vue-property-decorator';
import { OrganizationTypeValues, RoleName, SportName, BaseSoccerPositionValues } from '@/../types/enums';
import { CA_PROVINCES } from '@/../types/constants/ca-provinces';
import { US_STATES } from '@/../types/constants/us-states';
import { teamCreateStore, depthChartsStore, courseCreateStore } from '../store';
import { Currency } from '@/../types/enums/currency';
import { SelectorItem } from '@/mixins/selectors/selectorItem';

@Component
export class CountriesSelectorMixin extends Vue {
	get AvailableCountries(): SelectorItem[] {
		return teamCreateStore.locations;
	}
	get AvailableProvinces(): { label: string, items: SelectorItem[] } {
		if(this['formValue'] !== undefined){
			return this.getProvincesOrStates(this['formValue'].country as any);
		}
		return null;
	}
	getProvincesOrStates(country: 'CA' | 'US'): {label: string, items: SelectorItem[]} | null {
		if(country === 'US'){
			return {
				label: 'State',
				items: US_STATES,
			};
		}
		if(country === 'CA'){
			return {
				label: 'Province',
				items: CA_PROVINCES,
			};
		}
		return null;
	}
}

@Component
export class SportsSelectorMixin extends Vue {
	get SupportedSports() {
		return [SportName.Soccer];
	}
	get AvailablePositions() {
		return BaseSoccerPositionValues;
	}
	get PrimaryPositions() {
		return this.AvailablePositions
	}
	get SecondaryPositions() {
		return [...this.PrimaryPositions, null]
	}
	get PositionNumbers() {
		return [1,2,3,4,5,6,7,8,9,10,11]
	}
}

@Component
export class OrganizationTypeSelectorMixin extends Vue {
	get OrganizationTypes() {
		return OrganizationTypeValues;
	}
}

@Component
export class CoachRolesSelectorMixin extends Vue {
	get CoachRoles() {
		return teamCreateStore.coachRoles;
	}
}

@Component
export class DepthChartTemplatesMixin extends Vue {
	created() {
		depthChartsStore.loadGlobalTemplates();
	}
	get AvailableDepthChartTemplates() {
		return depthChartsStore.globalTemplates.map((dc) => {
			delete (<any>dc)._id;
			delete dc.id;
			return dc;
		});
	}
	get TemplatesReady() {
		return depthChartsStore.templatesInitialized && !depthChartsStore.loadingTemplates;
	}
}
@Component
export class CourseTemplatesMixin extends Vue {
	created() {
		courseCreateStore.loadTemplates();
	}
	get AvailableCourseTemplates() {
		return courseCreateStore.courseTemplates;
	}
	get CourseTemplatesReady(): boolean {
		return courseCreateStore.courseTemplatesInitialized && !courseCreateStore.courseTemplatesLoading;
	}
	get CourseTemplatesLoading(): boolean {
		return courseCreateStore.courseTemplatesLoading;
	}
}
@Component
export class AvailableCurrencies extends Vue {

	get AvailableCurrencies(): { text: string, value: Currency }[] {
		return [
			{ text: Currency.CAD.toUpperCase(), value: Currency.CAD },
			{ text: Currency.USD.toUpperCase(), value: Currency.USD },
		];
	}
}
@Component
export class AvailableRoles extends Vue {

	get AvailableRoles(): RoleName[] {
		return [
			RoleName.Athlete,
			RoleName.Parent,
			RoleName.Coach,
		];
	}
}

@Component
export class AvailableProgramTypes extends Vue {

	get AvailableProgramTypes(): string[]{
		return [
			"A License",
			"C License",
		];
	}

}
