import { CrudApi } from './CrudApi';
import { StatisticModel } from '../models/statistic/StatisticModel';
import { compareAgeGroup } from '@best-athletes/ba-types';
import { Comparison } from '../../types/interfaces';
import { AxiosRequestConfig } from 'axios';
import { GlobalStatsQuery } from '../../types/interfaces';

class StatisticApi extends CrudApi<StatisticModel>{
	statsCache: Record<string, StatisticModel[]> = {};
	constructor(){
		super('statistic', (obj) => new StatisticModel().load(obj));
	}

	async compare(body: GlobalStatsQuery, config?: AxiosRequestConfig): Promise<Comparison[]> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/compare`, body, config));
	}

	async globalStatistics(athleteId: string, body: GlobalStatsQuery, config?: AxiosRequestConfig): Promise<StatisticModel[]> {
		const key = this.getGlobalStatisticsKey(body);
		if (this.statsCache[key] === undefined){
			const results = await CrudApi.Api(c => c.post(`/${this.resource}/globalStatistics/${athleteId}`, body, config));
			this.statsCache[key] = results.map((stat: any) => new StatisticModel().load(stat)).sort((a: StatisticModel, b: StatisticModel) => compareAgeGroup(a.ageGroup, b.ageGroup));
		}
		return this.statsCache[key];
	}

	private getGlobalStatisticsKey(query: GlobalStatsQuery): string{
		return query.ageGroup.sort((a, b) => compareAgeGroup(a, b)).join(',');
	}
}
export const statisticApi = new StatisticApi();