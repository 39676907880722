import { AccessControlledModel } from '../AccessControlledModel';
import { PhysicalAssessmentData } from '../../../types/interfaces';
import { DevelopmentalAge, Gender } from '../../../types/enums';
import { AgeGroup } from '@best-athletes/ba-types';
export class AthleteAssessmentDataModel extends AccessControlledModel implements PhysicalAssessmentData {
	readonly __type = 'AthleteAssessmentDataModel';
	athleteId?: string;
	teamId?: string;
	organzationId?: string;
	dateCreated: Date = new Date();

	assessmentDate: Date; // MM/DD/YYYY
	playingLevel: AgeGroup; // U14, U13, etc
	competitiveLevel: number; // 1-5
	gender: Gender; // M/F
	dominantFoot: string; // L/R/N
	playingPosition: string; // Defensive Midfielder, etc
	dateOfBirth: string; // (DD/MM/YYYY)
	ageYear: number; // age in years excluding months
	ageMonthRemainder: number; // remainder of age in months
	ageMonthDecimal: number; // =ageMonthRemainder/12
	age: number; // ageYear + ageMonthDecimal

	mass: number; // kg
	standingHeight: number; // cm
	trueSittingHeight: number; // cm =sittingHeightWithBox - boxHeight
	sittingHeightWithBox: number; // cm height of athlete sitting on box including box
	boxHeight: number; // box that athlete sits on height
	legLength: number; // standingHeight - sittingHeightWithBox 
	legTrunk: number; // =(height - sittingHeight)*sittingHeight
	ageLeg: number; // =age*(height-sittingHeight)
	ageSittingHeight: number; // =age * sittingHeight
	ageMass: number; // =age * mass
	massHeightRatio: number; // =(mass/height)*100
	bodyMassIndex: number;

	maturityOffset: number; // =-9.236+(0.0002708* (legTrunk) )-(0.001663* (ageLeg) )+(0.007216* (ageSittingHeight) )+(0.02292* (massHeightRatio) ) 
	ageOfPeakHeightVelocity: number; // year.month
	developmentalCategory: DevelopmentalAge; // Early/Average/Late 

	counterMovementJumpHeight: number; // cm
	power: number; // W =60.7*(mass)+45.3*(counterMovementJumpHeight)-2055 // I think there might be a mistake here

	dropJumpContactTime: number; // s
	dropJumpHeight: number; // cm
	reactiveStrengthIndex: number; // index =dropJumpHeight/dropJumpContactTime*100

	tenMeterSprint: number; // s
	acceleration: number; // m/s =(10/(tenMeterSprint)*3.6)/3.6
	twentyMeterSprint: number; // s
	thirtyFiveMeterSprint: number; // s
	twentyToThirtyFiveMeterSplit: number; // s 
	speed: number; // m/s =(15/AF2*3.6)/3.6

	yoyoIntermittentRecoveryTestStage: number; // m
	yoyoIntermittentRecoveryTestDistance: number;
	maximalAerobicVelocity: number; // km/h

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (obj['assessmentDate']) this.assessmentDate = new Date(obj['assessmentDate']);
		if (obj['dateCreated']) this.dateCreated = new Date(obj['dateCreated']);
		return this;
	}

	get Gender(): Gender {
		if (this.gender.toUpperCase() === 'F' || this.gender === Gender.Female) {
			return Gender.Female;
		} else if (this.gender.toUpperCase() === 'M' || this.gender === Gender.Male) {
			return Gender.Male;
		}
		console.warn("Failed to determine Gender from assessment data", { 'assessmentData.gender': this.gender });
		return Gender.Male;
	}
}