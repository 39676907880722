
import { Prop, Component, Vue } from "vue-property-decorator";

@Component
export class ExportSvg extends Vue {
	@Prop({ default: 32 }) private size: number;
	@Prop({ default: 'baColorPrimaryBlue' }) private color: string;
}

export default ExportSvg;
