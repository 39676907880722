
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin } from "../../mixins/VuetifyMixin";
import { mdiInformation, mdiInformationOutline } from '@mdi/js';

@Component
export default class HintText extends Mixins(VuetifyMixin) {
	mdiInformation  = mdiInformation
	mdiInformationOutline = mdiInformationOutline;


}
