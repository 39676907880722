
import { Component, Mixins, PropSync } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins";
import { AthleteProfileModel } from "@/models/athlete/AthleteProfileModel";
import {
	LanguageCode,
	Language,
	CountryCode,
	Country,
	SocialMedia,
	SocialMediaKeys
} from "@/../types/enums";
import { SocialMediaField } from '@/../types/interfaces';
import { mdiPlus, mdiCloseCircle, mdiChevronDown } from "@mdi/js";

type Item<T> = {
	text: string;
	value: T;
};

@Component({
	components: {}
})
export default class EditRecruitingProfileBio extends Mixins(VuetifyMixin) {
	@PropSync('editAthleteProfile', { required: true }) EditAthleteProfile: AthleteProfileModel;	
	
	mdiPlus = mdiPlus;
	mdiCloseCircle = mdiCloseCircle;
	mdiChevronDown = mdiChevronDown;

	isArrayValuesFull(array: Array<any>): boolean {
		/**
		 * Returns true if all the values in the array are set; false if not
		 */
		return array.length == array.filter(value => value).length;
	}

	async created(): Promise<void> {
		// Point the array reference to EditProfile's array
		this.eagerLanguagesSpoken = this.EditAthleteProfile.languagesSpoken;
		this.eagerCitizenships = this.EditAthleteProfile.citizenships;
		this.eagerSocialMediaProfiles = this.EditAthleteProfile.socialMedia;
	}

	// Lanaguages
	eagerLanguagesSpoken: LanguageCode[] = [];
	get LanguagesSpoken(): LanguageCode[] {
		return this.eagerLanguagesSpoken;
	}
	get languageItems(): Item<LanguageCode>[] {
		// Get all the languages from the Language type and turn it items array for autocomplete
		let items: Item<LanguageCode>[] = [];
		Object.keys(Language).forEach(lang_code => {
			items.push({
				text: Language[lang_code],
				value: lang_code as LanguageCode
			});
		});

		return items;
	}
	get isLanguagesFull(): boolean {
		return this.isArrayValuesFull(this.EditAthleteProfile.languagesSpoken);
	}

	// Citizenship
	eagerCitizenships: CountryCode[] = []
	get Citizenships(): CountryCode[] {
		return this.eagerCitizenships;
	}
	get citizenshipItems(): Item<CountryCode>[] {
		let items: Item<CountryCode>[] = [];

		Object.keys(Country).forEach(country_code => {
			items.push({
				text: Country[country_code],
				value: country_code as CountryCode
			});
		});

		return items;
	}
	get isCitizenshipsFull(): boolean {
		return this.isArrayValuesFull(this.EditAthleteProfile.citizenships);
	}

	// Social Media Platforms
	eagerSocialMediaProfiles: SocialMediaField[] = [];
	get SocialMediaProfiles(): SocialMediaField[] {
		return this.eagerSocialMediaProfiles;
	}
	get platformItems(): Item<SocialMedia>[] {
		let items: Item<SocialMedia>[] = [];

		Object.keys(SocialMedia)
			.filter(x => !(parseInt(x) >= 0))
			.forEach(media_key => {
				items.push({
					text: media_key as SocialMediaKeys,
					value: SocialMedia[media_key]
				});
			});
		return items;
	}
	get isPlatformsFull(): boolean {
		let platformsSelected: SocialMedia[] = [];
		let platformLinks: string[] = [];

		this.EditAthleteProfile.socialMedia.forEach(athletePlatform => {
			platformsSelected.push(athletePlatform.platform);
			platformLinks.push(athletePlatform.link);
		});

		return (
			this.isArrayValuesFull(platformLinks) &&
			platformsSelected.length ==
				platformsSelected.filter(value => value).length
		);
	}
}
