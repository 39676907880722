import lf from 'localforage';

lf.config({
	name: 'BestAthletes',
});

export const localforage = lf;
export const LocalForagePlugin = {
	install(Vue, options){
		Vue.prototype.$localforage = localforage;
	}
};
