
import Vue from 'vue';
import { config } from '@/config/config';
export default Vue.extend({
	computed:{
		version(){
			return config.version;
		}
	}
})
