import { Country } from '../enums/country';

/** Only the countries configured in Stripe, Canada and the US */
export const COUNTRIES = [
	{ value: 'Canada', text: Country.CA },
	{ value: 'United States', text: Country.US },
];

/** All country ISO codes + names */
export const ALL_COUNTRIES = Object.entries(Country).map(([iso, name]) => ({
	value: iso,
	text: name,
}));