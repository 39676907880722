
import { Component, Prop, Mixins } from 'vue-property-decorator'
import {
	mdiChevronLeft,
	mdiFileUpload,
	mdiVideoPlus,
	mdiCancel,
	mdiPencilOutline,
	mdiPlay,
	mdiAttachment,
	mdiCloseCircle,
	mdiTrashCanOutline
} from '@mdi/js'

import Page from '@/views/Page.vue'
import Alert from '@/components/ui/Alert.vue'
import Carousel from '@/components/ui/Carousel.vue';
import VideoClipPreview from '@/components/ui/videos/VideoClipPreview.vue';
import { filesize } from '@/pipes'
import { getMediumDateString, formatDurationShort } from '@/helpers/date'

import BAVideoPlayer from '@/components/ba-video/BAVideoPlayer.vue'

import RoundedButton from '@/components/forms/RoundedButton.vue'
import { VideoUploadMixin } from '@/mixins'
import { VideoModel } from '@/models/video/VideoModel'
import { VideoClipModel } from '@/models/video/VideoClipModel'
import { LoopMode } from '@/../types/enums'
import ClipPlayerClipDetails from "@/components/videoClipPlayer/ClipPlayerClipDetails.vue";
import { BehaviorSubject } from "rxjs";

@Component({
	components: {
		Alert,
		BAVideoPlayer,
		Page,
		RoundedButton,
		ClipPlayerClipDetails,
		Carousel,
		VideoClipPreview,
	}
})
export default class ClipPlayerStudio extends Mixins(VideoUploadMixin) {
	@Prop({ default: false, type: Boolean }) value: boolean
	updateValue(value: boolean): void{
		this.$emit('input', value);
		if(value === false){
			this.clearCurrentClip();
		}
	}
	@Prop({ default: []}) videos: VideoModel[];
	@Prop({ default: []}) videoClips: VideoClipModel[];
	@Prop({ default: null }) selectedVideoClip: VideoClipModel | null;

	/** If this component is fullscreen, what route to go back to when exiting */
	@Prop({ default: null }) exitTo: string | null;
	exit(): void{
		if(this.exitTo !== null){
			this.$router.push({
				name: this.exitTo
			});
		}
		return this.updateValue(false);
	}

	get VideoPlayerSectionStyle(): Record<string, any>{
		return {
			'max-width': `850px`,
		};
	}

	mdiChevronLeft = mdiChevronLeft
	mdiFileUpload = mdiFileUpload
	mdiCancel = mdiCancel
	mdiVideoPlus = mdiVideoPlus
	mdiPlay = mdiPlay
	mdiAttachment = mdiAttachment
	mdiCloseCircle = mdiCloseCircle
	mdiPencilOutline = mdiPencilOutline
	mdiTrashCanOutline = mdiTrashCanOutline

	currentVideo: VideoModel | null = null
	currentClip: VideoClipModel | null = null;
	@Prop({ default: () => new BehaviorSubject<VideoClipModel | null>(null)}) currentClipObservable: BehaviorSubject<VideoClipModel | null>;

	async created(): Promise<void> {
		this.currentClipObservable.subscribe(clip => this.updateCurrentClip(clip));
	}

	async updateCurrentClip(clip: VideoClipModel | null): Promise<void>{
		this.currentClip = clip;
	}

	get CurrentVideo(): VideoModel | null {
		if(this.currentClip === null) return null;
		const video = this.videos.find(v => v.id === this.currentClip.video);
		if(!video){
			return null;
		}
		return video;
	}
	get CurrentClipVideoSrc(): string | null{
		if(this.currentClip === null) return null;
		const video = this.videos.find(v => v.id === this.currentClip.video);
		if(!video){
			return null;
		}
		return video.SourceUrl;
	}

	// for uploading files
	selectedVideoFile: File | null = null
	selectedVideoFileMetadata: any | null
	isUploadingSV = false
	showUpload = false
	savingSVUploadPercentage = 0
	theMuxUploadId: string = ""
	editorVisible = true;

	@Prop({ default: () => LoopMode.LOOP_PLAYLIST})
	loopMode: LoopMode;

	clipDetailFormVisible = false;

	deleteClipDialogVisible = false;

	$refs: {
		videoPlayer: BAVideoPlayer
	}

	get CurrentClipName():string{
		if(!this.currentClip) return 'No Clip Selected';
		return this.currentClip.name
	}

	filesize(value: number): string {
		return filesize(value, 1)
	}

	datestring(theDate: Date): string {
		return getMediumDateString(theDate)
	}

	formatDurationShort(theDuration: number): string {
		return formatDurationShort(theDuration, true, 's')
	}

	formatStartTime(theStartTime: number): string {
		return 'at: ' + formatDurationShort(theStartTime, true, 's')
	}

	async selectedAVideo(video: VideoModel): Promise<void> {
		this.currentVideo = video;
	}

	/**
	 * Current Clip Position referes to the clip's position within the array
	 */
	get currentClipPosition(): number {
		return this.videoClips.findIndex(clip => clip == this.currentClip);
	}

	@Prop({ type: Boolean, default: true }) autoplay: boolean;
	onClipEnded(): void{
		if(this.autoplay === true){
			this.playNextClip();
		}
	}

	playNextClip(): void {
		// Get the new video clip
		// console.log("Play Next Clip");
		let newClip;
		// BUG: Cannot use NO_LOOP Mode/ pass null into observable; most likely need to change video handling to observable
		// if (this.loopMode === LoopMode.NO_LOOP){
		// 	newClip = null;
		// }
		if (this.loopMode === LoopMode.LOOP_VIDEO) {
			newClip = this.videoClips[this.currentClipPosition]
		}
		else if (this.loopMode === LoopMode.LOOP_PLAYLIST) {
			newClip = this.currentClipPosition + 1 >= this.videoClips.length ? this.videoClips[0] : this.videoClips[this.currentClipPosition + 1]
		}
		this.playClip(newClip);
	}

	playAll(): void{
		this.playClip(this.videoClips[0] ?? null);
	}

	async playClip(clip: VideoClipModel): Promise<void>{
		this.currentClipObservable.next(clip)
	}
	clearCurrentClip(): void{
		this.currentClipObservable.next(null);
	}
	clipIsPlaying(clip: VideoClipModel): boolean{
		if(!this.currentClip) return false;
		return this.currentClip.id === clip.id;
	}

}
