
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiApps } from '@mdi/js';
import UserMenu from '../ui/UserMenu.vue';
import { VuetifyMixin, AppHostnameMixin, AuthMixin } from '../../mixins';
import AppSwitcherIcon from './AppSwitcherIcon.vue';
import AdminAppSvg from '../svg/logos/AdminAppSvg.vue';
import AthleteAppSvg from '../svg/logos/AthleteAppSvg.vue';
import CoachAppSvg from '../svg/logos/CoachAppSvg.vue';
import ClubAdminAppSvg from '../svg/logos/ClubAdminAppSvg.vue';
import { RoleName } from '../../../types/enums';
import { AdminAppRoles, ClubAdminAppRoles } from '../../../types/constants/role-lists';
import { hasRole } from '@/directives/v-require-role';
import { VMenu } from 'vuetify/lib';

@Component({
	components: {
		AppSwitcherIcon,
		AdminAppSvg,
		AthleteAppSvg,
		CoachAppSvg,
		ClubAdminAppSvg,
		UserMenu,
	},
})
export default class AppSwitcher extends Mixins(AppHostnameMixin, VuetifyMixin, AuthMixin){
	@Prop({ default: mdiApps }) icon: string;
	@Prop({ default: 'baColorVibrantBlue' }) iconColor: string;
	@Prop({ default: false, type: Boolean }) card: boolean;

	get Component(): any{
		if(this.card) return 'div';
		return VMenu;
	}
	get Elevation(): number{
		if(this.card) return 0;
		return 16;
	}
	get Width(): string{
		if(this.card) return '100%';
		return '348';
	}

	get Cols(): Record<string, number>{
		if(this.ClubAdminRoles.map(r => hasRole(r)).reduce((a,b) => a || b)){
			return {
				cols: 12,
				sm: 4,
			};
		}
		return {
			cols: 12,
			sm: 6,
		};
	}

	get HasAnyRoles(): boolean{
		return this.hasAnyRole(this.AdminRoles) || this.hasAnyRole(this.ClubAdminRoles);
	}

	hasAnyRole(roles: RoleName[]): boolean{
		return roles.reduce((a, role) => a || hasRole(role), false);
	}

	get AdminRoles(): RoleName[]{
		return AdminAppRoles;
	}

	get ClubAdminRoles(): RoleName[]{
		return ClubAdminAppRoles;
	}
}
