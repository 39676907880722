
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { CrudApi } from '../../api/CrudApi';
import { VideoModel } from '@/models/video/VideoModel';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import { MyCoachMixin } from '@/mixins';
import { errorIs403, errorIs401 } from '@/helpers/error-handler';
import CoachRecruitingStore from '@/store/targetedSharing/CoachRecruiting.store';
import { getModule } from 'vuex-module-decorators';
import { Tag } from '@/models/tag/Tag';
import { logGAEvent } from '@/plugins/firebase';
const coachRecruitingSharingStore = getModule(CoachRecruitingStore);

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class VideoLibraryProvider extends Mixins(MyCoachMixin){
	@Prop({ default: 'div' }) private as: string;

	@Prop({ default: '/' }) private apiPrefix: string;
	@Prop() private parentId: string;
	@Watch('parentId') parentIdChanged(): void{
		this.loadVideoLibrary();
	}
	@Prop({ type: Boolean, default: false }) private disabled: boolean;
	/** Only Videos & Clips with these tags will appear, and any new Videos/Clips will have these tags */
	@Prop({ default: [] }) private tagFilter: Tag[];
	@Prop({ type: Boolean, default: true }) private sourceVideos: boolean;
	@Prop({ type: Boolean, default: true }) private clips: boolean;
	@Prop({ default: null }) private sharingUrlId: string | null;

	loading: boolean = false;
	SourceVideos: VideoModel[] = [];
	VideoClips: VideoClipModel[] = [];

	get SlotProps(): {
		loading: boolean,
		SourceVideos: VideoModel[],
		saveVideo: (clip: VideoModel) => Promise<VideoModel>,
		deleteVideo: (clip: VideoModel) => Promise<void>,
		VideoClips: VideoClipModel[],
		saveClip: (clip: VideoClipModel) => Promise<VideoClipModel>,
		deleteClip: (clip: VideoClipModel) => Promise<void>,
		} {
		return {
			loading: this.loading,
			SourceVideos: this.SourceVideos,
			saveVideo: async (video: VideoModel): Promise<VideoModel> => this.saveVideo(video),
			deleteVideo: async (video: VideoModel): Promise<void> => this.deleteVideo(video),
			VideoClips: this.VideoClips,
			saveClip: async (clip: VideoClipModel): Promise<VideoClipModel> => this.saveClip(clip),
			deleteClip: async (clip: VideoClipModel): Promise<void> => this.deleteClip(clip),
		};
	}

	created(): void {
		if(!this.disabled && this.parentId !== undefined){
			this.loadVideoLibrary();
		}
	}

	async loadVideoLibrary(): Promise<void>{
		this.loading = true;
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		if(this.sourceVideos) await this.lookupSourceVideos(this.parentId).catch(() => {});
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		if(this.clips) await this.lookupVideoClips(this.parentId).catch(() => {});

		this.loading = false;
	}

	get TagFilterSubjectIds(): string[]{
		return this.tagFilter.filter(tag => tag.subjectId !== undefined).map(tag => tag.subjectId);
	}

	get QueryParams(): Record<string, string> {
		const query = {}

		if (this.sharingUrlId !== null) {
			query['sharingUrlId'] = this.sharingUrlId;
		}
		return query;
	}

	async lookupSourceVideos(parentId: string): Promise<void>{
		let videos: VideoModel[] = [];
		try{
			videos = await CrudApi.Api((api) => api.get(`${this.apiPrefix}/${parentId}/videos`, {params: this.QueryParams }));
		}catch(e){
			if(!errorIs401(e) && !errorIs403(e)) throw e;
			const shareVideos = await coachRecruitingSharingStore.getVideosIfShareExists({
				athleteId: parentId,
				coachId: this.MyCoachId
			});
			if(shareVideos === null){
				throw e;
			}
			videos = shareVideos;
		}
		this.SourceVideos = videos
			.map((video: any) => new VideoModel().load(video))
			.filter((video: VideoModel) => video.hasTag(this.tagFilter));
	}

	async saveVideo(video: VideoModel): Promise<VideoModel>{
		video.parentId = this.parentId;
		video.addTags(this.tagFilter);
		const result = await CrudApi.Api((api) => api.post(`${this.apiPrefix}/${this.parentId}/videos`, video));
		const savedVideo = new VideoModel().load(result);
		const index = this.SourceVideos.findIndex(video => video.id === savedVideo.id);
		if(index > -1){
			this.SourceVideos.splice(index, 1, savedVideo);
		}else{
			this.SourceVideos.push(savedVideo);
		}
		logGAEvent("video_uploaded");
		return savedVideo;
	}
	async deleteVideo(video: VideoModel): Promise<void>{
		const index = this.SourceVideos.findIndex(c => c.id === video.id);
		if(index > -1){
			await CrudApi.Api((api) => api.delete(`${this.apiPrefix}/${this.parentId}/videos/${video.id}`));
			await Promise.allSettled(this.VideoClips.filter((clip: VideoClipModel) => clip.video === video.id).map(clip => {
				return this.deleteClip(clip);
			}));
			this.SourceVideos.splice(index, 1);
			await this.loadVideoLibrary();
		}
	}

	async lookupVideoClips(parentId: string): Promise<void>{
		let clips = [];
		try{
			clips = await CrudApi.Api((api) => api.get(`${this.apiPrefix}/${parentId}/videoClips`, { params: this.QueryParams }));
		}catch(e){
			if(!errorIs401(e) && !errorIs403(e)) throw e;
			const shareClips = await coachRecruitingSharingStore.getVideoClipsIfShareExists({
				athleteId: parentId,
				coachId: this.MyCoachId
			});
			if(shareClips === null){
				throw e;
			}
			clips = shareClips;
		}
		if(this.sourceVideos){
			// TODO: This filters out orphaned clips who's parents have been deleted. This should be dealt with server side instead.
			this.VideoClips = clips
				.filter((clip: any) => this.SourceVideos.find(v => v.id === clip.video) !== undefined)
				.map((clip: any) => new VideoClipModel().load(clip))
				.filter((clip: VideoClipModel) => clip.hasTag(this.tagFilter));
		}else{
			this.VideoClips = clips
				.map((clip: any) => new VideoClipModel().load(clip))
				.filter((clip: VideoClipModel) => clip.hasTag(this.tagFilter));
		}
	}

	async saveClip(clip: VideoClipModel): Promise<VideoClipModel>{
		clip.parentId = this.parentId;
		clip.addTags(this.tagFilter);
		const result = await CrudApi.Api((api) => api.post(`${this.apiPrefix}/${this.parentId}/videoClips`, clip));
		const savedClip = new VideoClipModel().load(result);
		const index = this.VideoClips.findIndex(clip => clip.id === savedClip.id);
		if(index > -1){
			this.VideoClips.splice(index, 1, savedClip);
		}else{
			this.VideoClips.push(savedClip);
		}
		logGAEvent("video_clip_created");
		return savedClip;
	}
	async deleteClip(clip: VideoClipModel): Promise<void>{
		const index = this.VideoClips.findIndex(c => c.id === clip.id);
		if(index > -1){
			await CrudApi.Api((api) => api.delete(`${this.apiPrefix}/${this.parentId}/videoClips/${clip.id}`));
			this.VideoClips.splice(index, 1);
		}
	}
}
