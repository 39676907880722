/**
 * Takes a YouTube URL and returns the ID or false
 * Source: https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url#8260383
 * @param url Youtube URL
 * @returns Youtube ID or False
 */
export function youtubeParser(url: string): string | false {
	const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	const match = url.match(regExp);
	return (match && match[7].length == 11) ? match[7] : false;
}