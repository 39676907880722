
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';
import JerseySvg from "@/components/svg/JerseySvg.vue";

@Component({
	components: { JerseySvg }
})
export default class Jersey extends Mixins(VuetifyMixin){


	@Prop({default: '', type: Number}) number: number;
	@Prop({default: 10, type: Number}) size: number;

	@Prop({default: "#053243FF", type: String}) jerseyColor: string;
	@Prop({default: "#FDB62F", type: String}) collarColor: string;
	@Prop({default: "#ffffffff", type: String}) fontColor: string;

	get NumberStyle() {
		return {
			// 'z-index': 100,
			'font-size': `${this.size*1.7}px`,
			'color': this.fontColor,
			position: 'relative',
			left: `-50%`
		}
	}
	get SvgStyle() {
		return {
			width: `${this.size * 5}px`,
			height: 'auto'
		}
	}
}
