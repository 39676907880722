import { NavigationGuard } from 'vue-router';
import { authGuard } from '@/auth/authGuard';
import { userStore } from '@/store';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { isCoachRoute } from './coachAuthGuard';
import { getFlagValue } from '@/feature-flags';

/**
 * Runs authGuard, then checks if the user's Athlete Profile exists and redirects to setup otherwise.
 */
export const athleteAuthGuard: NavigationGuard = (to, from, next) => {
	return authGuard(to, from, async () => {
		const hasAthleteProfile = userStore.HasAthleteProfile;
		const routeToCreateUser = to.name === Routes.RootOnboarding;
		if (userStore.currentProfileData !== null && userStore.currentProfileData.type === 'coach'){
			if(!isCoachRoute(to.path)){
				/**
				 * Workaround for known issue: https://best-athletes.atlassian.net/wiki/spaces/HINDER/pages/2014347265/Coach+Redirect+on+Login
				 */
				if (getFlagValue('hin-1484-coach-redirect-workaround') && (to.query.state !== undefined && to.query.code !== undefined)){
					return next();
				}
				const coachPath = `/c${to.path}`;
				return next({
					path: coachPath,
					query: { ...to.params, ...to.query },
					params: to.params,
				});
			}
		}else if (isCoachRoute(to.path)) {
			const athletePath = to.path.replace('/c', '/');
			return next({
				path: athletePath,
				query: { ...to.params, ...to.query },
				params: to.params,
			});
		}

		if (getFlagValue('HIN-1629-email-verification-onboarding-screen')){
			if (userStore.EmailVerified === false){
				return next({
					name: Routes.EmailVerification,
					query: { ...to.params, ...to.query},
					params: to.params
				});
			}
		}

		// If the user does not have a profile create one.
		if (!hasAthleteProfile && !routeToCreateUser) {
			// console.log("[athleteAuthGuard] No Profile, Redirect to RootOnboarding");
			return next({ name: Routes.RootOnboarding, query: to.query, params: to.params });
		}

		// No point in setting up a profile if we have one.
		if (hasAthleteProfile && routeToCreateUser) {
			// console.log("[athleteAuthGuard] Has profile, Redirect to AthleteDashboard");
			return next({ name: 'AthleteDashboard', query: to.query, params: to.params });
		}

		// console.log("[athleteAuthGuard] Checks passed, continue to route");
		return next();
	});
}