
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '../../../../mixins/VuetifyMixin';
import { DepthChartGrid } from '../../../../models/team/DepthChartOnTeam';
import PositionCard from '../PositionCard.vue';

interface DepthChartRow{
	row: number,
	rows: string[],
}

@Component({
	components: { PositionCard },
})
export default class SoccerFieldGrid extends Mixins(VuetifyMixin){

	@Prop({ default: false, type: Boolean }) private showGrid: boolean;
	@Prop({ default: true, type: Boolean }) private reverse: boolean;
	@Prop({ default: 660 }) private height: number;
	@Prop({ required: true }) private grid: DepthChartGrid;

	get Rows(){
		return this.reverse ? this.grid.rows.slice().reverse() : this.grid.rows;
	}
	get RowCount(){
		// The Goalie row is fixed
		return this.grid.rows.length + 1;
	}
	get XPadding(){
		return 20;
	}
	get YPadding(){
		return 50;
	}
	get RowHeight(){
		return (this.height - (this.YPadding*2)) / this.RowCount;
	}
	get RowStyle(){
		return {
			height: `${this.RowHeight}px`
		}
	}
	get ColStyle(){
		return {
			'margin': '2px',
			'max-width': '150px',
		}
	}

	addPosition(row,col){
		this.$emit('add:position', { row,col });
	}
	addGoalie(){
		this.$emit('add:goalie');
	}
}
