import { SWParticipant } from '../../../types/interfaces/SmartwaiverTypes';
import { FrontEndModel } from '../FrontEndModel';

export class BAEventWaiverModel extends FrontEndModel {
	eventId: string;
	orderId: string;

	smartwaiverId: string;
	smartwaiverTemplateId: string;
	title: string;
	firstName: string;
	lastName: string;
	email: string;
	created: Date;
	expired: boolean;
	verified: boolean;
	participants: SWParticipant[];

	load<BAEventWaiverModel>(obj: Partial<BAEventWaiverModel>): this {
		Object.assign(this, obj);
		if (obj['created']) this.created = new Date(obj['created']);
		return this;
	}
}