
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VueConstructor } from 'vue/types/umd';

/**
 * Allow a form to automatically set focus to it's first element
 */
@Component
export class AutofocusMixin extends Vue {
	$refs:{
		autofocusFirstEl: any;
	}
	@Prop({ type: Boolean, default: false }) autofocus: boolean;
	mounted(){
		if (this.autofocus === true && this.$refs.autofocusFirstEl){
			this.$nextTick(() => {
				this.$refs.autofocusFirstEl.$el.focus();
			})
		}
	}
}
