import { Currency } from "@/../types/enums/currency";

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
const currencies = {
	[Currency.USD]: 'en-US',
	[Currency.CAD]: 'en-CA',
	// Example Future Currencies
	'GBP': 'en-GB',
	'EUR': 'en-GB',
};

/**
 * Formats a price in a given currency using Intl.NumberFormat
 * 
 * @param value The price given in the smallest denomination ie, pennies. This method will divide by 100 to get the output in dollars
 * @param currency The currency the price represents
 * 
 * Docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
export function formatPrice(value: number, currency: Currency): string {
	const formatter = new Intl.NumberFormat(currencies[currency], {
		style: 'currency',
		currency,
	})
	return formatter.format(value / 100);
}