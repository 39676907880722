import { api } from './api'

export async function findById(id) {
	return api(c => c.get('/athlete/' + id))
}

export async function findByEmail(email) {
	return api(c => c.get('/athlete/email/' + email))
}

export async function getAggregations() {
	return api(c => c.get('/athlete/aggregations'))
}

export async function getAll(filter) {
	return api(c => c.get('/athlete', {params: filter}))
}

// @TODO temporary remove before prod
export async function buildAll() {
	return api(c => c.get('/search/build-all'))
}

export async function insert(obj) {
	return api(c => c.post('/athlete', obj))
}

export async function update(obj) {
	return api(c => c.put('/athlete/' + obj.id, obj))
}

export async function save(user) {
	return user.id ? update(user) : insert(user)
}

export default {
	buildAll,
	findById,
	findByEmail,
	getAggregations,
	getAll,
	insert,
	update,
	save
}
