import { SetupIntent } from "@stripe/stripe-js";
import { StartCheckoutParams } from '@/../types/interfaces/StartCheckoutParams';
import { CheckoutIntentModel } from "@/models/checkoutIntent/CheckoutIntentModel";
import { LineItemModel } from "@/models/checkoutIntent/LineItemModel";
import { PaymentHistoryModel } from "@/models/stripe/PaymentHistoryModel";
import { CustomerModel } from "@/models/customer/CustomerModel";
import { api } from "./api";
import router from "@/router/router";
import { AthleteCheckoutPage } from "@/../types/constants/web_client_user.routes";

export class CustomerApi{
	static Api = api;
	constructor(
		/** API Prefix this customer resource should be loaded from ie; /athlete/123456. `/customer` will be appended to the end */
		public prefix: string
	) {}

	get ApiPrefix(): string{
		return `${this.prefix}/customer`;
	}

	async getCustomerOrCreate(): Promise<CustomerModel>{
		const customerRes = await CustomerApi.Api((c) => c.get(`${this.ApiPrefix}/get-or-create`));
		return new CustomerModel().load(customerRes);
	}

	/**
	 * Will query URL like <apiurl>/athlete/<athlete ID>/customer/create-setup-intent
	 * to get a SetupIntent object
	 */
	static async createSetupIntent(resource: string, resourceId: string): Promise<SetupIntent> {
		const url = `${resource}/${resourceId}/customer/create-setup-intent`;
		const data = await CustomerApi.Api((c) => c.get(url))
		return data;
	}
	static async findCheckoutIntent(resource: string, resourceId: string, id: string): Promise<CheckoutIntentModel<any>> {
		const url = `${resource}/${resourceId}/customer/checkout-intent/${id}`;
		const data = await CustomerApi.Api((c) => c.get(url))
		return new CheckoutIntentModel().load(data);
	}
	static async createCheckoutIntent(resource: string, resourceId: string, params: StartCheckoutParams): Promise<CheckoutIntentModel<any>> {
		const url = `${resource}/${resourceId}/customer/checkout-intent`;
		const data = await CustomerApi.Api((c) => c.post(url, params))
		return new CheckoutIntentModel().load(data);
	}
	/**
	 * Creates a CheckoutIntent and navigates the current tab to the checkout URL on stripe
	 */
	static async openNewCheckoutSessionUrl(resource: string, resourceId: string, params: StartCheckoutParams): Promise<void> {
		const checkout = await this.createCheckoutIntent(resource, resourceId, params);
		router.push({
			name: AthleteCheckoutPage,
			params:{
				checkoutIntentId: checkout.id,
			},
			query:{
				step: "redirect",
			}
		});
	}
	static async setCheckoutIntentLineItems(resource: string, resourceId: string, id:string , params: { lineItems: LineItemModel<any>[] }): Promise<CheckoutIntentModel<any>> {
		const url = `${resource}/${resourceId}/customer/checkout-intent/${id}/line-items`;
		const data = await CustomerApi.Api((c) => c.put(url, params))
		return new CheckoutIntentModel().load(data);
	}
	static async getPaymentHistory(resource: string, resourceId: string): Promise<PaymentHistoryModel[]> {
		const url = `${resource}/${resourceId}/customer/payment-history`;
		const data = await CustomerApi.Api((c) => c.get(url))
		return data.map((d: any) => new PaymentHistoryModel().load(d));
	}
}