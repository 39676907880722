import { getDaysBetween } from '@/helpers';
import { mdiCookieMinusOutline } from '@mdi/js';
import { AthleteMetric, AthleteMetricValues } from '../../../types/enums';
import { AccessControlledModel } from '../AccessControlledModel';
import { SelfAssessmentTestModel } from './SelfAssessmentTestModel';

export class SelfAssessmentModel extends AccessControlledModel {
	readonly __type = 'SelfAssessmentModel';
	athleteId?: string;
	startDate: Date
	standingHeightCm: number | null = null;
	trueSittingHeightCm: number | null = null;
	sittingHeightWithBoxCm: number | null = null;
	bodyMassKg: number | null = null;
	boxHeightCm: number | null = null;
	
	accelerationResults: SelfAssessmentTestModel = new SelfAssessmentTestModel(AthleteMetric.Acceleration);
	speedResults: SelfAssessmentTestModel = new SelfAssessmentTestModel(AthleteMetric.Speed);
	powerResults: SelfAssessmentTestModel = new SelfAssessmentTestModel(AthleteMetric.Power);
	agilityResults: SelfAssessmentTestModel = new SelfAssessmentTestModel(AthleteMetric.Agility);
	recoveryResults: SelfAssessmentTestModel = new SelfAssessmentTestModel(AthleteMetric.Recovery);

	resultsKey(metric: AthleteMetric): string{
		return `${metric}Results`;
	}
	results(metric: AthleteMetric): SelfAssessmentTestModel{
		return this[this.resultsKey(metric)];
	}

	/** This assessment was started within the last 5 days */
	get IsExpired(): boolean{
		const days = getDaysBetween(this.startDate, new Date()).days;
		return days >= 5;
	}
	/** This assessment has results for all Metrics */
	get IsComplete(): boolean{
		const isComplete = AthleteMetricValues.map(metric => this.results(metric).finalResult).reduce((a, b) => {
			return a && b !== null
		}, true);
		return isComplete;
	}
	/** This assessment has at least one test completed */
	get IsStarted(): boolean{
		const isStarted = AthleteMetricValues.map(metric => this.results(metric).finalResult !== null).reduce((a, b) => {
			return a || b
		});
		return isStarted;
	}

	// Compatibility fields with AssessmentDataModel
	get assessmentDate(): Date{
		return this.startDate;
	}
	set assessmentDate(date: Date){
		this.startDate = date;
	}

	get tenMeterSprint(): string{
		if(this.accelerationResults.finalResult === null) return "";
		return `${this.accelerationResults.finalResult}`;
	}
	set tenMeterSprint(_: string){ return }
	get twentyToThirtyFiveMeterSplit(): string{
		if(this.speedResults.finalResult === null) return "";
		return `${this.speedResults.finalResult}`;
	}
	set twentyToThirtyFiveMeterSplit(_: string){ return }
	get counterMovementJumpHeight(): string{
		if(this.powerResults.finalResult === null) return "";
		return `${this.powerResults.finalResult}`;
	}
	set counterMovementJumpHeight(_: string){ return }
	get reactiveStrengthIndex(): string{
		if(this.agilityResults.finalResult === null) return "";
		return `${this.agilityResults.finalResult}`;
	}
	set reactiveStrengthIndex(_: string){ return }
	get yoyoIntermittentRecoveryTestStage(): string{
		if(this.recoveryResults.finalResult === null) return "";
		return `${this.recoveryResults.finalResult}`;
	}
	set yoyoIntermittentRecoveryTestStage(_: string){ return }


	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (obj['startDate']) this.startDate = new Date(obj['startDate']);
		if (obj['accelerationResults']) this.accelerationResults = new SelfAssessmentTestModel().load(obj['accelerationResults']);
		if (obj['speedResults']) this.speedResults = new SelfAssessmentTestModel().load(obj['speedResults']);
		if (obj['powerResults']) this.powerResults = new SelfAssessmentTestModel().load(obj['powerResults']);
		if (obj['agilityResults']) this.agilityResults = new SelfAssessmentTestModel().load(obj['agilityResults']);
		if (obj['recoveryResults']) this.recoveryResults = new SelfAssessmentTestModel().load(obj['recoveryResults']);
		return this;
	}
}