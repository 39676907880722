import { CrudApi } from './CrudApi';
import { AthleteProfileModel } from '../models/athlete/AthleteProfileModel';
import { PaginatedResponse, PublicProfileInfo, QueryOptions, RecruitingProfileGetParam, RepositoryQuery } from '@/../types/interfaces';
import { RecruitingProfileModel } from '@/models/athlete/RecruitingProfileModel';
import { CustomerApi } from "./CustomerApi";
import { ViewTrackingRecordViewModel } from '@/models/viewTrackingRecord/viewTrackingRecordViewModel';
import { AxiosRequestConfig } from 'axios';
import { AthleteAssessmentDataModel } from '@/models';
import { AthleteAutocompleteInfo, CompareDto, GetSpiderChartApiResponse, AthleteScoutingReportDetails } from '@best-athletes/ba-types';

class AthleteApi extends CrudApi<AthleteProfileModel>{
	constructor() {
		super('athlete', (obj) => new AthleteProfileModel().load(obj));
	}
	async getBasicProfile(id: string): Promise<PublicProfileInfo<AthleteProfileModel>> {
		return await CrudApi.Api(c => c.get(`/${this.resource}/public/${id}/profile-basic`));
	}
	
	async getAvailableProfilesForUser(): Promise<AthleteProfileModel[]> {
		const result = await CrudApi.Api(c => c.get(`/${this.resource}/available-profiles`));
		return result.map(a => this.create(a));
	}
	async getProfilePicture(id: string): Promise<PublicProfileInfo<AthleteProfileModel>> {
		return await CrudApi.Api(c => c.get(`/${this.resource}/public/${id}/profile-picture`));
	}
	async getScoutingReportDetails(id: string): Promise<AthleteScoutingReportDetails> {
		const details = await CrudApi.Api(c => c.get(`/${this.resource}/${id}/scouting-report-details`));
		return {
			...details,
			dateOfBirth: details.dateOfBirth ? new Date(details.dateOfBirth) : undefined,
		};
	}

	async getAthleteRecruitingProfile(id: string, options: RecruitingProfileGetParam): Promise<RecruitingProfileModel> {
		const result = await CrudApi.Api((c) => c.get(`/${this.resource}/recruiting-profile/${id}`, {params: options}));
		return new RecruitingProfileModel().load(result);
	}

	async getSharingProfile(id: string, sharingUrlId: string): Promise<RecruitingProfileModel> {
		/**
		 * Same as recruiting profile except the url
		 */
		const result = await CrudApi.Api((c) => c.get(`/${this.resource}/${id}/sharing/${sharingUrlId}`));
		return new RecruitingProfileModel().load(result);
	}

	async athleteAutocomplete(search: string, options: QueryOptions, config?: AxiosRequestConfig): Promise<PaginatedResponse<AthleteAutocompleteInfo>> {
		const response: PaginatedResponse<AthleteAutocompleteInfo> = await CrudApi.Api(
			(c) => c.post(`/${this.resource}/autocomplete`, { search }, this.applyOptionsToRequest(options, config))
		);
		return {
			total: response.total,
			count: response.count,
			page: response.page,
			totalPages: response.totalPages,
			docs: response.docs,
		};
	}

	async queryViewRecords(id: string, query: RepositoryQuery<ViewTrackingRecordViewModel>, options: QueryOptions, config?: AxiosRequestConfig): Promise<PaginatedResponse<ViewTrackingRecordViewModel>> {
		const response: PaginatedResponse<ViewTrackingRecordViewModel> = await CrudApi.Api(
			(c) => c.post(`/${this.resource}/${id}/viewRecords/query`, query, this.applyOptionsToRequest(options, config))
		);
		return {
			total: response.total,
			count: response.count,
			page: response.page,
			totalPages: response.totalPages,
			docs: response.docs.map((doc: any) => new ViewTrackingRecordViewModel().load(doc)),
		};
	}

	async queryAssessmentsByAthleteId(athleteId: string, query: RepositoryQuery<AthleteAssessmentDataModel>, options: QueryOptions, config?: AxiosRequestConfig): Promise<PaginatedResponse<AthleteAssessmentDataModel>> {
		const response: PaginatedResponse<AthleteAssessmentDataModel> = await CrudApi.Api(
			(c) => c.post(`/${this.resource}/${athleteId}/assessments/query`, query, this.applyOptionsToRequest(options, config))
		);
		return {
			total: response.total,
			count: response.count,
			page: response.page,
			totalPages: response.totalPages,
			docs: response.docs.map((doc: any) => new AthleteAssessmentDataModel().load(doc)),
		};
	}

	async createSetupIntent(id: string): Promise<{client_secret: string} | null> {
		return CustomerApi.createSetupIntent('athlete', id)
	}

	async getAssessmentRadarChart(athleteId: string, body: CompareDto, config?: AxiosRequestConfig): Promise<GetSpiderChartApiResponse> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/${athleteId}/assessments/compare`, body, config));
	}

}
export const athleteApi = new AthleteApi();