import { getEnumValues } from './get-enum-values';
export enum PerformanceCategory{
	Recreational="Recreational",
	Competitive="Competitive",
	HighPerformance="HighPerformance",
	College="College",
	Professional="Professional",
	ProAcademy="ProAcademy",
	International="International",
}
export enum PerformanceCategoryIndex{
	Recreational=1,
	Competitive=2,
	HighPerformance=3,
	College=3,
	Professional=4,
	ProAcademy=4,
	International=5,
}
export const PerformanceCategoryValues = getEnumValues<PerformanceCategory>(PerformanceCategory);

/**
 * Returns the 5 performance category values based on age
 */
export const getPerformanceCategoryValues = (over18: boolean = false): PerformanceCategory[] => {
	if (over18) {
		return PerformanceCategoryValues.filter(p => p !== PerformanceCategory.HighPerformance && p !== PerformanceCategory.ProAcademy);
	}
	return PerformanceCategoryValues.filter(p => p !== PerformanceCategory.College && p !== PerformanceCategory.Professional);
};