
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, AuthMixin } from '../../mixins';
import { CommentModel } from '../../models/discussion/CommentModel';
import CircledAvatar from '../../components/ui/CircledAvatar.vue';
import { formatDate } from '../../helpers/date';


@Component({
	components: { CircledAvatar }
})
export default class Comment extends Mixins(AuthMixin, VuetifyMixin) {
	@Prop({ type: CommentModel }) comment: CommentModel;
	@Prop({ type: Boolean, default: false }) private editMode: boolean;
	formatDate = formatDate;

	submit():void{
		this.$emit('submit',this.comment)
	}

	cancel() {
		this.comment.body = '';
		this.$emit("cancel");
	}

	get IsValid(){
		return this.comment.body && this.comment.body.length > 1;
	}

	get IsMe(){
		return this.comment.userId === this.$auth.coachId
		|| this.comment.userId === this.$auth.athleteId
		|| this.comment.userId === this.$auth.user.id;
	}

	get AvatarOutlineColor(){
		if(this.IsMe) return this.getColor('baColorPrimaryBlue');
		return this.getColor('baColorAccentGold');
	}
}
