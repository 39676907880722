import * as LDClient from 'launchdarkly-js-client-sdk';
import { setFlags } from './index';
import { getInstance } from '../auth/auth0Plug'; 
import { UserModel } from '@/models/user/UserModel';
import { config } from '../config/config'

export const LaunchDarklyClientKey = config.VUE_APP_LAUNCH_DARKLY_CLIENT_KEY || process.env.VUE_APP_LAUNCH_DARKLY_CLIENT_KEY || '61894c2a0d89aa15579acc98';

/**
 * Load Feature Flags from Launch Darkly and register a listener for live updates.
 */
export const useLaunchDarkly = async () => {
	const authService = getInstance();
	const user = authService.user as UserModel;
	const ldclientUser: LDClient.LDUser = {
		key: authService.user.id,
		email: user.email,
		firstName: user.firstName,
		lastName: user.lastName,
		avatar: user.pictureUrl,
		custom: {
			groups: user.email.indexOf('@bestathletes.co') > -1 ? 'bestathletes-staff-email' : undefined,
		}
	};
	// @TODO: Dynamically set client key.
	const ldclient = LDClient.initialize(LaunchDarklyClientKey, ldclientUser, {
		bootstrap: 'localStorage'
	});
	ldclient.on('change', setFlags);
	await new Promise<void>((res) => {
		ldclient.on('ready', () => {
			setFlags(ldclient.allFlags());
			res();
		});
	});
}