
import Vue from 'vue'
import { mdiAccountCancel } from '@mdi/js'
export default Vue.extend({
	data(){
		return {
			mdiAccountCancel,
		}
	}
})
