export const MaleExpectedAgePHV: number = 13.5;
export const FemaleExpectedAgePHV: number = 11.5;

export const U13_Fake_Statistics = [
	{
		"type": "standard",
		"gender": "male",
		"ageGroup": "U13",
		"developmentalAge": "All",
		"performanceCategory": "Recreational",
		"acceleration": 2.25,
		"speed": 2.59,
		"power": 22,
		"agility": 0.7,
		"recovery": 13.2
	},
	{
		"type": "standard",
		"gender": "male",
		"ageGroup": "U13",
		"developmentalAge": "All",
		"performanceCategory": "HighPerformance",
		"acceleration": 1.9,
		"speed": 2.34,
		"power": 32,
		"agility": 1.44,
		"recovery": 16.4
	},
	{
		"type": "standard",
		"gender": "male",
		"ageGroup": "U13",
		"developmentalAge": "All",
		"performanceCategory": "International",
		"acceleration": 1.82,
		"speed": 1.95,
		"power": 39,
		"agility": 1.66,
		"recovery": 21.3
	},
	{
		"type": "standard",
		"gender": "male",
		"ageGroup": "U13",
		"developmentalAge": "All",
		"performanceCategory": "Competitive",
		"acceleration": 2.22,
		"speed": 2.55,
		"power": 23,
		"agility": 0.9,
		"recovery": 12.1
	},
	{
		"type": "standard",
		"gender": "male",
		"ageGroup": "U13",
		"developmentalAge": "All",
		"performanceCategory": "ProAcademy",
		"acceleration": 1.86,
		"speed": 2.4,
		"power": 35.69,
		"agility": 1.67,
		"recovery": 17.5
	}
]
export const O20_Fake_Statistics = [
	{
		"type": "standard",
		"gender": "male",
		"ageGroup": "O20",
		"developmentalAge": "All",
		"performanceCategory": "International",
		"acceleration": 1.42,
		"speed": 1.5,
		"power": 53,
		"agility": 1.9,
		"recovery": 22.1
	},
	{
		"type": "standard",
		"gender": "male",
		"ageGroup": "O20",
		"developmentalAge": "All",
		"performanceCategory": "College",
		"acceleration": 1.57,
		"speed": 1.75,
		"power": 42,
		"agility": 1.55,
		"recovery": 19.4
	},
	{
		"type": "standard",
		"gender": "male",
		"ageGroup": "O20",
		"developmentalAge": "All",
		"performanceCategory": "Recreational",
		"acceleration": 1.85,
		"speed": 2.3,
		"power": 28,
		"agility": 1.2,
		"recovery": 15.1
	},
	{
		"type": "standard",
		"gender": "male",
		"ageGroup": "O20",
		"developmentalAge": "All",
		"performanceCategory": "Professional",
		"acceleration": 1.5,
		"speed": 1.85,
		"power": 53,
		"agility": 1.95,
		"recovery": 24.1
	},
	{
		"type": "standard",
		"gender": "male",
		"ageGroup": "O20",
		"developmentalAge": "All",
		"performanceCategory": "Competitive",
		"acceleration": 1.95,
		"speed": 2,
		"power": 35,
		"agility": 1.5,
		"recovery": 19.1
	}
];