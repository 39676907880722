import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * Add this Mixin to any component using a MultiStep Form.
 * 
 * Provides steps, currentStep, computed props for steps being valid, previous and next functions.
 * 
 * Allows startingStep and formValue to be initialized through props
 */
@Component({})
export class MultiStepFormMixin extends Vue {

	@Prop({ default: null }) injectSteps: { [key: string]: Record<string, any> } | null;

	// Allow pre-filling the form via Props
	created(): void{
		if(this.injectSteps !== null){
			for(const key of Object.keys(this.injectSteps)){
				Object.assign(this.formValue[key], this.injectSteps[key]);
			}
		}
		if (this.$route.params.currentStep !== undefined) {
			const step = +this.$route.params.currentStep;
			if(step >= 1 && step <= this.steps){
				this.currentStep = step;
			}
		}
	}

	steps: number = 3;
	currentStep: number = 1;
	/**
	 * Should calling previous use window.history.back() when on the first step
	 */
	goBackWithPrevious: boolean = true;

	formValue = {};

	get CurrentStepKey(): string {
		return `step${this.currentStep}`;
	}
	get CurrentStepValid(): boolean {
		if (!this.formValue[this.CurrentStepKey]) return false;
		return this.formValue[this.CurrentStepKey].valid;
	}

	async next(validate?: () => Promise<boolean>): Promise<void>{
		if (validate && validate.call !== undefined && await validate()) {
			if(this.currentStep < this.steps){
				this.currentStep++;
			}
		}
		else {
			if(!this.CurrentStepValid) return;
			if(this.currentStep < this.steps){
				this.currentStep++;
			}
		}
	}
	previous(): void{
		if (this.currentStep > 1) {
			this.currentStep--;
		}else if(this.currentStep === 1 && this.goBackWithPrevious === true){
			window.history.back();
		}
	}

	get IsLastStep(): boolean{
		return this.currentStep === this.steps;
	}
}