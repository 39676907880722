import { AthleteProfileModel } from "./AthleteProfileModel";
import { SpqDataModel } from "@/models/athlete/SpqDataModel";
import { SelfAssessmentModel } from "@/models/athlete/SelfAssessmentModel";
import { AthleteAssessmentDataModel } from "@/models/athlete/AthleteAssessmentDataModel";
import { TeamModel } from "@/models/team";
export class RecruitingProfileModel extends AthleteProfileModel{
	SPQResults: SpqDataModel | null = null;
	selfAssessment: SelfAssessmentModel | null = null;
	physicalPerformance: AthleteAssessmentDataModel | null = null;
	teams: TeamModel[] = [];
	athleteProfile: AthleteProfileModel;

	load(data: Record<string, any>): this{
		const { SPQResults, selfAssessment, physicalPerformance, teams, ...athleteProfile } = data;
		// Convert the data to models; needed as there are getters and setters that are required
		this.SPQResults = SPQResults ? new SpqDataModel().load(SPQResults) : null;
		this.physicalPerformance = physicalPerformance ? new AthleteAssessmentDataModel().load(physicalPerformance) : null;
		this.selfAssessment = selfAssessment ? new SelfAssessmentModel().load(selfAssessment) : null;
		this.athleteProfile = athleteProfile ? new AthleteProfileModel().load(athleteProfile) : null;

		if (teams){
			this.teams = teams.map((team: any) => new TeamModel().load(team));
		}
		return this;
	}
}