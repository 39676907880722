
import { Component, Mixins, PropSync } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';
import { CoachProfileModel } from '@/models/coach/CoachProfileModel';
import { ExperienceItem } from '@/models/coach/CoachResumeModel';
import { mdiPlus } from '@mdi/js';
import CoachExperienceRow from "@/components/profile/settings/CoachExperienceRow.vue";

@Component({
	components: {
		CoachExperienceRow
	}
})
export default class CoachSettingCoachingExperience extends Mixins(VuetifyMixin){
	@PropSync('coachProfile', { required: true, type: CoachProfileModel }) 
	CoachProfile: CoachProfileModel;

	mdiPlus = mdiPlus;

	async created(): Promise<void> {
		this.eagerExperinceItems = this.CoachProfile.resume.experience;
	} 

	eagerExperinceItems: ExperienceItem[] = []
	get Experience(): ExperienceItem[]{
		return this.eagerExperinceItems;
	}

	get DefaultExperienceItem(): ExperienceItem {
		return new ExperienceItem().load({
			title: '',
			team: '',
			from: new Date(),
			to: new Date(),
			description: '',
		})
	}

}
