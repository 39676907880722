/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfig } from 'vue-router';
import { requireRolesGuard } from './routeGuards/requireRolesGuard';
import { AdminSideNavLayout } from '../layouts';
import ImpersonationLayout from '../layouts/ImpersonationLayout.vue';
import * as AdminRoutes from '../../types/constants/admin.routes';
import { AdminAppRoles } from '../../types/constants/role-lists';
import { HinderRouteMetadata } from './router';
import { EventAdminRouteConfigs } from './EventAdminRouteConfigs';
import { OrgAdminRouteConfigs } from './OrgAdminRouteConfigs';


const AdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/AdminDashboard.vue');
const OrganizationAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/OrganizationAdminDashboard.vue');
const TeamAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/TeamAdminDashboard.vue');
const TeamCreateNew = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/TeamAdminCreate.vue');
const VerifiedAssessmentAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/VerifiedAssessmentAdminDashboard.vue');
const OrganizationCreate = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/OrganizationCreate.vue');
const UserAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/UserAdminDashboard.vue');
const SchoolSearchAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/schoolSearch/SchoolSearchAdminDashboard.vue');
const SchoolCreate = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/schoolSearch/SchoolCreate.vue');
const Assessment = () => import(/* webpackChunkName: "AdminModule" */ '@/views/dataEntry/Assessment.vue');
const BackendJobs = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/BackendJobs.vue');
const FeatureFlags = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/FeatureFlags.vue');
const SchemaUpgrade = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/SchemaUpgrade.vue');
const SPQUploadAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/SPQUploadAdminDashboard.vue');

export const AdminRouteConfigs: RouteConfig[] = [
	{
		path: '/',
		component: AdminSideNavLayout,
		beforeEnter: requireRolesGuard(AdminAppRoles, 'any'),
		children: [
			{
				path: '/',
				name: AdminRoutes.AdminDashboard,
				component: AdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'BA Admin Dashboard',
				},
			},
			{
				path: '/users',
				name: AdminRoutes.UserAdminDashboard,
				component: UserAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Users',
				},
			},
			{
				path: '/schools',
				name: AdminRoutes.SchoolSearchAdminDashboard,
				component: SchoolSearchAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'School Search',
				},
			},
			{
				path: '/school/create/:currentStep?',
				name: AdminRoutes.SchoolCreate,
				component: SchoolCreate,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'School Setup',
				},
			},
			{
				path: '/school/:editSchoolId/edit/:currentStep?',
				name: AdminRoutes.SchoolEdit,
				component: SchoolCreate,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Edit School',
				},
			},
			{
				path: '/verified-assessments',
				name: AdminRoutes.VerifiedAssessmentAdminDashboard,
				component: VerifiedAssessmentAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Verified Assessments',
				},
			},
			{
				path: '/teams',
				name: AdminRoutes.TeamAdminDashboard,
				component: TeamAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Team Dashboard',
				},
			},
			{
				path: '/teams/create/:currentStep?',
				name: AdminRoutes.TeamCreateNew,
				component: TeamCreateNew,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Team Setup',
				},
			},
			{
				path: '/teams/:editTeamId/edit/:currentStep?',
				name: AdminRoutes.TeamEdit,
				component: TeamCreateNew,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Edit Team',
				},
			},
			{
				path: '/organizations',
				name: AdminRoutes.OrganizationAdminDashboard,
				component: OrganizationAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Organization Dashboard',
				},
			},
			{
				path: '/organization/create/:currentStep?',
				name: AdminRoutes.OrgCreate,
				component: OrganizationCreate,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Organization Setup',
				},
			},
			{
				path: '/organization/:editOrganizationId/edit/:currentStep?',
				name: AdminRoutes.OrgEdit,
				component: OrganizationCreate,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Edit Organization',
				},
			},
			{
				path: '/assessmentAdd',
				name: AdminRoutes.AssessmentAdd,
				component: Assessment,
				props: true,
			},
			{
				path: '/jobs',
				name: AdminRoutes.Jobs,
				component: BackendJobs,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Backend Jobs'
				}
			},
			{
				path: '/feature-flags',
				name: AdminRoutes.FeatureFlags,
				component: FeatureFlags,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Feature Flags'
				}
			},
			{
				path: '/schema-upgrade',
				name: AdminRoutes.SchemaUpgrade,
				component: SchemaUpgrade,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Schema Upgrade'
				}
			},
			{
				path: '/spq-upload',
				name: AdminRoutes.SPQUploadAdminDashboard,
				component: SPQUploadAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Upload SPQ'
				}
			},
			...EventAdminRouteConfigs
		]
	},
	{
		path: '/',
		component: ImpersonationLayout,
		props:{
			title: "You are viewing this Organization as a System Admin"
		},
		meta: <HinderRouteMetadata>{
			systemBarColor: 'baColorAdminPrimary'
		},
		beforeEnter: requireRolesGuard(AdminAppRoles, 'any'),
		children: OrgAdminRouteConfigs.map(r => ({
			...r,
			path: `orgAdmin/${r.path}`,
		})),
	}
];