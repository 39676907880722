
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '../../../mixins/VuetifyMixin';
import { PlayerOnTeam } from '../../../models/team';
import { DepthChartPosition } from '@/models/depthChart';
import PlayerSelector from '../DepthChart/Soccer/PlayerSelector.vue';
import { mdiPencil } from '@mdi/js';
export interface PositionCardProps{
	height?: number;
	width?: number;
	loading?: boolean;
	empty?: boolean;
	backgroundColor?: string;
	bodyTextColor?: string;
	headerColor?: string;
	headerTextColor?: string;
	goalie?: boolean;
	position: DepthChartPosition;
	players?: PlayerOnTeam[] ;
	maxNames?: number;
}

@Component({
	components: { PlayerSelector },
})
export default class LineupPositionCard extends Mixins(VuetifyMixin) implements PositionCardProps{

	@Prop({ default: 100 }) height: number;
	@Prop({ default: 122 }) width: number;
	mdiPencil = mdiPencil
	active = false;

	get SizeProps(): Record<string,any>{
		if(this.goalie && this.empty){
			return {
				width: this.width * 0.9,
				height: this.height * 0.65,
			}
		}
		return {
			width: this.width,
			height: this.height,
		};
	}

	@Prop({ required: true }) position: DepthChartPosition;
	@Prop({ default: false, type: Boolean }) loading: boolean;
	@Prop({ default: false, type: Boolean }) empty: boolean;
	@Prop({ default: 'baColorAppForeground' }) backgroundColor: string;
	@Prop({ default: 'baColorPrimaryText' }) bodyTextColor: string;
	@Prop({ default: 'baColorSuperLightBlue' }) headerColor: string;
	@Prop({ default: 'baColorPrimaryText' }) headerTextColor: string;
	@Prop({ default: false, type: Boolean }) goalie: boolean;
	@Prop({ default: false }) fromCreateLineup: boolean;
	@Prop({ default: false }) showPlayerSelector: boolean;

	get Empty(): boolean{
		if (this.position.playerIds === undefined){
			if (this.players.length === 0){
				return true
			} else {
				return false
			}
		}
		return this.empty || this.position.playerIds[0] === null
	}

	get Color(): string{
		return this.goalie ? this.getColor('baColorDeepBlue') : this.getColor(this.backgroundColor);
	}
	get BodyTextColor(): string{
		return this.goalie ? this.getColor('white') : this.getColor(this.bodyTextColor);
	}
	get BodyStyle(): Record<string,any>{
		return {
			'color': this.BodyTextColor,
			'background-color': this.Color,
		}
	}
	get HeaderColor(): string{
		return this.goalie ? this.getColor('baColorAccentGold1') : this.getColor(this.headerColor);
	}
	get HeaderTextColor(): string{
		return this.getColor(this.headerTextColor);
	}
	get HeaderStyle(): Record<string,any>{
		return {
			'color': this.getColor('baColorDeepBlue'),
			'background-color': this.HeaderColor,
		}
	}
	get PositionName(): string{
		if (this.position) {
			return this.position.position
		}
		return ''
	}
	
	get PositionNumber(): string | null{
		if(this.position){
			return this.position.number;
		}
		return null;
	}
	get label(): string{
		if(this.position){
			return this.position.label;
		}
		return "00";
	}

	@Prop({ default: () => ([
		{name: "Ryan Lawson"},
	]) }) players: PlayerOnTeam[];
	@Prop({ default: 3 }) maxNames: number;

	get PreviewPlayers(): PlayerOnTeam[]{
		return this.players.slice(0,this.maxNames);
	}
	updatePlayer(player: PlayerOnTeam): void{
		this.$emit('edit:player', player)
	}
}
