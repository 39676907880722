
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiClose, mdiPencil } from '@mdi/js';
import { LineupModel } from '../../models/lineup';
import { VuetifyMixin } from '../../mixins';
import Lineup from './Lineup.vue';
import { TeamModel } from '../../models/team';
import { ContextMenuItem } from '../../../types/interfaces';

@Component({
	components: { Lineup },
})
export default class LineupCard extends Mixins(VuetifyMixin) {

	@Prop({ required: true }) private team: TeamModel;
	@Prop({ default: null, required: true }) private lineup: LineupModel;
	@Prop({ type: Boolean, default: false }) private share: boolean;
	@Prop({ type: Boolean, default: false }) private disabled: boolean;
	updateShare(value: boolean): void{
		this.share = value;
		this.$emit('update:share', value);
	}

	get Title(): string{
		if(!this.lineup) return '';
		return this.lineup.name;
	}

	get ContextMenuItems(): ContextMenuItem[]{
		return [
			{
				text: 'Edit Lineup',
				click: () => {
					this.$emit('switch');
				},
				icon: mdiPencil,
			},
			{
				text: 'Clear Lineup',
				click: () => {
					this.$emit('clear');
				},
				icon: mdiClose,
			}
		];
	}

}
