import { CrudApi } from './CrudApi';
import { SelfAssessmentModel } from '../models/athlete/SelfAssessmentModel';

class SelfAssessmentApi extends CrudApi<SelfAssessmentModel>{
	constructor(){
		super('selfAssessment', (obj) => new SelfAssessmentModel().load(obj));
	}

	async getLatestSelfAssessmentResults({ athleteId }: { athleteId: string }): Promise<SelfAssessmentModel | null>{
		try{
			const obj = await CrudApi.Api(c => c.get(`${this.resource}/athlete/${athleteId}/summary`));
			return this.create(obj);
		} catch (e) {
			if (e.response.status === 404) {
				return null;
			}
			throw e;
		}
	}
	async findAllByAthleteId({ athleteId }: { athleteId: string }): Promise<SelfAssessmentModel[]>{
		const objs = await CrudApi.Api(c => c.get(`${this.resource}/athlete/${athleteId}`));
		return objs.map((obj: any) => this.create(obj));
	}
	async findLatestByAthleteId({ athleteId }: { athleteId: string }): Promise<SelfAssessmentModel | null>{
		try{
			const obj = await CrudApi.Api(c => c.get(`${this.resource}/athlete/${athleteId}/latest`));
			return this.create(obj);
		} catch (e) {
			console.log("findLatestByAthleteId", e);
			if (e.response.status === 404) {
				return null;
			}
			throw e;
		}
	}
}
export const selfAssessmentApi = new SelfAssessmentApi();