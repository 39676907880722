import { TaggableModel } from '../tag/TaggableModel';
import { Tag } from '../tag/Tag';

export class VideoClipModel extends TaggableModel {
	// Video ID not the actual video
	video: string | null = null
	parentId: string = null

	inHighlightReel: boolean = false;

	name: string = ''

	start: number = 0.0
	duration: number = 0.0

	thumbnailUrl: string = ''
	text: string = ''

	create_date: Date = new Date();

	category: string[] | null = null;

	load<T = this>(obj: Partial<T>): this {
		Object.assign(this, obj);
		if (obj['create_date']) this.create_date = new Date(obj['create_date']);
		if (Array.isArray(obj['tags'])) this.tags = obj['tags'].map((tag: Tag) => new Tag(tag.text, tag.subjectId, tag.color));
		return this;
	}

	get StartTime(): number{
		return this.start;
	}
	get EndTime(): number{
		return this.start + this.duration;
	}

	static ATHLETE_CLIP_CATEGORIES: string[] = [
		'Goals',
		'Assists',
		'Long Passes',
		'Pass Completions',
		'Through Balls',
		'Corners',
		'Penalty Kicks',
		'Set Pieces',
		'Free Kicks',
		'Crossing',
		'1v1 Offense',
		'1V1 Defense',
		'Tackles',
		'Interceptions',
		'Clearances',
		'Defensive Positioning',
		'Recovery Runs',
		'Aerial Duels',
		'Blocks',
		'Shots',
	];
}
