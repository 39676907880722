import { getEnumValues } from './get-enum-values';
export enum UniqueTokenType {
	/** Code which adds an athlete to a team */
	AthleteJoinCode='athleteJoinTeamCode',
	/** Code which links an athlete to a player on a team */
	PlayerJoinCode='playerJoinTeamCode',
	/** Code which links an athleteAssessmentData to an Athlete Profile */
	ClaimAssessmentCode='claimAssessmentCode',
	/** Code which links an SPQ20 Assessment to an Athlete Profile */
	ClaimSPQ20AssessmentCode='claimSPQ20AssessmentCode',
	/** Scouting report code */
	ScoutingReportCode='scoutingReportCode',
}
export const UniqueTokenTypeValues = getEnumValues<UniqueTokenType>(UniqueTokenType)