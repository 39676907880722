
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { RecurrenceRuleModel } from '@/models/calendar/RecurrenceRuleModel';
import WeekdaySelect from '@/components/calendar/WeekdaySelect.vue';
import { RecurrenceRuleFrequency } from '@/../types/enums';

type RRuleItem = {
	text: string;
	value: RecurrenceRuleModel | null;
	openDialog?: boolean;
}

@Component({
	components:{
		WeekdaySelect,
	}
})
export default class RecurrenceSelector extends Mixins(VuetifyMixin){
	@Prop({ default: () => new Date() }) startDate: Date;
	@Prop({ default: () => new Date() }) focusDate: Date;
	@Prop({ default: false, type: Boolean }) editMode: Date;
	@Prop({ default: null }) rule: RecurrenceRuleModel | null;

	isDisabled: boolean = false;
	updateRule(): void{
		if (this.formValue.interval < 1){
			this.isDisabled = true;
		} else{
			this.isDisabled = false;
		}
		this.$emit('update:rule', this.ComputedRule);
	}
	@Watch('rule') ruleChanged(rule: RecurrenceRuleModel): void{
		this.selectedRule = rule;
	}

	selectedRule: RecurrenceRuleModel | null = null;
	selectRule(item: RRuleItem): void {
		if(item === null) return;
		if(item === undefined) return;
		if(item.openDialog){
			this.dialog = true;
		}
		this.selectedRule = item.value;
		this.$emit('update:rule', this.selectedRule);
	}
	dialog: boolean = false;
	rules = [
		RecurrenceRuleModel.WeeklyToday(this.startDate),
		RecurrenceRuleModel.MonthlyToday(this.startDate),
	];

	async datePicked(): Promise<void> {
		this.formValue.endOptions = 1;
		this.updateRule();
	}

	get SuggestedRules(): RRuleItem[]{
		if(this.customRule !== null){
			this.rules = [
				RecurrenceRuleModel.WeeklyToday(this.startDate),
				RecurrenceRuleModel.MonthlyToday(this.startDate),
			];
			this.rules.push(this.customRule);
		}else if(this.selectedRule !== null && this.rules!.includes(this.selectedRule)){
			this.rules = [
				RecurrenceRuleModel.WeeklyToday(this.startDate),
				RecurrenceRuleModel.MonthlyToday(this.startDate),
			];
		} else if (this.rule !== null && this.rules.length < 3 && this.appliedCustomRule){
			this.appliedCustomRule = false;
			if (this.rule.weekdays.length > 0 && this.rule.interval !== null){
				this.rules.push(this.rule)
			} else {
				this.rules = [
					RecurrenceRuleModel.WeeklyToday(this.startDate),
					RecurrenceRuleModel.MonthlyToday(this.startDate),
				];
			}
			const standardFormValue = {
				interval: this.rule.interval,
				frequency: this.rule.frequency,
				weekdays: this.rule.weekdays,
				count: this.rule.count,
			}
			//Auto populate the recurrance form with previous selections
			if (this.rule.count !== null){
				this.formValue = {
					...standardFormValue,
					endDate: null,
					endOptions: 2,
				}
			}else if (this.rule.until !== null){
				this.formValue = {
					...standardFormValue,
					endDate: this.rule.until,
					endOptions: 1,
				}
			} else {
				this.formValue = {
					...standardFormValue,
					endDate: null,
					endOptions: 0,
				}
			}	
		} else {
			this.rules = [
				RecurrenceRuleModel.WeeklyToday(this.startDate),
				RecurrenceRuleModel.MonthlyToday(this.startDate),
			];
		}
		return [
			{
				text: "Does not repeat",
				value: null,
			},
			...this.rules.map(rule => ({
				text: rule.toText(),
				value: rule,
			})),
			{
				text: "Custom...",
				value: this.ComputedRule,
				openDialog: true,
			},
		];
	}

	get FreqItems(): { text: string, value: RecurrenceRuleFrequency }[]{
		return [
			{
				text: "Day",
				value: RecurrenceRuleFrequency.Daily,
			},
			{
				text: "Week",
				value: RecurrenceRuleFrequency.Weekly,
			},
			{
				text: "Month",
				value: RecurrenceRuleFrequency.Monthly,
			},
			{
				text: "Year",
				value: RecurrenceRuleFrequency.Yearly,
			},
		]
	}
	appliedCustomRule: boolean = false;
	applyCustomRule(): void{
		this.appliedCustomRule = true;
		this.customRule = this.ComputedRule;
		this.selectedRule = this.customRule;
		this.dialog = false;
		this.formValue = {
			interval: this.formValue.interval,
			frequency: this.formValue.frequency,
			weekdays: this.formValue.weekdays,
			count: this.formValue.count,
			endDate: this.formValue.endDate,
			endOptions: this.formValue.endOptions,
		};
	}

	formValue = {
		interval: 1,
		frequency: RecurrenceRuleFrequency.Weekly,
		weekdays: [],
		count: null,
		endDate: null,
		endOptions: 0,
	};

	customRule: RecurrenceRuleModel | null = null;
	get ComputedRule(): RecurrenceRuleModel{
		const rule = new RecurrenceRuleModel(this.formValue.frequency);
		rule.interval = this.formValue.interval;
		rule.weekdays = this.formValue.weekdays;
		rule.until = null;
		rule.startDate = this.editMode ? this.startDate : this.focusDate;
		if(this.formValue.endOptions === 1){
			rule.until = this.formValue.endDate;
		}else if (this.formValue.endOptions === 2){
			rule.count = this.formValue.count;
		} 
		return rule;
	}
	get ComputedRuleText(): string{
		return this.ComputedRule.toText();
	}
}
