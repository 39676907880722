import { Component,  Vue } from 'vue-property-decorator';
import { Stripe } from "@stripe/stripe-js";
import { CustomerApi } from '@/api/CustomerApi';
import { AthleteLineItemMeta } from '@/models/checkoutIntent/AthleteLineItemMeta';
import { AthleteCheckoutPage } from '@/../types/constants/web_client_user.routes';
import { StartCheckoutParams } from '@/../types/interfaces';
import { ProductModel } from '@/models/product/ProductModel';

@Component
export class StripeMixin extends Vue {
	$stripe: Stripe;

	async buyProduct(title: string, product: ProductModel, parentId: string): Promise<void>{
		const params: StartCheckoutParams<AthleteLineItemMeta> = {
			title,
			parentId: parentId,
			products:[
				{
					productId: product.stripeProductId,
					quantity: 1,
					meta:{
						athleteId: parentId,
					}
				}
			]
		};
		const intent = await CustomerApi.createCheckoutIntent('athlete', parentId, params);
		this.$router.push({
			name: AthleteCheckoutPage,
			params:{
				...this.$route.params,
				checkoutIntentId: intent.id
			}
		});
	}
}