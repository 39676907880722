
import Vue from 'vue';
import { VChip } from 'vuetify/lib';
export default Vue.extend({
	render(h) {
		return h(VChip, {
			class: "px-2 waivers-required-chip upper-case baColorPrimaryText--text",
			style: "font-weight: 500; font-size: 9px; line-height: 11px; letter-spacing: 0.12em;",
			attrs: {
				'x-small': true,
				'label': true,
				...this.$attrs,
			},
			on: this.$listeners,
			scopedSlots: this.$scopedSlots,
		}, Object.keys(this.$slots).map(slot => h('template', { slot }, this.$slots[slot])))
	}
})
