/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { CoachProfileModel } from '../../models/coach/CoachProfileModel'
import { coachApi } from '@/api/CoachApi';

const Mutations = {
	CREATE_RESUME: 'CREATE_RESUME',
	CREATE_RESUME_SUCCESS: 'CREATE_RESUME_SUCCESS',
	CREATE_RESUME_FAILURE: 'CREATE_RESUME_FAILURE',

	PATCH_RESUME: 'PATCH_RESUME',
	PATCH_RESUME_SUCCESS: 'PATCH_RESUME_SUCCESS',
	PATCH_RESUME_FAILURE: 'PATCH_RESUME_FAILURE',
}
const name = 'CoachResumeCreateStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class CoachResumeCreateModule extends VuexModule {

	initSelectorDataLoading: boolean = false

	loading: boolean = false;

	@Action({
		rawError: true,
		commit: Mutations.CREATE_RESUME_SUCCESS
	}) async createCoachResume(coachAndResume: CoachProfileModel) {
		this.context.commit(Mutations.CREATE_RESUME);
		try {
			const coach = await coachApi.patch(coachAndResume);
			this.context.commit('CoachProfileStore/SET_PROFILE', coach, { root: true });
			return coach;
		} catch (e) {
			console.error("Failed to Create Coach Resume", e);
			this.context.commit(Mutations.CREATE_RESUME_FAILURE, e);
		}
	}

	@Mutation [Mutations.CREATE_RESUME]() {
		this.loading = true;
	}
	@Mutation [Mutations.CREATE_RESUME_SUCCESS](coachAndResume: CoachProfileModel) {
		this.loading = false;
	}
	@Mutation [Mutations.CREATE_RESUME_FAILURE](error: any) {
		this.loading = false;
	}

	@Action({
		rawError: true,
		commit: Mutations.PATCH_RESUME_SUCCESS
	}) async patchCoachResume(coachAndResume: CoachProfileModel) {
		this.context.commit(Mutations.PATCH_RESUME);
		try {
			const coach = await coachApi.patch(coachAndResume);
			this.context.commit('CoachProfileStore/SET_PROFILE', coach, { root: true });
			return coach;
		} catch (e) {
			console.error("Failed to Create Coach Resume", e);
			this.context.commit(Mutations.PATCH_RESUME_FAILURE, e);
		}
	}

	@Mutation [Mutations.PATCH_RESUME]() {
		this.loading = true;
	}
	@Mutation [Mutations.PATCH_RESUME_SUCCESS](coachAndResume: CoachProfileModel) {
		this.loading = false;
	}
	@Mutation [Mutations.PATCH_RESUME_FAILURE](error: any) {
		this.loading = false;
	}
}
