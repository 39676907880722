import Vue from 'vue';

const getDefaultState = () => {
	return {
		defaultValue: false, // Undefined flags' default
		flags:{
			'dark-mode-toggle':{
				value: false,
			},
			/** Label non-production environments with badge on the logo */
			'environment-name-label':{
				value: false,
			},
			'HIN-1298-lineup-from-formation-button-toggle':{
				value: false,
			},
			'access-control-resend':{
				value: false,
			},
			'team-event-attendance':{
				value: false,
			}
		}
	}
}

// initial state
const state = getDefaultState()

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
	RESET_FLAG_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_FLAG(state, { key, value }) {
		Vue.set(state.flags, key, { value });
	},
}

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
}