/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Vue } from 'vue-property-decorator';
import { AdminAppHostname, AdminAppHostnames, AthleteAppHostname, ClubAdminAppHostname, ClubAdminAppHostnames, CoachingAppHostname } from '../../types/constants/hostnames';
import { CDN } from "@/../types/constants/cdns";
import { EnvironmentName } from '../../types/constants/environments';
import { config } from '@/config/config';
import { AppType } from "@/../types/AppType";
import { UserProfileModel } from '@/models/UserProfileModel';
import { isCoachRoute } from '@/router/routeGuards/coachAuthGuard';
import { userStore } from '@/store';

@Component
export class AppHostnameMixin extends Vue {
	envName: EnvironmentName = config.BA_ENV;

	get Hostname(): string {
		return location.host;
	}

	get AdminAppActive(): boolean {
		return AdminAppHostnames.includes(this.Hostname as any);
	}
	get AthleteAppActive(): boolean {
		return userStore.currentProfileData && userStore.currentProfileData.type === 'athlete';
	}
	get ClubAdminAppActive(): boolean {
		return ClubAdminAppHostnames.includes(this.Hostname as any);
	}
	get CoachAppActive(): boolean {
		return userStore.currentProfileData && userStore.currentProfileData.type === 'coach';
	}

	get getAppType(): AppType {
		if (this.AdminAppActive) return "admin"
		else if (this.ClubAdminAppActive) return "clubadmin"
		else if (this.CoachAppActive) return "coach"
		else if (this.AthleteAppActive) return "athlete"
		else return null
	}

	get AdminAppUrl(): AdminAppHostname {
		switch (this.envName) {
		case 'local':
			return 'admin.local.bestathletes.co:8080';
		case 'dev':
			return 'admin.dev.bestathletes.co';
		case 'testing':
			return 'admin.testing.bestathletes.co';
		case 'stage':
		case 'staging':
			return 'admin.stage.bestathletes.co';
		case 'production':
			return 'admin.app.bestathletes.co';
		default:
			return 'admin.local.bestathletes.co:8080';
		}
	}
	get AthleteAppUrl(): AthleteAppHostname {
		switch (this.envName) {
		case 'local':
			return 'app.local.bestathletes.co:8080';
		case 'dev':
			return 'dev.bestathletes.co';
		case 'testing':
			return 'testing.bestathletes.co';
		case 'stage':
		case 'staging':
			return 'stage.bestathletes.co';
		case 'production':
			return 'app.bestathletes.co';
		default:
			return 'app.local.bestathletes.co:8080';
		}
	}
	get CoachAppUrl(): CoachingAppHostname {
		switch (this.envName) {
		case 'local':
			return 'app.local.bestathletes.co:8080/c';
		case 'dev':
			return 'dev.bestathletes.co/c';
		case 'testing':
			return 'testing.bestathletes.co/c';
		case 'stage':
		case 'staging':
			return 'stage.bestathletes.co/c';
		case 'production':
			return 'app.bestathletes.co/c';
		default:
			return 'app.local.bestathletes.co:8080/c';
		}
	}
	get ClubAdminAppUrl(): ClubAdminAppHostname {
		switch (this.envName) {
		case 'local':
			return 'club.local.bestathletes.co:8080';
		case 'dev':
			return 'club.dev.bestathletes.co';
		case 'testing':
			return 'club.testing.bestathletes.co';
		case 'stage':
		case 'staging':
			return 'club.stage.bestathletes.co';
		case 'production':
			return 'club.app.bestathletes.co';
		default:
			return 'club.local.bestathletes.co:8080';
		}
	}

	get CDNBaseUrl(): CDN {
		switch (this.envName) {
		case 'local':
			return 'cdn-dev.bestathletes.co';
		case 'dev':
			return 'cdn-dev.bestathletes.co';
		case 'testing':
			return 'cdn-testing.bestathletes.co';
		case 'stage':
		case 'staging':
			return 'cdn-stage.bestathletes.co';
		case 'production':
			return 'cdn.bestathletes.co';
		default:
			return 'cdn.bestathletes.co';
		}
	}

	openAdminApp(): void {
		if (this.AdminAppActive){
			this.$router.push('/').catch(() => {});
			return;
		}
		window.location.href = `https://${this.AdminAppUrl}`;
	}
	openAthleteApp(): void {
		window.location.href = `https://${this.AthleteAppUrl}`;
	}
	openCoachApp(): void {
		window.location.href = `https://${this.CoachAppUrl}`;
	}
	openClubAdminApp(): void {
		if (this.ClubAdminAppActive){
			this.$router.push('/').catch(() => {});
			return;
		}
		window.location.href = `https://${this.ClubAdminAppUrl}`;
	}

	selectProfile(profile: UserProfileModel, type?: 'athlete' | 'coach'): void{
		window.location.href = this.selectProfileUrl(profile, type);
	}

	selectProfileUrl(profile: UserProfileModel, type?: 'athlete' | 'coach'): string {
		let href = `${window.location.origin}${window.location.pathname}?u=${profile.id}`;
		if (type === 'athlete' && isCoachRoute(window.location.pathname)) {
			href = `${window.location.origin}/?u=${profile.id}`;
		} else if (type === 'coach' && !isCoachRoute(window.location.pathname)) {
			href = `${window.location.origin}/c?u=${profile.id}`;
		}
		return href;
	}
}
