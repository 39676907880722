/* eslint-disable @typescript-eslint/no-unused-vars */

import { NavigationGuard, Route } from 'vue-router';
import { actionUrlStore } from '@/store';
import { isOnboardingRoute } from '../routeHelpers';

/**
 * Has the user visited a URL with a specific action associated?
 * 
 * If so, queue it up so we can complete the action once they've signed up/logged in.
 */
export const actionUrlPreGuard: NavigationGuard = async (to, _from, next) => {
	// console.log("actionUrlPreGuard");
	if (to.query.action !== undefined && !Array.isArray(to.query.action)){
		await actionUrlStore.queueAction({newActionId: to.query.action, to});
	} else if (!actionUrlStore.actionsInitialized) {
		await actionUrlStore.initActionQueue();
	}
	return next()
}

export const actionUrlPostHook: (to: Route, from: Route) => any = async (to, _from) => {
	// console.log("actionUrlPostHook", { 'actionUrlStore.HasActions': actionUrlStore.HasActions })
	if (isOnboardingRoute(to.name)){
		// console.log("isOnboardingRoute skip actions")
		if(actionUrlStore.HasRedirectAction){
			// console.log("Exec redirect action");
			await actionUrlStore.execAction(actionUrlStore.redirectQueuedWith);
		}
		return; // Don't exec actions before we have a user profile.
	}
	if (actionUrlStore.HasActions){
		try{
			await actionUrlStore.execActions();
		}catch(e){
			console.error('Error Executing Actions', e);
		}
	}
}
