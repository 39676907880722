import { User, UserTypeMap } from "@/../types/interfaces";


export function setDelightedProperties(user: User, map: Partial<UserTypeMap> = {}, fullName: string): void{
	if (!window['delighted']){
		console.warn('failed to initialize delighted');
		return;
	}
	if (window['delighted'].survey){
		const referral = user.user_metadata !== undefined ? user.user_metadata.referral : undefined;
		window['delighted'].survey({
			email: user.email,
			name: fullName,
			properties: {
				referral,
				email_verified: user.email_verified,
				...map,
			}
		})
	}
}
