/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfig, Route } from 'vue-router';
import { coachAuthGuard } from './routeGuards/coachAuthGuard';
import { CoachSideNavLayout } from '@/layouts';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { HinderRouteMetadata } from './router';

const InstructorCourseList = () => import(/* webpackChunkName: "CourseInstructorModule" */'@/views/InstructorCourseList.vue');
const CourseDashboard = () => import(/* webpackChunkName: "CourseInstructorModule" */'@/views/CourseDashboard.vue');
const NewAssignment = () => import(/* webpackChunkName: "CourseInstructorModule" */'@/views/NewAssignment.vue');
const CourseCreate =  () => import(/* webpackChunkName: "CourseInstructorModule" */'@/views/CourseCreate.vue');
const AssignmentEvaluation = () => import(/* webpackChunkName: "CourseInstructorModule" */'@/views/AssignmentEvaluation.vue');


export const InstructorRouteConfigs: RouteConfig[] = [{
	beforeEnter: coachAuthGuard,
	path: '/i',
	component: CoachSideNavLayout,
	props: true,
	redirect: { name: Routes.InstructorCourseList },
	children:[
		{
			path: 'course',
			name: Routes.InstructorCourseList,
			component: InstructorCourseList,
			props: true,
			meta: <HinderRouteMetadata>{
				pageTitle: "Licensing Courses",
			}
		},
		{
			path: 'course/:courseId',
			name: Routes.CourseDashboard,
			component: CourseDashboard,
			props: true,
			meta: <HinderRouteMetadata>{
				pageTitle: "Back to courses",
				useBackButton: true,
			}
		},
		{
			path: 'course/:courseId/edit/:currentStep?',
			name: Routes.EditCourse,
			component: CourseCreate,
			props: (to: Route) => {
				return {
					editCourseId: to.params['courseId']
				}
			},
			meta: <HinderRouteMetadata>{
				pageTitle: "Back to course",
				useBackButton: true,
			}
		},
		{
			path: 'course/:courseId/newassignment',
			name: Routes.NewAssignment,
			component: NewAssignment,
			props: true,
			meta: <HinderRouteMetadata>{
				pageTitle: "Back to course",
				useBackButton: true,
			}
		},
		{
			path: 'create/course',
			name: Routes.CourseCreate,
			component: CourseCreate,
			meta: <HinderRouteMetadata>{
				pageTitle: "Create a Course",
			}
		},
		{
			path: 'course/:courseId/student/:studentId/assignment/:assignmentId',
			name: Routes.AssignmentEvaluation,
			component: AssignmentEvaluation,
			meta: <HinderRouteMetadata>{
				pageTitle: "Assignment Evaluation",
			}
		},
	]
}];