var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-btn',_vm._b({class:{
		'ba-rounded-button': true, 
		'px-6 py-5': !_vm.dense,
		'px-4': _vm.dense,
		'RoundedClass': !_vm.Rounded,
		'text-transform-none': !_vm.upperCase,
		'light-text': _vm.lightText,
		[_vm.textColor]: true,
	},style:(_vm.AddButtonStyle),attrs:{"type":"button","color":_vm.color,"outlined":_vm.outlined,"rounded":_vm.Rounded},on:{"click":(e) => _vm.$emit('click', e)},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-progress-circular',{attrs:{"color":_vm.loaderColor,"size":_vm.loaderSize,"width":_vm.loaderWidth,"indeterminate":""}})]},proxy:true}])},'v-btn',_vm.$attrs,false),[(_vm.icon !== null)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":_vm.smallIcon,"color":_vm.color}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._t("default",function(){return [_vm._v(" Add ")]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }