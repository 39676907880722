import { AthleteMetric } from '../../types/enums';
export function getMetricColor(value: AthleteMetric): string {
	switch (value) {
	case AthleteMetric.Acceleration:
		return 'baColorHighPerformanceBlue'
	case AthleteMetric.Speed:
		return 'baColorProPurple';
	case AthleteMetric.Power:
		return 'baColorInternationalPink';
	case AthleteMetric.Agility:
		return 'baColorCompetitiveTeal';
	case AthleteMetric.Recovery:
		return 'baColorRecreationalGreen';
	default:
		return 'baColorUnrankedYellow';
	}
}
