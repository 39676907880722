
import { Component, Mixins, PropSync } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';
import { AwardItem } from '@/models/coach/CoachResumeModel';
import DatePicker from "@/components/forms/DatePicker.vue";
import { mdiCloseCircle } from '@mdi/js';
import { YearPicker } from "@/components/forms/YearPicker.vue";

@Component({
	components: { 
		DatePicker,
		YearPicker
	}
})
export default class CoachChampionshipRow extends Mixins(VuetifyMixin){
	@PropSync('championship', { required: true }) 
	Championship: AwardItem;

	mdiCloseCircle = mdiCloseCircle;
}
