
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiDotsHorizontal, mdiChevronDown, mdiPencil, mdiContentDuplicate, mdiCloseCircle, mdiPlus } from '@mdi/js';
import ContextMenu from '../../ui/ContextMenu.vue';
import SoccerDepthChart from './Soccer/SoccerDepthChart.vue';
import { VuetifyMixin } from '../../../mixins/VuetifyMixin';
import { FormRulesMixin } from '../../../mixins/FormRulesMixin';
import { TeamModel } from '../../../models/team';
import { DepthChartPosition } from '../../../models/depthChart';
import { teamDashboardStore } from '../../../store';

@Component({
	components: { ContextMenu, SoccerDepthChart },
})
export default class DepthChart extends Mixins(FormRulesMixin, VuetifyMixin){
	mdiDotsHorizontal = mdiDotsHorizontal;
	mdiChevronDown = mdiChevronDown;
	get HeaderStyle(){
		return {
			'border-top': `1px solid #074E69 !important`,
			'background-color': this.getColor('baColorDeepBlue'),
		}
	}
	get DepthCharts(){
		return this.team.depthCharts;
	}
	get DepthChartLoading(){
		return teamDashboardStore.saveDepthChartLoading || teamDashboardStore.deleteDepthChartLoading;
	}

	@Prop({ required: true }) private team: TeamModel;
	@Prop({ default: 'baColorSuperLightBlue' }) private color: string;
	@Prop({ default: 'baColorPrimaryBlue' }) private borderColor: string;
	contextMenuItems = [
		{ icon: mdiPencil, text: "Rename", click: this.confirmRename },
		{ icon: mdiContentDuplicate, text: "Duplicate", click: this.confirmDuplicate},
		{ icon: mdiCloseCircle, text: "Delete", click: this.confirmDepthChart },
		{ icon: mdiPlus, text: "New Depth Chart", click: this.newDepthChart },
	];

	get SelectedDepthChartId(){
		return teamDashboardStore.selectedDepthChartId;
	}
	get SelectedDepthChart(){
		return teamDashboardStore.SelectedDepthChart;
	}
	async selectDepthChart(id?: string){
		await teamDashboardStore.selectDepthChart(id);
	}

	get ConfirmDeleteTitle(){
		return this.SelectedDepthChart ? `Delete "${this.SelectedDepthChart.name}"?` : 'Delete?';
	}
	showDeleteDialog: boolean = false;
	confirmDepthChart(){
		this.showDeleteDialog = true;
	}
	async deleteSelectedDepthChart(){
		this.showDeleteDialog = false;
		await teamDashboardStore.deleteDepthChart({
			teamId: this.team.id,
			depthChartId: this.SelectedDepthChartId,
		});
	}

	get ConfirmDuplicateTitle(){
		return this.SelectedDepthChart ? `Make a copy of "${this.SelectedDepthChart.name}"?` : 'Make a copy of this depth chart?';
	}
	showDuplicateDialog: boolean = false;
	duplicateNameValid: boolean = true;
	duplicateName: string = "";
	confirmDuplicate(){
		this.duplicateName = `Copy of ${this.SelectedDepthChart.name}`;
		this.showDuplicateDialog = true;
	}
	async duplicateSelectedDepthChart(){
		this.showDuplicateDialog = false;
		await teamDashboardStore.duplicateDepthChart({
			teamId: this.team.id,
			name: this.duplicateName,
			depthChartId: this.SelectedDepthChartId,
		});
	}

	get ConfirmRenameTitle(){
		return this.SelectedDepthChart ? `Rename "${this.SelectedDepthChart.name}"?` : 'Rename this depth chart?';
	}
	showRenameDialog: boolean = false;
	renameNameValid: boolean = true;
	newName: string = "";
	confirmRename(){
		this.newName = this.SelectedDepthChart.name;
		this.showRenameDialog = true;
	}
	async renameSelectedDepthChart(){
		this.showRenameDialog = false;
		await teamDashboardStore.renameDepthChart({
			teamId: this.team.id,
			newName: this.newName,
			depthChartId: this.SelectedDepthChartId,
		});
	}

	newDepthChart(){
		this.$emit('new');
	}

	editPosition(position: DepthChartPosition){
		teamDashboardStore.editDepthChartPosition({
			depthChartId: this.SelectedDepthChart.id,
			position,
		});
	}
}
