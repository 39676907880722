import { AthleteAssessmentDataModel } from '@/models/athlete/AthleteAssessmentDataModel';
import { SelfAssessmentModel } from '@/models/athlete/SelfAssessmentModel';
import { MetricData } from 'types/interfaces';
import { AthleteMetric } from '../../types/enums';
import { getMetricUnitLabel } from './metric-unit-label.pipe';
import { getMetricUnit } from './metric-unit.pipe';
import { assessmentMetricValueOrNull } from '@/pipes/assessment-metric-value-or-null.pipe';
import { metricHasValue } from './metric-has-value.pipe';

export function assessmentToMetric(assessmentData: AthleteAssessmentDataModel | SelfAssessmentModel, metric: AthleteMetric): MetricData {
	if (!assessmentData) {
		return {
			label: '--', value: '--', unit: '', missing: true,
		}
	}
	let value: string;
	let missing: boolean = true;
	switch (metric) {
	case AthleteMetric.Acceleration:
		value = assessmentMetricValueOrNull(assessmentData.tenMeterSprint);
		missing = !metricHasValue(assessmentData.tenMeterSprint);
		break;
	case AthleteMetric.Speed:
		value = assessmentMetricValueOrNull(assessmentData.twentyToThirtyFiveMeterSplit);
		missing = !metricHasValue(assessmentData.twentyToThirtyFiveMeterSplit);
		break;
	case AthleteMetric.Power:
		value = assessmentMetricValueOrNull(assessmentData.counterMovementJumpHeight);
		missing = !metricHasValue(assessmentData.counterMovementJumpHeight);
		break;
	case AthleteMetric.Agility:
		value = assessmentMetricValueOrNull(assessmentData.reactiveStrengthIndex);
		missing = !metricHasValue(assessmentData.reactiveStrengthIndex);
		break;
	case AthleteMetric.Recovery:
		value = assessmentMetricValueOrNull(assessmentData.yoyoIntermittentRecoveryTestStage);
		missing = !metricHasValue(assessmentData.yoyoIntermittentRecoveryTestStage);
		break;
	default:
		value = assessmentMetricValueOrNull();
		missing = true;
	}
	return {
		label: getMetricUnitLabel(metric),
		unit: getMetricUnit(metric),
		value,
		missing,
	};
}
