import LogRocket from 'logrocket';
import { UserTypeMap } from 'types/interfaces';
import { config } from '../config/config'
export const LOG_ROCKET_APP_ID = config.VUE_APP_LOG_ROCKET_APP_ID || process.env.VUE_APP_LOG_ROCKET_APP_ID;
// export const LOG_ROCKET_APP_ID = 'yuebri/best-athletes-dev';
if(LOG_ROCKET_APP_ID){
	LogRocket.init(LOG_ROCKET_APP_ID);
}

type LRUserTraits = {
	name: string,
	email: string,
	[key: string]: any
} & Partial<UserTypeMap>;

export function setLogRocketIdentity(userId: string, traits: LRUserTraits): void{
	if(LOG_ROCKET_APP_ID){
		return LogRocket.identify(userId, traits);
	}
}
