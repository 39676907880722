import store from '../store';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';

import { VideoModel } from '@/models/video/VideoModel';
import { videoClipCategoriesModelApi } from '@/api/VideoClipCategoriesApi';
import { SportName } from '@/../types/enums';
import { ClipCategoryModel } from '@/models/video-clip-categories/ClipCategoryModel';
import { sourceVideoMuxModelApi } from '@/api/SourceVideoMuxApi';
import { SourceVideoMuxModel } from '@/models/source-video/SourceVideoMuxModel';
import { SourceVideoFileDetails } from '@/models/source-video/SourceVideoMuxUpload';

const ClipCreationToolMutationTypes = {
	CREATE_SV: 'CREATE_SV',
	UPDATE_SV: 'UPDATE_SV',
	DELETE_SV: 'DELETE_SV',

	LOAD_ALL_SVS: 'LOAD_ALL_SVS',
	LOADING_SVS: 'LOADING_SVS',
	LOADED_SVS_SUCCESS: 'LOADED_SVS_SUCCESS',
	LOADED_SVS_FAILURE: 'LOADED_SVS_FAILURE',
	UNLOAD_THE_SVS: 'UNLOAD_THE_SVS',

	SV_BACKEND_UPLOAD_ATTEMPTING: 'SV_BACKEND_UPLOAD_ATTEMPTING',
	SV_BACKEND_UPLOAD_SUCCESS: 'SV_BACKEND_UPLOAD_SUCCESS',
	SV_BACKEND_UPLOAD_FAILURE: 'SV_BACKEND_UPLOAD_FAILURE',
	SV_BACKEND_UPDATE_PROGRESS: 'SV_BACKEND_UPDATE_PROGRESS',
	SV_BACKEND_CREATE_UPCHUNK: 'SV_BACKEND_CREATE_UPCHUNK',

	SV_CREATE_UPLOAD: 'SV_CREATE_UPLOAD',
	SV_CREATE_UPLOAD_SUCCESS: 'SV_CREATE_UPLOAD_SUCCESS',
	SV_CREATE_UPLOAD_FAILURE: 'SV_CREATE_UPLOAD_FAILURE',

	SV_CANCEL_UPLOAD: 'SV_CANCEL_UPLOAD',
	SV_CANCEL_UPLOAD_SUCCESS: 'SV_CANCEL_UPLOAD_SUCCESS',
	SV_CANCEL_UPLOAD_FAILURE: 'SV_CANCEL_UPLOAD_FAILURE',
}

@Module({
	namespaced: true,
	dynamic: true,
	name: 'VideoClipCreationstore',
	store: store
})
export default class VideoClipModule extends VuexModule {

	initSelectorDataLoading: boolean = false;

	savingSVToBackend: boolean = false;
	saveSVProgressPercentage: number = 0;
	uploadObject: any | null = null;

	cancellingAnSVUpload = false;

	loadingSVsList: boolean = false;
	theSVsListInitialized: boolean = false;

	sourceVideosLoaded: VideoModel[] = []

	clipCategories: ClipCategoryModel[] = [];
	
	@MutationAction
	async updateClipCategories() {
		const clipCategories = (await videoClipCategoriesModelApi.findBySport({sportName: SportName.Soccer})).flatMap(categories => categories.clipCategories);

		return { clipCategories };
	}

	/** 
	* Load all the Source Videos from DB                                    
	*/
	@Action({
		rawError: true,
		commit: ClipCreationToolMutationTypes.LOADED_SVS_SUCCESS
	}) async loadAllSourceVideosFromDB(payload: any) {
		this.context.commit(ClipCreationToolMutationTypes.LOADING_SVS);
		try {
			return await sourceVideoMuxModelApi.getAll();
		} catch (e) {
			console.error("Failed to Load The Source Videos", e);
			this.context.commit(ClipCreationToolMutationTypes.LOADED_SVS_FAILURE, e);
		}
	}

	@Action({
		rawError: true,
		commit: ClipCreationToolMutationTypes.LOADED_SVS_SUCCESS
	}) async loadSourceVideosForTeamEvent(withId: string) {
		this.context.commit(ClipCreationToolMutationTypes.LOADING_SVS);
		try {
			return await sourceVideoMuxModelApi.getSourceVideoForTeamEvent(withId);
		} catch (e) {
			console.error("Failed to Load The Source Videos for Team Event", e);
			this.context.commit(ClipCreationToolMutationTypes.LOADED_SVS_FAILURE, e);
		}
	}

	@Action({
		rawError: true,
		commit: ClipCreationToolMutationTypes.LOADED_SVS_SUCCESS
	}) async loadSourceVideosForEvaluationAssignment(withId: string) {
		this.context.commit(ClipCreationToolMutationTypes.LOADING_SVS);
		try {
			return await sourceVideoMuxModelApi.getSourceVideoForEvaluationAssignment(withId);
		} catch (e) {
			console.error("Failed to Load The Source Videos for Team Event", e);
			this.context.commit(ClipCreationToolMutationTypes.LOADED_SVS_FAILURE, e);
		}
	}

	// Not implemented in API or Backend yet (BAS - July 2, 2020)
	// @Action({
	//   rawError: true,
	//   commit: ClipCreationToolMutationTypes.LOADED_SVS_SUCCESS
	// }) async loadSourceVideosForAthlete(withId: string) {
	//   this.context.commit(ClipCreationToolMutationTypes.LOADING_SVS);
	//   try {
	//     return await sourceVideoMuxModelApi.getSourceVideoForAthlete(withId);
	//   } catch (e) {
	//     console.error("Failed to Load The Source Videos for Athlete", e);
	//     this.context.commit(ClipCreationToolMutationTypes.LOADED_SVS_FAILURE, e);
	//   }
	// }

	@Mutation [ClipCreationToolMutationTypes.LOADING_SVS]() {
		this.loadingSVsList = true;
	}
	@Mutation [ClipCreationToolMutationTypes.LOADED_SVS_SUCCESS](svsArray: VideoModel[]) {
		// console.log('In the clip creation tool editor getting all SVs and the returned array is: ', svsArray);
		this.loadingSVsList = false;
		this.theSVsListInitialized = true;
		this.sourceVideosLoaded = svsArray; // probably want to push these on one by one.
	}
	@Mutation [ClipCreationToolMutationTypes.LOADED_SVS_FAILURE](error: any) {
		this.loadingSVsList = false;
	}

	@Action({
		rawError: true
	}) async unloadSourceVideosFromStore() {
		this.context.commit(ClipCreationToolMutationTypes.UNLOAD_THE_SVS);
	}
	@Mutation [ClipCreationToolMutationTypes.UNLOAD_THE_SVS]() {
		while (this.sourceVideosLoaded.length > 0) {
			// console.log('Popping a value from the sourceVideosLoaded array')
			this.sourceVideosLoaded.pop()
		}
		this.theSVsListInitialized = false;
	}


	/** 
		* Create and Upload a Source Videos to Mux                              
		*/
	// @Action({
	//   rawError: true
	// }) async createAndUploadSourceVideo(theSVFile: SourceVideoFileDetailsWithFile) {
	//   this.context.commit(ClipCreationToolMutationTypes.SV_BACKEND_UPLOAD_ATTEMPTING);
	//   let uploadSVReply: SourceVideoMuxModel;
	//   try {
	//     uploadSVReply = await sourceVideoMuxModelApi.getDirectUploadURL(theSVFile);
	//     console.log("In the Clip create store the reply from the backend is : ", uploadSVReply);
	//   } catch (e) {
	//     console.error("Failed to Create the Source Video", e);
	//     this.context.commit(ClipCreationToolMutationTypes.SV_BACKEND_UPLOAD_FAILURE, e);
	//   }
	//   // const thePayload: any = {
	//   //   "theURL": uploadSVReply.muxVideoUploadUrl,
	//   //   "theFileString": theSVFile.theFile
	//   // }

	//   // this.context.commit(ClipCreationToolMutationTypes.SV_BACKEND_CREATE_UPCHUNK, thePayload);

	//   const upload = UpChunk.createUpload({
	//     endpoint: uploadSVReply.muxVideoUploadUrl,
	//     file: theSVFile.theFile,
	//     chunkSize: 1024, // Uploads the file in ~1mb chunks
	//   });

	//   // subscribe to events
	//   upload.on('error', err => {
	//     console.error('💥 🙀', err.detail);
	//   });

	//   upload.on('progress', progress => {
	//     console.log(`So far we've uploaded ${progress.detail}% of this file.`);
	//     Vue.nextTick(() => {
	//       this.context.commit(ClipCreationToolMutationTypes.SV_BACKEND_UPDATE_PROGRESS, progress);
	//     })
	//   });

	//   upload.on('success', () => {
	//     console.log("Wrap it up, we're done here. 👋");
	//     this.context.commit(ClipCreationToolMutationTypes.SV_BACKEND_UPLOAD_SUCCESS);

	//   });

	//   return uploadSVReply;


	// }
	@Mutation [ClipCreationToolMutationTypes.SV_BACKEND_UPLOAD_ATTEMPTING]() {
		this.savingSVToBackend = true;
		this.saveSVProgressPercentage = 0;
	}
	@Mutation [ClipCreationToolMutationTypes.SV_BACKEND_UPLOAD_SUCCESS]() {
		this.savingSVToBackend = false;
	}
	@Mutation [ClipCreationToolMutationTypes.SV_BACKEND_UPDATE_PROGRESS](progress: any) {
		console.log("In the SV Upload progress mutation and progress is: ", progress)
		this.saveSVProgressPercentage = null;
		this.saveSVProgressPercentage = progress.detail;
		console.log("In the SV Upload progress mutation and the percentage is: ", this.saveSVProgressPercentage)
	}

	@Action({
		rawError: true,
		commit: ClipCreationToolMutationTypes.SV_CREATE_UPLOAD_SUCCESS
	}) async createSVUploadShell(theSVFile: SourceVideoFileDetails) {
		this.context.commit(ClipCreationToolMutationTypes.SV_CREATE_UPLOAD);
		try {
			return await sourceVideoMuxModelApi.getDirectUploadURL(theSVFile);
		} catch (e) {
			console.error("Failed to Create the Source Video Shell", e);
			this.context.commit(ClipCreationToolMutationTypes.SV_CREATE_UPLOAD_FAILURE, e);
		}
	}
	@Mutation [ClipCreationToolMutationTypes.SV_CREATE_UPLOAD]() {
		this.savingSVToBackend = true;
	}
	@Mutation [ClipCreationToolMutationTypes.SV_CREATE_UPLOAD_SUCCESS](theSVShell: SourceVideoMuxModel) {
		this.savingSVToBackend = false;
	}
	@Mutation [ClipCreationToolMutationTypes.SV_CREATE_UPLOAD_FAILURE](error: any) {
		this.savingSVToBackend = false;
	}

	@Action({
		rawError: true,
		commit: ClipCreationToolMutationTypes.SV_CANCEL_UPLOAD_SUCCESS
	}) async cancelSVUpload(theUploadId: string) {
		this.context.commit(ClipCreationToolMutationTypes.SV_CANCEL_UPLOAD);
		try {
			return await sourceVideoMuxModelApi.cancelCurrentUpload(theUploadId);
		} catch (e) {
			console.error("Failed to Cancel the current upload", e);
			this.context.commit(ClipCreationToolMutationTypes.SV_CANCEL_UPLOAD_FAILURE, e);
		}
	}
	@Mutation [ClipCreationToolMutationTypes.SV_CANCEL_UPLOAD]() {
		this.cancellingAnSVUpload = true;
	}
	@Mutation [ClipCreationToolMutationTypes.SV_CANCEL_UPLOAD_SUCCESS](cancelResult: any) {
		this.cancellingAnSVUpload = false;
	}
	@Mutation [ClipCreationToolMutationTypes.SV_CANCEL_UPLOAD_FAILURE](error: any) {
		this.cancellingAnSVUpload = false;
	}


}
