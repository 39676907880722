import { Component, Vue } from 'vue-property-decorator';

/**
 * Provides helpers to access Vuetify theme and color information.
 */
@Component
export class VuetifyMixin extends Vue {
	get theme(): 'light' | 'dark' {
		if (!this.$vuetify.theme) return 'light';
		if (this.$vuetify.theme.dark) return 'dark';
		return 'light';
	}

	getColor(color: string, dark = false): string {
		if (!this.$vuetify.theme) return color;
		return this.$vuetify.theme.themes[dark ? 'dark' : this.theme][color] as string || color;
	}
	
	get IsMobile(): boolean {
		return this.$vuetify.breakpoint.mobile;
	}
	get IsSmallScreen(): boolean {
		return this.$vuetify.breakpoint.smAndDown;
	}
	get IsDark(): boolean {
		if (!this.$vuetify.theme) return false;
		return this.$vuetify.theme.dark;
	}
}
