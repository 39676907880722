import { FrontEndModel } from '../FrontEndModel';
export class CalendarModel extends FrontEndModel {
	parentId: string = "";
	name: string = "";
	description: string = "";
	timezone: string = "";
	constructor(parentId: string){
		super();
		this.parentId = parentId;
	}
}