import { DirectiveFunction } from 'vue';
import { commentNode } from './commentNode';
import { userStore } from '../store';
import { RoleName } from '@/../types/enums';

export function hasRole(roleName: RoleName): boolean {
	return userStore.user.roles.includes(roleName);
}

/**
 * Requires any or all of a given role or list of roles to be present on the user for the element to appear.
 */
export const requireRoleDirective: DirectiveFunction = function (el, binding, vnode): void {
	let op: 'any' | 'all' = 'all';
	if (binding.arg === 'any'){
		op = 'any';
	}
	const roles: RoleName | RoleName[] = binding.value;
	let showFeature: boolean = false;
	if(Array.isArray(roles)){
		showFeature = roles.map(role => hasRole(role)).reduce(
			op === 'all' ? (a,b) => a && b : (a,b) => a || b
		);
	}else{
		showFeature = hasRole(roles);
	}
	if(showFeature !== true){
		commentNode(el,vnode); // Replaces element with comment, hiding it.
	}
};