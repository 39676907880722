export enum MuxVideoUploadStatus {
  Uploading = 'uploading',
  Preparing = 'preparing',
  Partial = 'partial',
  Cancelled = 'cancelled',
  Ready = 'ready',
  Deleted = 'deleted',
}

export enum SourceVideoUsageType {
  TeamEvent = 'TeamEvent',
  Athlete = 'Athlete',
  Evaluation = 'Evaluation'
}
