
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mdiChevronDown, mdiTransfer } from '@mdi/js';

@Component
export default class ImpersonationLayout extends Vue{
	mdiTransfer = mdiTransfer;
	mdiChevronDown = mdiChevronDown;

	@Prop({ default: null }) title: string | null;

}
