import { TeamEventType } from '../../types/enums';

export function teameventtype(role: TeamEventType): string {
	switch (role) {
	case TeamEventType.Game: return 'Game';
	case TeamEventType.Practice: return 'Practice';
	case TeamEventType.Social: return 'Meeting';
	default:
		return "Game";
	}
}