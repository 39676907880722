
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiPlus, mdiCloseCircle, mdiChevronDown } from '@mdi/js';
import FormAddArea from '@/components/forms/FormAddArea.vue';
import { StaffRole } from '@/../types/enums/staff-role';
import { FormRulesMixin } from '@/mixins';

@Component({
	components: { FormAddArea },
})
export default class TeamCreateStaffInfo extends Mixins(FormRulesMixin){
	@Prop({ default: true }) selfRoleOptional: boolean;
	@Prop({ default: false, type: Boolean}) isEdit:boolean;

	mdiPlus = mdiPlus;
	mdiCloseCircle = mdiCloseCircle;
	mdiChevronDown = mdiChevronDown;
	staffTypes = [
		{ label: "Head Coach", value: StaffRole.HeadCoach },
		{ label: "Assistant Coach", value: StaffRole.AssistantCoach },
		{ label: "Administrator", value: StaffRole.Admin },
	];

	addStaffMember(): void{
		this.value.staffMembers.push(this.defaultStaffMember());
	}
	removeStaffMember(index: number): void{
		this.value.staffMembers.splice(index,1);
	}
	defaultStaffMember(): Record<string, string>{
		return {
			type: "",
			firstName: "",
			lastName: "",
			email: "",
		};
	}

	@Prop({ default: () => ({
		valid: false,
		myRole: "",
		makeEmailPublic: false,
		staffMembers: [],
		sendStaffInvitations: false,
	}) }) value;
	input(): void{
		this.$emit('input', this.value);
	}

	get showStaffMembers(): boolean{
		return this.value.staffMembers.length > 0;
	}

	$refs:{
		form: HTMLFormElement;
	}

	async submit($event: Event): Promise<void> {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}
}
