
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiAlert } from '@mdi/js';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';

@Component
export default class Alert extends Mixins(VuetifyMixin){
	@Prop({ default: mdiAlert }) icon: string;
	@Prop() iconSize: number | string;
	@Prop({ default: 'baColorSuperLightBlue' }) private color: string;
	@Prop({ default: 'baColorPrimaryBlue' }) private borderColor: string;
	@Prop({ default: false, type: Boolean }) private hideIcon: boolean;
	@Prop({ default: false, type: Boolean }) private dense: boolean;
	/** Use Red Error Colors */
	@Prop({ default: false, type: Boolean }) private error: boolean;
	@Prop({ default: false, type: Boolean }) private warning: boolean;

	get Color(): string{
		if(this.error) return 'baColorAlertRedBG';
		if(this.warning) return 'baColorLightWarning';
		return this.color;
	}
	get BorderColor(): string{
		if(this.error) return 'baColorAlertRed';
		if(this.warning) return 'baColorAccentGold';
		return this.borderColor;
	}

	get AlertStyle(): Record<string, string>{
		return {
			'color': `${this.getColor(this.IsDark ? 'baColorAppBackground' : 'baColorPrimaryText')}`,
			'background-color': `${this.getColor(this.Color)} !important`,
			'border': `1px solid ${this.getColor(this.BorderColor)}`,
			'font-size': this.dense ? '12px' : undefined,
			'padding': this.dense ? '12px' : undefined,
		};
	}
}
