
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiChevronDown } from '@mdi/js';
import { AclPermissionValues } from '../../../types/permissions';
import { title } from '../../pipes';
import { VuetifyMixin } from '../../mixins';

@Component
export default class PermissionsSelector extends Mixins(VuetifyMixin){
	title = title;
	mdiChevronDown = mdiChevronDown;
	@Prop({ default: () => [] }) addPermissions: string[];
	@Prop({ default: () => [] }) private value: string[];
	input(value: string[]): void{
		this.$emit('input', value);
	}

	get Permissions(): string[]{
		return [...AclPermissionValues, ...this.addPermissions];
	}
}
