
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../../mixins/VuetifyMixin';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import VideoThumbnail from '@/components/ui/videos/VideoThumbnail.vue';
import { formatDatePretty } from '@/helpers';

@Component({
	components: { VideoThumbnail }
})
export default class VideoClipPreview extends Mixins(VuetifyMixin){

	@Prop({ default: false, type: Boolean }) dark: boolean;
	@Prop({ default: false, type: Boolean }) isPlaying: boolean;
	@Prop({ required: true }) videoClip: VideoClipModel;
	@Prop({ default: 120 }) size: number;
	/** Use 16:9 aspect ratio */
	@Prop({ default: false, type: Boolean }) wide: boolean;
	@Prop({ default: false, type: Boolean }) hideDuration: boolean;


	clicked(): void{
		this.$emit('click', this.videoClip);
	}

	get AspectRatio(): number{
		if(this.wide) return 1.7778; // 16:9
		return 1.333; // 4:3
	}

	get Width(): number{
		return this.size * this.AspectRatio;
	}
	get Height(): number{
		return this.size;
	}
	get IconSize(): number{
		return this.Height / 3;
	}
	get ThumbnailStyle(): Record<string, any>{
		return {
			width: `${this.Width}px`,
		}
	}
	get VideoClipDate(): string{
		return formatDatePretty(new Date(this.videoClip.create_date));
	}
	get VideoClipDurationStr(): string{
		return `${this.videoClip.duration} sec`;
	}
}
