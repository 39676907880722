
import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import { VuetifyMixin, SportsSelectorMixin } from "@/mixins";
import { AthleteProfileModel } from "@/models/athlete/AthleteProfileModel";
import HintText from "@/components/ui/HintText.vue";
import LinkText from "@/components/ui/LinkText.vue";
import {
	Mass,
	Length,
	BaseSoccerPosition,
	DominantSide
} from "@/../types/enums";
import ProfilePictureUpload from "@/components/forms/ProfilePictureUpload.vue";
import { mdiChevronDown, mdiTrashCan, mdiPlus } from "@mdi/js";

type Item<T> = {
	text: string;
	value: T;
};

@Component({
	components: {
		HintText,
		LinkText,
		ProfilePictureUpload
	}
})
export class EditRecruitingProfileSports extends Mixins(
	VuetifyMixin,
	SportsSelectorMixin
) {
	@PropSync('editAthleteProfile', { required: true }) EditAthleteProfile: AthleteProfileModel;

	mdiChevronDown = mdiChevronDown;
	mdiTrashCan = mdiTrashCan;
	mdiPlus = mdiPlus;

	lazyDominantSide: DominantSide = DominantSide.Left;

	created(): void {
		this.lazyDominantSide = this.EditAthleteProfile.dominantSide;
	}
	get birthDate(): Date {
		/**
		 * Returns Date from athlete profile birthdate
		 */
		return new Date(this.EditAthleteProfile.birthDate);
	}

	get secondaryPositionItems(): Item<BaseSoccerPosition>[] {
		/**
		 * Returns an array of enum keys as text and enum values as values for vuetify items
		 */
		let items: Item<BaseSoccerPosition>[] = [];

		Object.keys(BaseSoccerPosition)
			.filter(x => !(parseInt(x) >= 0))
			.forEach(position => {
				items.push({
					text: position as keyof typeof BaseSoccerPosition,
					value: BaseSoccerPosition[position]
				});
			});
		return items;
	}
	get primaryPositionItems() {
		// Primary options cannot be None
		return this.secondaryPositionItems;
	}

	get weightUnitText() {
		return this.EditAthleteProfile.weightUnit == Mass.KILOGRAM
			? "kg"
			: "lb";
	}
	toggleWeight() {
		this.EditAthleteProfile.weightUnit =
			this.EditAthleteProfile.weightUnit == Mass.KILOGRAM
				? Mass.POUND
				: Mass.KILOGRAM;
	}

	get heightUnitText() {
		return this.EditAthleteProfile.heightUnit == Length.CENTIMETER
			? "cm"
			: "in";
	}
	toggleHeight() {
		this.EditAthleteProfile.heightUnit =
			this.EditAthleteProfile.heightUnit == Length.CENTIMETER
				? Length.INCH
				: Length.CENTIMETER;
	}
	isClicked(side: string): void {
		if (side == "Left") {
			this.$set(this.EditAthleteProfile, 'dominantSide', DominantSide.Left)
			this.lazyDominantSide = DominantSide.Left;
		} else if (side == "Right") {
			this.$set(this.EditAthleteProfile, 'dominantSide', DominantSide.Right)
			this.lazyDominantSide = DominantSide.Right;
		} else if (side == "Ambidextrous") {
			this.$set(this.EditAthleteProfile, 'dominantSide', DominantSide.Ambidextrous)
			this.lazyDominantSide = DominantSide.Ambidextrous;
		}
	}
	get IsAmbidextrousClicked(): boolean {
		return this.lazyDominantSide === DominantSide.Ambidextrous;
	}
	get IsRightClicked(): boolean {
		return this.lazyDominantSide === DominantSide.Right;
	}
	get IsLeftClicked(): boolean {
		return this.lazyDominantSide === DominantSide.Left;
	}
}

export default EditRecruitingProfileSports;
