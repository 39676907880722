import { userStore } from '@/store';
import { Route, NavigationGuardNext } from 'vue-router';
import { getInstance } from "./auth0Plug";

export async function authGuard(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
	const profileId = to.query.u ?? null;
	const authService = getInstance();
	const fn = async () => {

		if (!userStore.userProfilesInitialized) {
			await userStore.loadUserProfiles();
		}
		
		if (profileId !== null && !Array.isArray(profileId)) {
			await userStore.useProfile({
				profileId,
			});
		}

		// If the user is authenticated, continue with the route
		if (authService.isAuthenticated) {
			// console.log("[authGuard] isAuthenticated, continue");
			return next();
		}

		// Otherwise, log in
		// console.log("[authGuard] loginWithRedirect", JSON.stringify({ appState: { targetUrl: to.fullPath } }));
		authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
	};

	// If loading has already finished, check our auth state using `fn()`
	if (!authService.loading) {
		return fn();
	}

	// Watch for the loading property to change before we check isAuthenticated
	authService.$watch("loading", (loading: boolean) => {
		// console.log("Loading Finished")
		if (loading === false) {
			return fn();
		}
	});
}