
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { mdiMapMarkerOutline } from '@mdi/js'

import { VideoJsPlayer } from 'video.js'
import BestAthletesLogo from '@/components/ui/BestAthletesLogo.vue'
import BAVideoJSPlayer from '@/components/ba-video/BAVideoJSPlayer.vue'
import BAVideoTimelineSlider from '@/components/ba-video/BAVideoTimelineSlider.vue';
import BAVideoToolBar from '@/components/ba-video/BAVideoToolBar.vue';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import { LocalForageMixin } from '@/mixins';
import { VideoModel } from '@/models/video/VideoModel'
import { BehaviorSubject } from 'rxjs'

@Component({
	components: {
		BestAthletesLogo,
		BAVideoJSPlayer,
		BAVideoTimelineSlider,
		BAVideoToolBar,
	},
})
export default class BAVideoPlayer extends Mixins(LocalForageMixin) {
	mdiMapMarkerOutline = mdiMapMarkerOutline;

	scrubberPosition: number = 50;
	
	$refs: Vue["$refs"] & {
			videoPlayer: { player: VideoJsPlayer },
			baVideoTimelineSlider: BAVideoTimelineSlider,
	}
	// Basing this work off of this Git Library showing usage of video.js 7:
	// https://github.com/alterhu2020/vue-videojs7

	playerOptions = {
		autoplay: true,
		controls: false,
		fluid: true,
		aspectRatio: '16:9',
		controlBar: {
			pictureInPictureToggle: false,
			fullscreenToggle: true,
			currentTimeDisplay: true,
			timeDivider: true,
			durationDisplay: true
		}
	}

	localForagePersistFields: Array<string | [string, any]> = [
		['volume', 0.8],
		['isMuted', false],
	];

	currentTime: number = 0;
	totalTime: number = 0;
	startMarkerTime: number = 0;
	stopMarkerTime: number = 0;
	async onTimeUpdate(): Promise<void>{
		await this.updateTimeFromPlayer();
		await this.checkClipEnded();
	}
	async hasClipEnded(): Promise<boolean> {
		return this.currentTime >= this.clip.EndTime;
	}
	async checkClipEnded(): Promise<void>{
		// If there's no clips don't do anything
		if(!this.clip) return;
		// If it is in the middle of changing the clip; wait for it to change the clip
		if(this.inMiddleOfChangingClip) return;
		// Clip has been changed and now we can check if it has ended
		if(await this.hasClipEnded()){
			if (!this.clippingPlayedAfterEnd){
				await this.pause()
			}

			await this.onClipEnded();
		}
	}
	get ClipEndTime(): number{
		if(!this.clip) return 0;
		return this.clip.EndTime;
	}
	async onClipEnded(): Promise<void>{
		this.$emit('clip:ended');
	}

	volume: number = 0.8;
	async volumeChanged(vol: number): Promise<void>{
		this.volume = vol;
		if(this.PlayerReady){
			(await this.VideoPlayer).volume(this.volume);
			this.persistField('volume');
		}
		this.setMute(false);
	}
	isPlaying: boolean = false;
	isMuted: boolean = false;
	async setMute(mute: boolean): Promise<void>{
		this.isMuted = mute;
		this.persistField('mute');
		if(this.PlayerReady){
			(await this.VideoPlayer).muted(this.isMuted);
		}
	}
	async play(): Promise<void>{
		if (this.clip && await this.hasClipEnded()) {
			this.clippingPlayedAfterEnd = true
		}
		await (await this.VideoPlayer).play();
		this.isPlaying = true;
	}
	async pause(): Promise<void>{
		(await this.VideoPlayer).pause();
		this.isPlaying = false;
	}
	jump(amount: number): void{
		this.jumpPlayerTime(amount);
	}
	addClipClicked(): void{
		this.$emit('click:add-clip');
	}
	getCurrentClipTimelineData(): VideoClipModel{
		return new VideoClipModel().load(this.$refs.baVideoTimelineSlider.getCurrentClipTimelineData());
	}
	toggleMute(): void{
		this.setMute(!this.isMuted);
	}
	get NoVideo(): boolean{
		return this.videoSrc === null;
	}

	@Prop({ type: Boolean, default: false }) clipping: boolean;
	@Prop({ type: Boolean, default: true }) clippingAvailable: boolean;
	clippingPlayedAfterEnd: boolean;

	@Prop({ type: Boolean, default: false }) loop: boolean;
	@Prop({ type: Boolean, default: false }) showClipInfo: boolean;
	@Prop({ type: Boolean, default: false }) hideClipButton: boolean;
	@Prop({ default: [] }) clipMarkers: VideoClipModel[];
	@Prop({ default: [] }) clipHovered: boolean[];
	updateClipHovered(clipHovered: boolean[]): void{
		this.$emit('update:clip-hovered', clipHovered);
	}
	// BUG: Cannot pass null into this or will pause the video
	@Prop({ default: () => new BehaviorSubject<VideoClipModel | null>(null)}) currentClipObservable: BehaviorSubject<VideoClipModel | null>;

	async created(): Promise<void> {
		this.loadFields();
		this.currentClipObservable.subscribe(clip => this.clipChanged(clip))
	}
	clip: VideoClipModel | null;
	// Need when switching to clips that have a start time less than the current clip
	// will skip the video all together and continue to next because timeupdate -> checkClipEnded -> next clip
	inMiddleOfChangingClip: boolean = false;
	async clipChanged(clip: VideoClipModel | null): Promise<void>{
		// Set inMiddleOfChangingClip to true to avoid checkClipEnded from going to next video; as mentioned above
		this.inMiddleOfChangingClip = true
		
		// Flag to stop video playback when playing clip during clipping
		this.clippingPlayedAfterEnd = false

		const oldVideo: string = this.clip ? this.clip.video : null;
		this.clip = clip;
		// If the same video is meant to be set -> it won't be set again -> loadmetadata won't be fired twice
		// there we manually have to load/set the timeline and positions		
		if ((clip && oldVideo === clip.video) || (this.video && this.video.id === clip?.video) || (await this.VideoPlayer).src() === this.videoSrc) {
			await this.clipLoaded()
		}
		else if (clip === null) {
			(await this.VideoPlayer).pause();
		}
		// Renables checkClipEnded
		this.inMiddleOfChangingClip = false;
	}
	@Prop({ type: String, default: null }) videoSrc: string | null;
	@Prop({ type: VideoModel, default: null }) video: VideoModel | null;

	playerInitialized: boolean = false;

	@Watch('video', { immediate: true }) async videoChanged(newVideo: VideoModel): Promise<void> {
		if(!newVideo){
			console.warn("BAVideoPlayer: No valid video source provided");
			return;
		}
		if(!this.PlayerReady){
			console.warn("BAVideoPlayer: No valid video source provided");
			return;
		}

		(await this.VideoPlayer).src(newVideo.VideoJSSource);
	}
	playerIsReady: boolean = false;
	onPlayerReady(): void{
		this.playerIsReady = true;
		this.volumeChanged(this.volume); // Ensure player is using our UI volume
		this.playVideo(this.video);
	}

	async updateTimeFromPlayer(): Promise<void>{
		this.currentTime = (await this.VideoPlayer).currentTime();
	}
	async jumpPlayerTime(timeDiff: number): Promise<void>{
		this.currentTime = await this.seekPlayerTime(this.currentTime + timeDiff);
	}
	pausedForSeek: boolean = false;
	seekStart(): void{
		this.pausedForSeek = this.isPlaying;
		this.pause();
	}
	seekEnd(): void{
		if(this.pausedForSeek) this.play();
	}
	async seekPlayerTime(time: number): Promise<number>{
		let seekTime = time;
		if (seekTime < 0) {
			seekTime = 0
		}
		if (this.totalTime > 0 && seekTime >= this.totalTime) {
			seekTime = this.totalTime - 0.2;
		}
		(await this.VideoPlayer).currentTime(seekTime);
		this.seekEnd();
		return seekTime;
	}

	/** Gets a promise which resolves to the video player once it is ready */
	get VideoPlayer(): Promise<VideoJsPlayer> {
		if(!this.$refs.videoPlayer){
			return new Promise(resolve => {
				const unWatchPlayer = this.$watch('PlayerReady', (ready: boolean) => {
					if(ready === true){
						resolve(this.$refs.videoPlayer.player);
						unWatchPlayer();
					}
				});
			});
		}
		return Promise.resolve(this.$refs.videoPlayer.player as VideoJsPlayer);
	}

	get PlayerReady(): boolean{
		return this.playerIsReady;
	}

	async playButtonManual(): Promise<void> {
		(await this.VideoPlayer).play()
	}

	async pauseButtonManual(): Promise<void> {
		(await this.VideoPlayer).pause()
	}


	async onStateChangedEvent(e: any): Promise<void> {
		const theEventArray: any[] = Object.keys(e)		
		switch (theEventArray[0]) {
		case 'timeupdate':
			this.totalTime = (await this.VideoPlayer).duration();
			break;
		case 'canplay':
			this.$emit('canplay');
			break;
		case 'seeked':
			break;
		case 'loadedmetadata':
			// this.totalTime = (await this.VideoPlayer).duration();
			// this.$refs.baVideoTimelineSlider.initializeTimeline();
			console.log(`Loadedmetadata`);
			
			await this.clipLoaded()
			this.$emit('loadedmetadata');
			break;
		case 'canplaythrough':
			// this.totalTime = (await this.VideoPlayer).duration();
			// this.$refs.baVideoTimelineSlider.initializeTimeline();
			console.log(`canplaythrough`);
			this.$emit('canplaythrough');
			break;
		case 'playerresize':
			this.updatePlayerWidthFromSelf();
			break;
		case 'play':
			this.isPlaying = true;
			break;
		case 'pause':
			this.isPlaying = false;
			break;
		}
	}


	playerWidth: number = null;
	onResizeEvent(): void {
		// console.log("onResizeEvent", { VideoPlayer: this.VideoPlayer });
		this.updatePlayerWidthFromSelf();
	}
	async updatePlayerWidthFromSelf(): Promise<void>{
		this.playerWidth = (await this.VideoPlayer).currentDimension('width');
	}

	async resetClipMarkers(): Promise<void>{
		return this.$refs.baVideoTimelineSlider.resetRangeSliders()
	}
	async clipLoaded(): Promise<void> {
		if (this.clip !== null) {
			this.inMiddleOfChangingClip = true;
			this.totalTime = (await this.VideoPlayer).duration();
			this.currentTime = 0;
			await this.$refs.baVideoTimelineSlider.onResize()
			this.$refs.baVideoTimelineSlider.rangeValues = [
				this.$refs.baVideoTimelineSlider.timeToScrubberValue(this.clip.StartTime),
				this.$refs.baVideoTimelineSlider.timeToScrubberValue(this.clip.EndTime)
			]
			await this.seekPlayerTime(this.clip.StartTime);
			(await this.VideoPlayer).play()
			this.inMiddleOfChangingClip = false;
		}
	}

	async playVideo(video: VideoModel | null): Promise<void>{
		await this.resetPlayer();
		if(video !== null){
			(await this.VideoPlayer).src(video.VideoJSSource)
		}
	}

	setMarkersFromClip(clip: VideoClipModel): void{
		this.startMarkerTime = 0;
		this.stopMarkerTime = 0;
		if(!clip) return;
		this.$nextTick(() => {
			this.startMarkerTime = clip.StartTime;
			this.stopMarkerTime = clip.EndTime;
		})
	}

	async resetPlayer(): Promise<void>{
		(await this.VideoPlayer).reset() // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
		this.currentTime = 0;
		this.totalTime = 0;
		this.$refs.baVideoTimelineSlider.resetRangeSliders();
		this.isPlaying = false;
		this.playerInitialized = false;
	}
}
