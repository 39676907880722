/**
 * FREQ= values from:  
 * https://icalendar.org/iCalendar-RFC-5545/3-8-5-3-recurrence-rule.html
 */
export enum RecurrenceRuleFrequency{
	Minutely ="MINUTELY",
	Hourly="HOURLY",
	Daily="DAILY",
	Weekly="WEEKLY",
	Monthly="MONTHLY",
	Yearly="YEARLY",
}