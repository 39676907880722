/* RyzerMixin.tx */
/* RyzerMixin contains functionality used by Ryzer Mindset related components */

import { Component, Prop, Vue } from 'vue-property-decorator';
import { capitalize } from '@/pipes';

import RyzerEagle from "@/assets/img/ryzer/eagle-full.png";
import RyzerEngineer from "@/assets/img/ryzer/engineer-full.png";
import RyzerIce from "@/assets/img/ryzer/ice-full.png";
import RyzerKnight from "@/assets/img/ryzer/knight-full.png";
import RyzerMaverick from "@/assets/img/ryzer/maverick-full.png";
import RyzerMusketeer from "@/assets/img/ryzer/musketeer-full.png";
import RyzerRocket from "@/assets/img/ryzer/rocket-full.png";
import RyzerTrailblazer from "@/assets/img/ryzer/trailblazer-full.png";

/**
 * Provides access to Ryzer icons and data types
 */
@Component
export class RyzerMixin extends Vue {
	@Prop({default:'maverick'}) athleteType: string;

	imgAthleteTypeEagle = RyzerEagle;
	imgAthleteTypeEngineer = RyzerEngineer;
	imgAthleteTypeIce = RyzerIce;
	imgAthleteTypeKnight = RyzerKnight;
	imgAthleteTypeMaverick = RyzerMaverick;
	imgAthleteTypeMusketeer = RyzerMusketeer;
	imgAthleteTypeRocket = RyzerRocket;
	imgAthleteTypeTrailblazer = RyzerTrailblazer;


	colorRyzerEagle = "#C79E0D"
	colorRyzerEngineer = "#009247"
	colorRyzerIce = "#7A7B79"
	colorRyzerKnight = "#6B297A"
	colorRyzerMaverick = "#FA6A09"
	colorRyzerMusketeer = "#183257"
	colorRyzerRocket = "#D70007"
	colorRyzerTrailblazer = "#3ABBEC"
	colorRyzerGeneral = "#1D50B6"

	urlEagle = "http://athletetypes.ryzer.com/eagle.cfm"
	urlEngineer = "http://athletetypes.ryzer.com/engineer.cfm"
	urlIce = "http://athletetypes.ryzer.com/ice.cfm"
	urlKnight = "http://athletetypes.ryzer.com/knight.cfm"
	urlMaverick = "http://athletetypes.ryzer.com/maverick.cfm"
	urlMusketeer = "http://athletetypes.ryzer.com/musketeer.cfm"
	urlRocket = "http://athletetypes.ryzer.com/rocket.cfm"
	urlTrailblazer = "http://athletetypes.ryzer.com/trailblazer.cfm"
	urlUnknown = "https://athletetypes.ryzer.com/"

	get AthleteType() : string {
		return capitalize(this.athleteType);
	}

	get AthleteTypeColor() : string {
		if( this.AthleteType === "Eagle" ) return this.colorRyzerEagle;
		if( this.AthleteType === "Engineer" ) return this.colorRyzerEngineer;
		if( this.AthleteType === "Ice" ) return this.colorRyzerIce;
		if( this.AthleteType === "Knight" ) return this.colorRyzerKnight;
		if( this.AthleteType === "Maverick" ) return this.colorRyzerMaverick;
		if( this.AthleteType === "Musketeer" ) return this.colorRyzerMusketeer;
		if( this.AthleteType === "Rocket" ) return this.colorRyzerRocket;
		if( this.AthleteType === "Trailblazer" ) return this.colorRyzerTrailblazer;
		return this.colorRyzerGeneral;		
	}

	get AthleteTypeURL() : string {
		if( this.AthleteType === "Eagle" ) return this.urlEagle;
		if( this.AthleteType === "Engineer" ) return this.urlEngineer;
		if( this.AthleteType === "Ice" ) return this.urlIce;
		if( this.AthleteType === "Knight" ) return this.urlKnight;
		if( this.AthleteType === "Maverick" ) return this.urlMaverick;
		if( this.AthleteType === "Musketeer" ) return this.urlMusketeer;
		if( this.AthleteType === "Rocket" ) return this.urlRocket;
		if( this.AthleteType === "Trailblazer" ) return this.urlTrailblazer;
		return this.urlUnknown;		
	}

	get AthleteTypeImg() : string | null {
		if( this.AthleteType === "Eagle" ) return this.imgAthleteTypeEagle;
		if( this.AthleteType === "Engineer" ) return this.imgAthleteTypeEngineer;
		if( this.AthleteType === "Ice" ) return this.imgAthleteTypeIce;
		if( this.AthleteType === "Knight" ) return this.imgAthleteTypeKnight;
		if( this.AthleteType === "Maverick" ) return this.imgAthleteTypeMaverick;
		if( this.AthleteType === "Musketeer" ) return this.imgAthleteTypeMusketeer;
		if( this.AthleteType === "Rocket" ) return this.imgAthleteTypeRocket;
		if( this.AthleteType === "Trailblazer" ) return this.imgAthleteTypeTrailblazer;
		return null;
	}

	get AthleteTypeTextColor(): Record<string, any>{
		return {
			color: this.AthleteTypeColor
		}
	}

	get RyzerTitleTextColor(): Record<string, any>{
		return {
			color: this.colorRyzerGeneral
		}
	}
}
