import { PluginFunction } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { config } from '../config/config'

const pubKey = config.StripePublicKey

export const stripe = loadStripe(pubKey);
export const StripePlugin:  {install: PluginFunction<null>} = {
	async install(Vue) {
		Vue.prototype.$stripe = await stripe;
	}
}
