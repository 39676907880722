
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import VideoThumbnail from '@/components/ui/videos/VideoThumbnail.vue';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

@Component({
	components: { VideoThumbnail }
})
export default class Carousel extends Mixins(VuetifyMixin){

	currentOffset: number = 0;
	@Prop() items: any[];
	@Prop({ default: 180 }) itemWidth: number;
	@Prop({ default: false, type: Boolean }) dark: boolean;

	carouselWidth: number = null;
	$refs:{
		carouselRef: HTMLDivElement;
	}

	mounted(): void{
		this.onResize();
	}

	onResize(): void{
		this.carouselWidth = this.$refs.carouselRef.clientWidth;
	}

	mdiChevronLeft = mdiChevronLeft;
	mdiChevronRight = mdiChevronRight;
	get AtEndOfList(): boolean {
		return (this.TotalItemsWidth - this.CurrentOffsetPx) <= this.carouselWidth
	}
	get AtHeadOfList(): boolean {
		return this.currentOffset === 0;
	}

	get CarouselOffsetStyle(): Record<string, any>{
		return {
			width: `${this.TotalItemsWidth}px`,
			transform: `translateX(${-1 * this.CurrentOffsetPx}px)`,
		}
	}
	get TotalItemsWidth(): number{
		return this.items.length * this.itemWidth;
	}

	get CurrentOffsetPx(): number{
		return this.currentOffset * this.itemWidth;
	}

	moveLeft(): void {
		if (!this.AtHeadOfList) {
			this.currentOffset -= 1;
		}
	}
	moveRight(): void {
		if (!this.AtEndOfList) {
			this.currentOffset += 1;
		}
	}
}
