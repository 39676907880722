
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiMagnify, mdiChevronDown } from '@mdi/js';
import { mdiPencil, mdiCloseCircle, mdiPlus } from '@mdi/js';
import CircledAvatar from '../ui/CircledAvatar.vue';
import ContextMenu from '../ui/ContextMenu.vue';
import { DepthChartPosition, DepthChartModel } from '../../models/depthChart';
import { DataTableHeader } from 'vuetify';
import { VuetifyMixin, FormRulesMixin, DepthChartTemplatesMixin } from '../../mixins';
import { TeamModel, PlayerOnTeam } from '../../models/team';
import { LineupModel } from '../../models/lineup';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import Jersey from '../teams/Jersey.vue';
@Component({
	components: { CircledAvatar, ContextMenu, Jersey },
})
export default class Lineup extends Mixins(DepthChartTemplatesMixin, FormRulesMixin, VuetifyMixin) {
	mdiMagnify = mdiMagnify;
	mdiChevronDown = mdiChevronDown;

	@Prop({ default: 'baColorDeepBlue' }) private color: string;
	@Prop({ default: true, type: Boolean }) headerPadding: boolean;
	@Prop({ default: false, type: Boolean }) readOnly: boolean;
	@Prop({ default: 630 }) private tableHeight: number;
	@Prop({ required: true }) private team: TeamModel;
	/** Team Event to create this lineup for. Could be null if the event hasn't been created yet. */
	@Prop({ default: null }) teamEvent: CalendarEventModel | null;
	@Prop({ default: null }) lineup: LineupModel | null;
	@Prop({ default: 163 }) subHeight: number;
	get SubInnerHeight(): number{
		return this.subHeight - 16;
	}
	updateLineup(): void{
		this.$emit('update:lineup', this.formLineup);
	}

	formLineup: LineupModel | null = null;

	mounted(): void{
		if(this.lineup !== null){
			this.formLineup = this.lineup;
		}
	}

	selectedFormation: DepthChartModel | null = null;
	selectTemplate(template: DepthChartModel): void{
		const formation = template.copy();
		formation.autoFillPositions(this.team.players);
		this.formLineup = LineupModel.GenerateLineup("New Lineup", formation);
		this.formLineup.formation = formation.name;
		this.updateLineup();
	}


	headers: DataTableHeader<any>[] = [
		{
			text: 'No.',
			value: 'number',
		},
		{
			text: 'Player',
			value: 'firstName',
		},
		{
			text: 'Position',
			value: 'position',
		},
	];

	contextMenuItems = [
		{ icon: mdiPencil, text: "Rename", click: this.confirmRename },
		// { icon: mdiContentDuplicate, text: "Duplicate", click: this.confirmDuplicate},
		{ icon: mdiCloseCircle, text: "Delete", click: this.confirmDeleteLineup },
		{ icon: mdiPlus, text: "New Lineup", click: this.newLineup },
	];


	get Team(): TeamModel{
		return this.team;
	}

	get playersOnTeam(): PlayerOnTeam[]{
		return this.Team.players;
	}

	playersFromTeam(playerIds: string[]): PlayerOnTeam[]{
		return playerIds.map(id => this.Team.players.find(p => p.id === id)).filter(p => !!p);
	}

	playerFromTeam(playerId: string): PlayerOnTeam | Record<string,unknown>{
		const player = this.Team.players.find(player => player.id === playerId);
		if(player === undefined) return {}; // Avoid undefined errors in the template before the team is loaded.
		return player;
	}

	selectSub(positionIndex: number, player: PlayerOnTeam): void{
		const newPosArray: string[] = [];
		newPosArray.push(player.id);
		this.LineupSubstitutes[positionIndex].playerIds = newPosArray;
	}

	selectStarter(positionIndex: number, player: PlayerOnTeam): void{
		const position: Partial<DepthChartPosition> = this.LineupPositions[positionIndex];
		const newPosArray: string[] = [];
		newPosArray.push(player.id);
		this.LineupPositions[positionIndex].playerIds = newPosArray;
		position.playerIds = newPosArray;
	}

	get Lineup(): LineupModel | null{
		return this.formLineup || this.lineup;
	}
	get LineupId(): string | null{
		if(this.formLineup === null) return null;
		return this.formLineup.id;
	}

	changeOrder(positionIndex: number, player: PlayerOnTeam): void{
		const position: Partial<DepthChartPosition> = this.LineupPositions[positionIndex];
		const itemToBeMovedIndex = position.playerIds.findIndex(playerId => playerId === player.id);
		position.playerIds.splice(itemToBeMovedIndex, 1);
		position.playerIds.unshift(player.id);
	}
	get LineupSubstitutes(): DepthChartPosition[]{
		if(this.Lineup === null) return [];
		if(this.Lineup.substitutes.length === 0) return [];
		
		return this.Lineup.substitutes;
	}
	get SubLength(): number{
		if (this.LineupSubstitutes.length > 3) return this.LineupSubstitutes.length;
		else{
			let count = 0;
			for (let i = 0; i < this.LineupSubstitutes.length; i++){
				if (this.LineupSubstitutes[i].playerIds[0]){
					count ++
				}
			}
			return count;
		}
	}
	get LineupPositions(): DepthChartPosition[]{
		if(this.Lineup === null) return [];
		return this.Lineup.positions;
	}

	get ConfirmDeleteTitle(): string{
		return this.Lineup ? `Delete "${this.Lineup.name}"?` : 'Delete?';
	}
	showDeleteDialog: boolean = false;
	confirmDeleteLineup(): void{
		this.showDeleteDialog = true;
	}
	async deleteSelectedLineup(): Promise<void>{
		this.showDeleteDialog = false;
	}

	showRenameDialog: boolean = false;
	renameNameValid: boolean = true;
	newName: string= "";
	confirmRename(): void{
		this.newName = this.Lineup.name;
		this.showRenameDialog = true;
	}
	async renameSelectedLineup(): Promise<void>{
		this.showRenameDialog = false;
	}

	newLineup(): void{
		this.$emit('new');
	}

	get HeaderStyle(): Record<string, string>{
		return {
			'border-top': this.color === 'baColorDeepBlue' ? `1px solid #074E69 !important` : undefined,
			'background-color': this.getColor(this.color),
		}
	}

	get TableStyle(): Record<string, string>{
		return {
			'overflow-y': 'auto',
			'max-height': `${this.tableHeight}px`,
		}
	}
}
