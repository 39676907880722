export enum VideoStatus {
	WAITING = 'waiting',
	CREATED = 'created',
	PREPARING = 'preparing',
	READY = 'ready'
}

export enum VideoResolution{
	"8K" = "8K",
	"4K" = "4K",
	"1080P" = "1080P",
	"720P" = "720P",
	"480P" = "480P",
	"360P" = "360P",
	"240P" = "240P",
}
