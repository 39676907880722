import { UserPreferenceModel } from "@/models/user/UserPreferenceModel";
import { CrudApi } from "./CrudApi";

export class UserPreferenceApi extends CrudApi<UserPreferenceModel> {
	constructor(protected parentResource: string, protected parentId: string) {
		super(`${parentResource}/${parentId}/userPreference`, (obj) => new UserPreferenceModel().load(obj));
	}

	public async findOneByParentIdOrCreate(): Promise<UserPreferenceModel>{
		return new UserPreferenceModel().load(await UserPreferenceApi.Api(c => c.post(`${this.resource}/findOneByParentIdOrCreate`)))
	}

}