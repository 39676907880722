/* api.js */
/* Axios used to access BA api */

import * as axios from 'axios'
import * as authService from '../auth/auth0Plug';
import { config } from '../config/config'

export const rootUrl = config.VUE_APP_API_ROOT_URL || process.env.VUE_APP_API_ROOT_URL || 'https://app.local.bestathletes.co:8080/api';

// instance of axios
let axiosInstance = null

export async function api(cb) {

	// if this is the first API call, create an axios instance
	if (!axiosInstance) {
		// create axios instanc to the BA api URL
		axiosInstance = axios.create({
			baseURL: rootUrl,
			headers: {}
		});

		// add an interceptor so that API requests are checked for authorization
		axiosInstance.interceptors.request.use((config) => {
			const auth = authService.getInstance();
			if (auth && auth.token) {
				config.headers.Authorization = `Bearer ${auth.token}`;
			}
			return config;
		});
	}

	// make the specified API call and return the response data
	const response = await cb(axiosInstance)
	return response.data;
}