import Vue from 'vue';
import store from '../store';
import { Module, VuexModule, Mutation, Action,  } from 'vuex-module-decorators';

const Mutations = {
	SET_CACHE: 'SET_CACHE'
};

const name = 'ImagesStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class ImagesModule extends VuexModule {

	nameCache: Record<string, string> = {};
	cache: Record<string, string> = {};

	get GetFromCache() {
		return (id: string): string | undefined => this.cache[id] !== undefined ? this.cache[id] : undefined;
	}
	get GetNameFromCache() {
		return (id: string): string | undefined => this.nameCache[id] !== undefined ? this.nameCache[id] : undefined;
	}

	@Action({
		rawError: true,
	}) async setCache({ id, url, fullName }: { id: string, url: string, fullName?: string }): Promise<void> {
		this.context.commit(Mutations.SET_CACHE, { id: id, url: url, fullName});
	}

	@Mutation [Mutations.SET_CACHE]({ id, url, fullName }: { id: string, url: string, fullName?: string }): void {
		Vue.set(this.cache, id, url);
		if (fullName){
			Vue.set(this.nameCache, id, fullName);
		}
	}
}
