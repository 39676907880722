import { AccessControlledModel } from '../AccessControlledModel';

export class BAEventAttendeeModel extends AccessControlledModel {
	userId?: string;

	athleteProfileId!: string;
	athleteProfileShortId!: string;
	name!: string;
	firstName!: string;
	lastName!: string;
	email!: string;
	status!: string;

	eventbriteId!: string;
	eventbriteEventId!: string;
	eventbriteOrderId!: string;
	eventbriteTicketClassId!: string;
	eventbriteTicketClassName!: string;
	load<T>(obj: T): this{
		return super.load <T>(obj);
	}
}