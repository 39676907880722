
import Vue from 'vue';
import { VCombobox } from 'vuetify/lib';
import { mdiChevronDown } from '@mdi/js';
export default Vue.extend({
	render(h) {
		return h(VCombobox, {
			attrs: {
				'outlined': true,
				'append-icon': mdiChevronDown,
				...this.$attrs,
			},
			on: this.$listeners,
			scopedSlots: this.$scopedSlots,
		}, Object.keys(this.$slots).map(slot => h('template', { slot }, this.$slots[slot])))
	}
})
