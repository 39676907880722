
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiChevronDown } from '@mdi/js';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';
import { FormRulesMixin } from '../../mixins/FormRulesMixin';
// @ts-ignore: https://github.com/vuejs/vue-cli/issues/1104
import FormGroupHeading from '../../ui/FormGroupHeading.vue';
import { DepthChartModel } from '../../models/depthChart';

@Component
export default class NewLineupForm extends Mixins(FormRulesMixin, VuetifyMixin){
	mdiChevronDown = mdiChevronDown;

	// v-model form 'valid' boolean
	@Prop({ default: false }) value: boolean ;
	input(value){ this.$emit('input', value); }

	@Prop({ default: false, type: Boolean }) loading: boolean;

	@Prop({ default: null }) depthCharts : DepthChartModel;

	formValid = false;
	formValue = {
		name: "",
		depthChart: null,
	}

	generateName: boolean = true;
	// generate lineup name based on depth-chart name/formation
	useDefaultName(value: DepthChartModel){
		if(this.generateName){
			const current_datetime = new Date();
			const formatted_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getFullYear();
			const lineupName = `${formatted_date} | ${value.grid.name}`;
			this.formValue.name = lineupName;
		}
	}

	submit($event: Event){
		$event.preventDefault();
		this.$emit('submit', this.formValue);
	}

	cancel(){
		this.$emit('cancel');
	}
}
