
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { teamDashboardStore, notificationStore } from '@/store';
import { PlayerOnTeam, TeamModel } from '@/models/team';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { PageState } from '@/models/PageState';

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class TeamDashboardInfoProvider extends Vue{
	@Prop({ default: 'div' }) as: string;

	@Prop({ required: true }) private teamId: string;
	/** Load TeamModel (Might not want to include this if just showing a calendar) */
	@Prop({ type: Boolean, default: false }) loadTeam: boolean;
	/** Load Team's Events for Calendar & Lineups */
	@Prop({ type: Boolean, default: false }) loadTeamEvents: boolean;
	@Watch('teamId')
	async loadTeamData(): Promise<void>{
		try{
			this.loading = true;
			if(this.loadTeam === true){
				await teamDashboardStore.loadTeam(this.teamId);
			}
		}catch(e){
			console.error("Failed to Load Team Info");
			notificationStore.pushErrorNotificationMessage("Failed to load team. Please try again later");
		}finally{
			this.loading = false;
		}
	}
	async created(): Promise<void> {
		await this.loadTeamData();
	}

	get CurrentTeamReady(): boolean{
		return !teamDashboardStore.loadingTeam && teamDashboardStore.initialized && teamDashboardStore.team !== null;
	}

	get CurrentTeam(): TeamModel | null{
		return teamDashboardStore.team;
	}
	get CurrentTeamId(): string{
		if (teamDashboardStore.team){
			return teamDashboardStore.team.id;
		}
		return  this.$route.params.teamId;
	}
	get CurrentTeamPlayers(): PlayerOnTeam[]{
		if(this.CurrentTeam === null) return [];
		return teamDashboardStore.team.players;
	}

	get PageState(): PageState{
		return teamDashboardStore.loadingTeamState;
	}
	
	loading: boolean = false;
	teamEvents: CalendarEventModel[] = [];

	get SlotProps(): Record<string, any>{
		return {
			loading: this.loading,
			CurrentTeamReady: this.CurrentTeamReady,
			CurrentTeam: this.CurrentTeam,
			CurrentTeamId: this.CurrentTeamId,
			CurrentTeamPlayers: this.CurrentTeamPlayers,
			PageState: this.PageState,
		};
	}

}
