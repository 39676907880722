<script>
import { mapState } from 'vuex';
export default {
	name: 'Feature',
	props: {
		name: String,
		as: {
			type: String,
			default: 'div',
		},
	},
	computed:{
		...mapState(['featureFlags']),
	},
	render(h){
		if(this.featureFlags.flags[this.name] !== undefined ? this.featureFlags.flags[this.name].value === true : false){
			return h(this.as, this.$slots.default);
		}else{
			return null;
		}
	}
}
</script>