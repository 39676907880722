
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CalendarEventParticipantResponse } from '../../../types/enums';
import { CalendarEventParticipant } from '../../../types/interfaces';
import { Tardiness } from '../../../types/enums/tardiness';
import { TeamModel, PlayerOnTeam } from '../../models/team';
import { StaffMember } from '../../models/team/StaffMember';
import { UserProfileModel } from '../../models/UserProfileModel';

@Component
export default class TeamMemberSelector extends Vue{

	@Prop({ type: Boolean, default: false }) disabled: boolean;
	@Prop({ type: Boolean, default: false }) loading: boolean;
	@Prop({ type: Boolean, default: true }) usePlayers: boolean;
	@Prop({ type: Boolean, default: true }) useStaff: boolean;
	@Prop({ type: Number, default: 550 }) maxHeight: number;
	@Prop({ default: [] }) participants: CalendarEventParticipant[];
	updateParticipants(): void{
		this.$emit('update:participants', this.participants);
	}

	@Prop({ required: true }) team: TeamModel;

	get MemberSelectorStyle(): Record<string, string>{
		return {
			'overflow-y': 'auto',
			'max-height': `${this.maxHeight}px`,
		};
	}

	playerToUserInfo(player: PlayerOnTeam): Partial<UserProfileModel>{
		return {
			id: player.athleteId,
			firstName: player.firstName,
			lastName: player.lastName,
		}
	}
	staffToUserInfo(staff: StaffMember): Partial<UserProfileModel>{
		return {
			id: staff.coachId,
			firstName: staff.firstName,
			lastName: staff.lastName,
		}
	}
	
	get PlayerParticipants(): CalendarEventParticipant[]{
		return this.team.players.map(p => ({
			id: p.id,
			name: `${p.firstName} ${p.lastName}`,
			email: p.email,
			userId: p.userId,
			athleteId: p.athleteId,
			coachId: null,
			rsvp: CalendarEventParticipantResponse.None,
			tardiness: Tardiness.OnTime
		}));
	}
	get PlayerParticipantIndexes(): Array<number | undefined>{
		return this.PlayerParticipants.map(p => this.participants.findIndex(part => part.id === p.id));
	}
	
	get StaffParticipants(): CalendarEventParticipant[]{
		return this.team.staffMembers.map(staff => ({
			id: staff.id,
			name: `${staff.firstName} ${staff.lastName}`,
			email: staff.email,
			userId: staff.userId,
			athleteId: null,
			coachId: staff.coachId,
			rsvp: CalendarEventParticipantResponse.None,
			tardiness: Tardiness.OnTime
		}));
	}
	get StaffParticipantIndexes(): Array<number | undefined>{
		return this.StaffParticipants.map(s => this.participants.findIndex(part => part.id === s.id));
	}

	get Players(): PlayerOnTeam[]{
		if(!this.team) return [];
		return this.team.players;
	}
	get Staff(): StaffMember[]{
		if(!this.team) return [];
		return this.team.staffMembers;
	}

	get AllPlayersSelected(): boolean{
		if (this.PlayerParticipantIndexes.length === 0){
			return false
		}
		return this.PlayerParticipantIndexes
			.map(index => index > -1)
			.reduce((a,b) => a && b);
	}
	toggleSelectAllPlayers(): void{
		if(this.AllPlayersSelected){
			// Replace participants with a new list without any of the players included
			this.participants = this.participants.filter(part => {
				return this.PlayerParticipants.find(playerPart => playerPart.id === part.id) === undefined;
			});
		}else{
			// For Each player, if they aren't in the  list, add them
			this.PlayerParticipants.forEach((participant, index) => {
				if(this.PlayerParticipantIndexes[index] === -1){
					this.participants.push(participant);
				}
			});
		}
	}

	get AllStaffSelected(): boolean{
		if (this.StaffParticipantIndexes.length === 0){ 
			return false
		}
		return this.StaffParticipantIndexes
			.map(index => index > -1)
			.reduce((a,b) => a && b);
	}
	toggleSelectAllStaff(): void{
		if(this.AllStaffSelected){
			this.participants = this.participants.filter(part => {
				return this.StaffParticipants.find(staffPart => staffPart.id === part.id) === undefined;
			});
		}else{
			this.StaffParticipants.forEach((participant, index) => {
				if(this.StaffParticipantIndexes[index] === -1){
					this.participants.push(participant);
				}
			});
		}
	}

}
