import { CrudApi } from './CrudApi';
import { SpqDataModel } from '../models/athlete/SpqDataModel';
import { logGAEvent } from '@/plugins/firebase';

//import { AssessmentEntry } from 'types/interfaces/PyhsicalAssessmentDataFormValue';

class SpqDataApi extends CrudApi<SpqDataModel> {
	constructor() {
		super('spqData', (obj) => new SpqDataModel().load(obj));
	}

	// async bulkInsert(assessments: AssessmentEntry[]) {
	// 	console.log(assessments);
	// 	return await CrudApi.Api(c => c.post(`/athleteAssessmentData/assessmentBulk`, {assessments: assessments}));
	// }
	async insertSPQResult(spqData: SpqDataModel): Promise<SpqDataModel | null> {
		return await CrudApi.Api(c => c.post(`${this.resource}/spq-upload`, spqData));
	}

	async resendSPQResult(spqData: SpqDataModel): Promise<SpqDataModel | null> {
		return await CrudApi.Api(c => c.post(`${this.resource}/spq-resend`, spqData));
	}

	async findLatestByAthleteId({ athleteId }: { athleteId: string }): Promise<SpqDataModel | null> {
		try{
			const obj = await CrudApi.Api(c => c.get(`${this.resource}/athlete/${athleteId}`));
			return this.create(obj);
		} catch (e) {
			if (e.response.status === 404) {
				return null;
			}
			throw e;
		}
	}

	async verifyClaimCode({ code }: { code: string }): Promise<{ valid: boolean, token: { id: string, name: string } }> {
		const result = await CrudApi.Api(c => c.get(`${this.resource}/claim-code/verify`, { params: { code } }));
		logGAEvent("spq_code_checked", { valid: result.valid });
		return {
			valid: result.valid,
			token: result.token,
		};
	}
	async associateFromClaimCode({ code, athleteId }: { code: string, athleteId: string }): Promise<SpqDataModel> {
		const result = await CrudApi.Api(c => c.post(`${this.resource}/claim-code/associate`, {}, { params: { code, athleteId } }));
		logGAEvent("spq_claimed");
		return this.create(result);
	}
}
export const spqDataApi = new SpqDataApi();