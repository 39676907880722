
import { Component, Mixins, PropSync } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';
import { CertificationItem } from '@/models/coach/CoachResumeModel';
import { mdiCloseCircle } from '@mdi/js';

@Component({
	components: { 
	}
})
export default class CoachPlayersCertificationRow extends Mixins(VuetifyMixin){
	@PropSync('certificate', { required: true })
	Certificate: CertificationItem;

	mdiCloseCircle = mdiCloseCircle;
}
