import { Currency } from '@/../types/enums/currency';
import { FrontEndModel } from '@/models/FrontEndModel';

export class LineItemModel<M = unknown> extends FrontEndModel {
	title: string;
	description: string | null;
	currency: Currency;
	amount: number;
	stripeProductId: string | null;
	stripePriceId: string | null;
	interval: "month" | "year" | null = null;
	intervalCount: number | null = null;
	quantity: number = 1;
	meta: M | null = null;
}