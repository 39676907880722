import { User, UserTypeMap } from "@/../types/interfaces";
import { LOG_ROCKET_APP_ID } from "./logrocket";


export function setIntercomIdentity(userId: string, user: User, map: Partial<UserTypeMap> = {}, fullName: string): void{
	if (!window['Intercom']){
		console.warn('failed to initialized intercom');
		return;
	}
	window['Intercom']("boot", {
		app_id: "ps033vco",
		name: fullName,
		email: user.email,
		userID: user.id,
		...map
	});
	if(LOG_ROCKET_APP_ID){
		window['Intercom']('update', {
			logrocketURL: `https://app.logrocket.com/${LOG_ROCKET_APP_ID}/sessions?u=${userId}`,
		});
	}
	
	
}
