
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { getDefaultEventTypes } from '../../helpers/default-event-types';
import { VuetifyMixin } from '../../mixins';
import BAChip from "@/components/ui/BAChip.vue";
import { TeamEventTypeData } from 'types/interfaces';

@Component({
	components: {
		BAChip
	}
})
export default class EventTypesGroup extends Mixins(VuetifyMixin){

	@Prop({ type: Boolean, default: false }) small: boolean;
	@Prop({ type: Boolean, default: false }) disabled: boolean;
	@Prop({ type: Boolean, default: false }) centered: boolean;
	@Prop({ type: Boolean, default: false }) buttons: boolean;
	@Prop({ default: 'auto' }) height;
	@Prop( { type: Array, default: getDefaultEventTypes }) eventTypes: TeamEventTypeData[];
	@Prop( { type: [Array, String] }) value;

	get MultipleSelect(): boolean{
		return this.$attrs.multiple !== undefined;
	}
	get chipGroupStyle(): Record<string, string>{
		return {
			'text-transform': 'uppercase',
			'height': `${this.height}px`,
		};
	}
	onInput(data): void{
		this.$emit('input', data);
	}
	filterIsActive (filterName): boolean {
		if(this.MultipleSelect){
			return this.value.find(filter => filter === filterName) !== undefined;
		}else{
			return this.value === filterName;
		}
	}
	getEventChipColor (event): string | undefined {
		if(this.filterIsActive(event.name)){
			return this.getColor(event.color);
		}
		return;
	}
}
