import { FrontEndModel } from '@/models/FrontEndModel';

export class TaxLineItemModel extends FrontEndModel {
	name: string = "";
	description: string | null = "";
	amount: number = 0;
	// Not importing the enum since this is read only anyways
	type: string = "";
	disabled: boolean = false;
	ComputedAmount: number = 0;
}