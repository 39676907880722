
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import { AuthMixin } from "@/mixins";
import { UserPreferenceApi } from "@/api/UserPreferenceApi";
import { UserPreferenceModel } from "@/models/user/UserPreferenceModel";
import { NotificationPreferenceInterface } from "types/interfaces";
import { mdiAlertCircleOutline } from '@mdi/js';

type NotificationSetting = keyof NotificationPreferenceInterface;

export interface NotificationToPreferenceSettingMapping {
	// UI Label that the user sees
	label: string;
	// Multiple mapping to the keys of NotificationPreferenceInterface
	mapping: NotificationSetting[];
	// The value the of the checkbox; initialized to the `and` of the the mappings
	value: boolean;
}

@Component
export class NotificationPreferenceSetting extends Mixins(
	VuetifyMixin,
	AuthMixin
) {
	mdiAlertCircleOutline = mdiAlertCircleOutline;
	@Prop({ default: [
		{
			label: "Core Account Emails",
			value: true,
			mapping: ["verifiedAssessments", "spqResults", "scoutingReports"]
		},
		{
			label: "Team Calendar Event Notifications",
			value: true,
			mapping: ["athleteTeamCalendarEvents"]
		},
		{
			label: "Marketing Emails",
			value: true,
			mapping: ["newsAndUpdates"]
		}
	]}) 
	notificationSettings: NotificationToPreferenceSettingMapping[];

	unsavedProfilePreference: UserPreferenceModel | null = null;
	unsubscribeAll: boolean = false;

	get UnsubscribeAll(): boolean {
		return this.unsubscribeAll;
	}
	set UnsubscribeAll(value: boolean) {
		this.unsubscribeAll = value
	}

	async created(): Promise<void> {
		this.unsavedProfilePreference = this.CurrentUserProfilePreference;
		this.UnsubscribeAll = true;

		// Go over each setting and initialize it
		for (let i = 0; i < this.notificationSettings.length; i++) {
			// Extract the mapping
			const settings = this.notificationSettings[i].mapping

			// Checks to see if all the mapped settings are true
			let value: boolean = true;
			for (const setting of settings) {
				value = value && this.unsavedProfilePreference.notificationPreference[setting] ? true : false
			}
			this.notificationSettings[i].value = value			
		}

		this.UnsubscribeAll = this.NotificationSettingAllFalse;
	}

	get Emails(): string[] {
		return [this.CurrentProfileEmail]
	}

	get NotificationSettingMappings(): NotificationSetting[] {
		return this.notificationSettings.flatMap(setting => setting.mapping);
	}

	get NotificationSettingAllTrue(): boolean {
		// Check if all the settings are set to true so we set the checkbox to true
		let allSetToTrue = true;
		for (const setting of this.notificationSettings) {
			if (setting.value === false) {
				allSetToTrue = false;
				break;
			}
		}
		return allSetToTrue;
	}
	get NotificationSettingAllFalse(): boolean {
		// Check if all the settings are set to true so we set the checkbox to true
		let allSetToFalse = true;
		for (const setting of this.notificationSettings) {
			if (setting.value === true) {
				allSetToFalse = false;
				break;
			}
		}
		return allSetToFalse;
	}

	async setAllNotificationSettingsTo(value: boolean): Promise<void> {
		for (const setting of this.NotificationSettingMappings) {
			this.unsavedProfilePreference.notificationPreference[setting] = value
		}
		for (let i = 0; i < this.notificationSettings.length; i++) {
			this.notificationSettings[i].value = value;
		}
	}
	async toggleSubscribeAll(value: boolean): Promise<void> {
		if (!value) {
			this.setAllNotificationSettingsTo(true);
			this.unsubscribeAll = false;
		} else {
			this.unsubscribeAll = true;
			this.setAllNotificationSettingsTo(false);
		}
	}

	async toggleOption(i: number, value: boolean): Promise<void> {
		if (value === true) {
			// If one value is set to false then unsubscribe all should be set to false
			this.unsubscribeAll = false;
		}

		// Set the unsubscribe checkbox to all true if the all of the settings are true
		if (this.NotificationSettingAllTrue) {
			this.unsubscribeAll = false;
		}

		// Otherwise is inderminate
		else if (this.NotificationSettingAllFalse) {
			this.unsubscribeAll = true;
		}

		// Update the state in the users notification preference
		for (const setting of this.notificationSettings[i].mapping) {
			this.unsavedProfilePreference.notificationPreference[setting] = value;
		}
	}

	async save(): Promise<void> {
		const savedProfilePreference = await new UserPreferenceApi(
			this.CurrentProfileType,
			this.CurrentUserProfileId
		).save(this.unsavedProfilePreference);
		this.unsavedProfilePreference = savedProfilePreference;
	}
}

export default NotificationPreferenceSetting;
