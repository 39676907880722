/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfig } from 'vue-router';
import { profileSetupGuard } from './routeGuards/profileSetupGuard';
import { AthleteSideNavLayout } from '@/layouts';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { EventChildRouteConfigs } from './EventChildRouteConfigs';
import { SettingsChildRouteConfigs } from './SettingsChildRouteConfigs';


const AthleteProfilesManagement = () => import(/* webpackChunkName: "ProfileManagementModule" */ '@/views/AthleteProfilesManagement.vue');
const UpgradeSubscriptionPage = () => import(/* webpackChunkName: "ProfileManagementModule" */ '@/views/UpgradeSubscriptionPage.vue');


export const ProfileManagementRouteConfigs: RouteConfig[] = [
	{
		beforeEnter: profileSetupGuard,
		path: '',
		component: AthleteSideNavLayout,
		props: true,
		meta:{
			pageTitle: 'Athlete Profiles',
		},
		children: [
			{
				path: '',
				component: AthleteProfilesManagement,
				name: Routes.AthleteProfilesManagement,
				meta: {
					pageTitle: 'Athlete Profiles',
				},
			},
			{
				path: 'upgrade',
				component: UpgradeSubscriptionPage,
				name: Routes.UpgradeSubscriptionPage,
				meta: {
					pageTitle: 'Upgrade Your Plan',
				},
			},
			...SettingsChildRouteConfigs,
			...EventChildRouteConfigs(Routes.AthleteProfilesManagement),
		]
	},
];