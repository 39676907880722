
import { Component, Vue, Prop } from 'vue-property-decorator'
import {
	mdiMagnify,
	mdiVolumeLow,
	mdiVolumeMedium,
	mdiVolumeHigh,
	mdiVolumeMute,
	mdiRewind5,
	mdiFastForward5,
	mdiPlay,
	mdiPause,
	mdiPlusBoxOutline,
} from '@mdi/js'
import { formatDurationForTimeline } from '@/helpers/date';
import { VideoClipModel } from '@/models/video/VideoClipModel';

@Component({
	components: {},
	computed: {}
})
export default class BAVideoToolbar extends Vue {
	mdiMagnify = mdiMagnify
	mdiVolumeLow = mdiVolumeLow
	mdiVolumeMedium = mdiVolumeMedium
	mdiVolumeHigh = mdiVolumeHigh
	mdiVolumeMute = mdiVolumeMute
	mdiRewind5 = mdiRewind5
	mdiFastForward5 = mdiFastForward5
	mdiPlay = mdiPlay
	mdiPause = mdiPause
	mdiPlusBoxOutline = mdiPlusBoxOutline

	@Prop({ type: Boolean, default: false }) clipping: boolean;
	@Prop({ type: Boolean, default: true }) clippingAvailable: boolean;
	@Prop({ type: Boolean, default: false }) hideClipButton: boolean;
	@Prop({ type: Boolean, default: false }) disabled: boolean;
	@Prop({ type: Boolean, default: false }) isPlaying: boolean;
	@Prop({ type: Boolean, default: false }) isMuted: boolean;
	@Prop({ type: Number, default: 0.2 }) currentVolume: number;
	@Prop({ type: Number, default: 0 }) playerCurrentTime: number;
	@Prop({ type: Number, default: 0 }) totalTime: number;

	get playerCurrentTimeDisplay(): string {
		return formatDurationForTimeline(this.playerCurrentTime);
	}

	get totalTimeDisplay(): string {
		return formatDurationForTimeline(this.totalTime)
	}

	startPlaying(): void {
		this.$emit('play');
	}

	pausePlaying(): void {
		this.$emit('pause');
	}

	jumpAheadOrBack(amount: number): void {
		this.$emit('jump', amount);
	}

	addClip(): void {
		this.$emit('click:add-clip');
	}

	volumeChange(vol: number): void {
		this.$emit('update:current-volume', vol);
	}

	muteVolumeToggle(): void {
		this.$emit('toggle-mute');
	}
}
