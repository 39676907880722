
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mdiPaperclip, mdiDotsVertical } from '@mdi/js';
import { FileRecordModel } from '../../models/file/FileRecordModel';
import AttachmentUploadItem from './AttachmentUploadItem.vue';
import { FileRecordApi } from '../../api/FileRecordApi';
@Component({
	components: { AttachmentUploadItem },
})
export default class AttachmentsArea extends Vue{
	mdiPaperclip = mdiPaperclip;
	mdiDotsVertical = mdiDotsVertical;

	public async uploadAllFiles(parentId?: string): Promise<void>{
		const keys = Object.keys(this.$refs).filter(key => key.includes('uploadItem'));
		for(const key of keys){
			const uploadItem: AttachmentUploadItem = this.$refs[key][0];
			if(uploadItem !== undefined && uploadItem['uploadFile'] !== undefined){
				await uploadItem.uploadFile(parentId);
			}
		}
	}

	@Prop({ default: false, type: Boolean }) disabled: boolean;
	@Prop({ default: false, type: Boolean }) readonly: boolean;
	@Prop({ default: false, type: Boolean }) hideDetails: boolean;
	@Prop({ default: 200, type: Number }) minHeight: number;
	@Prop({ default: "Add Attachments", type: String }) buttonText: string;
	@Prop({ default: false, type: Boolean }) smallButton: boolean;
	@Prop() apiPrefix?: string;
	@Prop() parentId?: string;
	@Watch('apiPrefix') idUpdated(): void{
		this.initAttachments();
	}

	@Prop({ default: true }) valid: boolean;
	@Prop({ default: () => [] }) files: FileRecordModel[];
	updateFiles(files: FileRecordModel[]): void{
		this.files = files;
		this.$emit('update:files', files);
	}

	get ButtonLoading(): boolean{
		if(this.readonly === true) return false;
		return this.attachmentsInitialized === false;
	}

	get Disabled(): boolean{
		return this.disabled || this.readonly;
	}

	get HasFiles(): boolean{
		return this.files.length > 0;
	}

	isHovered = false;
	fileReader = new FileReader();

	handleFileChange($event: Event & { target: HTMLInputElement }): void{
		this.isHovered = false;
		const files = [];
		files.push(...this.files);
		const fileList: FileList = $event.target.files;
		for(let i = 0; i < fileList.length; i ++){
			files.push(FileRecordModel.create({
				parentId: this.parentId,
				file: fileList.item(i),
			}));
		}
		this.updateFiles(files);
	}

	downloadFile(file: File): void{
		const blob = new Blob([file]);
		let dl = document.createElement('a');
		dl.href = window.URL.createObjectURL(blob);
		dl.download = file.name;
		dl.click();
		document.removeChild(dl);
	}
	mounted(): void{
		this.initAttachments();
	}
	async initAttachments(): Promise<void>{
		if(!this.attachmentsInitialized && !this.attachmentsLoading){
			return this.loadAttachments();
		}
	}
	get Api(): FileRecordApi{
		return new FileRecordApi(this.apiPrefix);
	}
	attachmentsLoading: boolean = false;
	attachmentsInitialized: boolean = false;
	async loadAttachments(): Promise<void>{
		if(!this.parentId) return;
		this.attachmentsLoading = true;
		try{
			const files = await this.Api.findAllWithAccess();
			this.files = files;
		}catch(e){
			console.error("Failed to load files", e);
		}finally{
			this.attachmentsLoading = false;
			this.attachmentsInitialized = true;
		}
	}

	onFileDeleted(index: number): void{
		this.files.splice(index, 1);
		this.updateFiles(this.files);
	}
}
