import { VideoResolution, VideoSource, VideoStatus } from '@/../types/enums'
import { MuxVideoDetail, VideoDetail, YoutubeVideoDetail } from '@/../types/interfaces'
import { TaggableModel } from '../tag/TaggableModel';
import { Tag } from '../tag/Tag';
export class VideoModel extends TaggableModel {
	parentId: string = '';
	source: VideoSource = VideoSource.Mux
	name: string = ""
	status: VideoStatus = VideoStatus.WAITING
	duration: number | null = null
	maxResolution?: VideoResolution
	thumbnailUrl: string = ""
	uploadDate: Date = new Date()
	detail: VideoDetail = {}

	load<T = this>(obj: Partial<T>): this{
		Object.assign(this, obj);
		if(obj['uploadDate']) this.uploadDate = new Date(obj['uploadDate'])
		if (Array.isArray(obj['tags'])) this.tags = obj['tags'].map((tag: Tag) => new Tag(tag.text, tag.subjectId, tag.color));		return this;
	}

	get SourceUrl(): string | null{
		if(this.source === VideoSource.Mux){
			const playback = (this.detail as MuxVideoDetail).playback_ids[0];
			if(playback === undefined) return null;
			const playbackId = playback.id;
			return `https://stream.mux.com/${playbackId}.m3u8`;
		}else if(this.source === VideoSource.Youtube){
			return `https://www.youtube.com/embed/${(this.detail as YoutubeVideoDetail).embedded_id}?modestbranding=1?controls=0`;
		}
		return null;
	}

	get VideoJSSource(): {type: string, src: string} {
		if (this.source === VideoSource.Mux) {
			return {
				type: 'application/x-mpegurl',
				src: this.SourceUrl
			}
		}
		else if (this.source === VideoSource.Youtube) {
			return {
				type: 'video/youtube', 
				src: this.SourceUrl
			}
		}
		else {
			return { type: '', src: '' }
		}
	}

	/**
	 * https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api	
	 */
	get YTThumbnailUrl(): string {
		return (this.detail as YoutubeVideoDetail).embedded_id !== undefined ?
			`https://img.youtube.com/vi/${(this.detail as YoutubeVideoDetail).embedded_id}/hqdefault.jpg` : 
			'';
	}
}
