import store from '../store';
import { Module, VuexModule, Mutation, Action, } from 'vuex-module-decorators';
import { StatisticModel } from '../../models/statistic'
import { AnalyticsAssessmentFilter, Comparison, GenericSpiderChart, RadarChartSeries, Gender } from '@best-athletes/ba-types';
import { ComparativeDataPageState } from '@/models/pageStates/ComparativeDataPageState';
import { AthleteAssessmentDataModel } from '@/models';
import { PaginatedResponse, AgeGroup } from '@best-athletes/ba-types';
import { ComparativeDataV2State, Mutations as ComparativeDataV2Mutations } from './ComparativeDataV2.store';

import * as U16MData from './sampleData/U16Male'
import * as U16FData from './sampleData/U16Female'

const Mutations = {
	RESET: 'RESET',
	SETUP_SAMPLE_DATA_MALE: 'SETUP_SAMPLE_DATA_MALE',
	SETUP_SAMPLE_DATA_FEMALE: 'SETUP_SAMPLE_DATA_FEMALE',
};

const name = 'ComparativeDataV2SampleDataStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class ComparativeDataV2SampleDataModule extends VuexModule implements ComparativeDataV2State {
	@Action async loadSpiderChart(): Promise<void> {
		const gender = this.context.rootGetters['UserStore/athlete'].gender;
		this.context.dispatch('setupSampleData', { gender });
	}
	@Action async loadLineChartPercentiles(): Promise<void> {
		const gender = this.context.rootGetters['UserStore/athlete'].gender;
		this.context.dispatch('setupSampleData', { gender });
	}
	@Action async setSpiderChartLoading(): Promise<void> { }
	@Action async setComparativeGroups(): Promise<void> { }
	featureAvailable: boolean = true;
	spiderChartState: ComparativeDataPageState = new ComparativeDataPageState("Initial");
	athleteId: string | null = null;
	assessment: AthleteAssessmentDataModel | null = null;
	spiderChartFilters: AnalyticsAssessmentFilter[] = [];
	spiderChartData: RadarChartSeries[] = [
		{
			values: [0, 0, 0, 0, 0],
			color: 'baColorLightBlue',
			borderColor: 'baColorDeepBlue',
			name: ''
		}
	];
	spiderChartLowSampleSize: boolean = false;
	spiderChartAvailable: boolean = false;
	assessmentPagination: PaginatedResponse<AthleteAssessmentDataModel> = {
		count: 0,
		docs: [],
		total: 0,
		page: 0,
	};
	
	lineChartState: ComparativeDataPageState = new ComparativeDataPageState("Initial");
	lineChartPercentiles: GenericSpiderChart[] = [];
	comparativeDataState: ComparativeDataPageState = new ComparativeDataPageState("Initial");
	compareGroups: AgeGroup[] = [];
	statistics: StatisticModel[] = [];
	comparisonLoading: boolean = false;
	comparisonInitialized: boolean = false;
	comparison: Comparison | null = null;
	get AssessmentList(): AthleteAssessmentDataModel[] {
		return this.assessmentPagination.docs;
	}
	get ComparativeDataLoading(): boolean {
		return this.comparativeDataState.IsLoading;
	}
	get Over18(): boolean {
		return [
			AgeGroup.U18,
			AgeGroup.U19,
			AgeGroup.U20,
			AgeGroup.O20,
		].includes(this.MyAgeGroup);
	}
	get MyAgeGroup(): AgeGroup {
		return this.context.rootGetters['AthleteProfileStore/AthleteAgeGroup'];
	}
	get ComparativeDataReady(): boolean {
		return this.comparativeDataState.IsReady;
	}
	get ComparisonReady(): boolean {
		return this.comparisonInitialized && this.comparison !== null;
	}
	get Comparison(): Comparison | null {
		return this.comparison;
	}

	@Action({
		rawError: true,
	}) async selectAssessment({ assessmentId }: { assessmentId: string }): Promise<void> {
		const assessment = this.AssessmentList.find(a => a.id === assessmentId) ?? null;
		if (assessment !== null) {
			this.context.commit(ComparativeDataV2Mutations.SELECT_ASSESSMENT, { assessment });
		}
	}
	@Mutation [ComparativeDataV2Mutations.SELECT_ASSESSMENT]({ assessment }: { assessment: AthleteAssessmentDataModel }): void {
		this.assessment = assessment;
		this.athleteId = assessment.athleteId;
	}

	@Action({}) async reset(): Promise<void> {
		this.context.commit(Mutations.RESET);
	}
	@Mutation [Mutations.RESET](): void {
		this.spiderChartState = new ComparativeDataPageState("Initial");
		this.athleteId = null;
		this.assessment = null;
		this.spiderChartFilters = [];
		this.spiderChartData = [
			{
				values: [0, 0, 0, 0, 0],
				color: 'baColorLightBlue',
				borderColor: 'baColorDeepBlue',
				name: ''
			}
		];
		this.spiderChartLowSampleSize = false;
		this.spiderChartAvailable = false;
		this.assessmentPagination = U16MData.assessmentPagination;

		this.lineChartState = new ComparativeDataPageState("Initial");
		this.lineChartPercentiles = [];
		this.comparativeDataState = new ComparativeDataPageState("Initial");
		this.compareGroups = [];
		this.statistics = [];
		this.comparisonLoading = false;
		this.comparisonInitialized = false;
		this.comparison = null;
	}

	@Action({}) async setupSampleData({ gender }: { gender: Gender }): Promise<void>{
		if(gender.toLowerCase() === Gender.Male){
			this.context.commit(Mutations.SETUP_SAMPLE_DATA_MALE);
		}else{
			this.context.commit(Mutations.SETUP_SAMPLE_DATA_FEMALE);
		}
	}

	@Mutation [Mutations.SETUP_SAMPLE_DATA_MALE](): void {
		this.athleteId = null;
		this.assessment = null;
		this.spiderChartFilters = U16MData.spiderChartFilters;
		this.spiderChartData = U16MData.spiderChartData;
		this.spiderChartLowSampleSize = false;
		this.spiderChartAvailable = true;
		this.assessmentPagination = U16MData.assessmentPagination;
		const [assessment] = U16MData.assessmentPagination.docs;
		this.athleteId = assessment.athleteId;
		this.assessment = assessment;

		this.lineChartPercentiles = U16MData.lineChartPercentiles;
		this.compareGroups = [];
		this.statistics = U16MData.statistics;
		this.comparisonLoading = false;
		this.comparisonInitialized = false;
		this.comparison = null;
		this.spiderChartState = new ComparativeDataPageState("Ready");
		this.lineChartState = new ComparativeDataPageState("Ready");
		this.comparativeDataState = new ComparativeDataPageState("Ready");
	}
	@Mutation [Mutations.SETUP_SAMPLE_DATA_FEMALE](): void {
		this.athleteId = null;
		this.assessment = null;
		this.spiderChartFilters = U16FData.spiderChartFilters;
		this.spiderChartData = U16FData.spiderChartData;
		this.spiderChartLowSampleSize = false;
		this.spiderChartAvailable = true;
		this.assessmentPagination = U16FData.assessmentPagination;
		const [assessment] = U16FData.assessmentPagination.docs;
		this.athleteId = assessment.athleteId;
		this.assessment = assessment;

		this.lineChartPercentiles = U16FData.lineChartPercentiles;
		this.compareGroups = [];
		this.statistics = U16FData.statistics;
		this.comparisonLoading = false;
		this.comparisonInitialized = false;
		this.comparison = null;
		this.spiderChartState = new ComparativeDataPageState("Ready");
		this.lineChartState = new ComparativeDataPageState("Ready");
		this.comparativeDataState = new ComparativeDataPageState("Ready");
	}
	
}
