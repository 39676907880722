
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
	VuetifyMixin,
} from "@/mixins";
@Component
export default class SectionHeading extends Mixins(
	VuetifyMixin,
) {
  @Prop({default: 'baColorDeepBlueText--text', required: false, type: String}) color: string;
  @Prop({default: '20px', required: false, type: String}) size: string;
}
