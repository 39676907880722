
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiUploadOutline, mdiCloudUploadOutline } from '@mdi/js';

import { VuetifyMixin } from '../../mixins/VuetifyMixin';
import FormAddArea from './FormAddArea.vue';
import {fileApi} from '../../api/FileApi';
import { UploadResponse } from '../utilities/upload/UploadDialog.vue';

@Component({
	components: { FormAddArea },
})
export default class FileUploadArea extends Mixins(VuetifyMixin){
	mdiUploadOutline = mdiUploadOutline;
	mdiCloudUploadOutline = mdiCloudUploadOutline

	@Prop({ required: true }) prefix: string;
	@Prop({ default: 140 }) height: number;
	@Prop() value: string;
	@Prop({default: () => ['image']}) allowedMimeTypes: string[];

	input(value: string){
		this.$emit('input', value);
	}

	isHovered = false;
	file: File | null = null;
	imagePreview: string | null = null;
	fileReader = new FileReader();
	uploading: boolean = false;
	progress: number = 0;

	dragover(): void { this.isHovered = true; }
	dragleave(): void { this.isHovered = false; }

	isAllowedMimetype(allowedMIMETypes: string[], givenMIMEType: string): boolean{
		return allowedMIMETypes.findIndex(allowed => givenMIMEType.includes(allowed)) !== -1;
	}
	previewImage(file): void{
		this.fileReader.onload = ($event: any) => {
			this.imagePreview = $event.target.result;
		}
		this.fileReader.readAsDataURL(file);
	}
	get imagePreviewStyle(): Record<string, any>{
		return {
			'max-height': `${this.height}px`,
		};
	}

	handleFileChange($event: any): void{
		this.isHovered = false;
		this.imagePreview = null;
		const files: FileList = $event.target.files;
		this.file = files.item(0);
		if (this.isAllowedMimetype(['image'], this.file.type)) {
			this.previewImage(this.file);
		}
		if(this.isAllowedMimetype(this.allowedMimeTypes, this.file.type)){
			this.uploadFile(this.file);
		}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.input(this.file);
		// Reset the file upload
		$event.target.value = ''
	}

	async uploadFile(file: File): Promise<void> {
		try {
			const result = await fileApi.upload(file, this.prefix, event => {
				this.progress = Math.round((100 * event.loaded) / event.total);
			});

			this.imagePreview = result.url;
			this.$emit('uploaded', {file, key: result.key, url: result.url} as UploadResponse);
			this.input(result.url);

		} catch (error) {
			console.error('File Upload Error', error);
		} finally {
			this.uploading = false;
		}
	}
}
