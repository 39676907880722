import { AccessControlledModel } from '../AccessControlledModel';

export class MarkupTagClipMuxModel extends AccessControlledModel {

	teamEventParentId: string = "";
	teamParentId: string = "";

	fromMuxSourceVideoId: string = "";
	fromMuxVideoPlaybackId: string = "";
	fromMuxVideoPlaybackUrl: string = "";
	fromMuxVideoAssetId: string = "";

	dateTagCreated: Date = new Date();

	// should probably change these to clipName, clipStart etc. at some point.
	markName: string = '';
	markStart: number = 0.0;
	markDuration: number = 0.0;
	markType: string = "";
	markText: string = "";
	markColor: string = "";

	additionalText: string = "";

	tagKeywords: string[] = [];

	voiceNoteUrl: string = "";

	createdByUserUid: string = "";

	screenShotUrl: string = "";
	screenShotFullName: string = '';

	load(obj: Record<string, any>): this{
		Object.assign(this, obj);
		this.dateTagCreated = obj['dateTagCreated'] ? new Date(obj['dateTagCreated']) : new Date();
		return this;
	}
}