import { Currency } from '@/../types/enums/currency';
import { FrontEndModel } from '../FrontEndModel';

export class CustomerModel extends FrontEndModel {
	parentId: string;
	stripeCustomerId: string;
	name: string;
	email: string;
	phone: string;
	currency: Currency = Currency.CAD;
	country: string;
}