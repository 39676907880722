import Vue from 'vue';
import { discussionApi, DiscussionApi } from '@/api/DiscussionApi';
import { CommentModel } from "@/models/discussion/CommentModel";
import { find } from 'lodash';
import {DiscussionModel} from "@/models/discussion/DiscussionModel";

const getDefaultDiscussion = () => {
	return {
		id: 'new',
		comments: [],
		createdAt: null
	}
};

// Default state
const getDefaultState = () => {
	return {
		allIds: [],
		byId: {}
	}
};

// initial state
const state = getDefaultState();

const getters = {

	defaultDiscussion: () => getDefaultDiscussion(),

	// Return a single discussion with the given id.
	find: (state) => (id) => {
		// console.debug('store/modules/discussions.getters.find(id)', id)
		return state.byId[id]
	},

	// Return a list of discussions in the order of `allIds`.
	list: (state, getters) => {
		// console.debug('store/modules/discussions.getters.list')
		return state.allIds.map(id => getters.find(id));
	},
};

const actions = {

	create: async ({ commit }, discussion) => {
		// console.debug('store/modules/discussions.actions.create(discussion)', discussion);
		discussion = await discussionApi.save(discussion);
		commit('add', discussion);
		return discussion;
	},

	load: async ({ commit }) => {
		// load all teams (for now)
		// console.debug('store/modules/discussions.actions.load')
		const discussions = await discussionApi.getAll();

		discussions.forEach(async (discussion) => {
			// console.debug('store/modules/discussions.actions.load.item', discussion)
			commit('add', discussion);
		});
	},

	loadById: async ({ commit }, id) => {
		// load all teams (for now)
		// console.debug('store/modules/discussions.actions.loadById(id)', id)
		try {
			const discussion = await discussionApi.findById(id);
			//console.log('got discussion')
			commit('add', discussion);
			return discussion
		} catch (err) {
			//discussion = this.getEmptyDiscussion();
			console.error('Could not get discussion with ID' , id)
		}
		return false
	},

	loadMulti: async ({ commit, getters }, filters) => {
		try {
			const items = await discussionApi.list(filters);
			commit('clear');
			items.forEach(item => {
				item.comments.map(comment => new CommentModel().load(comment));
				commit('add', item);
			});
		} catch (e) {
			console.error('crud.loadMulti: exception', e);
		}
		return getters.list;
	},

	add: ({commit}, discussion) => {
		const discussionModel = new DiscussionModel();
		discussionModel.load(discussion);
		console.log('add action', discussionModel);
		if (typeof discussionModel.comments === 'undefined') {
			discussionModel.comments = [];
		}
		commit('add', discussionModel);
		return discussionModel;
	},

	addComment: ({commit, getters}, {discussionId, comment}) => {
		const discussion = getters.find(discussionId);
		comment.id = new Date().valueOf();
		discussion.comments.push(comment);
		commit('add', discussion);
		return comment;
	},

	update: async ({commit, getters}, payload) => {
		commit('update', payload);
		return getters.find(payload.id);
	},

	persist: async ({commit, getters, state}, id) => {
		let item = getters.find(id);

		item = await discussionApi.save(item);
		// if we just saved a new item, replace the local id with the one we received from the backend
		if (id !== item.id) {
			commit('remove', {discussionId: id});
		}
		commit('add', item);
		return item;
	},

	persistComment: async ({commit, getters, state}, {discussionId, commentId}) => {
		let discussion = getters.find(discussionId);
		let comment = find(discussion.comments, {id: commentId});
		comment = await DiscussionApi.commentApi.save(comment);
		let commentIndex = discussion.comments.find(c => c.id === comment.id);
		discussion.comments.splice(commentIndex, 1, comment);
		console.log("discussion", { discussion });
		discussion = await discussionApi.save(discussion);
		const newCommentId = discussion.id;
		if (newCommentId !== commentId) {
			commit('removeComment', {discussionId: discussionId, commentId: commentId});
		}
		commit('add', discussion);
		return comment;
	},

	save: async ({commit}, discussion) => {
		commit('add', discussion);
		return discussion
	},

	saveAndPersist: async ({ dispatch, getters }, discussion) => {
		discussion = await dispatch('save', discussion);
		discussion = await dispatch('persist', discussion.id);
		return getters.find(discussion.id);
	},

};

const mutations = {
	add: (state, item) => {
		// console.debug('store/modules/discussions.mutations.add(state, item)', state, item)
		Vue.set(state.byId, item.id, item);
		if (state.allIds.includes(item.id)) return;
		state.allIds.push(item.id);
	},
	update: (state, item) => {
		// console.debug('store/modules/discussions.mutations.update', item)
		Vue.set(state.byId[item.id], item.key, item.value);
	},
	remove: (state, id) => {
		state.allIds.splice(state.allIds.indexOf(id), 1);
		Vue.delete(state.byId, id);
	},
	removeComment: (state, {discussionId, commentId}) => {
		console.log('removing ', discussionId, commentId);
		//state.allIds.splice(state.allIds[discussionId].indexOf(commentId), 1);
		Vue.delete(state.byId[discussionId].comments, commentId);
	}
};

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
}
