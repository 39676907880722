import { CrudApi } from './CrudApi';
import { UserModel } from '../models/user/UserModel';
import { AxiosRequestConfig } from 'axios';
import { RoleName } from '@/../types/enums';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { CoachProfileModel } from '@/models/coach/CoachProfileModel';
import { UserMetadata } from '@/../types/interfaces';
import { UserSubscriptions } from '@/../types/interfaces/UserSubscriptions';
import { SubscriptionModel } from '@/models/stripe/SubscriptionModel';

class UserApi{
	protected resource = 'user';
	protected create = (obj) => new UserModel().load(obj)

	async loadUser(config?: AxiosRequestConfig): Promise<UserModel | null> {
		try {
			const obj = await CrudApi.Api((c) => c.get(`/${this.resource}`, {
				...config,
				withCredentials: true,
			}));
			return this.create(obj);
		} catch (e) {
			if (e.response.status === 404) {
				return null;
			}
			throw e;
		}
	}
	async findByAuthId(id: string, config?: AxiosRequestConfig): Promise<UserModel | null> {
		try {
			const obj = await CrudApi.Api((c) => c.get(`/${this.resource}/auth/${id}`, {
				...config,
				withCredentials: true,
			}));
			return this.create(obj);
		} catch (e) {
			if (e.response.status === 404) {
				return null;
			}
			throw e;
		}
	}
	async addAthleteProfile(athlete: AthleteProfileModel, config?: AxiosRequestConfig): Promise<AthleteProfileModel> {
		const obj = await CrudApi.Api((c) => c.post(`/${this.resource}/athlete`, { athlete }, config));
		return new AthleteProfileModel().load(obj);
	}
	async addCoachProfile(coach: CoachProfileModel, config?: AxiosRequestConfig): Promise<CoachProfileModel> {
		const obj = await CrudApi.Api((c) => c.post(`/${this.resource}/coach`, { coach }, config));
		return new CoachProfileModel().load(obj);
	}
	async updateUserMetadata(user_metadata: UserMetadata, config?: AxiosRequestConfig): Promise<UserMetadata> {
		const response = await CrudApi.Api((c) => c.put(`/${this.resource}/userMetadata`, user_metadata, config));
		return response
	}
	async addUserRoles(roles: RoleName[], config?: AxiosRequestConfig): Promise<UserModel> {
		const obj = await CrudApi.Api((c) => c.put(`/${this.resource}/roles`, { roles } ,config));
		return this.create(obj);
	}
	async removeUserRoles(roles: RoleName[], config?: AxiosRequestConfig): Promise<UserModel> {
		const obj = await CrudApi.Api((c) => c.put(`/${this.resource}/roles`, { roles } ,config));
		return this.create(obj);
	}


	async parentUpgradeComplete(config?: AxiosRequestConfig): Promise<UserMetadata | undefined> {
		const result = await CrudApi.Api((c) => c.post(`/${this.resource}/parentUpgradeComplete`, {} ,config));
		return result.app_metadata;
	}

	async getUserSubscriptions(config?: AxiosRequestConfig): Promise<UserSubscriptions<SubscriptionModel>> {
		const result: UserSubscriptions<any> = await CrudApi.Api((c) => c.get(`/${this.resource}/subscriptions`, config));
		result.athleteProfiles.subscriptions = result.athleteProfiles.subscriptions.map(sub => new SubscriptionModel().load(sub));
		return result;
	}

	async resendVerificationEmail(): Promise<void> {
		await CrudApi.Api((c) => c.post(`/${this.resource}/email-verification/resend`));
	}

	async isOAuthAccount(config?: AxiosRequestConfig): Promise<boolean> {
		return CrudApi.Api(c => c.post(`/${this.resource}/is-oauth-account`, undefined, config))
	}

	async verifyPassword(password: string, config?: AxiosRequestConfig): Promise<boolean> {
		return CrudApi.Api(c => c.post(`/${this.resource}/verify-password`, { password }, config))
	}

	async changePassword(oldPassword: string, newPassword: string, config?: AxiosRequestConfig): Promise<boolean> {
		return CrudApi.Api(c => c.post(`/${this.resource}/change-password`, { oldPassword, newPassword }, config))
	}

	async requestPasswordEmail(config?: AxiosRequestConfig): Promise<boolean> {
		return CrudApi.Api(c => c.post(`/${this.resource}/change-password-via-email`, undefined, config))
	}

	async acceptTermsOfService(tosAccepted: boolean): Promise<void> {
		await CrudApi.Api((c) => c.post(`/${this.resource}/terms-of-service/accept`, { tosAccepted }));
	}
}
export const userApi = new UserApi();