import { CrudApi } from './CrudApi';
import { DiscussionModel } from '@/models/discussion/DiscussionModel';
import { CommentModel } from '@/models/discussion/CommentModel';
import { CommentCreatedEvents } from '@/../types/interfaces/CommentCreateMetadata';
import { DomainObjectChangeOptions } from 'types/interfaces';

class CommentApi extends CrudApi<CommentModel>{
	constructor() {
		super('discussion/comment', (obj) => new CommentModel().load(obj));
	}
}

export class DiscussionApi extends CrudApi<DiscussionModel> {
	constructor() {
		super('discussion', (obj) => new DiscussionModel().load(obj));
	}
	async addComment({comment,discussionId,meta}: { discussionId: string, comment: CommentModel, meta: CommentCreatedEvents & DomainObjectChangeOptions }): Promise<CommentModel> {
		const savedComment = await CrudApi.Api(c => c.post(`/${this.resource}/${discussionId}/addComment`, { comment, meta }));
		return new CommentModel().load(savedComment);
	}
	static commentApi = new CommentApi();
}

export const discussionApi = new DiscussionApi();