/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { CourseModel, RubricModel, AssignmentModel } from '../../models/course';
import { courseApi } from '@/api/CourseApi';
import { MarkupTagClipMuxModel } from '../../models/markup-tag-clip-mux/MarkupTagClipMuxModel';
import { PaginatedResponse, QueryOptions, RepositoryQuery } from 'types/interfaces';
import { OrganizationModel } from '@/models/organization/OrganizationModel';

export const Mutations = {
	LOAD_COURSE: 'LOAD_COURSE',
	LOAD_COURSE_SUCCESS: 'LOAD_COURSE_SUCCESS',
	LOAD_COURSE_FAILURE: 'LOAD_COURSE_FAILURE',

	QUERY_ALL_COURSES: 'QUERY_ALL_COURSES',
	QUERY_ALL_COURSES_SUCCESS: 'QUERY_ALL_COURSES_SUCCESS',
	QUERY_ALL_COURSES_FAILURE: 'QUERY_ALL_COURSES_FAILURE',

	SET_COACH_ID: 'SET_COACH_ID',
	LOAD_MY_COURSES: 'LOAD_MY_COURSES',
	LOAD_MY_COURSES_SUCCESS: 'LOAD_MY_COURSES_SUCCESS',
	LOAD_MY_COURSES_FAILURE: 'LOAD_MY_COURSES_FAILURE',

	LOAD_RUBRICS: 'LOAD_RUBRICS',
	LOAD_RUBRICS_SUCCESS: 'LOAD_RUBRICS_SUCCESS',
	LOAD_RUBRICS_FAILURE: 'LOAD_RUBRICS_FAILURE',

	CREATE_ASSIGNMENT: 'CREATE_ASSIGNMENT',
	CREATE_ASSIGNMENT_SUCCESS: 'CREATE_ASSIGNMENT_SUCCESS',
	CREATE_ASSIGNMENT_FAILURE: 'CREATE_ASSIGNMENT_FAILURE',

	ADD_COURSE: 'ADD_COURSE',
	REMOVE_COURSE: 'REMOVE_COURSE',
	
	UPDATE_A_COURSE: 'UPDATE_A_COURSE',
	UPDATE_A_COURSE_SUCCESS: 'UPDATE_A_COURSE_SUCCESS',
	UPDATE_A_COURSE_FAILURE: 'UPDATE_A_COURSE_FAILURE',

	DELETE_COURSE: 'DELETE_COURSE',
	DELETE_COURSE_SUCCESS: 'DELETE_COURSE_SUCCESS',
	DELETE_COURSE_FAILURE: 'DELETE_COURSE_FAILURE',
}

const name = 'CoursesStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class CoursesModule extends VuexModule {

	totalCourses: number = 0;
	allCoursesQueryPages: number = 0;
	allCoursesQueryTotal: number = 0;
	allCoursesInitialized: boolean = false;
	allCoursesLoading: boolean = false;
	allCourses: CourseModel[] = [];

	@Action({
		rawError: true
	})
	async queryAllCourses({ query, options }: {
		query?: RepositoryQuery<OrganizationModel>, options?: QueryOptions
	} = {}): Promise<void> {
		this.context.commit(Mutations.QUERY_ALL_COURSES);
		try {
			const [{ count }, response] = await Promise.all([
				courseApi.countAll(),
				courseApi.queryAll(
					{
						fields: ['name'],
						...query
					}, options,
				),
			]);
			this.context.commit(Mutations.QUERY_ALL_COURSES_SUCCESS, {
				count, response
			});
		} catch (e) {
			console.error("Failed to Load Course", e);
			this.context.commit(Mutations.QUERY_ALL_COURSES_FAILURE, e);
		}
	}

	@Mutation [Mutations.QUERY_ALL_COURSES](): void {
		this.allCoursesLoading = true;
	}
	@Mutation [Mutations.QUERY_ALL_COURSES_SUCCESS]({ count, response }: { count: number, response: PaginatedResponse<CourseModel> }): void {
		this.allCoursesLoading = false;
		this.allCoursesInitialized = true;
		this.totalCourses = count;
		this.allCoursesQueryPages = response.totalPages;
		this.allCoursesQueryTotal = response.total;
		this.allCourses = response.docs;
	}
	@Mutation [Mutations.QUERY_ALL_COURSES_FAILURE](error: any): void {
		this.allCoursesLoading = false;
	}


	myCoursesInitialized: boolean = false;
	myCoursesLoading: boolean = false;
	myCourses: CourseModel[] = [];

	currentCoachId: string = "";
	/** Filter out students courses client-side. This isn't a common case - it can be improved later if this feature gets more use */
	get MyCoursesSideNav(): CourseModel[]{
		return this.myCourses.filter(course => course.staffMembers.find(s => s.coachId === this.currentCoachId) !== undefined);
	}

	@Action({
		rawError: true
	})
	async loadMyCourses(coachId: string): Promise<void> {
		this.context.commit(Mutations.LOAD_MY_COURSES);
		try {
			const courses = await courseApi.findAllWithAccess();
			this.context.commit(Mutations.SET_COACH_ID, coachId);
			this.context.commit(Mutations.LOAD_MY_COURSES_SUCCESS, courses);
		} catch (e) {
			console.error("Failed to Load Course", e);
			this.context.commit(Mutations.LOAD_MY_COURSES_FAILURE, e);
		}
	}

	@Mutation [Mutations.SET_COACH_ID](coachId: string): void {
		this.currentCoachId = coachId;
	}
	@Mutation [Mutations.LOAD_MY_COURSES](): void {
		this.myCoursesLoading = true;
	}
	@Mutation [Mutations.LOAD_MY_COURSES_SUCCESS](courses: CourseModel[]): void {
		this.myCoursesLoading = false;
		this.myCourses = courses;
		this.myCoursesInitialized = true;
	}
	@Mutation [Mutations.LOAD_MY_COURSES_FAILURE](error: any): void {
		this.myCoursesLoading = false;
	}

	deleteCourseLoading: boolean = false;
	@Action({
		rawError: true
	})
	async deleteCourse(course: CourseModel): Promise<CourseModel | null> {
		this.context.commit(Mutations.DELETE_COURSE);
		try {
			await courseApi.delete(course);
			this.context.commit(Mutations.REMOVE_COURSE, course);
			this.context.commit(Mutations.DELETE_COURSE_SUCCESS);
			return course;
		} catch (e) {
			console.error("Failed to Load Course", e);
			this.context.commit(Mutations.DELETE_COURSE_FAILURE, e);
			return null;
		}
	}
	@Mutation [Mutations.DELETE_COURSE]() {
		this.deleteCourseLoading = true;
	}
	@Mutation [Mutations.DELETE_COURSE_SUCCESS]() {
		this.deleteCourseLoading = false;
	}
	@Mutation [Mutations.DELETE_COURSE_FAILURE](error: any) {
		this.deleteCourseLoading = false;
	}

	loadCourseLoading: boolean = false;
	@Action({
		rawError: true
	})
	async getCourseById(courseId: string): Promise<CourseModel | null> {
		this.context.commit(Mutations.LOAD_COURSE);
		try {
			const course = await courseApi.findById(courseId, { populate: [ 'studentRefs' ] });
			this.context.commit(Mutations.ADD_COURSE, course);
			this.context.commit(Mutations.LOAD_COURSE_SUCCESS, course);
			return course;
		} catch (e) {
			console.error("Failed to Load Course", e);
			this.context.commit(Mutations.LOAD_COURSE_FAILURE, e);
			return null;
		}
	}
	@Mutation [Mutations.LOAD_COURSE]() {
		this.loadCourseLoading = true;
	}
	@Mutation [Mutations.LOAD_COURSE_SUCCESS](course: CourseModel) {
		this.loadCourseLoading = false;
	}
	@Mutation [Mutations.LOAD_COURSE_FAILURE](error: any) {
		this.loadCourseLoading = false;
	}

	rubricsInitialized: boolean = false;
	rubricsLoading: boolean = false;
	rubrics: RubricModel[] = [];

	@Action({
		rawError: true
	})
	async loadRubrics() {
		this.context.commit(Mutations.LOAD_RUBRICS);
		try {
			const rubrics = await courseApi.findAllRubrics();
			this.context.commit(Mutations.LOAD_RUBRICS_SUCCESS, rubrics);
		} catch (e) {
			console.error("Failed to Create Course", e);
			this.context.commit(Mutations.LOAD_RUBRICS_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_RUBRICS]() {
		this.rubricsLoading = true;
	}
	@Mutation [Mutations.LOAD_RUBRICS_SUCCESS](rubrics: RubricModel[]) {
		this.rubricsLoading = false;
		this.rubrics = rubrics;
		this.rubricsInitialized = true;
	}
	@Mutation [Mutations.LOAD_RUBRICS_FAILURE](error: any) {
		this.rubricsLoading = false;
	}

	@Mutation [Mutations.ADD_COURSE](course: CourseModel) {
		const courseIndex = this.myCourses.findIndex(c => c.id === course.id);
		if (courseIndex > -1) {
			this.myCourses.splice(courseIndex, 1, course);
		} else {
			this.myCourses.push(course)
		}
	}
	@Mutation [Mutations.REMOVE_COURSE](course: CourseModel) {
		const courseIndex = this.myCourses.findIndex(c => c.id === course.id);
		if (courseIndex > -1) {
			this.myCourses.splice(courseIndex, 1);
		}
	}

	createAssignmentLoading: boolean = false;
	createAssignmentError: string | null = null;
	@Action({
		rawError: true
	})
	async createAssignment({ courseId, assignment, studentIds } :{
		courseId: string,
		assignment: AssignmentModel,
		studentIds: string[],
	}) {
		this.context.commit(Mutations.CREATE_ASSIGNMENT);
		try {
			const course = await courseApi.assignToStudents({
				courseId,
				assignment,
				studentIds,
			});
			this.context.commit(Mutations.CREATE_ASSIGNMENT_SUCCESS, course);
		} catch (e) {
			console.error("Failed to Load Course", e);
			this.context.commit(Mutations.CREATE_ASSIGNMENT_FAILURE, e);
		}
	}
	@Mutation [Mutations.CREATE_ASSIGNMENT](){
		this.createAssignmentLoading = true;
		this.createAssignmentError = null;
	}
	@Mutation [Mutations.CREATE_ASSIGNMENT_SUCCESS](course: CourseModel){
		this.createAssignmentLoading = false;
		const courseIndex = this.myCourses.findIndex(c => c.id === course.id);
		if(courseIndex > -1){
			this.myCourses.splice(courseIndex, 1, course);
		}else{
			this.myCourses.push(course);
		}
	}
	@Mutation [Mutations.CREATE_ASSIGNMENT_FAILURE](errorMessage: string){
		this.createAssignmentError = errorMessage;
	}
	


	updatingCourse: boolean = false;

	/** 
		* Add Update Clip in Students Course
		*/
	@Action({
		rawError: true
	})
	async addUpdateClipInAssignment({ theClip, courseId, studentId, assignmentId }: { theClip: MarkupTagClipMuxModel, assignmentId: string, courseId: string, studentId: string }) {
		this.context.commit(Mutations.UPDATE_A_COURSE);
		// console.log('-----------------------------------------');
		// console.log('In the addUpdateClipInAssignment store action');
		// console.log('theClip is: ', theClip);
		// console.log('courseId is: ', courseId);
		// console.log('studentId is: ', studentId);
		// console.log('assignmentId is: ', assignmentId);
		// console.log('-----------------------------------------');
		try {
			const tempCourse = this.myCourses.find(course => course.id === courseId);
			const studentIndex = tempCourse.students.findIndex(student => student.coachProfileId === studentId);
			const assignmentIndex = tempCourse.students[studentIndex].assignments.findIndex(asmnt => asmnt.id === assignmentId);

			// see if the Clip exists
			const existingIndex = tempCourse.students[studentIndex].assignments[assignmentIndex].sourceVideoClips.findIndex(clp => clp.id === theClip.id);
			if (existingIndex >= 0) {
				tempCourse.students[studentIndex].assignments[assignmentIndex].sourceVideoClips.splice(existingIndex, 1, theClip);
			} else {
				tempCourse.students[studentIndex].assignments[assignmentIndex].sourceVideoClips.push(theClip);
			}

			const updatedCourse = await courseApi.updateAssignmentForStudent({ courseId: courseId, studentId: studentId, assignment: tempCourse.students[studentIndex].assignments[assignmentIndex] });

			this.context.commit(Mutations.UPDATE_A_COURSE_SUCCESS, updatedCourse);
			return updatedCourse;

		} catch (e) {
			console.error("Failed to update status of Assignment in Course", e);
			this.context.commit(Mutations.UPDATE_A_COURSE_FAILURE, e);
		}
	}

	@Mutation [Mutations.UPDATE_A_COURSE]() {
		this.updatingCourse = true;
	}
	@Mutation [Mutations.UPDATE_A_COURSE_SUCCESS](theUpdate: CourseModel) {
		const courseIndex = this.myCourses.findIndex(course => course.id === theUpdate.id)
		this.myCourses.splice(courseIndex, 1, theUpdate)
		this.updatingCourse = false;
	}
	@Mutation [Mutations.UPDATE_A_COURSE_FAILURE](error: any) {
		this.updatingCourse = false;
	}



	/** 
		* Update an Assignment for a particular Student in a Course
		*/
	@Action({
		rawError: true
	})
	async updateAssignmentForStudent({ updatedAssignment, courseId, studentId }: { updatedAssignment: AssignmentModel, courseId: string, studentId: string }) {
		this.context.commit(Mutations.UPDATE_A_COURSE);
		// console.log('-----------------------------------------');
		// console.log('In the updateAssignmentForStudent store action');
		// console.log('updatedAssignment is: ', updatedAssignment);
		// console.log('courseId is: ', courseId);
		// console.log('studentId is: ', studentId);
		// console.log('-----------------------------------------');
		try {
			const tempCourse = this.myCourses.find(course => course.id === courseId);
			const studentIndex = tempCourse.students.findIndex(student => student.coachProfileId === studentId);
			const assignmentIndex = tempCourse.students[studentIndex].assignments.findIndex(asmnt => asmnt.id === updatedAssignment.id);

			let updatedCourse: CourseModel;
			if (assignmentIndex >= 0) {
				updatedCourse = await courseApi.updateAssignmentForStudent({ courseId: courseId, studentId: studentId, assignment: updatedAssignment });
				this.context.commit(Mutations.UPDATE_A_COURSE_SUCCESS, updatedCourse);
				return updatedCourse;
			} else {
				console.log("!! The assignement ID not found in course: " + courseId + ", for student with Coach Profile Id: " + studentId);
				throw new Error("The Assignment Id was not found in the course");
			}

		} catch (e) {
			console.error("Failed to update status of Assignment in Course", e);
			this.context.commit(Mutations.UPDATE_A_COURSE_FAILURE, e);
		}
	}

}
