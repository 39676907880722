import { FrontEndModel } from '../FrontEndModel';
import { RRule, Options as RRuleOptions, Weekday, Frequency } from 'rrule';
import { RecurrenceRuleFrequency } from '@/../types/enums/recurrence-rule-frequency';
import { RecurrenceRuleWeekday, RecurrenceRuleWeekdayValues } from '@/../types/enums/recurrence-rule-weekday';
import { RecurrenceRuleMonth } from '@/../types/enums/recurrence-rule-month';
import { capitalize } from 'lodash';

/**
 * Recurrence rule based on ICS Recurrence Rules definition: https://icalendar.org/iCalendar-RFC-5545/3-8-5-3-recurrence-rule.html
 */
export class RecurrenceRuleModel extends FrontEndModel {
	constructor(freq: RecurrenceRuleFrequency) {
		super();
		this.frequency = freq;
	}

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (obj['startDate']) this['startDate'] = new Date(obj['startDate']);
		if (obj['until'] && obj['until'] !== null) this['until'] = new Date(obj['until']);
		return this;
	}

	/** Should usually match the event's start date */
	startDate!: Date;
	/** Default: Monday - Start of week. Changing this will affect computed occurences. (WKST) in recurrence rule*/
	weekStart: RecurrenceRuleWeekday = RecurrenceRuleWeekday.Monday;
	/** Optional: How many total occurences */
	count: number | null = null;
	/** Optional: Spacing based on `frequency` attribute */
	interval: number | null = null;
	/** Optional: Daily, Weekly, etc. */
	frequency: RecurrenceRuleFrequency;
	/** Optional: Which weekdays to occur on (BYDAY) in recurrence rule. Can be combined with `months` */
	weekdays: RecurrenceRuleWeekday[] | null = null;
	/** Optional: Number prefix for BYDAY. ex; 2MO = 2nd Monday. Not 100% sure how this will work */
	nthWeekdays: number | null = null;
	/** Optional: Which months to occur on (BYMONTH) in recurrence rule. Can be combined with `weekdays` */
	months: RecurrenceRuleMonth[] | null = null;
	/** Optional: Which day of month (BYMONTHDAY) in recurrence rule negative values allowed for last days of month */
	monthDays: number[] | null = null;
	/** Optional: Which day of year (BYYEARDAY) in recurrence rule */
	yearDays: number[] | null = null;
	/**
	 * Optional: Which instance of the occurence to choose (BYSETPOS). This one is a bit hard to understand but if you want the 2nd last weekday of the month,
	 * You would provide all the weekdays for BYDAY with a FREQ of MONTHLY and a BYSETPOS of -2
	 * */
	setPosition: number | null = null;
	/** Optional: When the rule ends. Infinite if left out and count isn't defined. (UNTIL) in recurrence rule */
	until: Date | null = null;

	static WeeklyToday(start?: Date): RecurrenceRuleModel {
		const rule = new RecurrenceRuleModel(RecurrenceRuleFrequency.Weekly);
		if (start !== undefined && start !== null) {
			rule.startDate = start;
			rule.weekdays = [RecurrenceRuleWeekdayValues[start.getDay()]]
			return rule
		}
		rule.weekdays = [
			RecurrenceRuleWeekdayValues[new Date().getDay()],
		]
		return rule;
	}
	static MonthlyToday(start?: Date): RecurrenceRuleModel {
		const rule = new RecurrenceRuleModel(RecurrenceRuleFrequency.Monthly);
		if (start !== undefined && start !== null) {
			rule.startDate = start;
			rule.weekdays = [RecurrenceRuleWeekdayValues[start.getDay()]]
			return rule
		}
		rule.weekdays = [
			RecurrenceRuleWeekdayValues[new Date().getDay()],
		]
		return rule;
	}

	private getFrequency(): Frequency {
		switch (this.frequency) {
		case RecurrenceRuleFrequency.Minutely: return Frequency.MINUTELY;
		case RecurrenceRuleFrequency.Hourly: return Frequency.HOURLY;
		case RecurrenceRuleFrequency.Daily: return Frequency.DAILY;
		case RecurrenceRuleFrequency.Weekly: return Frequency.WEEKLY;
		case RecurrenceRuleFrequency.Monthly: return Frequency.MONTHLY;
		case RecurrenceRuleFrequency.Yearly: return Frequency.YEARLY;
		}
	}

	get RRule(): RRule {
		const opts: Partial<RRuleOptions> = {
			wkst: Weekday.fromStr(this.weekStart),
		};
		if (this.count !== null) opts['count'] = this.count;
		if (this.interval !== null) opts['interval'] = this.interval;
		if (this.startDate !== null && this.startDate !== undefined)
			opts['dtstart'] = new Date(Date.UTC(this.startDate.getUTCFullYear(), this.startDate.getUTCMonth(), this.startDate.getUTCDate()));
		if (this.until !== null) opts['until'] = new Date(Date.UTC(this.until.getUTCFullYear(), this.until.getUTCMonth(), this.until.getUTCDate()));
		if (this.frequency !== null) opts['freq'] = this.getFrequency();
		if (this.weekdays !== null) opts['byweekday'] = this.weekdays.map(w => Weekday.fromStr(w));
		if (this.monthDays !== null) opts['bymonthday'] = this.monthDays;
		if (this.months !== null) opts['bymonth'] = this.months;
		if (this.yearDays !== null) opts['byyearday'] = this.yearDays;
		return new RRule(opts);
	}

	get RRuleOptions(): Partial<RRuleOptions> {
		const opts: Partial<RRuleOptions> = {
			wkst: Weekday.fromStr(this.weekStart),
		};
		if (this.count !== null) opts['count'] = this.count;
		if (this.interval !== null) opts['interval'] = this.interval;
		if (this.startDate !== null) opts['dtstart'] = new Date(Date.UTC(this.startDate.getUTCFullYear(), this.startDate.getUTCMonth(), this.startDate.getUTCDate()));
		if (this.until !== null) opts['until'] = new Date(Date.UTC(this.until.getUTCFullYear(), this.until.getUTCMonth(), this.until.getUTCDate()));
		if (this.frequency !== null) opts['freq'] = this.getFrequency();
		if (this.weekdays !== null) opts['byweekday'] = this.weekdays.map(w => Weekday.fromStr(w));
		if (this.monthDays !== null) opts['bymonthday'] = this.monthDays;
		if (this.months !== null) opts['bymonth'] = this.months;
		if (this.yearDays !== null) opts['byyearday'] = this.yearDays;

		return opts;
	}

	toText(): string {
		return capitalize(this.RRule.toText());
	}

	isEqual(compareRule: RecurrenceRuleModel): boolean {
		// Check if all the properties match up
		if (Number(this.startDate) !== Number(compareRule.startDate) ||
			Number(this.until) !== Number(compareRule.until) ||
			this.weekStart !== compareRule.weekStart ||
			this.count !== compareRule.count ||
			this.interval !== compareRule.interval ||
			this.frequency !== compareRule.frequency ||
			this.nthWeekdays !== compareRule.nthWeekdays ||
			this.weekdays?.length !== compareRule.weekdays?.length ||
			this.months?.length !== compareRule.months?.length ||
			this.monthDays?.length !== compareRule.monthDays?.length ||
			this.yearDays?.length !== compareRule.yearDays?.length
		) {			
			return false
		}

		// Check if all the values in the array match up
		else{
			for (const key of ['weekdays', 'months', 'monthDays', 'yearDays']) {
				if (this[key] !== null) {
					for (let index = 0; index < this[key].length; index++) {
						if (this[key][index] !== this[key][index]) {
							return false
						}
					}
				}
			}
		}
		return true
	}
}